import FetchAxios from "../Fetch";

const ReservationData = [
    {
        id:"1",
        image:"https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        adress:"dldld ldmd ",
        date:"12-02-2022",
        time:"15h45",
        restaurant:"RAW CANVA"
    },
    {
        id:"2",
        image:"https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        adress:"dldld ldmd ",
        date:"12-02-2022",
        time:"15h45",
        restaurant:"RAW CANVA"
    },
    {
        id:"3",
        image:"https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        adress:"dldld ldmd ",
        date:"12-02-2022",
        time:"15h45",
        restaurant:"RAW CANVA"
    },
]

export const getPersonalInformaiton = (onDone)=>{
    return onDone(
        {
            firstname:"mmm",
            lastname:"llll",
            email:"lld@lld.md",
            phone:"06125684",
            city:"dmmld",
            country:"dldld"
        }
    );
    FetchAxios.get("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone(e.response);
        }
    });
}
export const EditPersonalInformaiton = (data,onDone)=>{
    return onDone();
    FetchAxios.post("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone();
        }
    });
    
}
/*********** UP COMING RESERVATION ***********/
export const getUpComing = (onDone)=>{
    return onDone(ReservationData);
    FetchAxios.get("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone(e.response);
        }
    });
}
export const removeUpComing = (data,onDone)=>{
    return onDone();
    FetchAxios.post("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone();
        }
    });
    
}

/*********** PAST RESERVATION ***********/
export const getPast = (onDone)=>{
    return onDone(ReservationData);
    FetchAxios.get("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone(e.response);
        }
    });
}
export const removePast = (data,onDone)=>{
    return onDone();
    FetchAxios.post("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone();
        }
    });
}
/*********** Favorite RESERVATION ***********/
export const getFavorite = (onDone)=>{
    return onDone(ReservationData);
    FetchAxios.get("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone(e.response);
        }
    });
}
export const removeFavorite = (data,onDone)=>{
    return onDone();
    FetchAxios.post("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone();
        }
    });
    
}