import Toast from "./Toast";
import axios from "axios";
import { ENV_SERVER } from "../env/ENV";

let token ="5454";
var FetchAxios = axios.create({
    baseURL: ENV_SERVER
  });
 
  
FetchAxios.interceptors.request.use(function (config) {
    return config;
  }, function (error) {
    return Promise.reject(error);
  });

FetchAxios.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    if (error?.response?.status === 404) {
      Toast.error(("Some thing Wrong"));
      }
    
    return Promise.reject(error);
  });

export default FetchAxios;
