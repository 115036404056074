import React, { useState } from "react";
import "../../../styles/client/Client.css";
import "../../../styles/client/ClientContact.css";
import DetailsContact from "./details";
import FormContact from "./form";
import SentMessage from "./sentMessage";


export default function Contact()
{
    const [sent,setSent]=useState(false);
    return (
        <React.Fragment>
            <div className="row m-4 mv-5p flex-side">
                <div className="col-sm-6 sid">
                    <DetailsContact />
                </div>
                <div className="col-sm-6 sid">
                    <div className="card-contact">
                        {sent&&<SentMessage/>}
                        {!sent&&<FormContact setSent={setSent}/>}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}