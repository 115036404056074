import { gql } from "@apollo/client";

export const CATEGORIES_QUERY = gql`
  query AllCategories($pageSize: Int, $page: Int,$search:String) {
    allCategories(pageSize: $pageSize, page: $page,search:$search) {
      page
      pages
      count
      hasNext
      objects {
        name
        id
      }
    }
  }
`;
