import React from 'react';
export const LogoTabla = ()=>
<div className="svg_logo">
    <svg width="182" height="55" viewBox="0 0 182 55" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.8 29.7156H10.6383V29.7195H15.8V29.7156Z" fill="#84C9BD"/>
        <path d="M54.0117 24.5185C52.634 10.7695 41.0771 0 27.0723 0C13.0712 0 1.51435 10.7695 0.136633 24.5185C0.0455443 25.4262 0 26.3454 0 27.276C0 28.0956 0.0379535 28.9114 0.10627 29.7156C1.26006 42.76 11.5682 53.1925 24.4914 54.4257C25.3416 54.5061 26.2031 54.5482 27.0723 54.5482C27.9452 54.5482 28.8029 54.5061 29.6531 54.4257C42.5725 53.1886 52.8845 42.7562 54.0383 29.7156C54.1104 28.9114 54.1445 28.0956 54.1445 27.276C54.1483 26.3454 54.099 25.4262 54.0117 24.5185ZM27.0723 5.2009C38.2268 5.2009 47.4609 13.6418 48.8121 24.5185H43.176C42.0753 17.5253 36.591 11.9797 29.6531 10.8422V8.90818H24.4914V10.8193C17.5004 11.9108 11.9554 17.4793 10.8395 24.5185H5.33627C6.68362 13.6418 15.9215 5.2009 27.0723 5.2009ZM24.4914 49.198C14.4186 47.9992 6.41035 39.8876 5.29832 29.7156H24.4914V49.198ZM16.1037 24.5185C17.2727 19.5398 21.717 15.8248 27.0077 15.8248C32.3061 15.8248 36.7542 19.5398 37.9194 24.5185H16.1037ZM29.6569 49.198V29.7156H48.85C47.7342 39.8915 39.7298 47.9992 29.6569 49.198Z" fill="#84C9BD"/>
        <path d="M43.3771 29.7156H38.2192V29.7195H43.3771V29.7156Z" fill="#84C9BD"/>
        <path d="M10.8395 24.5185C10.7066 25.3687 10.6345 26.2381 10.6345 27.1266V29.7194H5.29825C5.20717 28.919 5.16162 28.1032 5.16162 27.2798C5.16162 26.3454 5.21855 25.4262 5.33241 24.5224H10.8395V24.5185Z" fill="#43AB8C"/>
        <path d="M38.2192 27.1113V29.7194H29.6569V49.2018C28.8105 49.3013 27.949 49.355 27.076 49.355C26.2031 49.355 25.3416 49.3013 24.4952 49.2018V29.7156H15.8038V27.1228C15.8038 26.2266 15.9063 25.3534 16.1075 24.5147H37.9232C38.1167 25.3496 38.2192 26.219 38.2192 27.1113Z" fill="#43AB8C"/>
        <path d="M48.9866 27.276C48.9866 28.0994 48.941 28.9152 48.8499 29.7156H43.3808V27.1075C43.3808 26.2266 43.3125 25.3611 43.1797 24.5147H48.8158C48.9259 25.4224 48.9866 26.3415 48.9866 27.276Z" fill="#43AB8C"/>
        <path d="M15.8 29.7156H10.6383V29.7195H15.8V29.7156Z" fill="#532F88"/>
        <path d="M43.1797 24.5185H37.9193C36.7542 19.5398 32.306 15.8248 27.0077 15.8248C21.717 15.8248 17.2688 19.5398 16.1036 24.5185H10.8395C11.9553 17.4793 17.5003 11.9108 24.4914 10.8193V8.90817H29.653V10.8422C36.591 11.9797 42.079 17.5214 43.1797 24.5185Z" fill="#43AB8C"/>
        <path d="M43.3808 29.7156H38.2191V29.7195H43.3808V29.7156Z" fill="#532F88"/>
        <path d="M27.0722 0C13.0711 0 1.51428 10.7695 0.136564 24.5185H5.3362C6.68355 13.6418 15.9214 5.2009 27.076 5.2009C38.2305 5.2009 47.4646 13.6418 48.8158 24.5185H54.0116C52.6339 10.7695 41.0771 0 27.0722 0ZM48.8499 29.7156C47.7341 39.8876 39.7297 47.9992 29.6568 49.198V54.4295C42.58 53.1925 52.8882 42.76 54.042 29.7195H48.8499V29.7156ZM5.29825 29.7156H0.106201C1.25999 42.76 11.5682 53.1886 24.4914 54.4257V49.1941C14.4185 47.9992 6.41408 39.8876 5.29825 29.7156Z" fill="#43AB8C"/>
        <path d="M181.129 33.103C180.618 33.103 180.202 32.6833 180.202 32.1675V25.8168C180.202 22.8537 178.689 19.7137 174.74 19.7137C170.787 19.7137 167.655 21.0082 167.655 23.597C167.655 25.5235 169.629 25.4881 169.629 25.4881C172.36 25.4881 172.425 22.5099 172.425 22.5099C172.425 22.5099 172.49 20.8919 174.224 20.8919C175.958 20.8919 175.863 22.2166 175.863 23.1875C175.863 24.1583 175.863 25.2252 175.863 25.2252C175.863 25.2252 172.841 25.3212 169.309 27.5562C165.776 29.7911 166.417 34.3873 169.309 35.2266C172.2 36.0711 174.034 35.3227 175.863 33.8968C175.863 34.7665 176.564 35.439 177.391 35.439C177.481 35.439 177.572 35.434 177.662 35.4137L181.43 34.7362V33.0878H181.129V33.103ZM175.898 31.6619C175.898 31.6619 174.45 33.3255 172.606 32.5215C171.047 31.8389 171.629 29.1337 173.047 28.0517C174.49 26.9443 175.898 26.7117 175.898 26.7117V31.6619Z" fill="#43AB8C"/>
        <path d="M134.588 35.5906C136.124 35.5906 137.369 34.4565 137.369 33.0574C137.369 31.6583 136.124 30.5242 134.588 30.5242C133.052 30.5242 131.807 31.6583 131.807 33.0574C131.807 34.4565 133.052 35.5906 134.588 35.5906Z" fill="#43AB8C"/>
        <path d="M164.263 33.2445C164.077 33.0524 163.962 32.7945 163.962 32.5063V24.0318C163.962 24.0318 163.681 19.7188 159.487 19.7188C156.481 19.7188 154.622 22.1812 154.622 22.1812L154.291 22.5604C153.815 21.3115 152.713 19.7188 150.167 19.7188C147.161 19.7188 145.302 22.1812 145.302 22.1812V21.1042C145.302 20.371 144.66 19.8047 143.939 19.9058L139.83 20.462V22.2267C140.161 22.2267 140.456 22.3632 140.672 22.5756C140.887 22.793 141.017 23.0913 141.017 23.42V32.481C141.017 33.1434 140.486 33.6793 139.83 33.6793V35.3125H146.489V33.6793C146.159 33.6793 145.863 33.5428 145.647 33.3254C145.432 33.108 145.302 32.8097 145.302 32.481V24.4009C145.302 24.4009 146.795 21.802 148.694 22.6565C150.292 23.3745 150.162 24.7498 150.162 24.7498V32.6125C150.162 33.1889 149.696 33.6591 149.125 33.6591H149.06V35.3125H155.674V33.5479C155.389 33.5479 155.128 33.4316 154.938 33.2445C154.752 33.0524 154.637 32.7945 154.637 32.5063V24.3655C154.827 24.0571 156.24 21.8626 158.009 22.6565C159.608 23.3745 159.477 24.7498 159.477 24.7498V32.6125C159.477 33.1889 159.011 33.6591 158.44 33.6591H158.375V35.3125H164.989V33.5479C164.714 33.5529 164.453 33.4316 164.263 33.2445Z" fill="#43AB8C"/>
        <path d="M98.0043 19.7389C95.4338 19.7389 94.4867 21.3772 94.3164 21.721C94.2963 21.7615 94.2863 21.7867 94.2863 21.7867V17.0388C94.2863 16.9579 94.2963 16.8821 94.3164 16.8113V14.005L93.6048 14.2983L89.977 15.8L88.8145 16.2804C89.4709 17.1804 89.8216 18.2675 89.8216 19.385V31.1107C89.8216 31.5051 89.8768 31.8843 89.977 32.2433C90.3778 33.6793 91.5253 34.8018 92.9735 35.1608C93.2891 35.2417 94.0307 35.2923 94.3715 35.2923V32.9461C94.3715 32.9461 95.2985 35.5855 98.8512 35.5855C102.399 35.5855 105.275 32.6882 105.275 27.7431C105.275 22.8081 101.557 19.7389 98.0043 19.7389ZM97.4181 32.6528C95.8146 32.6528 94.3464 30.6101 94.3464 30.6101C94.3464 30.6101 94.3464 27.6218 94.3464 25.8318C94.3464 23.4199 95.3085 22.0396 96.6514 22.0396C99.1518 22.0396 100.199 24.8206 100.199 27.5712C100.194 30.332 99.0215 32.6528 97.4181 32.6528Z" fill="#43AB8C"/>
        <path d="M87.9876 33.0776C87.4765 33.0776 87.0656 32.658 87.0656 32.1473V25.8066C87.0656 22.8537 85.5573 19.7188 81.6138 19.7188C77.6703 19.7188 74.5435 21.0132 74.5435 23.597C74.5435 25.5184 76.5178 25.483 76.5178 25.483C79.2437 25.483 79.3088 22.5099 79.3088 22.5099C79.3088 22.5099 79.3739 20.8969 81.1027 20.8969C82.8314 20.8969 82.7362 22.2166 82.7362 23.1874C82.7362 24.1582 82.7362 25.2201 82.7362 25.2201C82.7362 25.2201 79.7247 25.3161 76.1971 27.546C72.6694 29.7708 73.3108 34.362 76.1971 35.2013C79.0833 36.0407 80.9123 35.2974 82.7412 33.8765C82.7412 34.7412 83.4427 35.4187 84.2645 35.4187C84.3547 35.4187 84.4449 35.4137 84.5351 35.3935L88.2932 34.7159V33.0675H87.9876V33.0776ZM82.7663 31.6366C82.7663 31.6366 81.3232 33.3001 79.4792 32.4911C77.9258 31.8136 78.5071 29.1084 79.9151 28.0263C81.3532 26.9241 82.7613 26.6915 82.7613 26.6915V31.6366H82.7663Z" fill="#43AB8C"/>
        <path d="M128.56 33.0776C128.049 33.0776 127.638 32.658 127.638 32.1473V25.8066C127.638 22.8537 126.13 19.7188 122.187 19.7188C118.243 19.7188 115.116 21.0132 115.116 23.597C115.116 25.5184 117.091 25.483 117.091 25.483C119.816 25.483 119.882 22.5099 119.882 22.5099C119.882 22.5099 119.947 20.8969 121.675 20.8969C123.404 20.8969 123.309 22.2166 123.309 23.1874C123.309 24.1582 123.309 25.2201 123.309 25.2201C123.309 25.2201 120.292 25.3161 116.77 27.546C113.242 29.7708 113.884 34.362 116.77 35.2013C119.656 36.0407 121.485 35.2974 123.314 33.8765C123.314 34.7412 124.016 35.4187 124.837 35.4187C124.927 35.4187 125.018 35.4137 125.108 35.3935L128.866 34.7159V33.0675H128.56V33.0776ZM123.339 31.6366C123.339 31.6366 121.896 33.3001 120.052 32.4911C118.499 31.8136 119.08 29.1084 120.488 28.0263C121.926 26.9241 123.334 26.6915 123.334 26.6915V31.6366H123.339Z" fill="#43AB8C"/>
        <path d="M66 16.2804L71.5019 14V18.6215H73.7869V19.8148H71.5019V33.2445C71.5019 34.1799 72.1082 35.0092 73.0002 35.2873H71.1511C68.8612 35.2873 67.0072 33.4164 67.0072 31.1158V19.385C67.0122 18.2726 66.6564 17.1804 66 16.2804Z" fill="#43AB8C"/>
        <path d="M106.538 16.2804L112.04 14V18.6215V19.8148V33.2445C112.04 34.1799 112.646 35.0092 113.538 35.2873H111.689C109.399 35.2873 107.545 33.4164 107.545 31.1158V19.385C107.55 18.2726 107.194 17.1804 106.538 16.2804Z" fill="#43AB8C"/>
        <path d="M94.3162 16.8113V21.7211C94.2962 21.7615 94.2861 21.7868 94.2861 21.7868V17.0389C94.2861 16.958 94.3012 16.8821 94.3162 16.8113Z" fill="#43AB8C"/>
    </svg>
</div>