import { useState, useEffect, useRef, useContext } from "react";
import LoaderPage from "../../../../components/Shared/LoaderPage";
import {
  getUpcomingOrders,
  AcceptUpcomingOrders,
  RejectUpcomingOrders,
} from "../../../../services/restaurant-admin/restaurant-admin";
import Toast from "../../../../services/Toast";
import OrdersElement from "./element/OrdersElement";
import Details from "./element/Details";
import { useQuery, useMutation } from "@apollo/client";
import { RESTAURANT_RESERVATION } from "../../../../services/Queries/reservationQuery";
import { EDIT_RESERVATION } from "../../../../services/mutations/reservationMutaions";
import { RESERVATION_KEYS } from "../../../../constants/keys";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { RestaurantManagerContext } from "../..";
const UpcomingOrders = () => {
  const [data, setData] = useState(false);
  const [selectedItem, setselectedItem] = useState(-1);
  const myRestaurant = useContext(RestaurantManagerContext);
  const [disable, setdisable] = useState(false);
  const detailsref = useRef(null);
  const [page, setPage] = useState(null);
  const [selectedReservation, setSelectedReservation] = useState(null);
  const [responseErrors, setResponseErrors] = useState([]);
  const onCompleteHandler = ({ editReservation }) => {
    const { success, errors, reservation } = editReservation;

    setResponseErrors([]);
    if (success) {
      Toast.success("It was done successfully");
      refetch();
      setdisable(false);
      let x = selectedItem;
      setselectedItem(-1);

      setTimeout(() => {
        document.getElementsByTagName("body")[0].scrollIntoView(true);
      }, 200);
    } else {
      try {
        const errorsObj = JSON.parse(errors);
        setdisable(false);
        for (const [key, value] of Object.entries(errorsObj)) {
          setResponseErrors((prev) => [...prev, ...value]);
        }
      } catch (e) {}
    }
  };

  const {
    loading,
    error,
    data: reservations,
    refetch,
  } = useQuery(RESTAURANT_RESERVATION, {
    variables: {
      filter: { type: RESERVATION_KEYS.TYPE.UP_COMING },
      restaurantReservationsId: myRestaurant?.id,
      page: page,
      pageSize: RESERVATION_KEYS.ADMIN_PAGE_SIZE,
    },
  });

  const handlePageChange = (e, val) => {
    setPage(val);
  };

  const onAccept = () => {
    setdisable(1);
    editReservation({
      variables: {
        status: RESERVATION_KEYS.STATUS.APPROVED,
        editReservationId: selectedReservation.id,
      },
    });
  };
  const onReject = () => {
    setdisable(2);
    editReservation({
      variables: {
        status: RESERVATION_KEYS.STATUS.CANCELED,
        editReservationId: selectedReservation.id,
      },
    });
  };
  const onChangeNote = (e) => {
    let x = [...data];
    x[selectedItem].note = e.target.value;
    setData(x);
  };
  const setSelectedData = (i, e) => {
    setselectedItem(i);
    setSelectedReservation(e);
    setResponseErrors([]);
    if (selectedItem != -1) {
      detailsref.current.scrollIntoView(true);
    } else
      setTimeout(() => {
        detailsref.current.scrollIntoView(true);
      }, 200);
  };

  if (loading) return <LoaderPage />;
  if (reservations.restaurantReservations.objects.length === 0)
    return (
      <div
        className="d-flex justify-content-center align-items-center card flex-grow-1"
        style={{ borderRadius: "10px", minHeight: "50vh" }}
      >
        There is no data !
      </div>
    );
  return (
    <div className="d-flex flex-grow-1 container_details">
      <div className="flex-grow-1 element-1-cont">
        <h3 className="card-title" style={{ padding: 0 }}>
          Upcoming Orders
        </h3>
        <br />
        <div className="d-flex " style={{ flexDirection: "column" }}>
          {reservations?.restaurantReservations.objects.map((e, i) => (
            <OrdersElement
              onClick={() => {
                setSelectedData(i, e);
              }}
              data={e}
              item={selectedItem != i}
              key={i}
            />
          ))}
          <Stack spacing={2} style={{ alignItems: "center" }}>
            <Pagination
              count={reservations?.restaurantReservations?.pages}
              page={reservations?.restaurantReservations?.page}
              onChange={handlePageChange}
              shape="rounded"
              color="success"
            />
          </Stack>
        </div>
      </div>
      <div
        ref={detailsref}
        style={{
          display: selectedItem == -1 && "none",
          flex: 1,
          width: "100%",
          minWidth: "300px",
        }}
      >
        <h3 className="card-title" style={{ padding: 0 }}>
          Details
        </h3>
        <br />
        {selectedItem != -1 && (
          <Details
            responseErrors={responseErrors}
            data={selectedReservation}
            doneButtonLabel={"Accept"}
            cancelButtonLaber={"Reject"}
            disableandLoad={disable}
            doneButtonClick={onAccept}
            cancelButtonClick={onReject}
            onChangeNote={onChangeNote}
          />
        )}
      </div>
    </div>
  );
};

export default UpcomingOrders;
