import React, { useEffect } from "react";
import "../../../styles/client/Client.css";
import "../../../styles/client/ClientLogin.css";
import Input from "../../../components/client/input";
import { validateGeneral } from "../../../validation/client";
import { useState } from "react";
import { ButtonPrimary } from "../../../components/client/button";
import Toast from "../../../services/Toast";
import { useLocation, useNavigate } from "react-router-dom";
import TextArea from "../../../components/client/textArea";
import { getUserData } from "../../../services/client/auth";
// import { reserve } from "../../../services/client/reservation";
import { CURRENT_USER } from "../../../services/Queries/UserQuery";
import { useMutation, useQuery } from "@apollo/client";
import LoaderPage from "../../../components/Shared/LoaderPage";
import { CREATE_RESERVATION } from "../../../services/mutations/restaurantMutaions";
import moment from "moment";

export default function Reserving(props) {
  const [responseErrors, setResponseErrors] = useState([]);
  const onCompletedHandler = (data) => { };
  const {
    loading,
    error,
    data: user,
  } = useQuery(CURRENT_USER, {
    onCompleted: onCompletedHandler,
    fetchPolicy: "network-only",
  });
  const onCompletedReserveHandler = ({ createReservation }) => {
    const { reservation, errors, success } = createReservation;

    setResponseErrors([]);
    if (success)
      navigate("/reservation/done", {
        state: { vkey: location.state?.item },
      });
    else {
      try {
        const errorsObj = JSON.parse(errors);

        for (const [key, value] of Object.entries(errorsObj)) {
          setResponseErrors((prev) => [...prev, ...value]);
        }
      } catch (e) { }
    }
  };
  const [reserve, { data, loading: loadingReserve, error: errorReserve }] =
    useMutation(CREATE_RESERVATION, {
      onCompleted: onCompletedReserveHandler,
    });
  const location = useLocation();
  const currentUser = user?.me;
  const navigate = useNavigate();

  const [code, setCode] = useState(null);

  const [notes, setNotes] = useState(null);

  const [userData, setUserData] = useState({});

  useEffect(() => {
    setUserData(getUserData());
  }, []);

  useEffect(() => {
    if (loading) return;
    if (!currentUser && !loading) {
      navigate("/login", {
        state: {
          ...location.state,
          next: "/reserving",
        },
      });
    }
  }, [user, currentUser?.id, loading, error]);

  const eligableForSending = () => {
    return true;
  };

  const returnTo = () => {
    let path = `/`;
    window.location.href = path;
  };
  // if (location.state?.item == null || location.state?.item == undefined) {
  //   returnTo();
  // }
  let guests = location.state?.item?.guests;
  if (loading) return <LoaderPage />;
  const onReserve = (e) => {
    e.preventDefault();

    let dateTimeReservation = null;
    let dater = null;
    let timer = null;
    console.log(' Location.state?.item  2', location.state?.item)
    if (location.state?.item) {
      console.log(' Location.state?.item ', location.state?.item)
      if (location.state?.item?.date) {
        dater = moment(location.state?.item.date);

        dateTimeReservation = dater.format('YYYY-MM-DDTHH:mm');
      }
    } else {
     
      try {
        const value = window.localStorage.getItem('item')
        const parsedValue = JSON.parse(value);

      } catch (e) {

      }

    }

    const variables = {
      commenter: notes,
      numberOfGuests: guests,
      restaurant: location.state.item.restaurantId,
      datetime: dateTimeReservation,
    };

    reserve({variables:variables})
    // navigate("/reservation/done", {
    //   state: { vkey: location.state?.item },
    // });

  };
  const isThereError = responseErrors?.length > 0;
  return (
    <React.Fragment>
      {currentUser && (
        <div className="row m-4">
          <div className="col-lg-6 p-4">
            {isThereError ? (
              responseErrors?.map((error, i) => (
                <p className=" title-1  w-100 text-danger">{error}</p>
              ))
            ) : (
              <div className="row">
                <div className="col-12">
                  <span className="title-1">
                    Verify your information <br />
                    before reserving
                  </span>
                </div>
                <div className="col-12">
                  <div
                    style={{ lineHeight: "30px", fontSize: "30px" }}
                    className="title-2 green w-100"
                  >
                    {location.state?.item?.title}
                  </div>
                  <div
                    style={{ lineHeight: "30px", fontSize: "20px" }}
                    className="title-2 green w-100"
                  >
                    {guests} Guest{guests > 1 ? "s" : ""} -{" "}
                    {moment(location.state?.item?.date).format('YYYY-DD-MM - HH:mm')} - {location.state?.item?.title}
                  </div>
                </div>
                <div className="col-12">
                  <div className="card-general flex-container">
                    <div style={{ flex: "25%" }}>
                      <img className="limitImg" src={currentUser.image} />
                    </div>
                    <div style={{ flex: "75%" }}>
                      <div className="w-100" style={{ fontSize: "28px" }}>
                        {currentUser.name}
                      </div>
                      <div className="w-100" style={{ fontSize: "15px" }}>
                        {currentUser.email} . {currentUser.phone}
                      </div>
                      <div className="w-100">
                        <span
                          className="simple-text clickable"
                          onClick={() => {
                            navigate("/user/profile");
                          }}
                        >
                          Change Info
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 h-30px mt-4">
                  <span className="title-3">Your note (Optional)</span>
                </div>
                <form>
                  <div className="col-12 ml-4 mr-4 mt-2">
                    <TextArea
                      id="notes"
                      className="textarea"
                      placeholder="Ex. I want the table beside the window"
                      onChange={(evt) => {
                        setNotes(evt.target.value);
                      }}
                      shouldSendData={(val) => { }}
                      validateFunc={() => []}
                    />
                  </div>
                  {/* <div className="col-12 ml-4 mr-4 mt-2">
                  <Input
                    id="code"
                    type="text"
                    value={location.state?.item.code}
                    placeholder="Promo Code"
                    onChange={(evt) => {
                      setCode(evt.target.value);
                    }}
                    shouldSendData={(val) => {}}
                    validateFunc={() => []}
                  />
                </div> */}
                  <div className="col-12 centerText">
                    <ButtonPrimary
                      style={{ width: "auto" }}
                      className="mx-auto"
                      text="Confirm"
                      click={onReserve}
                    />
                  </div>
                </form>
              </div>
            )}
          </div>
          <div className="col-lg-6">
            <img
              className="svgSize onlyDesktopSVG"
              src="/svg/Reserving.svg"
              alt="SVG"
            />
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
