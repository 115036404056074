import CRoutes from "../../routes/restaurant-admin";
import { LogoTabla } from "../../components/Shared/Logo";
import React, { createContext } from "react";
import SearchInput from "../../components/Shared/SearchInput";
import "../../styles/RestaurantAdmin.css";
import ProfileNavElement from "../../components/Shared/ProfileNavElement";
import { AiOutlineMenu } from "react-icons/ai";
import { useLeftNavBarOpen } from "../../hooks/LeftNavBar";
import { useQuery } from "@apollo/client";
import { MY_RESTAURANT_ID } from "../../services/Queries/reservationQuery";
import LoaderPage from "../../components/Shared/LoaderPage";
export const RestaurantManagerContext = createContext();
const RestaurantAdmin = () => {
  const OpenNavBarMobile = () => {
    useLeftNavBarOpen();
  };
  const {
    loading: loadingRestaurantId,
    error: errorRestaurantId,
    data: restaurantId,
  } = useQuery(MY_RESTAURANT_ID);
  if (loadingRestaurantId) return <LoaderPage />;
  return (
    <RestaurantManagerContext.Provider value={restaurantId?.myRestaurant}>
      <div className="page_container RES">
        <div className="top">
          <div className="d-flex align-items-center">
            <div
              className="rmv_mobile_lftnv add_mobile_lftnv"
              onClick={() => {
                OpenNavBarMobile();
              }}
            >
              <AiOutlineMenu
                size="20"
                color="#000"
                style={{ cursor: "pointer" }}
              />
            </div>
            <LogoTabla />
          </div>
          <div className="search_container">
            {/* <SearchInput /> */}
          </div>
          <ProfileNavElement />
        </div>
        <CRoutes />
      </div>
    </RestaurantManagerContext.Provider>
  );
};
export default RestaurantAdmin;
