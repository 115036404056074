import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.css";
import reportWebVitals from "./reportWebVitals";
import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloLink,
  ApolloProvider,
} from "@apollo/client";
import decodeJWT from "jwt-decode";
import { setContext } from "@apollo/client/link/context";
import TokenManger from "./api/TokenManger";
import { TokenRefreshLink } from "apollo-link-token-refresh";
import { REFRESH_TOKEN_MUTATION_JSON } from "./services/mutations/authMutations";
const URL = "https://api.tabla.ma/graphql/";
import createUploadLink from "apollo-upload-client/public/createUploadLink.js";
import { StrictMode } from 'react';
const httpLink = createUploadLink({
  uri: URL,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const { getToken } = TokenManger();

  const token = getToken();

  // return the headers to the context so httpLink can read them jwt

  return {
    headers: {
      ...headers,

      authorization: token ? `JWT ${token}` : "",
    },
  };
});
const {
  getRefreshToken,
  setRefreshToken,
  setToken,
  isValid,
  getToken,
  removeTokens,
} = TokenManger();
const refreshLink = new TokenRefreshLink({
  accessTokenField: "token",
  // Indicates the current state of access token expiration
  // If token not yet expired or user doesn't have a token (guest) true should be returned
  isTokenValidOrUndefined: () => {
    const token = getToken();

    // If there is no token, the user is not logged in
    // We return true here, because there is no need to refresh the token
    if (!token) return true;

    // Otherwise, we check if the token is expired
    const claims = decodeJWT(token);
    const expirationTimeInSeconds = claims.exp * 1000;
    const now = new Date();
    const isValid = expirationTimeInSeconds >= now.getTime();

    // Return true if the token is still valid, otherwise false and trigger a token refresh
    return isValid;
  },
  // Responsible for fetching refresh token
  fetchAccessToken: async () => {
    const refreshToken = getRefreshToken();
    const request = await fetch(URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: REFRESH_TOKEN_MUTATION_JSON(refreshToken),
    });

    return request.json();
  },
  // Callback which receives a fresh token from Response.
  // From here we can save token to the storage
  handleFetch: (token) => {
    setToken(token);
  },
  handleResponse: (operation, token) => (response) => {
    // here you can parse response, handle errors, prepare returned token to
    // further operations
    // returned object should be like this:
    // {
    //    access_token: 'token string here'
    // }

    return { token: response.data.refreshToken.token };
  },
  handleError: (err) => {
    console.warn("Your refresh token is invalid. Try to reauthenticate.");
    console.error(err);
    // Remove invalid tokens
    removeTokens();
  },
});

const client = new ApolloClient({
  // uri: authLink.concat(httpLink),
  link: ApolloLink.from([refreshLink, authLink, httpLink]),
  cache: new InMemoryCache(),
});

// Request a refresh token to then stores and returns the accessToken.

ReactDOM.render(
  <StrictMode>
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>
  </StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
