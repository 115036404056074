import { useQuery } from "@apollo/client";
import React, { useState } from "react";
import { CITIES_QUERY } from "../services/Queries/CitiesQuery";

const useCities = (props) => {
  const [cities, setCities] = useState([]);
  const onCompletedHandler = ({ allCities }) => {

    const { objects } = allCities;
    if (props)
    if (props.onCompleted) {
      props.onCompleted(allCities)
    }
    setCities(objects);

  };

  const { isLoading, error, data } = useQuery(CITIES_QUERY, {
    onCompleted: onCompletedHandler,
  });
  return {
    isLoading,
    error,
    cities,
  };
};

export default useCities;
