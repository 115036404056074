import React from "react";
import "../../../styles/client/Client.css";
import "../../../styles/LeftNavBar.css";
import { userDashboard } from "../../../constants/routesData/user-dashboard";
import { LinkActiveOrNot, LinkswithChildren } from "../../Shared/LinkActiveOrNot";
import { logout } from "../../../services/client/auth";
import { ButtonPrimary } from "../button";
import { BsBoxArrowRight } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

export default function LoggedInMenuItem(props)
{
    const navigate = useNavigate();
    let prefix="/user/"
    let url="user/";
    let i=0;
    return (
        <>
            <div className="w-100 mb-spec">
                <div className="w-100 mb-4">
                    <img className="limitImg30 mx-auto displayBlock" src={props?.user?.photo} />
                </div>
                <div className="centerText nameMenu">
                    {props?.user?.name}
                </div>
            </div>
            <section className="w-100">
                    {
                    userDashboard.map(e=>{
                        i++;
                        if(e?.children) 
                            return(<LinkswithChildren prefix={prefix} key={i}  onClick={(event)=>{props.done()}} element={e} urlcontainer={url}/>)
                        return( 
                            <div >
                                <LinkActiveOrNot prefix={prefix}  key={i}  onClick={(event)=>{props.done()}} to={e.path} urlcontainer={url} >
                                    <div className="icon">{e.icon} </div>
                                    <div className="title_">{e.title}</div>
                                </LinkActiveOrNot>
                            </div>
                        );
                    })
                    }
            </section>
            <div className="w-100">
                <ButtonPrimary  style={{justifyContent:"center"}}
                className="mx-auto marginMenu w-75 centerText " text={<><BsBoxArrowRight className="iconNextTo"/> Log out </>}
                    click={(event)=>{logout(props.done)}}/>
            </div>
        </>
    );
}