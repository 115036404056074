
import { useState, useEffect } from "react";
import LoaderPage from "../../../components/Shared/LoaderPage";
import { getDashboardData} from "../../../services/restaurant-admin/admin-dashboard";
import  Toast from "../../../services/Toast";
import { Button, TextField, OutlinedInput, Avatar } from "@mui/material";
import { BsChevronRight } from "react-icons/bs";
import LoadingButton from "@mui/lab/LoadingButton";
import ChartElement from "../../../components/Shared/ChartElement";
import {FaUserAlt, FaCity} from "react-icons/fa";
import {IoRestaurant} from "react-icons/io5";
import {ImBook} from "react-icons/im";
import { useNavigate } from "react-router-dom";

const Dashboard =()=>{
    const navigate = useNavigate();
    const [data,setData] = useState(false);
    const [message,setMessage] = useState("");
    const initData = ()=>{
        setData(-1);
        getDashboardData(setData);
    }
   
    useEffect(()=>{
        if(data===false)
            initData();
    });

    if(data === false || data === -1)
        return( <LoaderPage/>)
    return(
        <div className="d-flex flex-grow-1 container_details">
           <div className="flex-grow-1 element-1-cont" ><br/>
                <div className="d-flex justify-content-between align-items-center">
                    <h3 className="card-title dashboard" style={{ padding: 0 }}>Overview</h3>
                </div><br/>
                <div className="row listcard"><br/>
                    <OverViewElement color="#2ecc71" data={data.overview.users} label={'Total users'} Icon={<FaUserAlt/>} />
                    <OverViewElement color="#f1c40f" data={data.overview.restaurants} label={'Total restaurants'} Icon={<IoRestaurant/>} />
                    <OverViewElement color="#532f88" data={data.overview.reservations} label={'Total reservations'} Icon={<ImBook/>} />
                    <OverViewElement color="#ff4f4f" data={data.overview.cities} label={'Total cities'} Icon={<FaCity/>} />
                </div><br/><br/>
                <div className="row">
                    <div className="col-md-6 left">
                        <div className="d-flex justify-content-between align-items-center"> <h3 className="card-title dashboard" style={{ padding: 0 }}>Statistics</h3></div><br/>
                        <div>
                            <ChartElement color={"#2ecc71"} id="datachart" data={[{ name: 'restaurants', data: data.statics}]} />
                        </div><br/>
                        <div className="d-flex justify-content-between align-items-center"> <h3 className="card-title dashboard" style={{ padding: 0 }}>New restaurants</h3></div><br/>
                        <div className="d-flex " style={{ flexDirection:"column" }}>
                            {data.restaurants.map((e,i)=><AvailableRestaurantsElement onClick={()=>{navigate('/admin-dashboard/Restaurants')}} data={e}  />)}
                        </div>
                        <div  className="d-flex justify-content-center MoreDetail">
                            <Button  onClick={()=>{navigate('/admin-dashboard/Restaurants')}} variant="contained" style={{ color:"#fff" }}>View more</Button>
                        </div><br/>
                    </div>
                    <div className="col-md-6">
                        <div className="d-flex justify-content-between align-items-center"> <h3 className="card-title dashboard" style={{ padding: 0 }}>New users</h3></div><br/>
                        <div className="d-flex " style={{ flexDirection:"column" }}>
                            {data.users.map((e,i)=><AvailableUsersElement onClick={()=>{navigate('/admin-dashboard/Users')}} data={e}  />)}
                        </div>
                        <div  className="d-flex justify-content-center MoreDetail">
                            <Button  onClick={()=>{navigate('/admin-dashboard/Users')}} variant="contained" style={{ color:"#fff" }}>View more</Button>
                        </div><br/>
                        <div className="d-flex justify-content-between align-items-center"> <h3 className="card-title dashboard" style={{ padding: 0 }}>Reports</h3></div><br/>
                        <div className="d-flex " style={{ flexDirection:"column" }}>
                            {data.users.map((e,i)=><ReportsElement onClick={()=>{navigate('/admin-dashboard/Reports')}} data={e}  />)}
                        </div>
                        <div  className="d-flex justify-content-center MoreDetail">
                            <Button  onClick={()=>{navigate('/admin-dashboard/Reports')}} variant="contained" style={{ color:"#fff" }}>View more</Button>
                        </div><br/>
                    </div>
                </div>
            </div>
        </div>
    );
}



const OverViewElement=(props)=>{
    return(
        <div className="col-md-2 card d-flex align-items-center eleconta">
            <div className="el-dahsboard" style={{ background:props.color }} >{props.Icon}</div>
            <div className="textEle" style={{ color:props.color }} >{props.data}</div>
            <div className="ele_ico">{props.label}</div>
        </div>
    );
}

const AvailableRestaurantsElement = ({data,onClick,item})=>{
  
    return(
    <div className="boxOrderElement"  style={{ cursor:"pointer" }} onClick={()=>{onClick()}}>
        <div className="d-flex flex-grow-1 " >
            <Avatar  className="avatar_img" src={data.image} variant="rounded"/>
            <div className="d-flex justify-content-center flex-column">
                <h3 className="card-title" style={{ padding: 0,margin:0 }}>{data.name}</h3>
                <div className="d-flex  align-items-lg-stretch"  style={{ flexDirection:"column",marginTop:"5px" }}>
                    <p className="text-muted"><small>{data.email}</small></p>
                </div>
            </div>
        </div>
        <div className="d-flex align-items-center" >
            <Button disabled={item} className="button_right_icon" variant="contained" startIcon={<BsChevronRight size="25" color="#fff" />}/>
        </div>
    </div>
    );
}
const AvailableUsersElement = ({data,onClick,item})=>{
    return(
        <div className="boxOrderElement"  style={{ cursor:"pointer" }} onClick={()=>{onClick()}}>
            <div className="d-flex flex-grow-1 " >
                <Avatar  className="avatar_img" src={data.image} variant="rounded"/>
                <div className="d-flex justify-content-center flex-column">
                    <h3 className="card-title" style={{ padding: 0,margin:0 }}>{data.name}</h3>
                    <div className="d-flex  align-items-lg-stretch"  style={{ flexDirection:"column",marginTop:"5px" }}>
                        <p className="text-muted"><small>{data.email}</small></p>
                    </div>
                </div>
            </div>
            <div className="d-flex align-items-center" >
                <Button disabled={item} className="button_right_icon" variant="contained" startIcon={<BsChevronRight size="25" color="#fff" />}/>
            </div>
        </div>
    );
}

const ReportsElement = ({data,onClick,item,itemNumber})=>{

    return(
    <div className="boxOrderElement"  style={{ cursor:"pointer" }} onClick={()=>{onClick()}}>
        <div className="d-flex flex-grow-1 " >
            <Avatar  className="avatar_img" src={data.image} variant="rounded"/>
            <div className="d-flex justify-content-center flex-column">
                <h3 className="card-title" style={{ padding: 0,margin:0 }}>{data.name}</h3>
                <div className="d-flex  align-items-lg-stretch"  style={{ flexDirection:"column",marginTop:"5px" }}>
                    <p className="text-muted"><small>{data.email}</small></p>
                </div>
            </div>
        </div>
        <div className="d-flex align-items-center">
            <Button disabled={item} className="button_right_icon" variant="contained" startIcon={<BsChevronRight size="25" color="#fff" />}/>
        </div>
    </div>
    );
}



export default Dashboard;