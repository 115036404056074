import React from "react";
import "../../styles/client/Client.css";

export default function Separator(props)
{
    return (
        <div className="flex-container">
            <hr style={{flexGrow: 10}} className="flex-element"/>
            <div style={{flexGrow: 1}} className={`flex-element ${props.classNameText}`}>{props.text}</div>
            <hr style={{flexGrow: 10}} className="flex-element"/>
        </div>
    );
}