import React from "react";
import Navbar from "../../../components/navbar/navbarSearch";
import { Outlet } from "react-router-dom";
import Footer from "../../../footer";

export default function SearchLayout() {
    console.log(' SearchLayout  ')
    return(
        <React.Fragment>
            <Navbar />
                <Outlet />
            <Footer />
        </React.Fragment>
    );
}