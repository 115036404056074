import FetchAxios from "../Fetch";

const UpcomingOrdersDatat = [
    {
        orderN:"001",
        user:{name:"llel lele",email:"mmeme@dldld.ddml",phoneNumber:"021121",image:"https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"},
        date_creation:"2022-03-08 15:59:59.997",
        date_reservation:"2022-12-31 23:59:59.997",
        guest:1,
        note:"mllml",
    },
    {
        orderN:"002",
        user:{name:"psos lele",email:"lkl@dldld.ddml",phoneNumber:"021121",image:"https://images.pexels.com/photos/428364/pexels-photo-428364.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"},
        date_creation:"2022-03-09 15:59:59.997",
        date_reservation:"2022-12-31 23:59:59.997",
        guest:2,
        note:"6565",
    },
    {
        orderN:"001",
        user:{name:"llel lele",email:"mmeme@dldld.ddml",phoneNumber:"021121",image:"https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"},
        date_creation:"2022-03-08 15:59:59.997",
        date_reservation:"2022-12-31 23:59:59.997",
        guest:1,
        note:"mllml",
    },
  
];

const OffersData = [
    {id:1, percentage:"15", title:"pe mllmdll ", description:"dmldl mldmlm mlm lldmld lm lm !"},
    {id:2, percentage:"20", title:"pe mllmdll ", description:"dmldl mldmlm mlm lldmld lm lm !"},
    {id:3, percentage:"12", title:"pe mllmdll ", description:"dmldl mldmlm mlm lldmld lm lm !"},
];

const MenuData = [
    {id:1, price:"15", title:"pe mllmdll ", description:"dmldl mldmlm mlm lldmld lm lm !"},
    {id:2, price:"20", title:"pe mllmdll ", description:"dmldl mldmlm mlm lldmld lm lm !"},
    {id:3, price:"12", title:"pe mllmdll ", description:"dmldl mldmlm mlm lldmld lm lm !"},
];

const InboxData = [
    {
        user:{name:"psos lele",email:"lkl@dldld.ddml",phoneNumber:"021121",image:"https://images.pexels.com/photos/428364/pexels-photo-428364.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"},
        conversation:[
            {user:"psos lele",message:"ldlldld dlkdldk dldkd lkdkdkd lkdkdkl lkdkdkld. mldld dlldlkdkl"}
            ,{user:"admin",message:"ldkld dlkdkl dkkld.dlkld"}
        ]
    },
    {
        user:{name:"llel lele",email:"mmeme@dldld.ddml",phoneNumber:"021121",image:"https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"},
        conversation:[
            {user:"psos lele",message:"ldlldld dlkdldk dldkd lkdkdkd lkdkdkl lkdkdkld. mldld dlldlkdkl"}
        ]
    },
    
];

const ReviewsData = [
    {
        user:{name:"psos lele",email:"lkl@dldld.ddml",phoneNumber:"021121",image:"https://images.pexels.com/photos/428364/pexels-photo-428364.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"},
        review:{
            message:" lkl  lm qlms lmql m lsq, ùm sqù ms ml qs mpsd mldssld msdlms mqsp^, lksdsdk lksdlsd pqsp^sq dksjsdk",
            foodrating:4.2,
            servicerating:5,
            ambiencerating:4.2,
            rating:4.3,

        }
    }
];

/****** Dashboard ******/
export const getDashboard = (onDone)=>{
    return onDone({
        static:{
            orders:[31, 40, 28, 51, 42, 109, 100],
            views:[ 51, 42, 109, 100 ,58 ,20 ,62]
        },
        orders:UpcomingOrdersDatat,
        inbox:InboxData,
        reviews:{
            foodrating:4.2,
            servicerating:5,
            ambiencerating:4.2,
            rating:4.3,
        }
    });
    FetchAxios.get("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone(e.response);
        }
    });
    
}
/****** Upcoming Orders ******/
export const getUpcomingOrders = (onDone)=>{
    return onDone(UpcomingOrdersDatat);
    FetchAxios.get("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone(e.response);
        }
    });
    
}
export const AcceptUpcomingOrders = (data,onDone)=>{
    return onDone();
    FetchAxios.post("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone();
        }
    });
    
}
export const RejectUpcomingOrders = (data,onDone)=>{
    return onDone();
    FetchAxios.post("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone();
        }
    });
    
}
/****** Post Orders ******/
export const getPostOrders = (onDone)=>{
    return onDone(UpcomingOrdersDatat);
    FetchAxios.get("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone(e.response);
        }
    });
}
export const DeletePostOrders = (data,onDone)=>{
    return onDone();
    FetchAxios.post("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone();
        }
    });
    
}

/****** Rejected Orders ******/
export const getRejectedOrders = (onDone)=>{
    return onDone(UpcomingOrdersDatat);
    FetchAxios.get("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone(e.response);
        }
    });
    
}
export const ReAcceptRejectedOrders = (data,onDone)=>{
    return onDone();
    FetchAxios.post("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone();
        }
    });
    
}
export const DeleteRejectedOrders = (data,onDone)=>{
    return onDone();
    FetchAxios.post("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone();
        }
    });
    
}


/****** Offers  ******/
export const getOffers = (onDone)=>{
    return onDone(OffersData);
    FetchAxios.get("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone(e.response);
        }
    });
}
export const AddOffers = (data,onDone)=>{
    return onDone( {id:3, percentage:"12", title:"pe mllmdll ", description:"dmldl mldmlm mlm lldmld lm lm !"});
    FetchAxios.post("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone(e.response);
        }
    });
}
export const EditOffers = (data,onDone)=>{
    return onDone();
    FetchAxios.post("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone(e.response);
        }
    });
}
export const DeleteOffers = (data,onDone)=>{
    return onDone();
    FetchAxios.post("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone(e.response);
        }
    });
}

/****** Menu  ******/
export const getMenu = (onDone)=>{
    return onDone(MenuData);
    FetchAxios.get("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone(e.response);
        }
    });
}
export const AddMenu = (data,onDone)=>{
    return onDone( {id:5,...data});
    FetchAxios.post("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone(e.response);
        }
    });
}
export const EditMenu = (data,onDone)=>{
    return onDone();
    FetchAxios.post("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone(e.response);
        }
    });
}
export const DeleteMenu = (data,onDone)=>{
    return onDone();
    FetchAxios.post("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone(e.response);
        }
    });
}

/****** Extraservices  ******/
export const getExtraservices = (onDone)=>{
    return onDone(MenuData);
    FetchAxios.get("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone(e.response);
        }
    });
}
export const AddExtraservices = (data,onDone)=>{
    return onDone( {id:5,...data});
    FetchAxios.post("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone(e.response);
        }
    });
}
export const EditExtraservices = (data,onDone)=>{
    return onDone();
    FetchAxios.post("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone(e.response);
        }
    });
}
export const DeleteExtraservices = (data,onDone)=>{
    return onDone();
    FetchAxios.post("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone(e.response);
        }
    });
}

/****** Inbox  ******/
export const getInbox = (onDone)=>{
    return onDone(InboxData);
    FetchAxios.get("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone(e.response);
        }
    });
}
export const ReplyInbox = (data,onDone)=>{
    return onDone();
    FetchAxios.post("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone(e.response);
        }
    });
}
export const DeleteInbox = (data,onDone)=>{
    return onDone();
    FetchAxios.post("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone(e.response);
        }
    });
}

/****** Reviews  ******/
export const getReviews = (onDone)=>{
    return onDone(ReviewsData);
    FetchAxios.get("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone(e.response);
        }
    });
}
export const DeleteReviews = (data,onDone)=>{
    return onDone();
    FetchAxios.post("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone(e.response);
        }
    });
}
/****** Settings  ******/
export const getSettings= (onDone)=>{
    return onDone(
        {
            gallery:[],
            name:"mmm",
            email:"ll@lld.dlm",
            phone:"",
            adress:"",
            website:"",
            category:"",
            description:"",
            workhours:{
                monday:{open:true,from:"",to:"",reason:""},
                tuesday:{open:true,from:"",to:"",reason:""},
                wednesday:{open:true,from:"",to:"",reason:""},
                thursday:{open:true,from:"",to:"",reason:""},
                friday:{open:true,from:"",to:"",reason:""},
                saturday:{open:true,from:"",to:"",reason:""},
                sunday:{open:true,from:"",to:"",reason:""},
            },
            tables:{all:25,reserved:25}
        }
    );
    FetchAxios.get("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone(e.response);
        }
    });
}
export const EditSettings = (data,onDone)=>{
    return onDone();
    FetchAxios.post("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone(e.response);
        }
    });
}