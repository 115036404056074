import moment from "moment";

const addZero = (h)=>{
    return (h<10)?`0${h}`:h;
}
export const getTime = (date)=>{
    let d = Math.floor(( (new Date()) - new Date(date) )/1000);
    let x = Math.floor(d/(60*60*24*365));
    if(x>=1) return(`${x} year${x==1?'':'s'} ago`);
    x = Math.floor(d/(60*60*24*30));
    if(x>=1) return(`${x} month${x==1?'':'s'} ago`);
    x = Math.floor(d/(60*60*24));
    if(x>=1) return(`${x} day${x==1?'':'s'} ago`);
    x = Math.floor(d=d/3600);
    if(x>=1) return(`${x} hour${x==1?'':'s'} ago`);
    x = Math.floor(d=d/3600);
    if(x>1) return(`${x} minutes ago`);
    return("Just now");
}

export const getDateandHours = (date)=>{
    const ddate=moment(date)

    const d = new Date(date);
    let h = addZero(d.getHours());
    let m = addZero(d.getMinutes());
 
    return({date:`${addZero(ddate.get('D'))}-${addZero(d.getMonth()+1)}-${d.getFullYear()}`,hours:`${h}h${m}`})
}