import { gql } from "@apollo/client";

export const MY_RESERVATION = gql`
  query UserReservations(
    $filter: ReservationPublicSearchInput
    $pageSize: Int
    $page: Int
    $userReservationsId: ID
  ) {
    userReservations(
      filter: $filter
      pageSize: $pageSize
      page: $page
      id: $userReservationsId
    ) {
      page
      count
      pages
      hasNext
      hasPrev
      objects {
        status
        commenter
        numberOfGuests
        datetime
        id
        restaurant {
          status
          name
          image
          address
          description
          id
        }
      }
    }
  }
`;

export const MY_RESTAURANT_ID = gql`
  query MyRestaurant {
    myRestaurant {
      id
    }
  }
`;
export const MY_RESTAURANT = gql`
  query MyRestaurant {
    myRestaurant {
      status
      name
      rating
      foodRating
      serviceRating
      ambienceRating
      overview {
        totalPendingReservations
        totalReservations
        totalReservationsLeast7Days
        last7DaysReservations {
          labels
          daysReservationsCont
        }
        totalCanceledReservationsLeast7Days
      }
    }
  }
`;

export const RESTAURANT_RESERVATION = gql`
  query RestaurantReservations(
    $restaurantReservationsId: ID
    $search: String
    $pageSize: Int
    $page: Int
    $filter: ReservationPublicSearchInput
  ) {
    restaurantReservations(
      id: $restaurantReservationsId
      search: $search
      pageSize: $pageSize
      page: $page
      filter: $filter
    ) {
      page
      pages
      count
      hasNext
      hasPrev
      objects {
        id
        user {
          firstName
          lastLogin
          lastName
          email
          image
        }
        status
        numberOfGuests
        commenter
        datetime
        createdAt
      }
    }
  }
`;
