import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import Toast from "../../services/Toast";
import { BsChat, BsChatLeft, BsChatRight, BsChevronRight } from "react-icons/bs";
import { unliking, liking } from "../../services/client/general";
import { useNavigate, Link } from "react-router-dom";
import queryString from 'query-string'
import style from './style.module.css'
import moment from "moment";

const TimeOffer = ({ offer }) => {
  console.log(' offer ===>', offer)
  const timeRangeFrom = moment(offer.timeRangeFrom, 'HH:mm')
  const timeRangeTo = moment(offer.timeRangeTo, 'HH:mm')
  const isSame = timeRangeTo.isSame(timeRangeFrom)
  console.log(' timeRangeTo ==>', timeRangeTo)
  if (isSame) {
    <div className={style.menuTimeItem}>
      {timeRangeFrom.format('HH:mm')}

    </div>
  }
  return (
    <>
      <div className={style.menuTimeItemPercentage}>{`${offer.percentage}%`}</div>
      <div className={style.menuTimeItem}>

        {timeRangeFrom.format('HH:mm')} - {timeRangeTo.format('HH:mm')}


      </div>
    </>
  )
}
function ItemBoxResturant(props) {
  var redirectPath = "/reservation/";
  var path = "./info-";
  const navigate = useNavigate();
  const [liked, setLiked] = useState(false);
  const restaurant = props.dataRestaurant

  useEffect(() => {
    setLiked(props?.isLiked ? props.isLiked : false);
  }, []);

  function onDoneLiking() {
    let msg = (liked ? "Unliked" : "Liked") + " successfully";
    setLiked(!liked);
    Toast.success(msg);
  }

  function doLikeUnlike() {
    if (liked) return unliking(props?.id, onDoneLiking);
    return liking(props?.id, onDoneLiking);
  }

  const redirect = () => {
    navigate(redirectPath + props?.dataRestaurant.id);
  };
  const onHover = () => {
    if (props.onHover) props.onHover();
  };
  const onClick = () => {
    console.log("  onClick ");
    if (props.onClick) props.onClick();
  };
  const { selectedRestaurant } = props;
  const onHoverHandler = () => {
    if (props?.onHover) {
      props.onHover()
    }
  }
  console.log(' dataRestaurant?.timeOffers ==>', props.dataRestaurant?.timeOffers)
  return (
    <>
      {/* item ------------------------------------- */}
      <Link to={`/reservation/${props.id}?${queryString.stringify(props.searchParams, { skipNull: true })}`} target="_blank">
        <div
          // onClick={props.onClick}
          className={`itemBoxResturant sch ${selectedRestaurant
            ? selectedRestaurant.id === props.id
              ? "selected"
              : null
            : null
            }`}
          onMouseEnter={props?.onMouseEnter}
          onMouseLeave={props?.onMouseLeave}

        >
          <div className="boximg">
            <img
              src={props.img}
              alt=""

              className="w-100 card-image pointing"
            ></img>
          </div>
          <div className="cat_info">
            <div className="headitem">
              <div className="category">

                <span>{props?.category}</span>
              </div>
              <div
                className="card-title card-item div-inline pointing"
              // onClick={redirect}
              >
                {props.title}
              </div>

              <div className="card-description card-item div-inline">
                {props.city}
              </div>
              {props.dataRestaurant.menuOffer ?
                <div className={style.menuOffer}>
                  {`${props.dataRestaurant.menuOffer?.percentage}%  on Menu `}   - {props.dataRestaurant?.menuOffer?.title}
                </div> : null}

              {props.dataRestaurant?.timeOffers.objects.length > 0 && props.dataRestaurant?.timeOffers ?
                <div className={style.menuTime}>
                  {
                    props.dataRestaurant?.timeOffers.objects.map((off, i) => <TimeOffer offer={off} key={i} />)
                  }

                </div> : null}
              <div className="card-description2">


                <div className="capacity">
                  {/* <p>Category: </p> */}
                  {/* <span>{props?.category}</span> */}
                </div>
                {props.averagePrice ?
                  <div className={style.averagePrice}>
                    <span>Average price</span>
                    <span> {props.averagePrice} DH</span>

                  </div>
                  : null}

              </div>
            </div>
            <div className={style.reveiws}>

              <div
                className="rating"
              >
                <h5><strong> {props?.rating ? `${props?.rating}/5` : " "} </strong></h5>
              </div>


              <div
                className={style.commentsCount}
              >



                <span><BsChatRight /> {restaurant?.reviewsCount}</span>

              </div>
            </div>
          </div>
        </div>
      </Link>
    </>
  );
}

export default ItemBoxResturant;
