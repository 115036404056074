import { useState, useEffect, useRef, useContext } from "react";
import LoaderPage from "../../../components/Shared/LoaderPage";
import {
  getReviews,
  DeleteReviews,
} from "../../../services/restaurant-admin/restaurant-admin";
import Toast from "../../../services/Toast";
import LoadingButton from "@mui/lab/LoadingButton";
import { Avatar, Button } from "@mui/material";
import { BsChevronRight } from "react-icons/bs";
import { LogoTabla } from "../../../components/Shared/Logo";
import Rating from "@mui/material/Rating";
import CircularProgress from "@mui/material/CircularProgress";
import { RESTAURANT_REVIEWS_QUERY } from "../../../services/Queries/RestaurantQuery";

import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { RestaurantManagerContext } from "../";
import { REVIEWS_KEYS } from "../../../constants/keys";
import style from './reviewStyle.module.css'
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import { CREATE_USER_REPLAY_MUTAION } from "../../../services/mutations/restaurantMutaions";
import { GET_RESTUARNT_REVIEW_BY_ID_QUERY } from "../../../services/Queries/ResturantsQuery";
const Reviews = () => {
  const [data, setData] = useState(false);
  const [selectedItem, setSelectedItem] = useState(-1);
  const [disable, setDisable] = useState(false);
  const detailsRef = useRef(null);
  const myRestaurant = useContext(RestaurantManagerContext);
  const [page, setPage] = useState(null);
  const [selectedReview, setSelectedReview] = useState(null);

  const {
    loading,
    error,
    data: reviews,
    refetch,
  } = useQuery(RESTAURANT_REVIEWS_QUERY, {
    variables: {
      restaurantReviewsId: myRestaurant?.id,
      page: page,
      pageSize: REVIEWS_KEYS.ADMIN_PAGE_SIZE,
    },
  });
  const initData = () => {
    setData(-1);
    getReviews(setData);
  };
  const onDelete = () => {
    setDisable(2);
    DeleteReviews(data[selectedItem], () => {
      Toast.success("It was deleted successfully");
      let x = selectedItem;
      setSelectedItem(-1);
      setDisable(false);
      setData(data.filter((e, i) => i != x));
      setTimeout(() => {
        document.getElementsByTagName("body")[0].scrollIntoView(true);
      }, 200);
    });
  };

  const setSelectedData = (i, e) => {
    setSelectedItem(i);
    setSelectedReview(e);
    if (selectedItem != -1) detailsRef.current.scrollIntoView(true);
    else
      setTimeout(() => {
        detailsRef.current.scrollIntoView(true);
      }, 200);
  };
  const handlePageChange = (e, val) => {
    setPage(val);
  };
  if (loading) return <LoaderPage />;
  if (reviews?.restaurantReviews.objects.length === 0)
    return (
      <div
        className="d-flex justify-content-center align-items-center card flex-grow-1"
        style={{ borderRadius: "10px", minHeight: "50vh" }}
      >
        <h3>There is no data</h3>
      </div>
    );
  return (
    <div className="d-flex flex-grow-1 container_details RV">
      <div className="flex-grow-1 ">
        <h3 className="card-title" style={{ padding: 0 }}>
          Reviews
        </h3>

        <div className="d-flex " style={{ flexDirection: "column" }}>
          {reviews?.restaurantReviews.objects.map((e, i) => (
            <Element
              onClick={() => {
                setSelectedData(i, e);
              }}
              data={e}
              item={selectedItem != i}
              key={i}
            />
          ))}
          <Stack spacing={2} style={{ alignItems: "center" }}>
            <Pagination
              count={reviews?.restaurantReviews?.pages}
              page={reviews?.restaurantReviews?.page}
              onChange={handlePageChange}
              shape="rounded"
              color="success"
            />
          </Stack>
        </div>
      </div>
      <div
        ref={detailsRef}
        style={{
          display: selectedItem == -1 && "none",
          flex: 1,
          width: "100%",
          minWidth: "300px",
          borderLeft: '1px solid #777'
        }}
      >
        <h3 className="card-title" style={{ padding: 0, height: "25px" }}></h3>

        {selectedItem != -1 && (
          <Details
            data={selectedReview}
            cancelButtonLaber={"Delete"}
            disableandLoad={disable}
            cancelButtonClick={onDelete}
          />
        )}
      </div>
    </div>
  );
};

const Element = ({ data, onClick, item }) => {
  return (
    <div
      className="d-flex col-12 card order_container_element"
      style={{ cursor: "pointer" }}
      onClick={() => {
        onClick();
      }}
    >
      <div className="d-flex flex-grow-1 " style={{ alignSelf: "flex-start" }}>
        <Avatar
          className="avatar_img"
          src={data.user.image}
          variant="rounded"
        />
        <div className="">
          <h3 className="card-title" style={{ padding: 0, margin: 0 }}>
            {data.user.lastName}   {data.user.firstName}
          </h3>
          <div
            className="d-flex  align-items-lg-stretch"
            style={{ flexDirection: "column" }}
          >
            <p className="text-muted ">
              <small>{data.description}</small>
            </p>
          </div>
        </div>
      </div>
      <div
        className="d-flex align-items-center"
        style={{ alignSelf: "center" }}
      >
        <Button
          disabled={item}
          className="add_edit_btn_icon"
          variant="contained"
          startIcon={<BsChevronRight size="25" color="#fff" />}
        />
      </div>
    </div>
  );
};

const Details = (props) => {
  const [message, setMessage] = useState('')
  const [showReplies, setShowReplies] = useState(false)
  const rating = props.data?.rating;
  const handleOnCompletedRplayPost = ({ reviewById }) => {
    setMessage('')

  }
  const [postReplay, { loading, error }] = useMutation(CREATE_USER_REPLAY_MUTAION, {
    onCompleted: handleOnCompletedRplayPost

  })
  const [getReplies, { loading: loadingRreview, data: reviewRespnse, error: errorReview }] = useLazyQuery(GET_RESTUARNT_REVIEW_BY_ID_QUERY)



  const { data } = props;
  const onRelay = () => {
    if (message == null || message == '') {
      Toast.error("Please, add description to reply");
      return
    }
    setShowReplies(true)
    postReplay({
      variables: { review: data.id, message: message }, refetchQueries: [
        { query: GET_RESTUARNT_REVIEW_BY_ID_QUERY, variables: { reviewBy: data.id },fetchPolicy: 'no-cache' }
      ]
    })
  }
  useEffect(()=>{
    getReplies({ variables: { reviewBy: data?.id } })
  },[
  ])
  const UserData = () => {
    return (
      <div className="d-flex flex-column  align-items-center justify-content-center ">
        <Avatar
          style={{ margin: "0" }}
          className="avatar_img"
          src={data?.user.image}
          variant="rounded"
        />
        <br />
        <div style={{ textAlign: "center", margin: "auto" }}>
          <h3 className="card-title" style={{ padding: 0, margin: 0 }}>
            {` ${data.user.firstName} ${data.user.lastName}`}
          </h3>
          <div className="d-flex" style={{ flexDirection: "column" }}>
            <p className="text-muted ">
              <small>{data.user.email}</small>
            </p>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="d-flex">
      <div
        className="d-flex align-items-center justify-content-center ReviewRV"
        style={{ margin: "15px 20px", flexDirection: "column", width: "100%" }}
      >
        <UserData />
        <div
          className="d-flex flex-row align-items-center justify-content-center"
          style={{ width: "100%" }}
        >
          <div className="position-relative" style={{ marginRight: "10px" }}>
            <CircularProgress
              style={{ width: "60px", height: "60px" }}
              variant="determinate"
              value={rating * 20}
            />
            <div className="rate_label_number">
              <div className="rate_label" style={{ margin: 0 }}>
                {parseFloat(rating).toFixed(2)}
              </div>
            </div>
          </div>
          <div className="d-flex flex-column" style={{ marginLeft: "10px" }}>
            <Rating name="disabled" value={rating} precision={0.5} readOnly />
            {/* <div className="rate_label">Very Good</div> */}
          </div>
        </div>
        <br />
        <div className="d-flex w-100">
          <div className="text-center" style={{ padding: "5px" }}>
            <span className="rate_label" style={{ margin: 0 }}>
              {data.foodRating}
            </span>
            <br />
            <span className="text_rating_type2">Food</span>
          </div>
          <div
            className="text-center service_rate_container"
            style={{ padding: "5px" }}
          >
            <span className="rate_label" style={{ margin: 0 }}>
              {data.serviceRating}
            </span>
            <br />
            <span className="text_rating_type2">Service</span>
          </div>
          <div className="text-center" style={{ padding: "5px" }}>
            <span className="rate_label" style={{ margin: 0 }}>
              {data.ambienceRating}
            </span>
            <br />
            <span className="text_rating_type2">Ambience</span>
          </div>
        </div>
        <br />
        <p className="text-muted text-center messagereview">
          <a>{data.description}</a>
        </p>
        <br />
        <div
          className="d-flex w-100 justify-content-center "
          style={{ height: "45px" }}
        >
          <div>
            {reviewRespnse?.reviewById.count>0  ?
              <div >

                <Button size="small" onClick={() => getReplies({ variables: { reviewBy: data?.id } })}>show Replies</Button>

              </div> : null}

          </div>
          {/* {
            <LoadingButton
              variant="contained"
              color="error"
              type="danger"
              style={{
                color: "#fff",
                flex: 1,
                margin: "2px",
                borderRadius: "10px",
              }}
              className="class_button"
              disabled={props.disableandLoad}
              loading={props.disableandLoad}
              onClick={() => {
                props.cancelButtonClick();
              }}
            >
              {props.disableandLoad ? "" : props.cancelButtonLaber}
            </LoadingButton>
          } */}
        </div>


        {/* <Button size="small" onClick={()=>getReplies({variables:{reviewBy:data?.id}})}>show Replies</Button> */}

        <div className={style.repaliesContainer}>
          <div className={style.repalyList}>{reviewRespnse?.reviewById.replies.objects.map((reply, i) =>
            <div key={i} className={style.repalyItemContainer}>
              <div className="d-flex">
                <Avatar

                  src={reply.user?.image}

                />  <strong className={style.repalyItemName}>{reply.user.lastName} {reply.user.firstName}</strong>
              </div>
              <p className={style.repalyItemText}>
                {reply.message}
              </p>

            </div>)}


          </div>


          <textarea className={style.replayInput} title='replay' rows={2} cols={1}
            placeholder='reply'
            value={message}
            onChange={(e) => setMessage(e.target.value)} ></textarea>
          <div>
            <Button onClick={onRelay}
              variant="contained"
              color="primary"
              size="small"


            >submit</Button>
          </div>

        </div>

      </div>
    </div>
  );
};

export default Reviews;
