import React, { useEffect, useState } from "react";
import { LogoTabla } from "../Shared/Logo";
import defaults from "./default.png";
import Avatar from '@mui/material/Avatar';
// import { faCoffee } from "@fortawesome/free-brands-svg-icons";
import { NavLink, Bars, NavMenu, Nav } from "./Navbar-elements";
import MobileMenuBar from "../../components/client/menuBarMobile";
import { useQuery } from "@apollo/client";
import { getUserData } from "../../services/client/auth";
import DropDownLoggedIn from "../client/menuItems/dropDownLoggedIn";
import { useNavigate } from "react-router-dom";
import { CURRENT_USER } from "../../services/Queries/UserQuery";

function Navbar() {
  const { loading, error, data } = useQuery(CURRENT_USER, { fetchPolicy: "network-only" });
  let QueryUser;
  if (data) {
    QueryUser = data.me;
  }
  const token = localStorage.getItem("token");

  return (
    <>
      <div className="desktopOnly shadow">
        <Nav>
          <NavLink className="navlink" to="/">
            <LogoTabla />
          </NavLink>
          <Bars />
          {/* <NavMenu className="center">
            <NavLink to="/" className="navlink" activeStyle>
              Home
            </NavLink>
            <NavLink to="/restaurants" className="navlink" activeStyle>
              Restaurants
            </NavLink>
            <NavLink to="/foods" className="navlink" activeStyle>
              Foods
            </NavLink>
            <NavLink to="/about" className="navlink" activeStyle>
              About
            </NavLink>
            <NavLink to="/help" className="navlink" activeStyle>
              Help
            </NavLink>
          </NavMenu> */}
          <Bars />
          <NavMenu className="right">
            {!token && (
              <div className="flex-container">

                <NavLink className="p-0" to="/login">
                  <button className="btn-tabla btn" >
                    Login
                  </button>
                </NavLink>


                <NavLink className="p-0" to="/register">
                  <button className="btn btn-bg-tabla">
                    Sign up

                  </button>
                </NavLink>
              </div>
            )}
            {token && (
              <div>
                {loading ? (
                  <div className="header--isloading">
                    <div className="loading placeholderprofile">Image</div>
                    <div className="loading placeholdertitle">Title</div>
                  </div>
                ) : (
                  <div className="displayInline">

                    <DropDownLoggedIn
                      first={QueryUser?.firstName}
                      last={QueryUser?.lastName}
                      image={
                        <Avatar variant="rounded"
                          src={QueryUser?.image} />
                      }
                    />
                  </div>
                )}
              </div>
            )}
          </NavMenu>
        </Nav>
      </div>
      <div className="mobileOnly">
        <MobileMenuBar />
      </div>
    </>
  );
}

export default Navbar;
