import  React,{ useState, useEffect, useRef } from "react";
import LoaderPage from "../../../../components/Shared/LoaderPage";
import { getFAQPage,EditFAQPage} from "../../../../services/restaurant-admin/admin-dashboard";
import  Toast from "../../../../services/Toast";
import { Button, TextField } from "@mui/material";
import { BsChevronRight } from "react-icons/bs";
import LoadingButton from "@mui/lab/LoadingButton";
import { AiOutlineDelete } from "react-icons/ai";

const FAQPage =()=>{

    const [data,setData] = useState(false);
    const [selectedItem,setselectedItem] = useState(-1);
    const [DatatoShow,setDatatoShow] = useState([]);
    const [disable,setdisable] = useState(false);
    const detailsref = useRef(null)
    const [edit,setedit]=useState(false);

    const initData = ()=>{
        setData(-1);
        getFAQPage(setData);
    }
   
    
    const setSelectedData = (i)=>{
        setedit(false);
        setselectedItem(i);
        setDatatoShow({...data[i]});
        if(selectedItem!=-1)
            detailsref.current.scrollIntoView(true);
        else
            setTimeout(() => {
                detailsref.current.scrollIntoView(true);
            }, 200);
    }
    const setAdd = ()=>{
        setedit(true);
        setselectedItem(-1);
        setDatatoShow({title:"",data:[{question:'',answer:''}]});
        setselectedItem(-2);
        
        if(selectedItem!=-1)
            detailsref.current.scrollIntoView(true);
        else
            setTimeout(() => {
                detailsref.current.scrollIntoView(true);
            }, 200);
    }
   
    const onAddFAQPage = ()=>{
        setdisable(true);
        
            EditFAQPage(DatatoShow,()=>{
                let x = data;
                x[selectedItem]= {...DatatoShow};
                setData([...x]);
                Toast.success('offer edited successfully');
                CancelFAQPage();
            });
    }

    const onEditFAQPage = ()=>{
    }
    
    const CancelFAQPage = ()=>{
        setselectedItem(-1);
        setedit(false);
        setdisable(false);
    }
    
    useEffect(()=>{
        if(data===false)
            initData();
    });

    if(data === false || data === -1)
        return( <LoaderPage/>)
    
        
    return(
        <div className="d-flex flex-grow-1 container_details">
            <br/>
            <br/>
           <div className="flex-grow-1 element-1-cont" >
                <div className="d-flex justify-content-between align-items-center">
                </div>
                <br/>
                <div className="d-flex " style={{ flexDirection:"column" }}>
                   {data.map((e,i)=><FAQPageElement index={i} onClick={()=>{setSelectedData(i);}} data={e}  item={selectedItem!=i} />)}
                    <Button variant="contained" color="primary" onClick={()=>{setAdd();}}  style={{ color:"#fff",height:"40px",width:"130px" ,margin:"0 auto",borderRadius:"10px"}}>Add new</Button>
                </div>
            </div>
          
            <div ref={detailsref}  style={{display: selectedItem==-1 && "none",flex: 1,width: "100%", minWidth: "300px", marginLeft: "10px"}}>
                <br />
                {
                    selectedItem!=-1 ?
                    <FAQPageDetails 
                        data={DatatoShow}  disableandLoad = {disable}  edit={edit}  setedit={setedit} setdata ={setDatatoShow}
                        doneButtonLabel={"Save"} cancelButtonLaber={'Cancel'} editButtonLabel={'Edit'} deleteButtonLaber={'Delete'}
                        doneButtonClick={onAddFAQPage} cancelButtonClick={CancelFAQPage} editButtonClick={onEditFAQPage} 
                    />
                    : ''
                }
            </div>
        </div>
    )
}
 
const FAQPageElement = ({data,onClick,item,index})=>{
    const getIndex = ()=>{
        if(index<9) return('0'+(index+1)+". ");
        return((index+1)+". ");
    }
    return(
    <div className="d-flex col-12 card order_container_element "  style={{ cursor:"pointer",padding:"0 15px" }} onClick={()=>{onClick()}}>
        <div className="d-flex flex-grow-1 " >
            <div className="">
                <div className="card-title" style={{ padding: 0,margin:0 }}> {getIndex()} {data.title}</div>
            </div>
        </div>

        <div className="d-flex align-items-center" >
            <div disabled={item} className=" d-flex justify-content-center align-items-center button_right_icon" variant="contained" ><BsChevronRight size="25" color="#43ab8c" /></div>
        </div>
    </div>
    );
}

const FAQPageDetails = (props)=>{
        const AddorEdit = ()=>{
            props.doneButtonClick();
        }
        const onChange = (key,e,i)=>{
            let x = {...props.data};
            if(i==-1) x.title=e;
            else
                x['data'][i]={...x['data'][i],[key]:e};

            props.setdata({...x});
        }
        const setAdd = ()=>{
            var x = props.data;
            x.data = [...x.data,{question:"",answer:""}];
            props.setdata({...x});
        }
        const onDelete = (i)=>{
            let x = {...props.data};
            x.data = x.data.filter((e,j)=>i!=j);
            props.setdata({...x});
        }
        return(
            <div className="d-flex" style={{ flexDirection:"column" }} >
                    <div className="d-flex align-items-start" style={{  margin:"25px 5px",width:"100%" , flexDirection:"column"}}>
                        <div className="d-flex" style={{width:"100%" }}>
                        <TextField className="flex-grow-1" value={props.data?.title}  onChange={(e)=>{onChange("title",e.target.value,-1)}} label="Title" />
                        </div>
                        {
                           props.data.data.map((e,i)=><>
                            <div key={i+e.title} style={{ width:"100%" }} >
                                <div className="d-flex" style={{width:"100%",margin:"25px 0" }}>
                                    <TextField className="flex-grow-1" value={e.question}  label="Question" onChange={(e)=>{onChange('question',e.target.value,i)}} />
                                    <Button  onClick={()=>{onDelete(i)}}  className="add_edit_btn_icon" color="error"  variant="contained" style={{ margin:"0 20px" }} startIcon={<AiOutlineDelete style={{ margin:"auto" }} size="20" color="#fff" />}/>
                                </div>
                                <TextField style={{ width:"100%"}} value={e.answer} label="Description" onChange={(e)=>{onChange('answer',e.target.value,i)}} multiline rows={4} />
                            </div> 
                            </>)                      
                        }
                        <br/>
                        <Button variant="contained" color="primary" onClick={()=>{setAdd();}}  style={{ color:"#fff",height:"40px",width:"130px" ,margin:"0 auto",borderRadius:"10px"}}>Add new</Button>
                    </div>
                <div>
                <br/>
            </div>
            <div className="d-flex w-100 justify-content-center " style={{ height: "45px" }}>
                    {props?.cancelButtonLaber   && <LoadingButton variant="contained" color="secondary"  style={{ color:"#fff",flex:1,margin:"2px",borderRadius:"10px"}}  className="class_button"    disabled={props.disableandLoad} loading={props.disableandLoad==2}onClick={()=>{props.cancelButtonClick()}} >{props.disableandLoad==2?"":props.cancelButtonLaber}</LoadingButton>}
                    {props?.doneButtonLabel     && <LoadingButton variant="contained" style={{ color:"#fff",flex:1,margin:"2px",borderRadius:"10px"}}  className="class_button" disabled={props.disableandLoad} loading={props.disableandLoad==1} onClick={()=>{AddorEdit()}}>{props.disableandLoad==1?"":props.doneButtonLabel} </LoadingButton>}
            </div>
        </div>
        )
   
}

export default FAQPage;

