import { LOCAL_STORAGE_KEYS } from "../constants/keys";
import { JwtPayload } from "jwt-decode";
import decodeJWT from "jwt-decode";
const TokenManger = () => {
  const keys = LOCAL_STORAGE_KEYS;
  const setTokens = ({ refreshToken, token }) => {
    try {
      window.localStorage.setItem(keys.TOKEN, token);
      window.localStorage.setItem(keys.REFRESH_TOKEN, refreshToken);
    } catch (error) {
      // If error also return initialValue
      console.log(error);
    }
  };

  const getToken = () => {
    try {
      // Get token from local storage by key

      const item = window.localStorage.getItem(keys.TOKEN);
      // Parse stored json or if none return initialValue
      return item ? item : null;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return null;
    }
  };
  const setToken = (token) => {
    try {
      // Get from local storage by key

      const item = window.localStorage.setItem(keys.TOKEN, token);
    } catch (error) {
      // If error also return initialValue
      console.log(error);
    }
  };

  const getRefreshToken = () => {
    try {
      // Get refreshToken from local storage by key

      const item = window.localStorage.getItem(keys.REFRESH_TOKEN);
      // Parse stored json or if none return initialValue
      return item ? item : null;
    } catch (error) {
      // If error also return null
      console.log(error);
      return null;
    }
  };
  const setRefreshToken = () => {
    try {
      // set refreshToken from local storage by key

      window.localStorage.setItem(keys.REFRESH_TOKEN);
    } catch (error) {
      // If error also return null
      console.log(error);
    }
  };
  const removeTokens = () => {
    try {
      window.localStorage.removeItem(keys.TOKEN);
      window.localStorage.removeItem(keys.REFRESH_TOKEN);
    } catch (error) {
      // If error also return null
      console.log(error);
    }
  };
  const isValid = () => {
    const token = window.localStorage.getItem(keys.TOKEN);
    // if token is undefined then return false
    if (!token) return false;
    // Otherwise, we check if the token is expired
    const claims = decodeJWT(token);
    const expirationTimeInSeconds = claims.exp * 1000;
    const now = new Date();
    const isValid = expirationTimeInSeconds >= now.getTime();

    // Return true if the token is still valid, otherwise false and trigger a token refresh
    return isValid;
  };

  return {
    getToken,
    setToken,
    getRefreshToken,
    setRefreshToken,
    setTokens,
    removeTokens,
    isValid,
  };
};
export default TokenManger;
