import styled from "styled-components";
import { NavLink as Link } from "react-router-dom";
import { FaBars } from "react-icons/fa";

export const Nav = styled.nav`
height: 80px;
display: flex;
justify-content: space-between;
margin: 0px 33px;
align-items: center;
`;

export const NavLink = styled(Link)`
padding: 0px 32px;
    font-weight: 600;
    color: var(--bs-body-color);
`;

export const Bars = styled(FaBars)`
  display: none;
  color: #000;
`;

export const NavMenu = styled.div`
  display: flex;
  align-items: center;
`;
