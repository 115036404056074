import React from "react";
import "../../../styles/RestaurantAdmin.css";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import LoaderPage from "../../../components/Shared/LoaderPage";
import Calendar from "../../../components/Calendar/Calendar";
import Time from "../../../components/Calendar/Time";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import MapsCa from "../../../components/maps/Map";
import Map from "../../../components/Map";
import Option from "../../../components/inputSelect/select";
import { GET_RESTUARNT_REVIEWS_QUERY, GET_RESTUARNT_REVIEW_BY_ID_QUERY, GET_RESTURANT_BY_ID } from "./../../../services/Queries/ResturantsQuery";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import { useState, useEffect } from "react";
import { reservation, addReview } from "../../../services/client/reservation";
import { Button, CircularProgress, Rating } from "@mui/material";
import { FaRegMoneyBillAlt, FaStar } from "react-icons/fa";
import Toast from "../../../services/Toast";
import SearchSelect from '../../../components/SearchSelect'
import { Avatar } from "@mui/material";
import { BsHourglass, BsPerson } from "react-icons/bs";
import { CHECK_RESERVATION_QUERY } from "../../../services/Queries/RestaurantQuery"
import moment from "moment";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { CURRENT_USER } from "../../../services/Queries/UserQuery";
import { CREATE_REVIEW_MUTAION, CREATE_USER_REPLAY_MUTAION } from "../../../services/mutations/restaurantMutaions";
import style from "./style.module.css";
function useSearchQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
const ReviewElement = ({ data, currentUser }) => {
  const [message, setMessage] = useState('')
  const handleOnCompletedRplayPost = ({ reviewById }) => {
    setMessage('')

  }
  const [postReplay, { loading, error, }] = useMutation(CREATE_USER_REPLAY_MUTAION, { onCompleted: () => setMessage(''), })
  const [getReplies, { loading: loadingRreview, data: reviewRespnse, error: errorReview }] = useLazyQuery(GET_RESTUARNT_REVIEW_BY_ID_QUERY)

  const onRelay = () => {
    if (message == null || message == '') {
      Toast.error("Please, add description to reply");
      return
    }
    postReplay({ variables: { review: data.id, message: message } })
  }
  return (
    <div className={style.reviewElement}>
      <div className="d-flex review_element">
        <br />
        <div >

          <Avatar
            className="avatar_img"
            src={data.user?.image}

          />
        </div>
        <div className={style.flexColumn}>
          <div className="review_el_et">
            {`${data.user.lastName}  ${data.user.firstName}  `} <FaStar />
            <span style={{ color: "#FFCA28" }}>{data.rating}</span>
          </div>
          <div>
            <p className="des_review">{data.description} </p>
            {data.replies.count > 0 && !reviewRespnse?.reviewById ?
              <div >

                <Button size="small" onClick={() => getReplies({ variables: { reviewBy: data?.id } })}>show Replies</Button>

              </div> : null}
            {/* <Button size="small" onClick={onRelay}>show Replies</Button> */}

            <div className={style.repaliesContainer}>
              <div>{reviewRespnse?.reviewById.replies.objects.map((reply, i) =>
                <div key={i} className={style.repalyItemContainer}>
                  <div className="d-flex">
                    <Avatar

                      src={reply.user?.image}

                    />  <strong className={style.repalyItemName}>{reply.user.lastName} {reply.user.firstName}</strong>
                  </div>
                  <p className={style.repalyItemText}>
                    {reply.message}
                  </p>

                </div>)}


              </div>

              {data.user.id === currentUser?.id ? <>
                <textarea className={style.replayInput} title='replay' rows={2} cols={1}
                  placeholder='reply'
                  value={message}
                  onChange={(e) => setMessage(e.target.value)} ></textarea>
                <div>
                  <Button onClick={onRelay}
                    variant="contained"
                    color="primary"
                    size="small"


                  >submit</Button>
                </div>
              </>
                : null}
            </div>

          </div>
        </div>

        <br />

      </div>
      {/* <textarea ></textarea> */}
    </div>
  );
};
const ReviewDetails = ({
  rating,
  foodRating,
  ambienceRating,
  serviceRating,
}) => {
  return (
    <div className="d-flex ">
      <div
        className="d-flex align-items-center justify-content-center "
        style={{ margin: "15px 20px", flexDirection: "column", width: "100%" }}
      >
        <div
          className="d-flex flex-row align-items-center justify-content-center"
          style={{ width: "100%" }}
        >
          <div className="position-relative" style={{ marginRight: "10px" }}>
            <CircularProgress
              style={{ width: "10.5rem", height: "10.5rem" }}
              variant="determinate"
              value={rating * 20}
            />
            <div
              className="rate_label_number"
              style={{ width: "90px", height: "90px" }}
            >
              <div className="rate_label review" style={{ margin: 0 }}>
                {rating}
              </div>
            </div>
          </div>
          <div className="d-flex flex-column" style={{ marginLeft: "10px" }}>
            <Rating name="disabled" value={rating} precision={0.5} readOnly />
            {/* <div className="rate_label">Very Good</div> */}
          </div>
        </div>
        <br />
        <br />
        <div className="d-grid w-100">
          <div className="text-grid" style={{ padding: "5px" }}>
            <span className="rate_label show_rev" style={{ margin: 0 }}>
              {foodRating}
            </span>
            <span className="text_rating_type2">Food</span>
          </div>
          <div className="text-grid" style={{ padding: "5px" }}>
            <span className="rate_label show_rev" style={{ margin: 0 }}>
              {serviceRating}
            </span>
            <span className="text_rating_type2">Service</span>
          </div>
          <div className="text-grid" style={{ padding: "5px" }}>
            <span className="rate_label show_rev" style={{ margin: 0 }}>
              {ambienceRating}
            </span>
            <span className="text_rating_type2">Ambience</span>
          </div>
        </div>
        <br />
        <br />
      </div>
    </div>
  );
};

const ReviewsBox = ({ restaurantId, reviews }) => {
  const [review, setReview] = useState({ review: 1, description: "" })
  const [disable, setDisable] = useState(false)
  const onCompletedHandler = (data) => {


  }
  const onErrorHandler = (error) => {


  }
  const { loading: reviewsLoading, error: reviewsErrors, data: reviewsResonse } = useQuery(GET_RESTUARNT_REVIEWS_QUERY, {
    onCompleted: onCompletedHandler,
    onError: onErrorHandler,
    variables: {
      restaurantId: restaurantId
    }

  })
  const onCompletedCreateReviewHandler = ({ createReview }) => {
    const { errors, review, success } = createReview
    if (success) {
      setReview({ review: 1, description: "" })

    }

  }
  const { loading: userError, error: errorUser, data: currntUser } = useQuery(CURRENT_USER, { variables: { restaurantId: restaurantId }, nextFetchPolicy: 'no-cache' });
  const [createReview, { loading, error, data }] = useMutation(CREATE_REVIEW_MUTAION, {
    onCompleted: onCompletedCreateReviewHandler,

  })
  const onaddReview = () => {
    if (review.description == "") {
      Toast.error("Please, add description");
      return;
    }
    setDisable(true);
    createReview({
      variables: {
        description: review.description,
        ambienceRating: review.review,
        foodRating: review.review,
        serviceRating: review.review,
        restaurant: restaurantId
      },
      refetchQueries: [
        {
          query: GET_RESTUARNT_REVIEWS_QUERY, variables: {
            restaurantId: restaurantId

          }
        },
        { query: CURRENT_USER, nextFetchPolicy: 'no-cache', fetchPolicy: 'no-cache', variables: { restaurantId: restaurantId } }
      ]
    })
  };
  return (
    <div >

      <div className="book_element_2_el1">
        {reviewsResonse?.restaurantReviews.objects.length > 0 | currntUser?.me?.allegeableForReview ?
          <div className="title-1">Reviews</div> : null
        }
        <div className={style.reviewsConatiner}>
          {reviewsResonse?.restaurantReviews.objects.length > 0 ?
            reviewsResonse?.restaurantReviews.objects.map((e, i) => (
              <ReviewElement data={e} key={i} currentUser={currntUser?.me} />
            ))
            : null}
          {currntUser?.me?.allegeableForReview ?
            <div className="add_review">
              <Rating
                value={review.review}
                onChange={(event, r) => {
                  setReview({ ...review, review: r });
                }}
                precision={0.5}
              />
              <textarea
                className="form-control"
                placeholder="Write your review"
                rows="6"
                value={review.description}
                onChange={(event) => {
                  setReview({ ...review, description: event.target.value });
                }}
              />
              <div>
                <Button
                  disable={disable}
                  onClick={onaddReview}
                  variant="contained"
                  color="primary"
                  className="button_bok_menu sub"
                  id="button_bok_menu"
                >
                  Submit
                </Button>
              </div>
            </div>
            : null}
        </div>
      </div>
    </div>
  )
}
const Reservation = () => {
  const { id } = useParams();
  let query = useSearchQuery();

  const secondsString = query.get("time") ? query.get("time").split(':')[1] : moment().seconds()
  const hoursString = query.get("time") ? query.get("time").split(':')[0] : moment().hours()
  const targetOfferTime = moment(query.get("time"))
  const targetOfferDate = query.get("date") ? moment(query.get("date")) : moment()
  targetOfferDate.second(secondsString)
  targetOfferDate.hours(hoursString)
  const { loading: userError, error: errorUser, data: currntUser } = useQuery(CURRENT_USER, { variables: { restaurantId: id } });
  const [open, setOpen] = React.useState(false);
  const Days = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];
  const {
    loading,
    error,
    data: DataQuery,
  } = useQuery(GET_RESTURANT_BY_ID, {
    variables: {
      id,
      offerfilters: {
        date: targetOfferDate.format('YYYY-MM-DD'),
        // time: targetOfferDate.format('HH:mm')
      },
      offerMenuDate: targetOfferDate.format('YYYY-MM-DD')
    },
  });
  const [data, setdata] = useState(false);
  const [position, setPosition] = useState({
    latitude: -7.603869,
    longitude: 33.589886,
  });
  const [bookData, setBookData] = useState({
    guests: query.get("guests"),
    date: moment(query.get("date")),
    time: query.get("time"),
  });
  const handelReserve = ({ checkReservation }) => {

    const { isAvailable } = checkReservation;

    setOpen(false)
    if (isAvailable) {

      const tim = bookData.time
      const strTime = bookData.time.split(':');
      let strDate = bookData.date.hour(parseInt(strTime[0]));
      strDate = bookData.date.minute(parseInt(strTime[1]));
      if (window) {

        const reservationData =
        {
          date: strDate.format(),
          time: bookData.time,
          guests: bookData.guests,
          title: DataQuery.restaurantById.name,
          id: data.id,
          restaurantId: id,
          // code: bookData?.code,
        }

        window.localStorage.setItem('item', JSON.stringify(reservationData));


      }
      navigate("/reserving", {
        state: {
          item: {
            date: strDate.format(),
            time: bookData.time,
            guests: bookData.guests,
            title: DataQuery.restaurantById.name,
            id: data.id,
            restaurantId: id,
            // code: bookData?.code,
          },
        },
      });

    } else {
      setOpen(true)


    }

  }
  const [checkReservation, { data: datacheck, loading: isLoadindcheck, error: errorcheck, refetch }] = useLazyQuery(CHECK_RESERVATION_QUERY,
    { onCompleted: handelReserve, pollInterval: 0, nextFetchPolicy: 'no-cache', fetchPolicy: 'no-cache' })


  const [review, setReview] = useState({ review: 1, description: "" });
  const [disable, setDisable] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    reservation(id, setdata);
  }, []);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const onBook = (code) => {
    if (bookData.date == null) {
      Toast.error("Please, add date");
      return;
    }
    if (bookData.time == null) {
      Toast.error("Please, add time");
      return;
    }
    if (bookData.guests == 0 || bookData.guests == null) {
      Toast.error("Please, add number of guests");
      return;
    }
    if (code === undefined || code === null) {
      code = ""
    } else
      setBookData({ ...bookData, code: code });
    const tim = bookData.time
    const strTime = bookData.time.split(':');
    let strDate = bookData.date.hour(parseInt(strTime[0]));
    strDate = bookData.date.minute(parseInt(strTime[1]));
    // console.log(' strDate ==>', strDate.format())
    // console.log(' strDate ==>',  strDate.hour(),strDate.minutes())
    // console.log('  bookData ==>', bookData)
   

    checkReservation({
      variables: {
        reservation: {
          restaurant: id,
          datetime: strDate,
          numberOfGuests: bookData.guests,
        }
      },
      nextFetchPolicy: 'no-cache', fetchPolicy: 'no-cache'
    })
    // navigate("/reserving", {
    //   state: {
    //     item: {
    //       date: strDate.format(),
    //       time: bookData.time,
    //       guests: bookData.guests,
    //       title: DataQuery.restaurantById.name,
    //       id: data.id,
    //       restaurantId: id,
    //       code: code,
    //     },
    //   },
    // });
  };


  // useEffect(() => {
  //   console.log(' isAvailable ==> ')
  //   if (datacheck?.checkReservation) {
  //     console.log(' isAvailable ', datacheck?.checkReservation)
  //     setOpen(datacheck?.checkReservation)
  //   }

  // }, [datacheck?.checkReservation?.isAvailable,bookData.date,bookData.guests,bookData.time])
  if (loading) return <LoaderPage />;
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Reservation"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            All tables are reserved try to chanage  the date and time. or number of people
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>Disagree</Button> */}
          <Button onClick={handleClose} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      <div className="resNow">
        <div className="row elem__rese_top" style={{ width: "90vw", gap: "2vw" }}>
          <div className="image-grid">
            {DataQuery?.restaurantById.gallery?.map((e, index) => {
              return <img key={index} src={e?.file} />;
            })}
          </div>
        </div>

        <div className="d-flex book_container">

          <div className="book_element">
            <div className="date-time">
              <Calendar
                defaultValue={moment(query.get("date"))}
                onChange={(val) => setBookData({ ...bookData, date: val })}
              />
            </div>
          </div>
          <div className="book_element">
            <div className="date-time">
              <TimeElement
                value={bookData.time}
                onChange={(e) => setBookData({ ...bookData, time: e })}
                selectedDate={bookData?.date}
              />
            </div>
          </div>
          <div className="book_element">
            <GeustElement

              data={bookData.guests}
              onChange={(e) => setBookData({ ...bookData, guests: e })}
            />
          </div>
          <div className="book_element">
            <button
              onClick={onBook}
              className="btn-book"
              variant="contained"
              color="primary"
            >
              BOOK
            </button>
          </div>
        </div>
        <div className="book_element_2">
          <div className="book_element_2_el1">
            <div className="title-1">
              {DataQuery.restaurantById.name}
              <div className="text-muted adr">
                {DataQuery.restaurantById.address}
              </div>
            </div>
            <div className="soustitle">
              <p className="stat_left">
                <i className="icon-ic24-label"></i>
                {DataQuery.restaurantById.category.name}
              </p>
              <p className={"stat_right " + DataQuery.restaurantById.status}>
                <i className="far fa-info-circle"></i>
                {DataQuery.restaurantById.status}
              </p>
            </div>

            <div className="text-muted des">
              <br />
              {DataQuery?.restaurantById.description}
            </div>
            <br />
            {DataQuery?.restaurantById?.menu && (
              <MenuData data={DataQuery?.restaurantById.menu} />
            )}

            <div className="title-1 map">Location & Hours Of Work </div>

            <div className="element_map_work">
              {DataQuery?.restaurantById?.location?.coordinates ?
                <Map
                  restaurants={[DataQuery?.restaurantById]}
                  latitude={DataQuery?.restaurantById.location?.coordinates[0]}
                  longitude={DataQuery?.restaurantById.location?.coordinates[1]}
                /> : null}
              <div className="eleme_2_map">
                <div className="title-2 a">
                  {DataQuery?.restaurantById.address}
                </div>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <InputLabel
                      variant="standard"
                      htmlFor="uncontrolled-native"
                    ></InputLabel>
                    {DataQuery?.restaurantById?.workHours && (
                      <NativeSelect
                        defaultValue={10}
                        inputProps={{
                          name: "date",
                          id: "uncontrolled-native",
                        }}
                      >
                        <option>
                          Monday:{DataQuery.restaurantById.workHours.mondayFrom} -{" "}
                          {DataQuery.restaurantById.workHours.mondayTo}
                        </option>
                        <option>
                          Tuesday:{" "}
                          {DataQuery.restaurantById.workHours.tuesdayFrom} -{" "}
                          {DataQuery.restaurantById.workHours.tuesdayTo}
                        </option>
                        <option>
                          Wednesday:{" "}
                          {DataQuery.restaurantById.workHours.wednesdayFrom} -{" "}
                          {DataQuery.restaurantById.workHours.wednesdayTo}
                        </option>
                        <option>
                          Thursday:{" "}
                          {DataQuery.restaurantById.workHours.thursdayFrom} -{" "}
                          {DataQuery.restaurantById.workHours.thursdayTo}
                        </option>
                        <option>
                          Friday: {DataQuery.restaurantById.workHours.fridayFrom}{" "}
                          - {DataQuery.restaurantById.workHours.fridayTo}
                        </option>
                        <option>
                          Saturday:{" "}
                          {DataQuery.restaurantById.workHours.saturdayFrom} -{" "}
                          {DataQuery.restaurantById.workHours.saturdayTo}
                        </option>
                        <option>
                          Sunday: {DataQuery.restaurantById.workHours.sundayFrom}{" "}
                          - {DataQuery.restaurantById.workHours.sundayTo}
                        </option>
                      </NativeSelect>
                    )}
                  </FormControl>
                </Box>
                {DataQuery?.restaurantById.workHours && (
                  <div className="work_hours">
                    <div className="work_hours_element">
                      <div className="title_el">Monday</div>
                      <div className="hours_el">
                        <>
                          {DataQuery?.restaurantById.workHours.mondayFrom} -{" "}
                          {DataQuery?.restaurantById.workHours.mondayTo}
                        </>
                      </div>
                    </div>
                    <div className="work_hours_element">
                      <div className="title_el">Tuesday</div>
                      <div className="hours_el">
                        {" "}
                        {DataQuery.restaurantById.workHours.tuesdayFrom} -{" "}
                        {DataQuery.restaurantById.workHours.tuesdayTo}
                      </div>
                    </div>
                    <div className="work_hours_element">
                      <div className="title_el">WednesdayFrom</div>
                      <div className="hours_el">
                        {DataQuery.restaurantById.workHours.wednesdayFrom} -{" "}
                        {DataQuery.restaurantById.workHours.wednesdayTo}
                      </div>
                    </div>
                    <div className="work_hours_element">
                      <div className="title_el">Thursday</div>
                      <div className="hours_el">
                        {DataQuery.restaurantById.workHours.thursdayFrom} -{" "}
                        {DataQuery.restaurantById.workHours.thursdayTo}
                      </div>
                    </div>
                    <div className="work_hours_element">
                      <div className="title_el">FridayFrom</div>
                      <div className="hours_el">
                        {DataQuery.restaurantById.workHours.fridayFrom} -{" "}
                        {DataQuery.restaurantById.workHours.fridayTo}
                      </div>
                    </div>
                    <div className="work_hours_element">
                      <div className="title_el">Saturday</div>
                      <div className="hours_el">
                        {" "}
                        {DataQuery.restaurantById.workHours.saturdayIsOpen ? (
                          <>
                            {DataQuery.restaurantById.workHours.saturdayFrom} -{" "}
                            {DataQuery.restaurantById.workHours.saturdayTo}
                          </>
                        ) : (
                          "closed"
                        )}
                      </div>
                    </div>
                    <div className="work_hours_element">
                      <div className="title_el">Saturday</div>
                      <div className="hours_el">
                        {" "}
                        {DataQuery.restaurantById.workHours.sundayIsOpen ? (
                          <>
                            {DataQuery.restaurantById.workHours.sundayFrom} -{" "}
                            {DataQuery.restaurantById.workHours.sundayTo}
                          </>
                        ) : (
                          "closed"
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {DataQuery?.restaurantById?.extraServices.length > 0 && (
              <>
                <div className="title-1">Extra Services </div>
                <div className="row">
                  {DataQuery.restaurantById?.extraServices.map((e) => (
                    <div className="col elem_extra">{e.title}</div>
                  ))}
                </div>
              </>
            )}
          </div>

          <div className="book_element_2_el2">
            {DataQuery?.restaurantById?.rating && (
              <div className="elem_2_book_card">
                <ReviewDetails
                  rating={DataQuery?.restaurantById?.rating}
                  foodRating={DataQuery?.restaurantById?.foodRating}
                  ambienceRating={DataQuery?.restaurantById?.ambienceRating}
                  serviceRating={DataQuery?.restaurantById?.serviceRating}
                />
              </div>
            )}

            {/* {DataQuery?.restaurantById?.timeOffers.count > 0 || DataQuery?.restaurantById?.menuOffer ? */}
            <div className="elem_2_book_card">
              <br />
              <div className="text-center text-tabla-offer">Tabla Offers</div>
              <div className="offre-info">
                {DataQuery?.restaurantById?.menuOffer ?
                  <OfferElement
                    data={DataQuery?.restaurantById?.menuOffer}
                    onBook={() => {
                      onBook(menuOffer?.id);
                    }}
                  />
                  : null}
                {DataQuery?.restaurantById?.timeOffers?.objects.map((e, i) => (
                  <OfferElement
                    key={i}
                    data={e}
                    onBook={() => {
                      onBook(e?.id);
                    }}
                  />
                ))}
              </div>
            </div>

          </div>
        </div>
        <ReviewsBox reviews={DataQuery?.restaurantById?.reviews} restaurantId={id} />
        {/* <div className="book_element_2_el1">
        {DataQuery?.restaurantById?.reviews.objects.length > 0 | currntUser?.me?.allegeableForReview ?
          <div className="title-1">Reviews</div> : null
        }
        {DataQuery?.restaurantById?.reviews.objects.length > 0 ?
          DataQuery?.restaurantById?.reviews.objects.map((e, i) => (
            <ReviewElement data={e} key={i} />
          ))
          : null}
        {currntUser?.me?.allegeableForReview ?
          <div className="add_review">
            <Rating
              value={review.review}
              onChange={(event, r) => {
                setReview({ ...review, review: r });
              }}
              precision={0.5}
            />
            <textarea
              className="form-control"
              placeholder="Write your review"
              rows="6"
              value={review.description}
              onChange={(event) => {
                setReview({ ...review, description: event.target.value });
              }}
            />
            <div>
              <Button
                disable={disable}
                onClick={onaddReview}
                variant="contained"
                color="primary"
                className="button_bok_menu sub"
                id="button_bok_menu"
              >
                Submit
              </Button>
            </div>
          </div>
          : null}
      </div> */}

      </div>
    </>
  );
};

const OfferElement = ({ data, onBook }) => {
  return (
    <div className="elem_offer_b_c">
      <div className="ele_offer_book">
        <span>-{data.percentage}%</span> {data.title}
      </div>
      <p className="text-muted offre_inf">{data.description}</p>
      <div>
        <Button
          variant="contained"
          color="primary"
          className="button_bok_menu"
          onClick={() => onBook(data?.id)}
        >
          Make a reservation with this offer
        </Button>
      </div>
    </div>
  );
};

const WorkHoursElement = ({ data, index }) => {
  return (
    <div className="work_hours_element">
      <div className="title_el">{index}</div>
      <div className="hours_el">
        {data.open ? (
          <div>
            {" "}
            {data.from} - {data.to}{" "}
          </div>
        ) : (
          "closed"
        )}
      </div>
    </div>
  );
};

const GeustElement = ({ data, onChange, isOpen }) => {

  const [options, setptions] = useState([{ label: '1 person', value: 1 }, { label: '2 person', value: 2 }, { label: '3 person', value: 3 },
  { label: '4 person', value: 4 }, { label: '5 person', value: 5 }, { label: '6 person', value: 6 },
  { label: '7 person', value: 7 }, { label: '8 person', value: 8 }, { label: '9 person', value: 9 }, { label: '10 person', value: 10 }
  ])


  return (
    <SearchSelect options={options} placeholder='Number of people' IconBefore={<BsPerson className="iconSearch" />} onSelect={onChange} isOpen={isOpen}
      value={data} />
  );
};
const TimeElement = ({ data, onChange, isOpen, selectedDate, value }) => {
  const [options, setOptions] = useState([])
  const startHour = moment().hour(9);
  const EndHour = moment().hour(23);

  useEffect(() => {
    const hours = []

    let sh = 9
    console.log(' val > now ', moment(selectedDate).isSame(moment(), 'day'))
    if (moment(selectedDate).isSame(moment(), 'day') && moment().hour() > 9)
      sh = moment().hour() + 1

    while (sh < 25) {

      const pHours = moment().hour(sh).minutes(0).format('H:mm')

      hours.push({ value: pHours, label: pHours })
      const halfHour = moment().hour(sh).minutes(30).format('H:mm')
      hours.push(
        {
          value:
            halfHour, label: halfHour
        }
      );


      sh++;
    }
    setOptions(hours)
  }, [selectedDate])


  return (
    <SearchSelect options={options} placeholder='Time'
      value={value}
      IconBefore={<BsHourglass className="iconSearch" />}
      onSelect={onChange} isOpen={isOpen} />
  );
};
const MenuData = ({ data, averagePrice }) => {
  const [count, setCount] = useState(data.length > 4 ? 4 : data.length);
  const [open, setopen] = useState(false);

  const getAVG = () => {
    let i = 0;
    for (const el of Object.keys(data)) {
      i += parseFloat(data[el].price);
    }
    return Number(i / data.length).toFixed(2) + "$";
  };

  return (
    <div>
      {averagePrice && (
        <div className="text-muted">
          <FaRegMoneyBillAlt
            style={{
              width: "36px",
              height: "20px",
              color: "#fff",
              background: "#57b894",
              marginRight: "7px",
            }}
          />
          Average price {averagePrice}{" "}
        </div>
      )}

      {data.length > 0 && (
        <>
          <div className="title-1">Our menu</div>

          <div className="menu_elemnt_book">
            <br />
            <div className="gradMenu">
              {data.map((e, i) => (
                <div className="menu_item_book" key={i}>
                  <div className="menu_item_titl">{e.title}</div>
                  <div className="dashed_element"></div>
                  <div className="menu_item_price"> {e.price}DH </div>
                </div>
              ))}
            </div>
            {data.length > 4 && count <= 4 && (
              <div className="button_bok_menu_flex">
                <Button id="button_bok_menu">View full menu</Button>{" "}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};



export default Reservation;
