import { useState, useEffect } from "react";
import LoaderPage from "../../../../components/Shared/LoaderPage";
import { getMoreOptions,EditMoreOptions} from "../../../../services/restaurant-admin/admin-dashboard";
import  Toast from "../../../../services/Toast";
import LoadingButton from "@mui/lab/LoadingButton";
import SwitchElement from "../../../../components/Shared/Switch";
import { keyframes } from "@mui/material";

const MoreOptions =()=>{

    const [data,setData] = useState(false);
    const [newdata,setnewData] = useState(false);
    const [edit,setedit] = useState(false);
    const [disable,setdisable] = useState(false);
    const initData = ()=>{
        setData(-1);
        getMoreOptions((d)=>{setData(d);setnewData(d)});
    }
    
    
    const CancelMoreOptions = ()=>{
        setdisable(false);
        setedit(false);
        setnewData(data);
    }
    const onSave = ()=>{
        setdisable(1);
        EditMoreOptions(newdata,()=>{
            Toast.success("It was saved successfully");
            setdisable(false);
            setedit(false);
            setData(newdata);
        });
    }
    const onChange = (e,k,j)=>{
        if(edit===false) return;
        let x = {...newdata};
        x[j][k]=e.target.value;
        setnewData({...x});
    }
    
    useEffect(()=>{
        if(data===false)
            initData();
    });

    if(data === false || data === -1)
        return( <LoaderPage/>)
    
        
    return(
        <div className="d-flex flex-grow-1 container_details" style={{ maxWidth:"950px" }}>
           <div className="flex-grow-1 element-1-cont" >
                <div className="d-flex flex-column justify-content-start align-items-start">
                    <h3 className="card-title" style={{ padding: 0 }}>General settings</h3>
                    <div className="d-flex align-items-center">
                        <h5 className="text-muted" style={{ padding: 0,opacity:.9 }}>MoreOptions</h5>
                    </div>
                    <br/>
                </div>
                <br/>
                <div className="row">
                    <div className="col-sm-6">
                        <h3 className="card-title" style={{ padding: 0 }}>Facebook</h3>
                        <div className="col-12"><br/>
                            <label className="form-label label_primary" style={{ color:"#000" }}>Application ID</label>
                            <input type="text" value={newdata.facebook.id} onChange={(e)=>{onChange(e,'id','facebook')}}  className="form-control" placeholder="Address"/>
                        </div>
                        <div className="col-12"><br/>
                            <label className="form-label label_primary" style={{ color:"#000" }}>API Key</label>
                            <input type="text" value={newdata.facebook.apikey} onChange={(e)=>{onChange(e,'apikey','facebook')}}  className="form-control" placeholder="Address"/>
                        </div>
                        <div className="col-12"><br/>
                            <label className="form-label label_primary" style={{ color:"#000" }}>Secret</label>
                            <input type="text" value={newdata.facebook.secret} onChange={(e)=>{onChange(e,'secret','facebook')}}  className="form-control" placeholder="Address"/>
                        </div>
                        <div className="col-12"><br/>
                            <label className="form-label label_primary" style={{ color:"#000" }}>Description</label>
                            <input type="text" value={newdata.facebook.description} onChange={(e)=>{onChange(e,'description','facebook')}}  className="form-control" placeholder="Address"/>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <h3 className="card-title" style={{ padding: 0 }}>Google</h3>
                        <div className="col-12"><br/>
                            <label className="form-label label_primary" style={{ color:"#000" }}>Application ID</label>
                            <input type="text" value={newdata.google.id} onChange={(e)=>{onChange(e,'id','google')}}  className="form-control" placeholder="Address"/>
                        </div>
                        <div className="col-12"><br/>
                            <label className="form-label label_primary" style={{ color:"#000" }}>API Key</label>
                            <input type="text" value={newdata.google.apikey} onChange={(e)=>{onChange(e,'apikey','google')}}  className="form-control" placeholder="Address"/>
                        </div>
                        <div className="col-12"><br/>
                            <label className="form-label label_primary" style={{ color:"#000" }}>Secret</label>
                            <input type="text" value={newdata.google.secret} onChange={(e)=>{onChange(e,'secret','google')}}  className="form-control" placeholder="Address"/>
                        </div>
                        <div className="col-12"><br/>
                            <label className="form-label label_primary" style={{ color:"#000" }}>Description</label>
                            <input type="text" value={newdata.google.description} onChange={(e)=>{onChange(e,'description','google')}}  className="form-control" placeholder="Address"/>
                        </div>
                    </div>
                </div>
               
                <br/>
                {
                edit?
                    <div className="d-flex w-100 justify-content-center " style={{ height: "45px" }}>
                        {<LoadingButton variant="contained" color="secondary"  style={{ color:"#fff",flex:1,margin:"2px",borderRadius:"10px"}}  className="class_button"    disabled={disable} onClick={()=>{CancelMoreOptions()}} >Cancel</LoadingButton>}
                        {<LoadingButton variant="contained"color="primary" style={{ color:"#fff",flex:1,margin:"2px",borderRadius:"10px"}}  className="class_button" disabled={disable} onClick={()=>{onSave();}}>Done</LoadingButton>}
                    </div>
                :
                    <div className="d-flex w-100 justify-content-center " style={{ height: "45px" }}>
                        <LoadingButton variant="contained" color="primary"  style={{ color:"#fff",flex:1,margin:"2px",borderRadius:"10px"}}  className="class_button"    disabled={disable}onClick={()=>{setedit(true)}} >Edit</LoadingButton>
                    </div>
                }
            </div>

          
        </div>
    )
}

export default MoreOptions;