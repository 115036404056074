import React from "react";
import "../../../../styles/client/Client.css";
import "../../../../styles/client/ClientStatic.css";


export default function TemplateStatic(props) {
    return ( <
        React.Fragment >
        <
        div className = "centerizeContainer" > { props.comp } <
        /div> <
        /React.Fragment>
    )
}