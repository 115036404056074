import React from "react";
import LOGIN from "../pages/client-panel/login";
import REGISTER from "../pages/client-panel/register";
import { Routes, Route, Navigate, useSearchParams } from "react-router-dom";
import { ClientRouteData } from "../constants/routesData/client";
import { SearchParamsContext } from "../constants/contexts"
const ClientRoutes = () => {
  const getrole = localStorage.getItem("role");
  let [searchParams, setSearchParams] = useSearchParams();
  return (
    <div className="element">
      <SearchParamsContext.Provider value={{ searchParams, setSearchParams }} >
        <Routes>
          {/* <Route path="/login" element={getrole === "A_1" ?  <Navigate to="/user/profile"  />: <LOGIN/>}/> */}
          {/* <Route path="/login" element={<LOGIN />} />
        <Route path="/register" element={<REGISTER />} /> */}
          {ClientRouteData.map((e) => {
            return (
              <Route path={e.path} element={e.element}>
                {e?.children &&
                  e.children.map((c, i) => (
                    <Route
                      key={i}
                      path={c.path}
                      index={c?.index == true ? true : false}
                      element={c.element}
                    />
                  ))}
              </Route>
            );
          })}
        </Routes>
      </SearchParamsContext.Provider>
    </div>
  );
};

export default ClientRoutes;
