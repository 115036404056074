import React,{ useState, useEffect } from "react";
import LoaderPage from "../../../../components/Shared/LoaderPage";
import { getSearchBox,EditSearchBox} from "../../../../services/restaurant-admin/admin-dashboard";
import  Toast from "../../../../services/Toast";
import LoadingButton from "@mui/lab/LoadingButton";
import SwitchElement from "../../../../components/Shared/Switch";

const SearchBox =()=>{

    const [data,setData] = useState(false);
    const [newdata,setnewData] = useState(false);
    const [edit,setedit] = useState(false);
    const [disable,setdisable] = useState(false);
    const initData = ()=>{
        setData(-1);
        getSearchBox((d)=>{setData(d);setnewData(d)});
    }
    
    
    const CancelSearchBox = ()=>{
        setdisable(false);
        setedit(false);
        setnewData(data);
    }
    const onSave = ()=>{
        setdisable(1);
        EditSearchBox(data,()=>{
            Toast.success("It was saved successfully");
            setdisable(false);
            setedit(false);
        });
    }
    
    
    useEffect(()=>{
        if(data===false)
            initData();
    });
    const onChangeEl = (i,key)=>{
        if(!edit) return;
        let x =[...data];
        x[i]['data'][key]=!x[i]['data'][key];
        setData(x);
    }

    if(data === false || data === -1)
        return( <LoaderPage/>)
    
        
    return(
        <div className="d-flex flex-grow-1 container_details">
           <div className="flex-grow-1 element-1-cont" >
                <div className="d-flex flex-column justify-content-start align-items-start">
                    <h3 className="card-title" style={{ padding: 0 }}>Basic settings</h3>
                    <h5 className="text-muted" style={{ padding: 0,opacity:.9 }}>Search Box</h5><br/>
                </div>
                <br/>
                <div className="row">
                {
                        data.map((e,i)=>{

                            return(
                                <div key={i+"___"} className="col-12">
                                    <h3 className="card-title" style={{ padding: 0 }}>{e.section} </h3>
                                    <div>
                                        {Object.keys(e.data).map((a,b)=><div className="checkbox-element-show"> {a} <SwitchElement  checked={e.data[a]} onChange={()=>{onChangeEl(i,a)}} /></div>)}
                                    </div><br/><br/>
                                </div>
                            );
                        })
                    }
                </div>
                <br/><br/>
                {
                    edit?
                        <div className="d-flex w-100 justify-content-center " style={{ height: "45px" }}>
                            {<LoadingButton variant="contained" color="secondary"  style={{ color:"#fff",flex:1,margin:"2px",borderRadius:"10px"}}  className="class_button"    disabled={disable} onClick={()=>{CancelSearchBox()}} >Cancel</LoadingButton>}
                            {<LoadingButton variant="contained"color="primary" style={{ color:"#fff",flex:1,margin:"2px",borderRadius:"10px"}}  className="class_button" disabled={disable} onClick={()=>{onSave();}}>Done</LoadingButton>}
                        </div>
                    :
                        <div className="d-flex w-100 justify-content-center " style={{ height: "45px" }}>
                            <LoadingButton variant="contained" color="primary"  style={{ color:"#fff",flex:1,margin:"2px",borderRadius:"10px"}}  className="class_button"    disabled={disable}onClick={()=>{setedit(true)}} >Edit</LoadingButton>
                        </div>
                }
            </div>

          
        </div>
    )
}

 


export default SearchBox;