export function validateGeneral(value) {
    let out = [];
    if (value != null && value.trim() == "")
        out.push("Champ est requis");
    return out;
}
export function validateConfirmationPassword(value, confimedValue) {
    let out = [...validateGeneral(value)];
    if (out.length != 0)
        return out;
    if (confimedValue != null && value != confimedValue)
        out.push("Mot de passe ne correspands pas");
    return out;
}
export function validateEmail(value) {
    let out = [...validateGeneral(value)];
    if (out.length != 0)
        return out;
    if (value != null && !String(value)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ))
        out = ["Email format invalide"];
    return out;
}