import SearchLayout from "../../pages/client-panel/layout/SearchLayout";
import Home from "../../pages/client-panel/home";
import Login from "../../pages/client-panel/login";
import Register from "../../pages/client-panel/register";
import ForgetSendRequest from "../../pages/client-panel/forget/forget1";
import ForgetConfirmation from "../../pages/client-panel/forget/forget2";
import ForgetDone from "../../pages/client-panel/forget/forget3";
import ReservationDone from "../../pages/client-panel/static/ReservationDone";
import ErrorPage from "../../pages/client-panel/static/ErrorPage";
import RegisterRestaurant from "../../pages/client-panel/RegisterRestaurant";
import Contact from "../../pages/client-panel/contact";
import FAQ from "../../pages/client-panel/about";
import Help from "../../pages/client-panel/help";
import Reserving from "../../pages/client-panel/reserving";
import Reservation from "../../pages/client-panel/reservation";
import SearchClient from "../../pages/client-panel/search";
import FAQP from "../../pages/client-panel/FAQ";
import TermCondition from "../../pages/client-panel/TermCondition";
import PrivacyPolicy from "../../pages/client-panel/PrivacyPolicy";
import PasswordReset from "../../pages/client-panel/PasswordReset";
import VerificationPage from './../../pages/admin-dashboard/element/Pages/VerificationPage';
import Layout from "../../pages/client-panel/layout";

export const ClientRouteData = [

    {
        title: "",
        path: "/",
        element: <SearchLayout />,
        children: [
            // { title: "Home", path: "", index: true, element: <Home /> },
            { title: "Reservation", path: "reserving", element: <Reserving /> },
            { title: "Reservation", path: "reservation/:id", element: <Reservation /> },
            { title: "Register Restaurant", path: "register-restaurant", element: <RegisterRestaurant /> },
            { title: "Search", path: "search", element: <SearchClient /> },
            { title: "Search", path: "restaurants", element: <SearchClient /> },

        ]
    },

    {
        title: "",
        path: "/",
        element: <Layout />,
        children: [
            { title: "Home", path: "", index: true, element: <Home /> },
            { title: "Forget password", path: "password-reset/:token", element: <PasswordReset /> },
            { title: "Forget password", path: "forget", element: <ForgetSendRequest /> },
            { title: "Forget password", path: "forget/confirmation", element: <ForgetConfirmation /> },
            { title: "Forget password", path: "forget/confirmation/done", element: <ForgetDone /> },
            { title: "Reservation Successful", path: "reservation/done", element: <ReservationDone /> },
            { title: "Contact us", path: "contact", element: <Contact /> },
            { title: "About us", path: "about", element: <FAQ /> },
            { title: "Login", path: "Login", element: <Login /> },
            { title: "Register", path: "Register", element: <Register /> },
            { title: "Help", path: "help", element: <Help /> },
            { title: "Reservation", path: "reserving", element: <Reserving /> },
            { title: "Reservation", path: "reservation/:id", element: <Reservation /> },
            { title: "Register Restaurant", path: "register-restaurant", element: <RegisterRestaurant /> },
            { title: "Search", path: "search", element: <SearchClient /> },
            { title: "Search", path: "restaurants", element: <SearchClient /> },
            { title: "Search", path: "foods", element: <SearchClient /> },
            { title: "Page not found", path: "*", element: <ErrorPage /> },
            { title: "Help", path: "faq", element: <FAQP /> },
            { title: "terms", path: "terms", element: <TermCondition /> },
            { title: "privacy", path: "privacy", element: <PrivacyPolicy /> },
            { title: "VerificationPage", path: "VerificationPage", element: <VerificationPage /> },
        ]
    },


];