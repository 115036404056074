import Dashboard from "../../pages/admin-dashboard/element/Dashboard";
import HomePage from "../../pages/admin-dashboard/element/HomePage";
import Pages from "../../pages/admin-dashboard/element/Pages";
import AvailableRestaurants from "../../pages/admin-dashboard/element/Restaurants/AvailableRestaurants";
import BannedRestaurants from "../../pages/admin-dashboard/element/Restaurants/BannedRestaurants";
import AvailableUsers from "../../pages/admin-dashboard/element/Users/AvailableUsers";
import BannedUsers from "../../pages/admin-dashboard/element/Users/BannedUsers";
import Reports from "../../pages/admin-dashboard/element/Reports";
import Subscribtions from "../../pages/admin-dashboard/element/Subscribtions";
import BasicInfo from "../../pages/admin-dashboard/element/BasicSettings/BasicInfo";
import FAVicon from "../../pages/admin-dashboard/element/BasicSettings/FAVicon";
import Logo from "../../pages/admin-dashboard/element/BasicSettings/Logo";
import SocialMedia from "../../pages/admin-dashboard/element/BasicSettings/SocialMedia";
import Category from "../../pages/admin-dashboard/element/GeneralSettings/Category";
import Footer from "../../pages/admin-dashboard/element/GeneralSettings/Footer";
import Menu from "../../pages/admin-dashboard/element/GeneralSettings/Menu";
import MoreOptions from "../../pages/admin-dashboard/element/GeneralSettings/MoreOptions";
import NewsLetter from "../../pages/admin-dashboard/element/GeneralSettings/NewsLetter";
import SearchBox from "../../pages/admin-dashboard/element/GeneralSettings/SearchBox";
import {BsFillGridFill} from "react-icons/bs";
import {FaBook,FaPercent, FaInbox} from "react-icons/fa";
import {MdReport, MdSettingsApplications, MdSettingsSuggest} from "react-icons/md";
import {HiUsers} from "react-icons/hi";
import { AiFillHome } from "react-icons/ai";
import { RiPagesFill, RiMailStarFill, RiSettings4Fill } from "react-icons/ri";
import { BiRestaurant } from "react-icons/bi";


export const adminDashboard = [
    {   
        title:"Overview",
        icon:<BsFillGridFill className="svgicon1"/>,
        path:"Overview",
        element:<Dashboard/>
    },
    {   
        title:"Home Page",
        icon:<AiFillHome className="svgicon1"/>,
        path:"Home-page",
        element:<HomePage/>
    },
    {   
        title:"Pages",
        icon:<RiPagesFill className="svgicon1"/>,
        path:"Pages",
        element:<Pages/>
    },
    {   
        title:"Restaurants",
        icon:<BiRestaurant className="svgicon1"/>,
        path:"Restaurants",
        element:<AvailableRestaurants/>,
        children:[
            {title:"Available Restaurants",path:"Available",element:<AvailableRestaurants/>},
            {title:"Banned Restaurants",path:"Banned",element:<BannedRestaurants/>},
        ]
    },
    {   
        title:"Users",
        icon:<HiUsers className="svgicon1"/>,
        path:"Users",
        element:<AvailableUsers/>,
        children:[
            {title:"Available Users",path:"Available",element:<AvailableUsers/>},
            {title:"Banned Users",path:"Banned",element:<BannedUsers/>},
        ]
    },
    {   
        title:"Subscribtions",
        icon:<RiMailStarFill className="svgicon1"/>,
        path:"Subscribtions",
        element:<Subscribtions/>
    },
    {   
        title:"Reports",
        icon:<MdReport className="svgicon1"/>,
        path:"Reports",
        element:<Reports/>
    },
    {   
        title:"Basic settings",
        icon:<RiSettings4Fill className="svgicon1"/>,
        path:"Basic-settings",
        element:<FAVicon/>,
        children:[
            {title:"FAV icon",path:"Favicon",element:<FAVicon/>},
            {title:"Logo",path:"Logo",element:<Logo/>},
            {title:"Social Media",path:"Social-Media",element:<SocialMedia/>},
            {title:"Basic info",path:"Basic-info",element:<BasicInfo/>},
        ]
    },
    
    {   
        title:"General settings",
        icon:<MdSettingsSuggest className="svgicon1"/>,
        path:"General-settings",
        element:<Menu/>,
        children:[
            {title:"Menu",path:"Menu",element:<Menu/>},
            {title:"Search box",path:"Search-box",element:<SearchBox/>},
            {title:"Category",path:"Category",element:<Category/>},
            {title:"Footer",path:"Footer",element:<Footer/>},
            {title:"News letter",path:"News-letter",element:<NewsLetter/>},
            {title:"More options",path:"More-options",element:<MoreOptions/>},
        ]
    },
];