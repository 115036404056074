import { LinkActiveOrNot, LinkswithChildren } from "./LinkActiveOrNot";
import "../../styles/LeftNavBar.css";
import { useLeftNavBarClose } from "../../hooks/LeftNavBar";
import { LogoTabla } from "./Logo";
import { Link } from "react-router-dom";

const LeftNavbarNonResponsive = ({ data, url, logo }) => {
    const CloseNVInMobile = () => { useLeftNavBarClose(); }
    return (
        <div className="left_nav_ba card flag desktopOnly" id="lft_nv_ad" >
            <div className="text-icons-lnbv Back-left"><Link to='/'><span style={{color:'#000'}} > <i className="far fa-chevron-left"></i>Back </span></Link></div>
            {logo === true ? <div className="logo_lfnvb"><LogoTabla /></div> : ""}
            {
                data.map(e => {
                    if (e?.children)
                        return (<LinkswithChildren onClick={() => { CloseNVInMobile() }} element={e} urlcontainer={url} />)
                    return (
                        <div>

                            <LinkActiveOrNot onClick={() => { CloseNVInMobile() }} to={e.path} urlcontainer={url} >
                                <div className="display-flex">
                                    <div className="icon">{e.icon} </div>
                                    <div className="title_">{e.title}</div></div>

                            </LinkActiveOrNot>
                        </div>
                    );
                })
            }
        </div>
    );
}


export default LeftNavbarNonResponsive;