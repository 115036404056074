import React from "react";
import "../../styles/client/Client.css";
import { ButtonPrimary } from "./button";

export default function HelpElement(props)
{
    return (
        <div className={`card-general centerText ph-2 ${props.className}`}> 
            <div style={{fontSize:"30px"}} className="w-100 title-1 green mv-4p">{props.title}</div>
            <div className="simple-text mv-4p">{props.text}</div>
            <ButtonPrimary style={{width:"auto"}} className="mx-auto mv-4p" text={props.button} click={props.navigate}/>
        </div>
    );
}