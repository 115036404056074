import FetchAxios from "../Fetch";

const UpcomingOrdersDatat = [
    {
        orderN:"001",
        user:{name:"llel lele",email:"mmeme@dldld.ddml",phoneNumber:"021121",image:"https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"},
        date_creation:"2022-03-08 15:59:59.997",
        date_reservation:"2022-12-31 23:59:59.997",
        guest:1,
        note:"mllml",
    },
    {
        orderN:"002",
        user:{name:"psos lele",email:"lkl@dldld.ddml",phoneNumber:"021121",image:"https://images.pexels.com/photos/428364/pexels-photo-428364.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"},
        date_creation:"2022-03-09 15:59:59.997",
        date_reservation:"2022-12-31 23:59:59.997",
        guest:2,
        note:"6565",
    },
    {
        orderN:"001",
        user:{name:"llel lele",email:"mmeme@dldld.ddml",phoneNumber:"021121",image:"https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"},
        date_creation:"2022-03-08 15:59:59.997",
        date_reservation:"2022-12-31 23:59:59.997",
        guest:1,
        note:"mllml",
    },
  
];

const OffersData = [
    {id:1, percentage:"15", title:"pe mllmdll ", description:"dmldl mldmlm mlm lldmld lm lm !"},
    {id:2, percentage:"20", title:"pe mllmdll ", description:"dmldl mldmlm mlm lldmld lm lm !"},
    {id:3, percentage:"12", title:"pe mllmdll ", description:"dmldl mldmlm mlm lldmld lm lm !"},
];

const MenuData = [
    {id:1, price:"15", title:"pe mllmdll ", description:"dmldl mldmlm mlm lldmld lm lm !"},
    {id:2, price:"20", title:"pe mllmdll ", description:"dmldl mldmlm mlm lldmld lm lm !"},
    {id:3, price:"12", title:"pe mllmdll ", description:"dmldl mldmlm mlm lldmld lm lm !"},
];

const InboxData = [
    {
        user:{name:"psos lele",email:"lkl@dldld.ddml",phoneNumber:"021121",image:"https://images.pexels.com/photos/428364/pexels-photo-428364.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"},
        conversation:[
            {user:"psos lele",message:"ldlldld dlkdldk dldkd lkdkdkd lkdkdkl lkdkdkld. mldld dlldlkdkl"}
            ,{user:"admin",message:"ldkld dlkdkl dkkld.dlkld"}
        ]
    },
    {
        user:{name:"llel lele",email:"mmeme@dldld.ddml",phoneNumber:"021121",image:"https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"},
        conversation:[
            {user:"psos lele",message:"ldlldld dlkdldk dldkd lkdkdkd lkdkdkl lkdkdkld. mldld dlldlkdkl"}
        ]
    },
    
];

const ReviewsData = [
    {
        user:{name:"psos lele",email:"lkl@dldld.ddml",phoneNumber:"021121",image:"https://images.pexels.com/photos/428364/pexels-photo-428364.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"},
        review:{
            message:" lkl  lm qlms lmql m lsq, ùm sqù ms ml qs mpsd mldssld msdlms mqsp^, lksdsdk lksdlsd pqsp^sq dksjsdk",
            foodrating:4.2,
            servicerating:5,
            ambiencerating:4.2,
            rating:4.3,

        }
    }
];

const RestaurantsData = [
    {
        name:"mmd dldkd md",
        image:"https://images.pexels.com/photos/428364/pexels-photo-428364.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        phone:"06 2 222322 ",
        address:"dkdkl dldldl ldld",
        website:"lll ",
        category:"llll",
        email:"ldld@dldld.dmldlm"
    },
    {
        name:"mmd dldkd md",
        image:"https://images.pexels.com/photos/428364/pexels-photo-428364.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        phone:"06 2 222322 ",
        address:"dkdkl dldldl ldld",
        website:"lll ",
        category:"llll",
        email:"ldld@dldld.dmldlm"
    }
];
const UsersData = [
    {name:"llel lele",email:"mmeme@dldld.ddml",phoneNumber:"021121",image:"https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"},
    {name:"llel lele",email:"mmeme@dldld.ddml",phoneNumber:"021121",image:"https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"},
    {name:"llel lele",email:"mmeme@dldld.ddml",phoneNumber:"021121",image:"https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"},
];

const SocialMediaData = {facebook:"",linkdin:"",youtube:"",instagram:"",};
const BasicInfoData = {WebsiteTitle:"",Contactemail:"",PhoneNumber:"",Address:"",};
/****** OverView ******/
export const getDashboardData = (onDone)=>{
    return onDone(
        {
            overview:{users:825,restaurants:567,reservations:775,cities:17},
            statics:[31, 40, 28, 51, 42, 109, 100],
            users:UsersData,
            restaurants:RestaurantsData,
            reports:UsersData
        }
    );
    FetchAxios.get("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone(e.response);
        }
    });
}

/****** Available Restaurants ******/
export const getAvailableRestaurants = (onDone)=>{
    return onDone(RestaurantsData);
    FetchAxios.get("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone(e.response);
        }
    });
}
export const BanAvailableRestaurants = (data,onDone)=>{
    return onDone();
    FetchAxios.post("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone();
        }
    });
    
}
/****** Banned Restaurants ******/
export const getBannedRestaurants = (onDone)=>{
    return onDone(RestaurantsData);
    FetchAxios.get("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone(e.response);
        }
    });
}
export const UnBanRestaurants = (data,onDone)=>{
    return onDone();
    FetchAxios.post("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone();
        }
    });
    
}
/****** Available Users ******/
export const getAvailableUsers = (onDone)=>{
    return onDone(UsersData);
    FetchAxios.get("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone(e.response);
        }
    });
}
export const BanAvailableUsers = (data,onDone)=>{
    return onDone();
    FetchAxios.post("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone();
        }
    });
    
}
/****** Banned Users ******/
export const getBannedUsers = (onDone)=>{
    return onDone(UsersData);
    FetchAxios.get("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone(e.response);
        }
    });
}
export const UnBanUsers = (data,onDone)=>{
    return onDone();
    FetchAxios.post("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone();
        }
    });
    
}
/****** Social Media ******/
export const getSocialMedia = (onDone)=>{
    return onDone(SocialMediaData);
    FetchAxios.get("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone(e.response);
        }
    });
}
export const EditSocialMedia = (data,onDone)=>{
    return onDone();
    FetchAxios.post("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone();
        }
    });
    
}
/****** Social Media ******/
export const getBasicInfo = (onDone)=>{
    return onDone(BasicInfoData);
    FetchAxios.get("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone(e.response);
        }
    });
}
export const EditBasicInfo = (data,onDone)=>{
    return onDone();
    FetchAxios.post("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone();
        }
    });
    
}
/****** Fav Icon ******/
export const getFavIcon = (onDone)=>{
    return onDone("https://images.pexels.com/photos/428364/pexels-photo-428364.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500");
    FetchAxios.get("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone(e.response);
        }
    });
}
export const EditFavIcon = (data,onDone)=>{
    return onDone();
    FetchAxios.post("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone();
        }
    });
}
/****** Logo ******/
export const getLogo = (onDone)=>{
    let data = {
        mobile:"https://images.pexels.com/photos/428364/pexels-photo-428364.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    }
    return onDone(data);
    FetchAxios.get("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone(e.response);
        }
    });
}
export const EditLogo = (data,onDone)=>{
    return onDone();
    FetchAxios.post("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone();
        }
    });
    
}
/****** Subscribtions ******/
export const getSubscribtions = (onDone)=>{
    let data = ["ldlld@dkkd.dmdm","dlmldlmd@ldlld.lmdlmd"];
    return onDone(data);
    FetchAxios.get("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone(e.response);
        }
    });
}
export const sendSubscribtions = (data,onDone)=>{
    return onDone();
    FetchAxios.post("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone();
        }
    });
    
}
/****** Reports ******/
export const getReports = (onDone)=>{
    return onDone(UsersData);
    FetchAxios.get("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone(e.response);
        }
    });
}
export const sendReports = (data,onDone)=>{
    return onDone();
    FetchAxios.post("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone();
        }
    });
    
}
/****** Home Page ******/
export const getHomePage = (onDone)=>{
    return onDone({HeroSection:{title:"555",description:"tet ehetetb ",image:"null"},OtherSection:[{title:"Feautured restaurants",category:"1",column:2},{title:"Popular restaurants",category:"2",column:2},{title:"Categories",category:"3",column:2}]});
    FetchAxios.get("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone(e.response);
        }
    });
}
export const saveHomePage = (data,onDone)=>{
    return onDone();
    FetchAxios.post("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone();
        }
    });
    
}

/****** NewsLetter ******/
export const getNewsLetter = (onDone)=>{
    return onDone({show:true,title:"ddd",subtitle:"llmllm",email:"lkklkl"});
    FetchAxios.get("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone(e.response);
        }
    });
}
export const EditNewsLetter = (data,onDone)=>{
    return onDone();
    FetchAxios.post("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone();
        }
    });
    
}
/****** Page ******/
export const getPages = (onDone)=>{
    return onDone(
        {
            VerficationPage:{title:"555",description:"tet ehetetb ",image:"null"},
            FAQPage:[
                {
                title:"General",
                question:[{question:"mmldlmd  ?",answer:"dlmd  d;,d ;,dl, l;d;"}]
                },
                {
                title:"General",
                question:[{question:"mmldlmd  ?",answer:"dlmd  d;,d ;,dl, l;d;"}]
                },
                {
                title:"General",
                question:[{question:"mmldlmd  ?",answer:"dlmd  d;,d ;,dl, l;d;"}]
                },
            ]
        }
        );
    FetchAxios.get("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone(e.response);
        }
    });
}
export const EditPages = (data,onDone)=>{
    return onDone();
    FetchAxios.post("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone();
        }
    });
    
}
/****** General settings Menu ******/
export const getGeneralsettingsMenu = (onDone)=>{
    return onDone(
        {
            Header:[{title:"Home",url:"#"},{title:"Restaurant",url:"restaurants"}],
            Footer:[
                {
                    show:true,
                    menu:[{title:"Home",url:"#"},{title:"Restaurant",url:"restaurants"}]
                },
                {
                    show:true,
                    menu:[{title:"Home",url:"#"},{title:"Restaurant",url:"restaurants"}]
                },
                {
                    show:true,
                    menu:[{title:"Home",url:"#"},{title:"Restaurant",url:"restaurants"}]
                }
            ]
        }
        );
    FetchAxios.get("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone(e.response);
        }
    });
}
export const EditGeneralsettingsMenu = (data,onDone)=>{
    return onDone();
    FetchAxios.post("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone();
        }
    });
    
}
/****** General settings Category ******/
export const getGeneralsettingsCategory = (onDone)=>{
    return onDone(
        [{id:1,image:"dd",url:"mmllmml",title:"lll"},{id:2,image:"dd",url:"mmllmml",title:"lll"}]
        );
    FetchAxios.get("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone(e.response);
        }
    });
}
export const AddGeneralsettingsCategory = (data,onDone)=>{
    return onDone({id:1,image:"dd",url:"mmllmml",title:"lll"});
    FetchAxios.post("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone();
        }
    });
    
}
export const EditGeneralsettingsCategory = (data,onDone)=>{
    return onDone({id:1,image:"dd",url:"mmllmml",title:"lll"});
    FetchAxios.post("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone();
        }
    });
    
}
export const DeleteGeneralsettingsCategory = (data,onDone)=>{
    return onDone();
    FetchAxios.post("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone();
        }
    });
    
}
/****** General settings Footer ******/
export const getGeneralsettingsFooter = (onDone)=>{
    return onDone(
        {
            menus:[{title:"l5445ll"},{title:"lll"},{title:"sslll"},{title:"lllssss"}],
            description:"ldlldld",
            copyright:"llll"
        }
        );
    FetchAxios.get("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone(e.response);
        }
    });
}
export const EditGeneralsettingsFooter = (data,onDone)=>{
    return onDone({id:1,image:"dd",url:"mmllmml",title:"lll"});
    FetchAxios.post("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone();
        }
    });
    
}
/****** General settings MoreOptions ******/
export const getMoreOptions = (onDone)=>{
    return onDone(
        {
            google:{id:",kmllm",apikey:"klkllk",secret:"llkkllk",description:"pppp^p"},
            facebook:{id:",kmllm",apikey:"klkllk",secret:"llkkllk",description:"pppp^p"}
        }
    );
    FetchAxios.get("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone(e.response);
        }
    });
}
export const EditMoreOptions = (data,onDone)=>{
    return onDone();
    FetchAxios.post("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone();
        }
    });
    
}
/****** General settings SearchBox ******/
export const getSearchBox = (onDone)=>{
    return onDone(
        [   
            {
                section :"Home",
                data : {"Search area":true,"Date":true,"Time":true,"Category":false,"City":true,"Guests":false}
            },
            {
                section :"Search page",
                data : {"Search area":true,"Date":true,"Time":true,"Category":false,"City":true,"Guests":false}
            },
            {
                section :"Booking page",
                data : {"Search area":true,"Date":true,"Time":true,"Category":false,"City":true,"Guests":false}
            },
        ]
    );
    FetchAxios.get("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone(e.response);
        }
    });
}
export const EditSearchBox = (data,onDone)=>{
    return onDone();
    FetchAxios.post("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone();
        }
    });
    
}
/************** Pages  *************/
export const getVerificationPage = (onDone) =>{
    return(
        onDone({
            image:"https://images.pexels.com/photos/428364/pexels-photo-428364.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
            description:"Verify your Inforamtion" 
        })
    )
}
export const EditVerificationPage  = (data,onDone)=>{
    return onDone();
    FetchAxios.post("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone();
        }
    });
    
}
export const getFAQPage = (onDone) =>{
    return(
        onDone(
            [
                {
                    title:"General",
                    data:[{question:"dkdkdldlk",answer:"slmdmldlmd ldmdkl mlkddk kmdkd"},{question:"dkdkdldlk",answer:"slmdmldlmd ldmdkl mlkddk kmdkd"}]
                },
                {
                    title:"Gener55al",
                    data:[{question:"dkdkdldlk",answer:"slmdmldlmd ldmdkl mlkddk kmdkd"},{question:"dkdkdldlk",answer:"slmdmldlmd ldmdkl mlkddk kmdkd"}]
                },
                {
                    title:"Test 2 ",
                    data:[{question:"dkdkdldlk",answer:"slmdmldlmd ldmdkl mlkddk kmdkd"},{question:"dkdkdldlk",answer:"slmdmldlmd ldmdkl mlkddk kmdkd"}]
                }, 
                {
                    title:"ksklslk",
                    data:[{question:"dkdkdldlk",answer:"slmdmldlmd ldmdkl mlkddk kmdkd"},{question:"dkdkdldlk",answer:"slmdmldlmd ldmdkl mlkddk kmdkd"}]
                }
            ]
        )
    )
}
export const EditFAQPage  = (data,onDone)=>{
    return onDone();
    FetchAxios.post("/Orders/Upcoming")
    .then(e=>{
        if(e.response){
            onDone();
        }
    });
    
}




export const getTermConditionPage = (onDone)=>{
    return(
        onDone(
            [
                {
                    title:"General",
                    subtitle:"Lorem Ipsum is simply dummy",
                    data:`Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.
                    The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.`
                },
                {
                    title:"Cookies",
                    subtitle:"Lorem Ipsum is simply dummy",
                    data:`Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.
                    The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.`
                },
                {
                    title:"Links",
                    subtitle:"Lorem Ipsum is simply dummy",
                    data:`Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.
                    The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.`
                },
            ]
        )
    )
}

export const getPrivacyPolicyPage = (onDone)=>{
    return(
        onDone(
            [
                {
                    title:"General",
                    subtitle:"Lorem Ipsum is simply dummy",
                    data:`Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.
                    The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.`
                },
                {
                    title:"Cookies",
                    subtitle:"Lorem Ipsum is simply dummy",
                    data:`Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.
                    The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.`
                },
                {
                    title:"Links",
                    subtitle:"Lorem Ipsum is simply dummy",
                    data:`Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.
                    The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.`
                },
            ]
        )
    )
}
