import React, { useState } from "react";
import "../../../styles/client/Client.css";
import "../../../styles/client/ClientAbout.css";
import AboutBand from "./band";
import AboutFooterTop from "./footer1";
import AboutFooterButtom from "./footer2";
import AboutHeader from "./header";


export default function About()
{
    const [data,setData]=useState({});
    return (
        <React.Fragment>
            <div className="row">
                <div className="col-12 mv-5p">
                    <AboutHeader/>
                </div>
                <div className="col-12">
                    <AboutBand/>
                </div>
                <div className="col-12 mv-5p">
                    <AboutFooterTop/>
                </div>
                <div className="col-12">
                    <AboutFooterButtom/>
                </div>
            </div>
        </React.Fragment>
    )
}