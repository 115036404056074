import { useState, useEffect } from "react";
import LoaderPage from "../../../../components/Shared/LoaderPage";
import { getVerificationPage,EditVerificationPage} from "../../../../services/restaurant-admin/admin-dashboard";
import  Toast from "../../../../services/Toast";
import LoadingButton from "@mui/lab/LoadingButton";

const VerificationPage =()=>{

    const [data,setData] = useState(false);
    const [edit,setedit] = useState(false);
    const [disable,setdisable] = useState(false);
    const initData = ()=>{
        setData(-1);
        getVerificationPage((d)=>{setData(d);});
    }
    
    
    const CancelVerificationPage = ()=>{
        setdisable(false);
        setedit(false);
    }
    const onSave = ()=>{
        setdisable(1);
        EditVerificationPage(data,(a)=>{
            Toast.success("It was saved successfully");
            setdisable(false);
            setedit(false);
        });
    }
    const onChange = (e)=>{
        var file = e.target.files[0];
        if(file){
            var reader = new FileReader();
            reader.onload = function(){
                let d = e.target.parentNode;
                d.querySelector('.with_image').src= reader.result;
            }
            reader.readAsDataURL(file);
        }
    }
    
    useEffect(()=>{
        if(data===false)
            initData();
    });
    
    if(data === false || data === -1)
        return( <LoaderPage/>)
    return(
        <div className="d-flex flex-grow-1 container_details">
           <div className="flex-grow-1 element-1-cont" >
           <br/>
                <div className="row ">
                    <div className="col-12">
                    
                    <br/>
                        <div className="element_image">
                            <input disabled={!edit}  onChange={(e)=>onChange(e)} type="file"  className="input_image" accept="image/*"/>
                            <div className="no_image" style={{ display:(data.image!=null?"none":"block" )}}  >
                                <div className="plus_icon">+</div>
                                <div>Upload vector</div>
                            </div>
                            <img className="with_image " src={data.image}  style={{ display:(data.image==null?"none":"flex" )}}  />
                        </div><br/>
                        <div style={{ maxWidth:"500px",margin:"10px auto" }}>
                            <input disabled={!edit} value={data.description} className="form-control" defaultValue={data?.title} onChange={(e)=>{setData({...data,description:e.target.value})}}  style={{background:"#fff",height:"60px",borderRadius:"10px"}}/>
                        </div>
                        <br/>
                        <div className={"d-flex w-100 justify-content-center col-sm-6 "+(!edit?"":"d-none")}  style={{ height: "45px" }}>
                            <LoadingButton variant="contained" color="primary"  style={{ color:"#fff",flex:1,margin:"2px",borderRadius:"10px"}}  className="class_button"    disabled={disable}onClick={()=>{setedit(true)}} >Edit</LoadingButton>
                        </div>
                    </div>
                    <div className={"col-12 "+(edit?"":"d-none")}>
                        <div className="d-flex w-100 justify-content-center " style={{ height: "45px" }}>
                            {<LoadingButton variant="contained" color="secondary"  style={{ color:"#fff",flex:1,margin:"2px",borderRadius:"10px"}}  className="class_button"    disabled={disable} onClick={()=>{CancelVerificationPage()}} >Cancel</LoadingButton>}
                            {<LoadingButton variant="contained"color="primary" style={{ color:"#fff",flex:1,margin:"2px",borderRadius:"10px"}}  className="class_button" disabled={disable} onClick={()=>{onSave();}}>Done</LoadingButton>}
                        </div>
                    </div>
                </div>
                <br/>
            </div>
        </div>
    )
}

export default VerificationPage;