import React from "react";
import "../../../styles/client/Client.css";
import {
    Drawer,
    List,
    ListItem,
    ListItemText,
  } from "@material-ui/core";
  import { Link } from "react-router-dom";

export default function QuickLinksMenuItem(props)
{
    return (
        <div className="w-100 customLinks">
            <ListItem>
                <ListItemText>
                <Link onClick={(event)=>{props.done();}} to="/faq">FAQ</Link>
                </ListItemText>
            </ListItem>
            <ListItem>
                <ListItemText>
                <Link onClick={(event)=>{props.done();}} to="/policy">Privacy Policy</Link>
                </ListItemText>
            </ListItem>
            <ListItem>
                <ListItemText>
                <Link onClick={(event)=>{props.done();}} to="/terms">Terms and conditions</Link>
                </ListItemText>
            </ListItem>
            <ListItem >
                <ListItemText>
                <Link onClick={(event)=>{props.done();}} to="/contact">Contact us</Link>
                </ListItemText>
            </ListItem>
        </div>
    );
}