import React, { useState, useEffect, useRef } from "react";
import LoaderPage from "../../../components/Shared/LoaderPage";
import Toast from "../../../services/Toast";
import { Button, Avatar } from "@mui/material";
import { RiCloseLine } from "react-icons/ri";
import { getFavorite, removeFavorite } from "../../../services/client/dashboard";
import { useMutation, useQuery } from "@apollo/client";
import { CURRENT_USER, MY_LIKED_RESTAURNAT_QUERY } from "../../../services/Queries/UserQuery";
import { RESTAURANT_KEYS } from "../../../constants/keys";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack"
import { UNLIKE_A_RESTAURANT } from "../../../services/mutations/userMustation";
const Favorite = () => {

    const [data, setData] = useState(false);
    const [selectedItem, setselectedItem] = useState(-1);
    const [DatatoShow, setDatatoShow] = useState([]);
    const [disable, setdisable] = useState(false);
    const [page, setPage] = useState(null);
    const {
        loading,
        error,
        data: restaurants,
        refetch
    } = useQuery(MY_LIKED_RESTAURNAT_QUERY, {
        variables: {
            page: page,
            // pageSize: 2,
        },
    });
    const onCompletedHandlerUnLikeRestaurantMutation = ({ unlikeRestaurant }) => {
        console.log('unLikeRestaurant  ', unlikeRestaurant)
        const { success, errors } = unlikeRestaurant
       
        if (success) {
            Toast.success("It was deleted successfully");
            refetch()
            setselectedItem(-1);
            setdisable(false);

        }
    }
    const onErrorHandlerunLikeRestaurantMutation = (data) => {

    }
    const [
        unLikeRestaurant,
        { loading: loadingunLikeRestaurant, error: errorunLikeRestaurant, data: dataunLikeRestaurant },
    ] = useMutation(UNLIKE_A_RESTAURANT, {
        onCompleted: onCompletedHandlerUnLikeRestaurantMutation,
        onError: onErrorHandlerunLikeRestaurantMutation,
    });
    const detailsref = useRef(null);
    const initData = () => {
        setData(-1);

    }

    const setSelectedData = (i) => {
        setselectedItem(i);
        setDatatoShow(data[i]);

    }

    const onRemoveFavorite = (data) => {
        setdisable(true);
        unLikeRestaurant({ variables: { restaurant: data?.id } })

    }


    useEffect(() => {
        if (data === false)
            initData();
    });

    if (loading)
        return (<LoaderPage />)
    const handlePageChange = (e, val) => {
        setPage(val);
    };


    return (
        <div className="d-flex flex-grow-1 container_details">
            {restaurants?.likedRestaurant?.objects.length > 0 ?
                <>
                    <div className="flex-grow-1 element-1-cont" >
                        <div className="d-flex justify-content-between align-items-center">
                            <h3 className="card-title" style={{ padding: 0 }}>My reservations</h3>
                        </div>
                        <br />
                        <div className="d-flex " style={{ flexDirection: "column" }}>
                            {restaurants.likedRestaurant.objects.map((e, i) => <FavoriteElement key={i} item={i}
                                onRemoveFavorite={onRemoveFavorite} disable={disable} onClick={() => { setSelectedData(i); }}
                                data={e} />)}
                        </div>
                        <Stack spacing={2} style={{ alignItems: "center" }}>
                            <Pagination
                                count={restaurants?.likedRestaurant?.pages}
                                page={restaurants?.likedRestaurant?.page}
                                onChange={handlePageChange}
                                shape="rounded"
                                color="success"
                            />
                        </Stack>
                    </div>

                </>
                :
                <div className="d-flex justify-content-center align-items-center card flex-grow-1" style={{ borderRadius: "10px", minHeight: "50vh" }}>
                    <h3>There is no data </h3>
                    <br />
                </div>
            }

        </div>
    )
}


const FavoriteElement = ({ data, onClick, item, disable, onRemoveFavorite }) => {

    return (
        <div className="d-flex col-12 card order_container_element rder_container_element_1" style={{ cursor: "pointer", maxWidth: "700px" }} onClick={() => { onClick() }}>
            <div className="d-flex flex-grow-1 " >
                <Avatar className="avatar_img" src={data.image} variant="rounded" />
                <div className="d-flex justify-content-center flex-column">
                    <h3 className="card-title titleFav">{data.name}</h3>
                    <small className="descFav">{data.address}</small>
                    <div className="lineRow">
                        <div className="vide">
                        </div>
                        <Button disabled={disable} className="button_right_icon_cancel remove" color="error" variant="contained" onClick={() => { onRemoveFavorite(data) }} startIcon={<RiCloseLine />}>Remove</Button>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Favorite;