import { gql } from "@apollo/client";

export const LOG_IN = gql`
  # User log in

  mutation tokenAuth($password: String!, $email: String!) {
    tokenAuth(password: $password, email: $email) {
      token
      success
      errors
      user {
        email
        role
      }
      unarchiving
      refreshToken
    }
  }
`;

export const REGISTER_MUTATION = gql`
  mutation register(
    $firstName: String!
    $lastName: String!
    $email: String!
    $phone: String
    $city: String
    $country: String
    $password1: String!
    $password2: String!
  ) {
    register(
      firstName: $firstName
      lastName: $lastName
      email: $email
      phone: $phone
      city: $city
      country: $country
      password1: $password1
      password2: $password2
    ) {
      success
      errors
      refreshToken
      token
    }
  }
`;

export const PASSWORD_RESET_EMAIL_MUTATION = gql`
  # Send password reset email.
  #  For non verified users, send an activation email instead.
  #  Accepts both primary and secondary email.
  #  If there is no user with the requested email, a successful response is returned.

  mutation SendPasswordResetEmail($email: String!) {
    sendPasswordResetEmail(email: $email) {
      success
      errors
    }
  }
`;

export const PASSWORD_RESET_MUTATION = gql`
  mutation passwordReset(
    $newPassword1: String!
    $newPassword2: String!
    $token: String!
  ) {
    passwordReset(
      newPassword1: $newPassword1
      newPassword2: $newPassword2
      token: $token
    ) {
      success
      errors
    }
  }
`;
export const REFRESH_TOKEN_MUTATION_JSON = (refreshToken) => {
  return JSON.stringify({
    query: `
    mutation RefreshJwtToken($refreshToken: String!) {
          refreshToken(refreshToken: $refreshToken) {
          token
          payload
          success 
          errors
          refreshToken
        }
  }
`,
    variables: {
      refreshToken,
    },
  });
};


