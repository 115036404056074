import React, { useState } from "react";
import "../../../styles/client/Client.css";
import "../../../styles/client/ClientContact.css";


export default function SentMessage()
{
    return (
        <React.Fragment>
            <div className="w-100 centerText">
                <span style={{fontSize:"20px"}} className="title-1 colorIconGreen">Your message was sent successfully</span>
            </div>
            <div style={{marginBottom:"-20px"}} className="w-100 centerText">
                <span className="textLow-1">It is a long established fact that a reader will be distracted by the readable content.</span>
            </div>
        </React.Fragment>
    )
}