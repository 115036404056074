import React from "react";
import { ButtonPrimary } from "../../../components/client/button";
import "../../../styles/client/Client.css";
import TemplateStatic from "./template";
import { useNavigate } from "react-router-dom";
export default function ErrorPage() {

    const navigate = useNavigate();

    const navigateTo = (path, params) => {
        navigate(path, params);
    }

    const Comp = () => {
            return ( < >
                <
                div className = "row centerText" >
                <
                div className = "col-12" >
                <
                img style = {
                    { width: "40%", margin: "15px" } }
                className = "svgSize"
                src = "/svg/Error.svg"
                alt = "SVG" / >
                <
                /div> <
                div className = "col-12" >
                <
                ButtonPrimary style = {
                    { width: "auto" } }
                className = "mx-auto"
                text = "Back to home"
                click = {
                    (event) => {
                        navigateTo("/", {});
                    }
                }
                /> <
                /div> <
                /div> <
                />)};
                return ( <
                    React.Fragment >
                    <
                    TemplateStatic comp = { < Comp / > }
                    /> <
                    /React.Fragment>
                )
            }