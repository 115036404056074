import React from "react";
import IconLabeled from "../components/client/iconLabeled";
import { subscribeNewsLetter } from "../services/client/general";
import * as Styles from "./footerElement";
import {AiFillHome} from "react-icons/ai";
import {FaPizzaSlice} from "react-icons/fa";
import {GiKnifeFork} from "react-icons/gi";
import {ImUser} from "react-icons/im";

export default function Footer()
{
    return (
        <React.Fragment>
            <Styles.GreenSpot1>
                <Styles.Container3 className="row p-4-adapted">
                <div className="col-md-6">
                    <Styles.Headinnng1> Get notification from us Every update! </Styles.Headinnng1>
                    <Styles.Headinng2> Subscribe Get all notification from us </Styles.Headinng2>
                </div>
                <div className="col-md-6">
                    <div className="subBac">
                    <Styles.InputSubsc id="emailSub" placeholder=" Enter your email" />
                    <button className="subscribe-btn" onClick={()=>{subscribeNewsLetter(document.getElementById("emailSub").value);}}>Subscribe</button>
                    </div>
                </div>
                </Styles.Container3>
            </Styles.GreenSpot1>

            <div className="footer ">
                <div className="col-lg-3 mt-4">
                    <img src="/logo.png" className="logo" alt="tabla.ma" width="54px" />
                    <h3 className="green"> tabla.ma</h3>
                    <p className="footerParag">
                        It is a long established fact that a reader will be distracted by
                        the readable content of a page when looking at its layout.
                    </p>
                    <div className="qlListe">
                        <h5> Email: <a href="mailto:support@tabla.ma">support@tabla.ma</a></h5>
                        <h5> Phone: <a href="tel:2125585474758">+212 (558) 547 4758 </a></h5>
                    </div>
                </div>
                <div className="col-lg-3 mt-4">
                    <h4 className="quickLinks"> Quick Links</h4>
                    <div className="qlListe">
                        <h5>
                        <a href="/">Home</a>
                        </h5>
                        <h5>
                        <a href="/restaurants">Restaurants</a>
                        </h5>
                        <h5>
                        <a href="/foods">Foods</a>
                        </h5>
                        <h5>
                        <a href="/register-restaurant">Register your restaurant</a>
                        </h5>
                        <h5>
                        <a href="/register">Create an account</a>
                        </h5>
                    </div>
                </div>
                <div className="col-lg-3 mt-4">
                    <h4 className="quickLinks"> Navigations </h4>
                    <div className="qlListe">
                        <h5>
                        <a href="/">Home</a>
                        </h5>
                        <h5>
                        <a href="/about">About us</a>
                        </h5>
                        <h5>
                        <a href="/terms">Terms & Conditions</a>
                        </h5>
                        <h5>
                        <a href="/privacy">Privacy Policy</a>
                        </h5>
                    </div>
                </div>
                <div className="col-lg-3 mt-4">
                    <h4 className="quickLinks"> Support </h4>
                    <div className="qlListe">
                        <h5>
                        <a href="/contact">Contact us</a>
                        </h5>
                        <h5>
                        <a href="/faq">FAQ</a>
                        </h5>
                    </div>
                </div>
            </div>
            <Styles.CopyRight className="desktopOnly">
                All rights reserved by <span className="footerTabla">TABLA.MA</span> |
                2022©
            </Styles.CopyRight>
            <div className="mobileOnly menuBottom mt-4 flex-container">
                <div className="fg-1 flex-center centerText">
                    <IconLabeled url="/" icon={<AiFillHome/>} label="Home"/>
                </div>
                <div className="fg-1 flex-center centerText">
                    <IconLabeled url="/foods" icon={<FaPizzaSlice/>} label="Foods"/>
                </div>
                <div className="fg-1 flex-center centerText">
                    <IconLabeled url="/restaurants" icon={<GiKnifeFork/>} label="Restaurants"/>
                </div>
                <div className="fg-1 flex-center centerText">
                    <IconLabeled url="/user/profile" icon={<ImUser/>} label="Profil"/>
                </div>
            </div>
        </React.Fragment>
    )
}