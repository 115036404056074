import FetchAxios from "../Fetch";
import Toast from "../Toast";

export function authenticate(data)
{
    const body = {
        query: `mutation ($password: String!, $email: String!, $username: String!) {
            tokenAuth (
              password: $password,
              email: $email,
              username: $username
            ) 
          }
        `,
        variables: {
          password: data?.password,
          email: data?.email,
          username: data?.email
        }
    }
    FetchAxios.post("/graphql/",body).catch(err=>{console.log(err);}).then(response=>{console.log(response);})
}

export function register(data)
{

}

export function forget1(data)
{

}

export function forget2(data)
{

}

export function forget3(data)
{

}

export function getUserData()
{
  /* get token from redux  and after getting data map it to this data var*/
  /* otherwise get null if there is no auth */
  let data = {
    name:"Landers Devost",
    photo:"/placeholders/user.jpg",
    email:"name@outlook.com",
    phone:"+212 695 457 7894"
  }
  return data;
}
export function logout(onDone)
{
  if(onDone!=null&&onDone!=undefined)
    onDone();
  Toast.success("Se déconnecter avec succès");
}
export function registerRestaurant(data,done) {
  /* should return a boolean */
  done();
  return true;
}
