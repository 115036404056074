import "../../styles/client/Client.css";
import React, { forwardRef } from "react";
export function ButtonPrimary(props) {
  return (
    <button
      type="submit"
      style={props.style}
      className={`btn btn-success btn-book ${props.className}`}
      onClick={props.click}
    >
      {props.text}
    </button>
  );
}

export const StandardButtonPrimary = forwardRef(
  ({ children, ...rest }, ref) => (
    <button
      ref={ref || null}
      {...rest}
      className={`btn btn-success btn-book ${rest.className}`}
    >
      {children}
    </button>
  )
);
