export const LOCAL_STORAGE_KEYS = {
  REFRESH_TOKEN: "refreshToken",
  TOKEN: "token",
};

export const RESERVATION_KEYS = {
  STATUS: {
    PENDING: "PENDING",
    APPROVED: "APPROVED",
    CANCELED: "CANCELED",
    FULFILLED: "FULFILLED",
  },
  TYPE: {
    UP_COMING: "UP_COMING",
    PAST: "PAST",
  },
  ADMIN_PAGE_SIZE: 6,
  USER_PAGE_SIZE: 6,
};
export const RESTAURANT_KEYS = {
  USER_PAGE_SIZE: 6,
};
export const OFFERS_KEYS = {
  TYPE: {
    MENU: "MENU",
    TIME: "TIME",
  },
  ADMIN_PAGE_SIZE: 6,
  USER_PAGE_SIZE: 6,
};
export const MENU_KEYS = {

  ADMIN_PAGE_SIZE: 6,
  USER_PAGE_SIZE: 6,
};
export const EXTRASERVICES_KEYS = {

  ADMIN_PAGE_SIZE: 6,
  USER_PAGE_SIZE: 6,
};
export const REVIEWS_KEYS = {

  ADMIN_PAGE_SIZE: 6,
  USER_PAGE_SIZE: 6,
};
