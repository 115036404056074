import { gql } from "@apollo/client";
export const CURRENT_USER = gql`
  query currentUser($restaurantId: ID) {
    me {
      id
      firstName
      lastName
      isStaff
      email
      role
      phone
      country
      image
      pk
      allegeableForReview(restaurantId: $restaurantId)
      city {
        id
        name
      }
    }
  }
`;
export const MY_PROFILE_IMAGE_QUERY = gql`
  query Queries {
    myProfileImage
  }
`;
export const MY_LIKED_RESTAURNAT_QUERY = gql`
  query likedRestaurant {
    likedRestaurant (page:15, pageSize:10){
      page
     pages
     count
     hasNext
     hasPrev
     objects {
       id
       name
       image
       location
       address
       rating
       popularity
       description
     }
     
   }
  }
`;

