import { Link } from "react-router-dom";
import { useActiveLinkClass } from "../../hooks/useActiveLinkClass";



export const LinkActiveOrNot = ({urlcontainer,secondurl,onClick, children, to, ...props }) => {
  
  const prefix = props?.prefix ? props.prefix:"";
    return (
        <Link
        onClick={onClick}
          className={"text-icons-lnbv "+useActiveLinkClass(urlcontainer+to,true,urlcontainer+secondurl) } to={prefix+to} {...props}>
          {children}
        </Link>
    );
  }

export const LinkswithChildren = ({element,onClick,urlcontainer,...props})=>{
  const to = element.path;
  const prefix = props?.prefix ? props.prefix:"";
  
  return(
      <div>
        <Link onClick={onClick} className={"text-icons-lnbv "+useActiveLinkClass(urlcontainer+to,false)} to={to}>
        <div className="display-flex">
          <div className="icon">{element.icon} </div>
          <div className="title_">{element.title}</div></div>
        </Link>
          <div className={"Links-container "+useActiveLinkClass(urlcontainer+to,false)}>
            {
              element.children.map((e,i)=>
              <LinkActiveOrNot onClick={onClick} urlcontainer={urlcontainer}  to={prefix+to+"/"+e.path} secondurl={i==0?to:undefined}>
                <div className="title_">{e.title}</div>
              </LinkActiveOrNot>
              )
            }
          </div>
      </div>
  );
}
