import React from "react";
import { FaCity } from "react-icons/fa";
import { BiBookAlt } from "react-icons/bi";
import { BsFillPersonFill } from "react-icons/bs";
import { IoRestaurant } from "react-icons/io5";
import "../../App.css";
import {
  ButtonRegLog,
  Container2,
  ContainerLeft,
  ContainerRight,
  ForCompon,
  GreenSpot,
  Headinng1,
  MiniContainer1,
  MiniContainer2,
  NumbersOf,
  Paragr,
  TitleOf,
  TitleOfBi,
} from "./SecondPart-elements";
import { NavLink } from "react-router-dom";
import AboutBand from "../../pages/client-panel/about/band";

function SecondPart() {
  return (
    <div>
      <AboutBand />
      <div>
        <Container2 className="">
          <TitleOfBi> Are you a restaurant owner? </TitleOfBi>
          <MiniContainer2 className="rest-owner p-5 mb-4">
            <div className="row">
              <ContainerLeft className="col-md-6 flex-container flex-center">
                <img
                  src="./RegisterImage.svg"
                  alt=""
                  className="register-img w-100 p-4"
                />
              </ContainerLeft>
              <ContainerRight className="col-md-6">
                <Headinng1> Register Now </Headinng1>
                <Paragr>
                  There are many variations of passages of Lorem Ipsum available,
                  but the majority.
                </Paragr>
                <NavLink to="/register-restaurant#registerForm"><ButtonRegLog> Register </ButtonRegLog></NavLink>

                <Headinng1> Already have account? </Headinng1>
                <Paragr>
                  There are many variations of passages of Lorem Ipsum available,
                  but the majority.
                </Paragr>
                <NavLink to="/login"><ButtonRegLog> Login </ButtonRegLog></NavLink>
              </ContainerRight>
            </div>
          </MiniContainer2>
        </Container2>
      </div>
    </div>
  );
}

export default SecondPart;
