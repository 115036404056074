import React, { useState, useEffect } from "react";
import "../../styles/RestaurantAdmin.css";
import { FaUserAlt, FaMapMarkerAlt } from "react-icons/fa";
import { IoMdAnalytics } from "react-icons/io";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import SwitchElement from "../../components/Shared/Switch";
import UploadImages from "../../components/Shared/UploadImages";
import { MdOutlineDone } from "react-icons/md";
import "../../components/Button/Custom.css";
import useFormWithServerErrors from "../../hooks/useFormWithServerErrors";
import { FormProvider, useFormContext, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import TokenManger from "../../api/TokenManger";
import useCities from "../../hooks/useCities";
import { AiOutlineDelete, AiOutlinePlus } from "react-icons/ai";
import CircularProgress from "@mui/material/CircularProgress";
import {
  EMAIL_REGXR,
  NAME_REGXR,
  PASSWORD_VALIDATION,
  PHONE_NUMBER_VALIDATION,
  TIME_REGXR,
} from "../../validation/regexr";
import { useMutation } from "@apollo/client";
import { REGISTER_MUTATION } from "../../services/mutations/authMutations";
import {
  CREATE_RESTAURANT,
  EDIT_RESTAURANT,
  UPLOAD_RESTAURANT_IMAGE_MUTATION,
} from "../../services/mutations/restaurantMutaions";
import useCategories from "../../hooks/useCategories";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom"
const IsLoading = () => {
  return (
    <div
      style={{
        // do your styles depending on your needs.
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "250px",
      }}
    >
      <CircularProgress style={{ width: "unset", height: "unset" }} />
    </div>
  );
};
const RegisterRestaurant = (props) => {
  const [restaurant, setRestaurant] = useState(null);
  const { pathname } = useLocation();
  const [page, setpage] = useState(1);
  const [numberOfImagesToUpload, setNumberOfImagesToUpload] = useState(0);
  const [numberOfImagesBeingUploaded, setNumberOfImagesBeingUploaded] = useState(0)
  const [disable, setdisable] = useState(false);
  const { useFormMethods, setErrors, reset, resetField } = useFormWithServerErrors({
    useFormInit: { mode: "onChange" },
  });

  const onCompletedUploadImageHandler = ({ createImage }) => {
    const { errors, success, image } = createImage;
    console.log(' onCompletedUploadImageHandler ', createImage)
    if (success) {
      setNumberOfImagesBeingUploaded(numberOfImagesBeingUploaded + 1)
      setdisable(true);
      if (numberOfImagesToUpload == numberOfImagesBeingUploaded + 1) {
        const tokenManager = TokenManger();
        setpage(page + 1);
        tokenManager.removeTokens()
      }
    }
  };
  const [
    uploadImageGallery,
    { isLoading: isLoadingUploadImageGallery, error: errorUploadImageGallery },
  ] = useMutation(UPLOAD_RESTAURANT_IMAGE_MUTATION, {
    onCompleted: onCompletedUploadImageHandler,
  });
  const {
    handleSubmit,
    register,
    getValues,
    control,
    watch,
    trigger,
    formState: { errors, isValid },
  } = useFormMethods;
  const onCompletedRegisterHandler = ({ register }) => {
    const tokenManager = TokenManger();
    const { success, token, refreshToken, errors } = register;
    if (success) {
      tokenManager.setTokens({ token, refreshToken });

      setpage(page + 1);
    } else if (!success) {
      try {
        setErrors(errors);
      } catch (error) {
        console.log(' error on setErrors', error)
      }

    }
  };
  const onErrorRegisterUserHandler = (data) => {
    setpage(page + 1);
  };
  const onCompletedRegisterRestaurantHandler = ({ createRestaurant }) => {
    const { errors, success, restaurant } = createRestaurant;
    if (success) {
      setRestaurant(restaurant);
      setpage(page + 1);
    }
    // console.log("createRestaurant ==>", createRestaurant);
    //
  };
  const onErrorRegisterRestaurantHandler = (data) => {
    // console.log(" onErrorRegisterRestaurantHandler", data);
  };
  const onCompletedEditRestaurantHandler = ({ editRestaurant }) => {
    const { errors, success, restaurant } = editRestaurant;
    const tokenManager = TokenManger();
    if (success) {
      setRestaurant(restaurant);
      console.log(' Page === ', page)
      if (page == 3) {
        console.log(' data.gallery ===> ', data.gallery)

        if (data.gallery.length > 0) {
          setNumberOfImagesToUpload(data.gallery.length)
          for (const file of data.gallery) {


            uploadImageGallery({
              variables: { file: file, restaurant: restaurant.id },
            });

          }

        }
        // tokenManager.removeTokens()
      } else {

        setdisable(true);
        setpage(page + 1);
      }

    }

    //
  };
  const onErrorEditRestaurantHandler = (data) => { };
  const [
    registerUser,
    { loading: isRegisterLoading, error, data: registerUserData },
  ] = useMutation(REGISTER_MUTATION, {
    onCompleted: onCompletedRegisterHandler,
    onError: onErrorRegisterUserHandler,
  });
  const [
    registerRestaurant,
    {
      loading: isLoadingRegisterRestaurant,
      error: errorRegisterRestaurant,
      data: registerRestaurantUserData,
    },
  ] = useMutation(CREATE_RESTAURANT, {
    onCompleted: onCompletedRegisterRestaurantHandler,
    onError: onErrorRegisterRestaurantHandler,
  });
  const [
    editRestaurant,
    {
      loading: isLoadingEditRestaurant,
      error: errorEditRestaurant,
      data: editRestaurantUserData,
    },
  ] = useMutation(EDIT_RESTAURANT, {
    onCompleted: onCompletedEditRestaurantHandler,
    onError: onErrorEditRestaurantHandler,
  });
  const onCompletedCityHandler = (allCities) => {
    const { objects } = allCities;
    if (objects.length > 0) {
      // resetField('city', { defaultValue: objects[0] })
    }

  }
  const onCompletedCategoryHandler = (allCategories) => {
    const { objects } = allCategories;
    if (objects.length > 0) {

      // resetField('category', { defaultValue: objects[0] })
    }

  }
  const { isLoading: isLoadingCities, cities } = useCities({ onCompleted: onCompletedCityHandler });
  const { isLoading: isLoadingCategories, categories } = useCategories({ onCompleted: onCompletedCategoryHandler });
  const watchedPassword = watch("password1");

  const watchImage = watch("image", null);

  const isThereAMainImage = watchImage ? watchImage.length > 0 : false;
  const [data, setdata] = useState({
    gallery: [],
    workhours: {
      monday: { open: true, from: "", to: "", reason: "" },
      tuesday: { open: true, from: "", to: "", reason: "" },
      wednesday: { open: true, from: "", to: "", reason: "" },
      thursday: { open: true, from: "", to: "", reason: "" },
      friday: { open: true, from: "", to: "", reason: "" },
      saturday: { open: true, from: "", to: "", reason: "" },
      sunday: { open: true, from: "", to: "", reason: "" },
    },
    // tables:{all:25,reserved:25}
  });

  const onChangeEl = (e, key) => {
    let x = { ...data };
    x[key] = e.target.value;
    setdata(x);
  };

  const onChangeHoursData = (e, i) => {
    let x = { ...data };
    x.workhours[i] = e;
    setdata(x);
  };
  const onDone = (e) => {
    e.preventDefault();

    if (page == 1) {
      if (isValid)
        registerUser({ variables: getValues() });
      else {
        trigger(['category', 'city', 'phone', 'lastName', 'firstName', 'name'])
      }

    } else if (page == 2) {
      if (restaurant?.id) {

        const values = { ...getValues() }
        delete values['image']
        editRestaurant({
          variables: {
            ...values,
            editRestaurantId: restaurant.id,
          },
        })
      } else {
        registerRestaurant({ variables: getValues() });
      }

    }
    else if (page == 3) {
      const image = getValues("image") ? getValues("image")[0] : null;
      editRestaurant({
        variables: {
          ...getValues(),
          image: image,
          editRestaurantId: restaurant.id,
        },
      });
    }

  };

  const onSubmit = async (data) => {
    const image = data["image"] ? data.image[0] : null;
    // console.log(" image ", image, restaurant);
    editRestaurant({
      variables: {
        ...data,
        image: image,
        editRestaurantId: restaurant.id,
      },
    });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname])
  if (page == 4) return <StepFour />;
  return (
    <ErrorBoundary>
      <FormProvider {...useFormMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="d-flex flex-column bottom">
            <div className="row-top ">
              <TopElement title="Your Restaurant" index={1} page={page} />
              <TopElement title="Hours of work" index={2} page={page} />
              <TopElement title="Almost done" index={3} page={page} />
            </div>
            <div className="row fox">
              <div className="fox-side">
                <div className=" lef-con-rrp">
                  <div className="title-1 v2" style={{ fontSize: "22px" }}>
                    Add your restaurant to get more clients
                  </div>
                  <div>
                    <p className="text-muted desc">
                      There are many variations of passages of Lorem Ipsum
                      available, but the majority have suffered alteration in
                      some.
                    </p>
                  </div>
                  <div className="icons-cont">
                    <IconElM
                      Icon={FaUserAlt}
                      title={"More users"}
                      description={
                        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some."
                      }
                    />
                    <IconElM
                      Icon={FaMapMarkerAlt}
                      title={"All locations"}
                      description={
                        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some."
                      }
                    />
                    <IconElM
                      Icon={IoMdAnalytics}
                      title={"Popular Restaurants"}
                      description={
                        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some."
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="fox-side">
                <div className=" ele2-rrp" id="registerForm">
                  {page == 1 && (
                    <StepOne
                      cities={cities}
                      categories={categories}
                      isLoading={isRegisterLoading}
                    />
                  )}
                  {page == 2 && (
                    <StepTwo
                      data={data}
                      onChangeHoursData={onChangeHoursData}
                      isLoading={isLoadingRegisterRestaurant}
                    />
                  )}
                  {page == 3 && (
                    <StepThree
                      isThereAMainImage={isThereAMainImage}
                      data={data}
                      setData={setdata}
                      page={page}
                      isLoading={
                        isLoadingEditRestaurant || (numberOfImagesToUpload != numberOfImagesBeingUploaded)
                      }
                    />
                  )}
                  {
                    (page !== 3 || page !== 1 || page != 2) ? <IsLoading /> : null
                  }

                  <Stack spacing={2} direction="row" className="group-btn">
                    {
                      <Button
                        variant="contained"
                        className="btnUI btn-solid-Gree"
                        disabled={disable}
                        onClick={() => {
                          if (page != 1) setpage(page - 1);
                        }}
                      >
                        Back
                      </Button>
                    }
                    {page == 3 ? (
                      <Button
                        variant="contained"
                        className="btnUI btn-solid-primary"
                        disabled={disable}
                        type="submit"
                      >
                        Done
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        className="btnUI btn-solid-primary"
                        disabled={disable}
                        onClick={onDone}
                      >
                        Next
                      </Button>
                    )}
                    <br />
                    <br />
                  </Stack>
                </div>
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
    </ErrorBoundary>
  );
};

const TopElement = ({ title, page, index }) => {
  return (
    <div
      className={
        "d-flex flex-grow-1  justify-content-center  align-items-center top-element-n " +
        (page > index ? "top-elemen-active" : "")
      }
      style={{ cursor: "pointer", opacity: page != index && 0.5 }}
    >
      <div className="le-top-i">
        {page > index ? <MdOutlineDone /> : index}{" "}
      </div>
      <div className="le-top-ti">{title} </div>
    </div>
  );
};

const IconElM = ({ Icon, title, description }) => {
  return (
    <div className="d-flex">
      <div className="icon-con">
        <Icon />
      </div>
      <div className="d-flex flex-column" style={{ marginLeft: "15px" }}>
        <div className="tit-ele-ic-elm">{title}</div>
        <div className="text-muted offre_inf">{description}</div>
      </div>
    </div>
  );
};

const HoursWorkElement = (props) => {
  const {
    register,
    watch,
    control,
    formState: { errors, isValid },
  } = useFormContext();
  const watchIsOpen = watch(`workHours.${props.title}IsOpen`, false);
  const watchFrom = watch(`workHours.${props.title}From`);
  const watchTo = watch(`workHours.${props.title}To`);

  return (
    <>
      <div className="col-12 down dayy d-flex align-items-center">
        <div className="card-title" style={{ width: "120px", padding: 0 }}>
          {props.title}
        </div>

        <Controller
          name={`workHours.${props.title}IsOpen`}
          control={control}
          render={({ field }) => <SwitchElement {...field} />}
        />
        {watchIsOpen == true ? (
          <>
            <div className="from_to_reasen_ele">
              <input
                {...register(`workHours.${props.title}From`, {
                  value: "09:00",
                  required: {
                    value: watchIsOpen,
                  },
                  pattern: { TIME_REGXR },
                  shouldUnregister: !watchIsOpen,
                  deps: [`workHours.${props.title}IsOpen`],
                })}
                type="time"
                className="form-control day"
                placeholder="from"
              />

              <input
                type="time"
                {...register(`workHours.${props.title}To`, {
                  value: "20:00",

                  shouldUnregister: !watchIsOpen,
                  pattern: { TIME_REGXR },
                  deps: [`workHours.${props.title}IsOpen`],
                })}
                className="form-control day"
                placeholder="to"
              />
            </div>
          </>
        ) : null}
      </div>
      <div className="col-12  dayy d-flex align-items-center">
        <div
          className="card-title"
          style={{ width: "120px", padding: 0 }}
        ></div>
        <div className="from_to_reasen_ele text-sm pl-2">
          <div className="text-sm">
            <ErrorMessage
              errors={errors}
              name={`workHours.${props.title}From`}
              render={({ message }) => (
                <p className="text-danger text-sm">{message}</p>
              )}
            />
          </div>
          <div>
            <ErrorMessage
              errors={errors}
              name={`workHours.${props.title}To`}
              render={({ message }) => (
                <p className="text-danger text-sm">{message}</p>
              )}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const UploadTheMainImage = () => {
  const {
    register,
    watch,
    setValue,
    control,
    formState: { errors, isValid },
  } = useFormContext();
  const watchImage = watch("image", null);

  const isThereAFile = watchImage ? watchImage.length > 0 : false;
  const deleteImage = (e) => {
    setValue("image", "");
  };
  const url = isThereAFile ? URL.createObjectURL(watchImage[0]) : null;
  return (
    <div style={{ width: "fit-content", margin: "auto" }}>
      <h6>The main Image</h6>
      <div
        className="element_image"
        style={{ margin: "10px", position: "relative" }}
      >
        {isThereAFile ? (
          <>
            <img
              src={url}
              style={{ height: "100%", width: "100%", position: "absolute" }}
            />
            <span
              onClick={deleteImage}
              style={{
                height: "32%",
                width: "32%",
                position: "absolute",
                top: 1,
                right: 1,
                backgroundColor: "#000",
                opacity: "0.7",
              }}
            >
              <AiOutlineDelete
                style={{ margin: "auto" }}
                size="20"
                color="#fff"
              />
            </span>
          </>
        ) : (
          <>
            <span
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                height: "100%",
                width: "100%",
              }}
            >
              <AiOutlinePlus
                style={{ margin: "auto", opacity: "0.5" }}
                size="100%"
                color="#000"
              />
            </span>
            <input
              type="file"
              accept="image/*"
              {...register("image", { required: true })}
              style={{
                opacity: 0,
                height: "100%",
                width: "100%",
                position: "absolute",
              }}
              placeholder="Select an element"
            />
          </>
        )}
      </div>
    </div>
  );
};
const UploadImageGallery = ({ file }) => {
  const url = isThereAFile ? URL.createObjectURL(file) : null;
  return (
    <div
      className="element_image"
      style={{ margin: "10px", position: "relative" }}
    >
      {file ? (
        <>
          <img
            src={url}
            style={{ height: "100%", width: "100%", position: "absolute" }}
          />
          <span
            onClick={deleteImage}
            style={{
              height: "32%",
              width: "32%",
              position: "absolute",
              top: 1,
              right: 1,
              backgroundColor: "#000",
              opacity: "0.7",
            }}
          >
            <AiOutlineDelete
              style={{ margin: "auto" }}
              size="20"
              color="#fff"
            />
          </span>
        </>
      ) : (
        <>
          <span
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              height: "100%",
              width: "100%",
            }}
          >
            <AiOutlinePlus
              style={{ margin: "auto", opacity: "0.5" }}
              size="100%"
              color="#000"
            />
          </span>
          <input
            type="file"
            {...register("image", { required: true })}
            style={{
              opacity: 0,
              height: "100%",
              width: "100%",
              position: "absolute",
            }}
            placeholder="Select an element"
          />
        </>
      )}
    </div>
  );
};
const StepOne = ({ cities, categories, isLoading }) => {
  const {
    register,
    watch,
    control,
    formState: { errors, isValid },
  } = useFormContext();
  const watchedPassword = watch("password1");
  if (isLoading) return <IsLoading />;

  return (
    <div className="row ">
      <div className="heade-reg text-center ">
        <br />
        <div className="title-1 v2" style={{ fontSize: "22px" }}>
          Your restaurant information
        </div>
        <div className="text-muted offre_inf t">
          It is a long established fact that a reader will be distracted by the
          readable content.
        </div>
        <br />
      </div>
      <div className="col-12 down">
        <input
          {...register("name", {
            required: 'Name is Required ',
          })}
          type="text"
          className="form-control"
          placeholder="Restaurant name"
        />

        <ErrorMessage
          errors={errors}
          name="name"
          render={({ message }) => (
            <p className="text-danger text-sm">{message}</p>
          )}
        />
      </div>
      <div className="col-md-6 down">
        <input
          type="text"
          className="form-control"
          {...register("firstName", {
            pattern: NAME_REGXR,
            required: 'First name is required',
          })}
          placeholder="First Name"
        />

        <ErrorMessage
          errors={errors}
          name="firstName"
          render={({ message }) => (
            <p className="text-danger text-sm">{message}</p>
          )}
        />
      </div>
      <div className="col-md-6 down">
        <input
          type="text"
          className="form-control"
          {...register("lastName", {
            pattern: NAME_REGXR,
            required: 'Last name is required',
          })}
          placeholder="Last name"
        />
        <ErrorMessage
          errors={errors}
          name="lastName"
          render={({ message }) => (
            <p className="text-danger text-sm">{message}</p>
          )}
        />
      </div>

      <div className="col-md-6 down">
        <input
          type="text"
          className="form-control"
          {...register("email", {
            pattern: EMAIL_REGXR,
            required: true,
          })}
          placeholder="Email Adress"
        />
        <ErrorMessage
          errors={errors}
          name="email"
          render={({ message }) => (
            <p className="text-danger text-sm">{message}</p>
          )}
        />
      </div>
      <div className="col-md-6 down">
        <input
          type="text"
          className="form-control"
          {...register("phone", {
            required: 'phone is required',
            ...PHONE_NUMBER_VALIDATION

          })}
          placeholder="Phone Number"
        />
        <ErrorMessage
          errors={errors}
          name="phone"
          render={({ message }) => (
            <p className="text-danger text-sm">{message}</p>
          )}
        />
      </div>
      <div className="col-12 down  ">
        <input
          {...register("address", {
            required: true,
          })}
          type="text"
          className="form-control"
          placeholder="Address"
        />
        <ErrorMessage
          errors={errors}
          name="address"
          render={({ message }) => (
            <p className="text-danger text-sm">{message}</p>
          )}
        />
      </div>
      <div className="col-12 down">
        <select {...register("city", { required: 'Choose a city' })} className="form-control " title="city">
          <option value="" disabled selected> Choose a city</option>
          {cities.map((city, i) => (
            <option value={city.id} key={i}>

              {city.name}
            </option>
          ))}
        </select>
        <ErrorMessage
          errors={errors}
          name="city"
          render={({ message }) => (
            <p className="text-danger text-sm">{message}</p>
          )}
        />
      </div>
      <div className="col-12 down">
        <select {...register("category", { required: 'Choose a category' })} className="form-control " title="category">

          <option value="" disabled selected> Choose a category</option>
          {categories.map((category, i) => (
            <option value={category.id} key={i}>
              {category.name}
            </option>
          ))}

        </select>
        <ErrorMessage
          errors={errors}
          name="category"
          render={({ message }) => (
            <p className="text-danger text-sm">{message}</p>
          )}
        />
      </div>
      <div className="col-12 down">
        <input
          type="password"
          placeholder="Password"
          {...register("password1", PASSWORD_VALIDATION)}
          className="form-control"
        />

        <ErrorMessage
          errors={errors}
          name="password1"
          render={({ message }) => (
            <p className="text-danger text-sm">{message}</p>
          )}
        />
      </div>
      <div className="col-12 down">
        <input
          {...register("password2", {
            PASSWORD_VALIDATION,
            validate: (value) =>
              value === watchedPassword ||
              "Password Confirmation is not Equal to password",
          })}
          type="password"
          placeholder="Password Confirmation"
          className="form-control"
        />
        <ErrorMessage
          errors={errors}
          name="password2"
          render={({ message }) => (
            <p className="text-danger text-sm">{message}</p>
          )}
        />
      </div>
    </div >
  );
};
const StepTwo = ({ data, onChangeHoursData, isLoading }) => {
  if (isLoading) return <IsLoading />;

  return (
    <div className="row">
      <div className="heade-reg text-center head-bot">
        <br />
        <div className="title-1 v2" style={{ fontSize: "22px" }}>
          Your restaurant information
        </div>
        <div className="text-muted offre_inf t">
          It is a long established fact that a reader will be distracted by the
          readable content.
        </div>
      </div>
      {Object.keys(data.workhours).map((e, i) => (
        <HoursWorkElement
          key={e + i}
          title={e}
          data={data.workhours[e]}
          onChange={(j) => onChangeHoursData(j, e)}
        />
      ))}
    </div>
  );
};
const StepThree = ({ isThereAMainImage, data, page, isLoading, setData }) => {
  const {
    register,
    formState: { errors, isValid },
  } = useFormContext();
  if (isLoading) return <IsLoading />;
  return (
    <div className="row">
      <div className="heade-reg text-center ">
        <br />
        <div className="title-1 v2" style={{ fontSize: "22px" }}>
          Add your restaurant images
        </div>
        <div className="text-muted offre_inf t">
          It is a long established fact that a reader will be distracted by the
          readable content.
        </div>
        <br />
      </div>

      <UploadTheMainImage />
      {isThereAMainImage && (
        <UploadImages
          disabled={false}
          value={data.gallery}
          onChange={(e) => {
            setData({ ...data, gallery: e });
          }}
        />
      )}
      <div className="col-12 down-des">
        <textarea
          rows="5"
          {...register("description", { required: page == 3 })}
          type="text"
          className="form-control descRes"
          placeholder="Restaurant details"
        />
      </div>
    </div>
  );
};
const StepFour = () => {
  let navigate = useNavigate();
  return (
    <div className="ele_c_rrp">
      <div className="title-1 v2 v2">
        Your restaurant is under review by our team
      </div>
      <div style={{ minHeight: "215px" }}>
        <p className="text-muted offre_inf t">
          It is a long established fact that a reader will be distracted by the
          readable content.
        </p>
      </div>
      {
        <LoadingButton
          variant="contained"
          color="primary"
          style={{
            color: "#fff",
            flex: 1,
            margin: "2px",
            borderRadius: "10px",
          }}
          className="class_button"
          onClick={() => {
            navigate("/");
          }}
        >
          Back to home
        </LoadingButton>
      }
    </div>
  );
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}
export default RegisterRestaurant;
