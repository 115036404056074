export const EMAIL_REGXR = {
  value:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  message: "Email format invalid",
};
export const NAME_REGXR = {
  value: /^[A-Za-z]+$/i,
  message: "Name format invalid",
};
// value: /^(10|11|12|[1-9]):[0-5][0-9]$/,
export const TIME_REGXR = {
  value: /^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$/,
  message: "Time format invalid",
};
export const PHONE_NUMBER_REGXR =
  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
export const PHONE_NUMBER_VALIDATION = {
  pattern: {
    value: PHONE_NUMBER_REGXR,
    message: ` Incorrect phone number`,
  },
};
export const PASSWORD_VALIDATION = {
  minLength: { value: 8, message: "The minimum length is 8 characters ." },
  required: { value: true, message: "this Field is required" },
};

export const NUMBER_VALIDATION = (fieldName) => {
  return {
    pattern: {
      value: /(\d*\.\d+|\d+),?/,
      message: ` Incorrect  value for field ${fieldName} `,
    },
  };
};
