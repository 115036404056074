import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";
import moment from "moment";

class ChartElement extends Component {
  constructor(props) {
    super(props);
    const dates = props.categories.map((elm, i) =>
      moment(elm, "YYYY-MM-DD").format("MMMM D")
    );
    console.log(" dates ", dates);
    this.state = {
      series: [...props.data],
      options: {
        chart: {
          height: props.height,
          type: "area",
          toolbar: { show: false },
        },
        dataLabels: {
          enabled: true,
        },
        colors: [props.color],
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          labels: { show: true },
          categories: [...dates],
        },
        yaxis: {
          labels: { show: false },
        },
      },
    };
  }

  render() {
    return (
      <div>
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="area"
          height={350}
        />
      </div>
    );
  }
}

export default ChartElement;
