import React from "react";
import "../../../styles/client/Client.css";
import "../../../styles/client/ClientLogin.css";
import Input from "../../../components/client/input";
import { validateGeneral } from "../../../validation/client";
import { useState } from "react";
import {
  ButtonPrimary,
  StandardButtonPrimary,
} from "../../../components/client/button";
import { forget1 } from "../../../services/client/auth";
import Toast from "../../../services/Toast";
import { NavLink, useNavigate } from "react-router-dom";
import { StandardInput } from "../../../components/client/input";
import { useMutation } from "@apollo/client";
import { PASSWORD_RESET_EMAIL_MUTATION } from "../../../services/mutations/authMutations";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import LoaderPage from "../../../components/Shared/LoaderPage";
import { EMAIL_REGXR } from "../../../validation/regexr";
export default function ForgetSendRequest() {
  let navigate = useNavigate();
  const navigateTo = (params) => {
    let path = `/forget/confirmation`;
    navigate(path, params);
  };
  const [isValidEmail, setValidEmail] = useState(false);
  const eligableForSending = () => {
    return isValidEmail;
  };

  const [nonFieldErrors, setNonFieldErrors] = useState([]);
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();
  const handelOnCompleted = ({ sendPasswordResetEmail }) => {
    const { success, errors } = sendPasswordResetEmail;
    if (errors) {
      if ("nonFieldErrors" in errors) {
        setNonFieldErrors(errors["nonFieldErrors"]);
      }
      delete errors.nonFieldErrors;
      for (const [key, value] of Object.entries(errors)) {
        value.map((item) => {
          setError(key, item);
        });
      }
    } else if (success) {
      setNonFieldErrors([]);
      navigateTo({ state: { vkey: "ddd" } });
    }
  };
  const [sendPasswordResetEmailFun, { data, loading, error }] = useMutation(
    PASSWORD_RESET_EMAIL_MUTATION,
    {
      onCompleted: handelOnCompleted,
    }
  );
  const onSubmit = (data) => {
    sendPasswordResetEmailFun({ variables: data });
  };

  if (error) return `Submission error! ${error.message}`;
  return (
    <React.Fragment>
      <div className="row m-4">
        <div className="col-lg-6 p-4">
          <div className="row">
            <div className="col-12">
              <span className="title-1">Reset your password</span>
            </div>
            {loading ? (
              <LoaderPage />
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="col-12 mb-2 mt-2 text-danger">
                  {nonFieldErrors.map((error, i) => (
                    <p key={i}>{error.message}</p>
                  ))}
                </div>
                <div className="col-12 ml-4 mr-4 mt-2">
                  <StandardInput
                    id="email"
                    type="email"
                    placeholder="Email"
                    {...register("email", { pattern: EMAIL_REGXR })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="email"
                    render={({ message }) => (
                      <p className="text-danger">{message}</p>
                    )}
                  />
                </div>
                <div className="col-12 m-4">
                  <span>Do you remember you password?</span>
                  <NavLink to="/login" className="m-2 navlink green">
                    Sign In
                  </NavLink>
                </div>
                <div className="col-12 centerText">
                  <StandardButtonPrimary
                    className="mx-auto"
                    style={{ width: "auto" }}
                  >
                    Next
                  </StandardButtonPrimary>
                </div>
              </form>
            )}
          </div>
        </div>
        <div className="col-lg-6">
          <img
            className="svgSize onlyDesktopSVG"
            src="/svg/Forget1.svg"
            alt="SVG"
          />
        </div>
      </div>
    </React.Fragment>
  );
}
