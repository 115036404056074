import React from 'react';
import {
  Routes,
  Route,
} from "react-router-dom";
import { restaurantAdminData } from "../constants/routesData/restaurant-admin";
import LeftNavbar from "../components/Shared/LeftNavbar";
import { Fragment } from "react";



const CRoutes = ()=>{

    return(
        <div className="element">
            <LeftNavbar data={restaurantAdminData} url="admin/"/>
            <Routes>
                    {
                        restaurantAdminData.map(e=>{
                            if(e?.children)
                                return(
                                <Fragment>
                                    <Route path={e.path} element={e.element}/>
                                    {e?.children && e.children.map((c,i)=><Route path={e.path+"/"+c.path} element={c.element}/>)}
                                </Fragment>
                                );
                            return(
                            <Route path={e.path} element={e.element}/>
                            );
                        })
                    }
            </Routes>
        </div>
    )
}

export default CRoutes;