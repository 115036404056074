import React, {forwardRef, useState} from "react";
import withClickOutside from "./withClickOutside";
import "./style.css"


const Home = forwardRef(({ open, setOpen }, ref) => {
  const [time, setTime] = useState('1');
  const myArray = ['1', '2', '3', '4', '5', '6','7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18','19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30']; 

const handleClick = (e, param) => {
  e.preventDefault();
  setTime(param);
};
  return (
    <section ref={ref}>
      <p onClick={() => setOpen(!open)}>{time} {time == 1 && ("persone")} {time > 1 && ("people")}</p>

    {open &&(<div className="css-d09yz8 emy6t7y7 "> <div className="css-1xt2lie elvixqk4"><div className="css-1aw24z elh8w1h1"><div className="css-1ihzuzt elh8w1h0"> <div><div className="bodybox"><div className="Listbtn">{myArray.map(e => ( <div className="rowbtn"><button className="multi_btn" onClick={event => handleClick(event, e)}>{e} {e == 1 && ("persone")} {e > 1 && ("people")}</button></div>))}</div></div></div></div></div></div></div>)}
     
    </section>
  );
});

export default withClickOutside(Home);
