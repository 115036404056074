import { useState, useEffect, useRef } from "react";
import LoaderPage from "../../../components/Shared/LoaderPage";
import { getHomePage,saveHomePage} from "../../../services/restaurant-admin/admin-dashboard";
import  Toast from "../../../services/Toast";
import { BsChevronRight } from "react-icons/bs";
import LoadingButton from "@mui/lab/LoadingButton";

const HomePage =()=>{

    const [data,setData] = useState(false);
    const [selectedItem,setselectedItem] = useState(-1);
    const [DatatoShow,setDatatoShow] = useState(-1);
    const [disable,setdisable] = useState(false);
    const detailsref = useRef(null);
    const initData = ()=>{
        setData(-1);
        getHomePage(setData);
    }
    const setEdit = (i)=>{
        if(i==null) {setDatatoShow(data.HeroSection);setselectedItem(-2);return;}
        setDatatoShow(data.OtherSection[i]);
        setselectedItem(i);
    }
    const onCancel = ()=>{
        setselectedItem(-1);
        setDatatoShow(-1);
        setdisable(false);
    }
    const onSave = ()=>{
        setdisable(1);
        let x = {...data};
        if(selectedItem==-2) x ={...data,HomePage:DatatoShow};
        else x.OtherSection[selectedItem] =DatatoShow;
        saveHomePage(x,(a)=>{
            console.log(x);
            Toast.success("It was saved successfully");
            onCancel();
            //setData(a);
        });
    }
    useEffect(()=>{
        if(data===false)
            initData();
    });

    if(data === false || data === -1)
        return(<LoaderPage/>)
    return(
        <div className="d-flex flex-grow-1 container_details">
           <div className="flex-grow-1 element-1-cont HOME_ADMIN" style={{ maxWidth:"500px" }}>
                <div className="d-flex flex-column HeaderTop">
                    <h3 className="card-title dashboard" style={{ padding: 0 }}>Home Page</h3>
                    <h5 className="text-muted" style={{ padding: 0,opacity:.9 }}>Hero Section</h5>
                </div>
                <br/>
                <div className="d-flex " style={{ flexDirection:"column" }}>
                    <div className="form-group sideleft">
                        <input className="form-control" value={data?.HeroSection?.title} disabled="true" style={{background:"#fff",height:"60px",borderRadius:"10px"}}/>
                       
                        <textarea className="form-control" value={data?.HeroSection?.description} disabled="true" style={{background:"#fff", resize:"none",borderRadius:"10px" }} rows="5"/>
                    </div>
                    <br/>
                    <div className="d-flex w-100 justify-content-center " style={{ height: "45px" }}>
                        <LoadingButton variant="contained"color="primary" style={{ color:"#fff",flex:1,margin:"2px",borderRadius:"10px"}}  
                        className="class_button" disabled={disable} onClick={()=>{setEdit();}}>Edit</LoadingButton>
                    </div>
                </div><br/>
                <h5 className="text-muted" style={{ padding: 0,opacity:.9 }}>Other Sections</h5><br/>
                {data.OtherSection.map((e,i)=>
                        <div className="form-group position-relative" style={{ marginBottom:"20px" }} onClick={()=>{setEdit(i);}} >
                            <input className="form-control" value={e.title} disabled="true" style={{background:"#fff",height:"60px",borderRadius:"10px",fontWeight:600,cursor:"pointer"}}/>
                            <BsChevronRight color={selectedItem===i?"#43ab8c":"#000"} className="left_ic"/>
                        </div>)
                }
            </div>
            {selectedItem===-1?"":<EditHomePage key="element" onChange={setDatatoShow} item={selectedItem} data={DatatoShow} onCancel={onCancel} onSave={onSave} disa/>}
        </div>
    )
}

const EditHomePage = (props)=>{

    const onChange = (key,value)=>{
        console.log(value)
        let x = {};x[key]=value;
        console.log({...props.data,...x})
        props.onChange({...props.data,...x});
    }
    const onChangeImage = (e)=>{
        var file = e.target.files[0];
        onChange('image',file);
        if(file){
            var reader = new FileReader();
            reader.onload = function(){
                let d = e.target.parentNode;
                d.querySelector('.with_image').src= reader.result;
            }
            reader.readAsDataURL(file);
        }
    }
   
    return(
            <div className="flex-grow-1 element-1-cont details_container_leftborder" style={{ maxWidth:"500px" }}>
                <div className="d-flex flex-column  ">
                   
                    {
                    props.item==-2?
                        <Element1Edit data={props.data}  onChangeImage={onChangeImage} onChange={onChange} />
                    :
                        <Element2Edit data={props.data}  onChangeImage={onChangeImage} onChange={onChange} />
                    }
                    <br/>
                    <div className="d-flex w-100 justify-content-center " style={{ height: "45px" }}>
                        <LoadingButton variant="contained" style={{flex:1,margin:"2px",borderRadius:"10px"}}  color="secondary"  className="class_button"   
                        disabled={props.disableandLoad} loading={props.disableandLoad==2}onClick={()=>{props.onCancel()}} >Cancel</LoadingButton>
                        <LoadingButton variant="contained" style={{ color:"#fff",flex:1,margin:"2px",borderRadius:"10px"}}   className="class_button" 
                        disabled={props.disableandLoad} loading={props.disableandLoad==1} onClick={()=>{props.onSave()}}>{props.disableandLoad?"":"Save"}</LoadingButton>
                    </div>
                </div>
            </div>
    )
}


const Element1Edit = ({onChangeImage,onChange,data})=>{
    return(
        <div>
            <div className="form-group">
                <div className="element_image">
                        <input onChange={(e)=>onChangeImage(e)} type="file"  className="input_image" accept="image/*"/>
                        <div className="no_image" style={{ display:(data?.image!=null?"none":"block" )}}  >
                            <div className="plus_icon">+</div>
                            <div>Upload vector</div>
                        </div>
                        <img className="with_image "  src={data?.image}  style={{ display:(data?.image==(null)?"none":"flex" )}}  />
                </div><br/>
                <input className="form-control" defaultValue={data?.title} onChange={(e)=>{onChange('title',e.target.value)}}  style={{background:"#fff",height:"60px",borderRadius:"10px"}}/>
                <br/>
                <textarea className="form-control" defaultValue={data?.description} onChange={(e)=>{onChange('description',e.target.value)}} style={{background:"#fff", resize:"none",borderRadius:"10px" }} rows="5"/>
            </div>
        </div>
    );
}

const Element2Edit = ({onChangeImage,onChange,data})=>{
    return(
        <div><br/><br/><br/>
                <div className="form-group" style={{ marginBottom:"20px",marginTop:"14px" }}  >
                    <input className="form-control" value={data.title} disabled="true" style={{background:"#fff",height:"60px",borderRadius:"10px",fontWeight:600,cursor:"pointer"}}/>
                <div><br/>
                    <select value={data.category}  onChange={(e)=>{onChange('category',e.target.value)}} className="form-control form-control-lg" style={{background:"#fff",height:"60px",borderRadius:"10px",cursor:"pointer"}}>
                        <option value="1">4545</option>
                        <option  value="2">544slsmlsmlmsl</option>
                        <option  value="3"> msmmsms</option>
                    </select><br/>
                    <div className="form-group position-relative" style={{ marginBottom:"20px" }}  >
                        <input disabled="true" className="form-control" value={"Column"} style={{color:"#9e9e9e",background:"#fff",height:"60px",borderRadius:"10px",fontWeight:600,cursor:"pointer"}}/>
                        <div className="element_plus_moin">
                            <div className="element_plus" onClick={()=>{onChange('column',(data.column==1?1:data.column-1))}}>-</div>
                            {data.column}
                            <div className="element_plus" onClick={()=>{onChange('column',(data.column+1))}}>+</div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
export default HomePage;