import { Avatar } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { getDateandHours } from "../../../../../services/general";
import moment from "moment";


const Details = (props) => {
  const guest = props.data.numberOfGuests;
  const responseErrors = props.responseErrors;
  const Reservation = getDateandHours(props.data.datetime);
  const isThereError = props?.responseErrors?.length > 0;
  const reservetionDate = moment(props.data?.datetime)
  const today = moment()

  if (props.data != null)
    return (
      <div className="d-flex">
        <div
          className="d-flex align-items-start justify-content-center RS_Detail"
          style={{
            margin: "15px 20px",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Avatar
            style={{ margin: "auto" }}
            className="avatar_img AvatarDetail"
            src={props.data.user.image}
            variant="rounded"
          />
          <br />
          <div
            className="Card-Contact"
            style={{ textAlign: "center", margin: "auto" }}
          >
            <h3 className="card-title" style={{ padding: 0, margin: 0 }}>
              {props.data.user.name}
            </h3>
            <div className="d-flex" style={{ flexDirection: "column" }}>
              <span className="text-muted">
                <small>{props.data.user.email}</small>
              </span>
              <span className="text-muted">
                <small>{props.data.user.phoneNumber}</small>
              </span>
            </div>
          </div>
          <br />
          <div style={{ width: "100%" }}>
            <fieldset className="col-md-12 fieldset-ele">
              <div className="legend-cont">
                <legend>Reservation Details</legend>
              </div>
              <div className="panel panel-default">
                <div className="d-flex justify-content-lg-between">
                  <div className="p-3">
                    {guest} Guest{guest > 1 ? "s" : ""}{" "}
                  </div>
                  <div className="p-3">{Reservation.date}</div>
                  <div className="p-3">{Reservation.hours}</div>
                </div>
              </div>
            </fieldset>
          </div>
          <br />
          <div style={{ width: "100%" }}>
            <fieldset className="col-md-12 fieldset-ele messageDetail">
              <div className="legend-cont">
                <legend>Client note</legend>
              </div>
              <textarea
                className="text_note_area"
                value={props.data.commenter}
                onChange={(e) => {
                  props.onChangeNote(e);
                }}
              ></textarea>
            </fieldset>
          </div>
          <br />
          <div
            className="d-flex w-100 justify-content-center "
            style={{ height: "45px" }}
          >
            {isThereError
              ? responseErrors?.map((error, i) => (
                <p className=" text-center text-danger">{error}</p>
              ))
              : null}
          </div>
          <div
            className="d-flex w-100 justify-content-center "
            style={{ height: "45px" }}
          >
            {props?.doneButtonLabel && (
              <LoadingButton
                variant="contained"
                style={{
                  color: "#fff",
                  flex: 1,
                  margin: "2px",
                  borderRadius: "10px",
                }}
                className="class_button"
                disabled={!today.isSame(reservetionDate) | props.disableandLoad  }
                loading={props.disableandLoad == 1}
                onClick={() => {
                  props.doneButtonClick();
                }}

              >
                {props.disableandLoad == 1 ? "" : props.doneButtonLabel}{" "}
              </LoadingButton>
            )}
            {/* {props?.cancelButtonLaber && (
              <LoadingButton
                variant="contained"
                color="error"
                type="danger"
                style={{
                  color: "#fff",
                  flex: 1,
                  margin: "2px",
                  borderRadius: "10px",
                }}
                className="class_button"
                disabled={props.disableandLoad}
                loading={props.disableandLoad == 2}
                onClick={() => {
                  props.cancelButtonClick();
                }}
              >
                {props.disableandLoad == 2 ? "" : props.cancelButtonLaber}
              </LoadingButton>
            )} */}
          </div>
        </div>
      </div>
    );
  return;
};
export default Details;
