import { useState, useEffect } from "react";
import LoaderPage from "../../../../components/Shared/LoaderPage";
import { getFavIcon,EditFavIcon} from "../../../../services/restaurant-admin/admin-dashboard";
import  Toast from "../../../../services/Toast";
import LoadingButton from "@mui/lab/LoadingButton";

const FAVicon =()=>{

    const [data,setData] = useState(false);
    const [newdata,setnewData] = useState(null);
    const [edit,setedit] = useState(false);
    const [disable,setdisable] = useState(false);
    const initData = ()=>{
        setData(-1);
        getFavIcon((d)=>{setData(d);});
    }
    
    
    const CancelFAVicon = ()=>{
        setdisable(false);
        setedit(false);
        setnewData(null);
    }
    const onSave = ()=>{
        setdisable(1);
        EditFavIcon(newdata,(a)=>{
            Toast.success("It was saved successfully");
            setdisable(false);
            setedit(false);
            setData(a);
            setnewData(null);
        });
    }
    const onChange = (e)=>{
        
        var file = e.target.files[0];
        setnewData(file);
        if(file){
            var reader = new FileReader();
            reader.onload = function(){
                let d = e.target.parentNode;
                d.querySelector('.with_image').src= reader.result;
            }
            reader.readAsDataURL(file);
        }
       
    }
    
    useEffect(()=>{
        if(data===false)
            initData();
    });

    if(data === false || data === -1)
        return( <LoaderPage/>)
    
        
    return(
        <div className="d-flex flex-grow-1 container_details">
           <div className="flex-grow-1 element-1-cont" >
           <br/>
                <div className="row ">
                    <div className={edit?"col-sm-6":"col-12"}>
                    <div className="d-flex flex-column justify-content-start align-items-start">
                        <h3 className="card-title" style={{ padding: 0 }}>Basic settings</h3>
                        <h5 className="text-muted" style={{ padding: 0,opacity:.9 }}>FAV Icon</h5><br/>
                    </div>
                    <br/>
                        <div className="element_image">
                            <div className={"no_image "+(data!=null?"d-none":"")} >
                                <div className="plus_icon">+</div>
                                <div>Upload vector</div>
                            </div>
                            {data!=null?<img className="with_image " src={data}   />:""}
                        </div>
                        <br/>
                        <div className="d-flex w-100 justify-content-center " style={{ height: "45px" }}>
                            <LoadingButton variant="contained" color="primary"  style={{ color:"#fff",flex:1,margin:"2px",borderRadius:"10px"}}  className="class_button"    disabled={disable}onClick={()=>{setedit(true)}} >Edit</LoadingButton>
                        </div>
                    </div>
                    <div className={"col-sm-6 "+(edit?"":"d-none")}>
                        <div className="d-flex flex-column justify-content-start align-items-start">
                            <h3 className="card-title" style={{ padding: 0 }}>Edit</h3>
                            <h5 className="text-muted" style={{ padding: 0,opacity:0 }}>.</h5><br/>
                        </div>
                        <br/>
                        <div className="element_image">
                            <input onChange={(e)=>onChange(e)} type="file"  className="input_image" accept="image/*"/>
                            <div className="no_image" style={{ display:(newdata!=null?"none":"block" )}}  >
                                <div className="plus_icon">+</div>
                                <div>Upload vector</div>
                            </div>
                            <img className="with_image "  style={{ display:(newdata==null?"none":"flex" )}}  />
                        </div><br/>
                        <div className="d-flex w-100 justify-content-center " style={{ height: "45px" }}>
                            {<LoadingButton variant="contained" color="secondary"  style={{ color:"#fff",flex:1,margin:"2px",borderRadius:"10px"}}  className="class_button"    disabled={disable} onClick={()=>{CancelFAVicon()}} >Cancel</LoadingButton>}
                            {<LoadingButton variant="contained"color="primary" style={{ color:"#fff",flex:1,margin:"2px",borderRadius:"10px"}}  className="class_button" disabled={disable} onClick={()=>{onSave();}}>Done</LoadingButton>}
                        </div>
                    </div>
                </div>
                <br/>
            </div>
        </div>
    )
}

export default FAVicon;