import { useState, useEffect } from "react";
import LoaderPage from "../../../../components/Shared/LoaderPage";
import { getLogo,EditLogo} from "../../../../services/restaurant-admin/admin-dashboard";
import  Toast from "../../../../services/Toast";
import LoadingButton from "@mui/lab/LoadingButton";

const Logo =()=>{

    const [data,setData] = useState(false);
    const [newdata,setnewData] = useState({mobile:null,desktop:null});
    const [edit,setedit] = useState(false);
    const [disable,setdisable] = useState(false);

    const initData = ()=>{
        setData(-1);
        getLogo((d)=>{setData(d);});
    }
    
    const CancelLogo = ()=>{
        setdisable(false);
        setedit(false);
        setnewData({mobile:null,desktop:null});
    }
    const onSave = ()=>{
        setdisable(1);
        EditLogo(newdata,(a)=>{
            Toast.success("It was saved successfully");
            setdisable(false);
            setedit(false);
            setData(a);
            setnewData({mobile:null,desktop:null});
        });
    }
    const onChange = (e,key)=>{
        
        var file = e.target.files[0];
        let x = newdata;
        x[key]=file;
        console.log(x);
        setnewData({...x});
        if(file){
            var reader = new FileReader();
            reader.onload = function(){
                let d = e.target.parentNode;
                console.log(d.querySelector('.with_image'));
                d.querySelector('.with_image').src= reader.result;
            }
            reader.readAsDataURL(file);
        }
       
    }
    
    useEffect(()=>{
        if(data===false)
            initData();
    });

    if(data === false || data === -1)
        return( <LoaderPage/>)
    
        
    return(
        <div className="d-flex flex-grow-1 container_details">
           <div className="flex-grow-1 element-1-cont" >
           <br/>
                <div className="row ">
                    <div className={edit?"col-sm-6":"col-12"}>
                    <div className="d-flex flex-column justify-content-start align-items-start">
                        <h3 className="card-title" style={{ padding: 0 }}>Basic settings</h3>
                        <h5 className="text-muted" style={{ padding: 0,opacity:.9 }}>Logo</h5><br/>
                    </div>
                    <br/>
                        <div className="d-flex justify-content-center" style={{ margin:"auto" }}>
                            <div className="element_image"  style={{ margin:"auto 20px" }}>
                                <div className={"no_image "+((data?.desktop && data.desktop!=null)?"d-none":"")} >
                                    <div className="plus_icon">+</div>
                                    <div>Upload vector</div>
                                </div>
                                {(data?.desktop && data.desktop!=null)?<img className="with_image " src={data.desktop}   />:""}
                            </div>
                            <div className="element_image"  style={{ margin:"auto 20px" }}>
                                <div className={"no_image "+(((data?.mobile && data.mobile!=null))?"d-none":"")} >
                                    <div className="plus_icon">+</div>
                                    <div>Upload vector</div>
                                </div>
                                {(data?.mobile && data.mobile!=null)?<img className="with_image " src={data.mobile}   />:""}
                            </div>
                        </div>
                        <br/>
                        <div className="d-flex w-100 justify-content-center " style={{ height: "45px" }}>
                            <LoadingButton variant="contained" color="primary"  style={{ color:"#fff",flex:1,margin:"2px",borderRadius:"10px"}}  className="class_button"    disabled={disable}onClick={()=>{setedit(true)}} >Edit</LoadingButton>
                        </div>
                    </div>
                    <div className={"col-sm-6 "+(edit?"":"d-none")}>
                        <div className="d-flex flex-column justify-content-start align-items-start">
                            <h3 className="card-title" style={{ padding: 0 }}>Edit</h3>
                            <h5 className="text-muted" style={{ padding: 0,opacity:0 }}>.</h5><br/>
                        </div>
                        <br/>
                        <h5 className="text-muted" style={{ padding: 0,fontWeight:700,marginBottom:"20px",textAlign:"center" }}>Version Mobile</h5>
                        <div className="element_image">
                            <input onChange={(e)=>onChange(e,'mobile')} type="file"  className="input_image" accept="image/*"/>
                            <div className="no_image" style={{ display:(newdata.mobile!=null?"none":"block" )}}  >
                                <div className="plus_icon">+</div>
                                <div>Upload vector</div>
                            </div>
                            <img className="with_image "  style={{ display:(newdata.mobile==null?"none":"flex" )}}  />
                        </div>
                        <br/>
                        <br/>
                        <h5 className="text-muted" style={{ padding: 0,fontWeight:700,marginBottom:"20px",textAlign:"center" }}>Version Desktop</h5>
                        <div className="element_image">
                            <input onChange={(e)=>onChange(e,'desktop')} type="file"  className="input_image" accept="image/*"/>
                            <div className="no_image" style={{ display:(newdata.desktop!=null?"none":"block" )}}  >
                                <div className="plus_icon">+</div>
                                <div>Upload vector</div>
                            </div>
                            <img className="with_image "  style={{ display:(newdata.desktop==null?"none":"flex" )}}  />
                        </div>
                        <br/>
                        <br/>
                        <div className="d-flex w-100 justify-content-center " style={{ height: "45px" }}>
                            {<LoadingButton variant="contained" color="secondary"  style={{ color:"#fff",flex:1,margin:"2px",borderRadius:"10px"}}  className="class_button"    disabled={disable} onClick={()=>{CancelLogo()}} >Cancel</LoadingButton>}
                            {<LoadingButton variant="contained"color="primary" style={{ color:"#fff",flex:1,margin:"2px",borderRadius:"10px"}}  className="class_button" disabled={disable} onClick={()=>{onSave();}}>Done</LoadingButton>}
                        </div>
                    </div>
                </div>
                <br/>
            </div>
        </div>
    )
}

export default Logo;