import React from "react";
import {
  Routes,
  Route,
} from "react-router-dom";
import { userDashboard } from "../constants/routesData/user-dashboard";
import LeftNavbarNonResponsive from "../components/Shared/LeftNavbarNonResponsive";
import { Fragment } from "react";
import "../styles/LeftNavBar.css";
import Footer from "../footer";



const CRoutes = ()=>{

    return(
        <>
            <div className="element">
                <LeftNavbarNonResponsive data={userDashboard} url="user/"/>
                <Routes>
                        {
                            userDashboard.map(e=>{
                                if(e?.children)
                                    return(
                                    <Fragment>
                                        <Route path={e.path} element={e.element}/>
                                        {e?.children && e.children.map((c,i)=><Route path={e.path+"/"+c.path} element={c.element}/>)}
                                    </Fragment>
                                    );
                                return(
                                    <Route path={e.path} element={e.element}/>
                                );
                            })
                        }
                </Routes>
            </div>
            <Footer/>
        </>
    )
}

export default CRoutes;