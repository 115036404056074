import React from "react";
import "../../../styles/client/Client.css";
import "../../../styles/client/ClientLogin.css";
import { faFacebook, faGoogle } from "@fortawesome/free-brands-svg-icons";
import SocialButton from "../../../components/client/socialButton";
import Separator from "../../../components/client/separator";
import { StandardInput } from "../../../components/client/input";
import { validateGeneral, validateEmail } from "../../../validation/client";
import { EMAIL_REGXR } from "../../../validation/regexr";
import { useState } from "react";
import { ButtonPrimary } from "../../../components/client/button";
import { authenticate } from "../../../services/client/auth";
import Toast from "../../../services/Toast";
import { NavLink } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { LOG_IN } from "../../../services/mutations/authMutations";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import LoaderPage from "../../../components/Shared/LoaderPage";
import TokenManger from "../../../api/TokenManger";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
export default function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  const [nonFieldErrors, setNonFieldErrors] = useState([]);
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();

  const handelOnCompleted = ({ tokenAuth }) => {
    // Todo :   Use  refreshToken token  in the header request , protect private routes and tried   the  error case
    const { refreshToken, token, success, errors, user } = tokenAuth;
    if (errors) {
      if ("nonFieldErrors" in errors) {
        setNonFieldErrors(errors["nonFieldErrors"]);
      }
      delete errors.nonFieldErrors;
      for (const [key, value] of Object.entries(errors)) {
        setError(key, value);
      }
    } else if (success) {
      setNonFieldErrors([]);
      const { setTokens } = TokenManger();

      setTokens(tokenAuth);
      localStorage.setItem("role", user.role);
      console.log(" Check log in ");
      // due to unsync behaver of user login state we used window.location.herf 
      // Todo: fix the sync behaver of user login state 
      if (location?.state?.next) {
        navigate("/reserving", {
          state: {
            ...location.state,
          },
        });
      } else if (user.role === "A_1") {
        navigate("/user/profile");
        window.location.href = "/user/profile";
        console.log(" role  A_1");
      } else if (user.role === "A_2") {
        console.log(" role A_2");
       
        navigate("/admin/Dashboard");
        window.location.href = "/admin/Dashboard";
      }
    }
  };
  const [login, { data, loading, error }] = useMutation(LOG_IN, {
    onCompleted: handelOnCompleted,
  });

  const onSubmit = (data) => {
    login({ variables: data });
  };

  if (error) return `Submission error! ${error.message}`;
  const goToRegister = () => {
    navigate("/register", {
      state: {
        ...location.state,
      },
    });
  };
  return (
    <React.Fragment>
      <div className="row m-4 login">
        <div className="col-lg-6 p-4">
          <div className="row logIN">
            <div className="col-12 startText">
              <span className="title-1 Tlog">Access to your account</span>
            </div>
            <div className="col-12 startText mb-3 Tdes">
              <span className="textLow-1">
                It is a long established fact that a reader will be distracted
                by the readable content.
              </span>
            </div>
            <div className="SocialLogin">
              <div className="col-6 startText">
                <SocialButton
                  icon={faFacebook}
                  text="Facebook"
                  callback={() => {
                    console.log("clicked");
                  }}
                />
              </div>
              <div className="col-6 startText">
                <SocialButton
                  icon={faGoogle}
                  text="Google"
                  callback={() => {
                    console.log("clicked");
                  }}
                />
              </div>
            </div>
            <div className="col-12 mb-2 mt-2 borderOR">
              <Separator text="Or login with" />
            </div>
            {loading ? (
              <LoaderPage />
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="text-danger">
                  {nonFieldErrors.map((error, i) => (
                    <p key={i}>{error.message}</p>
                  ))}
                </div>
                <div className="col-12 ml-4 mr-4">
                  <StandardInput
                    id="email"
                    type="email"
                    placeholder="Email"
                    {...register("email", {
                      required: true,
                      pattern: EMAIL_REGXR,
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="email"
                    render={({ message }) => (
                      <p className="text-danger">{message}</p>
                    )}
                  />
                </div>
                <div className="col-12 ml-4 mr-4">
                  <StandardInput
                    id="password"
                    type="password"
                    placeholder="Password"
                    //   onChange={(evt) => {
                    //     setPassword(evt.target.value);
                    //   }}
                    //   shouldSendData={setValidPassword}
                    //   validateFunc={validateGeneral}
                    {...register("password", { required: true })}
                  />
                  <p>{errors.password?.message}</p>
                </div>
                <div className="col-12 forgot">
                  <span>Can't remember your password?</span>
                  <NavLink to="/forget" className="m-2 navlink green">
                    Reset it
                  </NavLink>{" "}
                  or{" "}
                  <Button onClick={goToRegister} variant="text">
                    {" "}
                    register Now{" "}
                  </Button>
                </div>
                <div className="col-12 buttonSubmit">
                  <button
                    className={`btn btn-success btn-book mx-auto `}
                    style={{ width: "100px" }}
                  >
                    Login
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
        <div className="col-lg-6">
          <img
            className="svgSize onlyDesktopSVG"
            src="./svg/Login.svg"
            alt="SVG"
          />
        </div>
      </div>
    </React.Fragment>
  );
}
