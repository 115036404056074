import { useMatch } from "react-router-dom";
import { useState, useEffect } from "react";

export const useActiveLinkClass = (to, end, secondurl) => {

    const [classN, setClassN] = useState("")
    let match = useMatch({
        path: to,
        end: end != undefined ? end : true
    });
    let match2 = useMatch({
        path: secondurl != undefined ? secondurl : to,
        end: true
    });
    useEffect(() => {
        function checkClass() {
            setClassN(match || match2 ? "active-link" : "");
        }
        return (checkClass())
    })
    return classN;
}