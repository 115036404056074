import { useState, useEffect, useRef } from "react";
import LoaderPage from "../../../../components/Shared/LoaderPage";
import Toast from "../../../../services/Toast";
import { Button, Avatar } from "@mui/material";
import { RiCloseLine } from "react-icons/ri";
import moment from "moment";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import {
  getUpComing,
  removeUpComing,
} from "../../../../services/client/dashboard";
import { MY_RESERVATION } from "../../../../services/Queries/reservationQuery";
import { useMutation, useQuery } from "@apollo/client";
import { EDIT_RESERVATION } from "../../../../services/mutations/reservationMutaions";
import { RESERVATION_KEYS } from "../../../../constants/keys";

const Past = () => {
  const [page, setPage] = useState(null);
  const {
    loading,
    error,
    data: reservation,
  } = useQuery(MY_RESERVATION, {
    variables: {
      filter: { type: RESERVATION_KEYS.TYPE.PAST },
      page: page,
      pageSize: RESERVATION_KEYS.ADMIN_PAGE_SIZE,
    },
  });
  const [data, setData] = useState(false);
  const [selectedItem, setselectedItem] = useState(-1);
  const [DatatoShow, setDatatoShow] = useState([]);
  const [disable, setdisable] = useState(false);
  const detailsref = useRef(null);

  const initData = () => {
    setData(-1);
    getUpComing(setData);
  };

  const setSelectedData = (i) => {
    setselectedItem(i);
    setDatatoShow(data[i]);
  };

  const onremoveUpComing = (item) => {
    setdisable(1);
    removeUpComing(data[item], () => {
      Toast.success("It was deleted successfully");
      let x = item;
      setselectedItem(-1);
      setdisable(false);
      setData(data.filter((e, i) => i != x));
    });
  };

  if (loading) return <LoaderPage />;
  const handlePageChange = (e, val) => {
    setPage(val);
  };

  return (
    <div className="d-flex flex-grow-1 container_details">
      {reservation?.userReservations.objects.length > 0 ? (
        <div className="flex-grow-1 element-1-cont">
          <div className="d-flex justify-content-between align-items-center">
            <h3 className="card-title" style={{ padding: 0 }}>
              My reservations
            </h3>
          </div>
          <br />
          <div className="d-flex " style={{ flexDirection: "column" }}>
            {reservation?.userReservations.objects.map((e, i) => (
              <UpComingElement
                item={i}
                onremoveUpComing={onremoveUpComing}
                disable={disable}
                onClick={() => {
                  setSelectedData(i);
                }}
                data={e}
              />
            ))}
            <Stack spacing={2} style={{ alignItems: "center" }}>
              <Pagination
                count={reservation?.userReservations?.pages}
                page={reservation?.userReservations?.page}
                onChange={handlePageChange}
                shape="rounded"
                color="success"
              />
            </Stack>
          </div>
        </div>
      ) : (
        <div
          className="d-flex justify-content-center align-items-center card flex-grow-1"
          style={{ borderRadius: "10px", minHeight: "50vh" }}
        >
          <h3>There is no data </h3>
          <br />
        </div>
      )}
    </div>
  );
};

const UpComingElement = ({
  data,
  onClick, 
  item,
  disable,
  onremoveUpComing,
}) => {
  const dateTime = moment(data.datetime)
    .format("YYYY/MM/DD , HH:mm:ss ")
    .toString();
  const [cancelReservation, { loading, error, data: reservation }] =
    useMutation(EDIT_RESERVATION);
  const onCancel = () => {
    // CANCELED
    cancelReservation({ variables: { status: "Canceled" } });
  };
  return (
    <div
      className="d-flex col-12 card order_container_element rder_container_element_1"
      style={{ cursor: "pointer", maxWidth: "700px" }}
      onClick={() => {
        onClick();
      }}
    >
      <div className="d-flex flex-grow-1 ">
        <Avatar
          className="avatar_img"
          src={data?.restaurant?.image}
          variant="rounded"
        />
        <div className="d-flex justify-content-center flex-column">
          <h3 className="card-title titleFav">{data?.restaurant.name}</h3>
          <small className="descFav">{data.address}</small>
          <div className="lineRow">
            <div className="time_element_res">
              <span>{dateTime} </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Past;
