import React from 'react';
import { GoogleMap, LoadScript, OverlayView, LoadScriptNext, Marker, useJsApiLoader, OverlayViewF, InfoWindow, InfoBox, InfoWindowF } from '@react-google-maps/api';
import { useNavigate, Link } from "react-router-dom";
import queryString from 'query-string'
import style from './style.module.css';;
const divSelectdStyle = {
    zIndex: '3000',
    padding: '0',
    fontWeight: 'bold',
    width: '250px',
    fontSize: 'larger',
    // height: '200px',
    display: 'flex',
    flexDirection: 'column'
};
const selectedArrowDownStyle = {
    width: 0,
    height: 0,
    borderLeft: '16px solid transparent',
    borderRight: '16px solid transparent',
    borderTop: '5px solid black',

}
const containerStyle = {
    width: '100%',
    height: '100%'
};
const divStyle = {

    padding: '0',
    fontWeight: 'bold',
    textAlign: 'center',
    width: '34px',
    fontSize: 'larger',
    height: '60px',
    display: 'flex', flexDirection: 'column'
};
const onClick = () => {
   
};
const getPixelPositionOffset = (width, height) => {
    console.log(' width, height', width, height)
    return {
        x: -(width / 4),
        y: -(height / 4),
    }
}
// transparent
const arrowDown = {
    width: 0,
    height: 0,
    borderLeft: '16px solid transparent',
    borderRight: '16px solid transparent',
    borderTop: '5px solid white',


}
const ResturantMapView = ({ restaurant, selectRestaurantId, selectRestaurant, onSelectRestuarant, hoveredRestaurant }) => {
    console.log(' ')

    if (restaurant.id == selectRestaurantId || !restaurant?.location) {
        return (<></>)
    }

    const getPixelPositionOffse = (width, height) => ({
        x: -(width / 3),
        y: -(height / 3),
    })
    const isHoverd = hoveredRestaurant?.id == restaurant?.id ? true : false
    const hasMenuOffer = restaurant.menuOffer ? true : false
    if (hasMenuOffer) {
        return (
            <OverlayViewF
                // position={restaurant.postion}
                onRemove={() => { console.log(' console  onRemove ') }}
                onUnmount={() => { console.log(' console  onUnmount ') }}
                position={{
                    lat: parseFloat(restaurant?.location?.coordinates[1]),
                    lng: parseFloat(restaurant?.location?.coordinates[0]),
                }}
                mapPaneName={OverlayView.FLOAT_PANE}
                getPixelPositionOffset={getPixelPositionOffse}
                zIndex={isHoverd ? 20 : 10}
            >

                <div className={style.marker}
                    onClick={onSelectRestuarant}
                >
                    <div className={isHoverd ? style.markerRatingHovered : style.markerRating}>
                        <p style={{ margin: 'auto', borderBottom: '1px solid green' }}>{restaurant?.rating ? restaurant?.rating : ":"}</p>
                    </div>
                    <div className={style.styledDiscount}>
                        <p style={{ margin: 'auto' }} >{restaurant?.menuOffer ? restaurant?.menuOffer.percentage : ":"}% </p>
                    </div>
                    <div className={style.arrowDownRartingOffer} ></div>
                    <div style={{ backgroundColor: '#00000073', padding: '1px', borderRadius: '100%', height: '2%' }}></div>

                </div>
            </OverlayViewF >
        )
    }
    return (
        <OverlayViewF
            // position={restaurant.postion}
            onRemove={() => { console.log(' console  onRemove ') }}
            onUnmount={() => { console.log(' console  onUnmount ') }}
            position={{
                lat: parseFloat(restaurant?.location?.coordinates[1]),
                lng: parseFloat(restaurant?.location?.coordinates[0]),
            }}
            mapPaneName={OverlayView.FLOAT_PANE}
            getPixelPositionOffset={getPixelPositionOffse}
            zIndex={isHoverd ? 20 : 10}
        >

            <div className={style.marker}
                onClick={onSelectRestuarant}
            >
                <div className={isHoverd ? style.markerRatingHovered : style.markerRating}>
                    <p style={{ margin: 'auto', borderBottom: '1px solid green' }}>{restaurant?.rating ? restaurant?.rating : ":"}</p>
                </div>
                {/* <div style={{ backgroundColor: 'black', color: 'white', height: '40%', display: 'flex', fontSize: '9px' }}><p style={{ margin: 'auto' }} >{restaurant?.discoun} </p></div> */}
                <div className={isHoverd ? style.arrowDownRaringHoverd : style.arrowDownRaring} ></div>
                <div style={{ backgroundColor: '#00000073', padding: '1px', borderRadius: '100%', height: '2%' }}></div>

            </div>
        </OverlayViewF >
    )
}
const mapOptions = {
    panControl: false,
    zoomControl: true,
    fullscreenControl: false,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    overviewMapControl: false,
    rotateControl: false,
    // zoomControlOptions :{position:google.maps.ControlPosition.TOP_RIGHT}
}
const GoogleMapView = ({ latitude, longitude, restaurants, restaurant, onSelectMapRestaurant, searchParams, hoveredRestaurant }) => {
    const [map, setMap] = React.useState(null);
    const [currentPosition, setPosition] = React.useState({ latitude: latitude, longitude: longitude });
    const [center, setCenter] = React.useState(null);

    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: "AIzaSyBqawZzj7ZhfcWrLMfZ60KO9OyCVX0SWhY",

        region: "MA"
        // ...otherOptions
    })
    const [selectRestaurant, setSetelectRestaurant] = React.useState(null)
    const onLoad = React.useCallback(
        function onLoad(mapInstance) {
           
            setMap(mapInstance)
        }
    )
    const onSelectRestuarant = (restaurant) => {
        setSetelectRestaurant(restaurant)
    }
    React.useEffect(() => {
      
        if (map && restaurant && longitude && latitude) {
            map.setCenter({ lat: latitude, lng: longitude });
        }
    }, [latitude, longitude, restaurant?.id, map]);

    if (isLoaded)

        return (

            <GoogleMap
                // options={options}

                onLoad={onLoad}
                mapContainerStyle={containerStyle}
                zoom={10}
                center={{ lng: parseFloat(latitude), lat: parseFloat(longitude) }}
                options={mapOptions}
            >
                <>
                    {restaurants ?

                        restaurants?.map((r, i) =>
                            <ResturantMapView
                                hoveredRestaurant={hoveredRestaurant}
                                key={i}
                                restaurant={r}
                                onSelectRestuarant={() => onSelectMapRestaurant(r)}
                                selectRestaurant={restaurant}
                                selectRestaurantId={restaurant?.id}
                            />)

                        : null}
                </>
                {restaurant ?
                    <InfoWindowF
                        onCloseClick={() => onSelectMapRestaurant(null)}
                        position={{
                            lat: parseFloat(restaurant?.location?.coordinates[1]),
                            lng: parseFloat(restaurant?.location?.coordinates[0]),
                        }}

                        mapPaneName={OverlayView.MARKER_LAYER}
                        bounds={restaurant?.postion}
                        zIndex={50000}
                    >
                        <Link to={`/reservation/${restaurant.id}?${queryString.stringify(searchParams, { skipNull: true })}`} target="_blank">
                            <div style={divSelectdStyle}
                            // onClick={() => onSelectRestuarant(restaurant)}
                            >
                                <div style={{
                                    backgroundColor: 'white', height: '120px', color: '#222', display: 'flex',
                                    border: '1px solid #888', alignItems: "flex-start"
                                }}>
                                    <img
                                        src={restaurant.image}
                                        alt=""
                                        style={{ margin: 'auto' }}
                                        className=" card-image " />
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <div style={{
                                        backgroundColor: 'white', height: '40%', fontSize: 'smaller',
                                        justifyContent: 'left', display: 'flex', flex: '1', color: '#777',
                                        alignItems: "flex-start",
                                        flexDirection: 'column'
                                    }
                                    }>
                                        <span >{restaurant?.category.name}</span>

                                        <h5> <strong>{restaurant?.name}</strong></h5>
                                    </div>
                                    <div style={{ display: 'flex', justifyItems: 'center', alignItems: 'center' }}>
                                        <span style={{ margin: 'auto' }}>{restaurant?.rating ? restaurant?.rating : "New"}</span>
                                    </div>
                                </div>
                                {/* <div>Aavrige price <span>15</span> </div> */}
                            </div>
                        </Link>
                    </InfoWindowF>
                    : null}
            </GoogleMap>

        )

    if (loadError) {
        return <div>Map cannot be loaded right now, sorry.</div>
    }
    return <div style={{ height: '100%', width: '100%', backgroundColor: 'blue' }}></div>
};

export default React.memo(GoogleMapView);