import { useState, useEffect } from "react";
import LoaderPage from "../../../../components/Shared/LoaderPage";
import { getNewsLetter,EditNewsLetter} from "../../../../services/restaurant-admin/admin-dashboard";
import  Toast from "../../../../services/Toast";
import LoadingButton from "@mui/lab/LoadingButton";
import SwitchElement from "../../../../components/Shared/Switch";

const NewsLetter =()=>{

    const [data,setData] = useState(false);
    const [newdata,setnewData] = useState(false);
    const [edit,setedit] = useState(false);
    const [disable,setdisable] = useState(false);
    const initData = ()=>{
        setData(-1);
        getNewsLetter((d)=>{setData(d);setnewData(d)});
    }
    
    
    const CancelNewsLetter = ()=>{
        setdisable(false);
        setedit(false);
        setnewData(data);
    }
    const onSave = ()=>{
        setdisable(1);
        EditNewsLetter(newdata,()=>{
            Toast.success("It was saved successfully");
            setdisable(false);
            setedit(false);
            setData(newdata);
        });
    }
    const onChange = (e,k)=>{
        console.log(e,k);
        if(edit==false) return;
        let x = {...newdata};
        x[k]=e.target.value;
        setnewData(x);
    }
    
    useEffect(()=>{
        if(data===false)
            initData();
    });

    if(data === false || data === -1)
        return( <LoaderPage/>)
    
        
    return(
        <div className="d-flex flex-grow-1 container_details" style={{ maxWidth:"650px" }}>
           <div className="flex-grow-1 element-1-cont" >
                <div className="d-flex flex-column justify-content-start align-items-start">
                    <h3 className="card-title" style={{ padding: 0 }}>General settings</h3>
                    <div className="d-flex align-items-center">
                        <h5 className="text-muted" style={{ padding: 0,opacity:.9 }}>NewsLetter</h5>
                        <SwitchElement checked={newdata.show} onChange={(v)=>setnewData({...newdata,show:(!newdata.show)})} />
                    </div>
                    <br/>
                </div>
                <br/>
                <div className="col-12">
                    <label className="form-label label_primary" style={{ color:"#000" }}>Title</label>
                    <input disabled={!(newdata.show)} type="text" value={newdata.title} onChange={(e)=>{onChange(e,'title')}}  className="form-control" placeholder="Address"/>
                </div>
                <br/>
                <div className="col-12">
                    <label className="form-label label_primary" style={{ color:"#000" }}>Subtitle</label>
                    <input type="text"  disabled={!(newdata.show)} value={newdata.subtitle} onChange={(e)=>{onChange(e,'subtitle')}}  className="form-control" placeholder="Address"/>
                </div>
                <br/>
                <div className="col-12">
                    <label className="form-label label_primary" style={{ color:"#000" }}>Subtitle</label>
                    <input type="text"  disabled={!(newdata.show)} value={newdata.email} onChange={(e)=>{onChange(e,'email')}}  className="form-control" placeholder="Address"/>
                </div>
                <br/>
                {
                edit?
                    <div className="d-flex w-100 justify-content-center " style={{ height: "45px" }}>
                        {<LoadingButton variant="contained" color="secondary"  style={{ color:"#fff",flex:1,margin:"2px",borderRadius:"10px"}}  className="class_button"    disabled={disable} onClick={()=>{CancelNewsLetter()}} >Cancel</LoadingButton>}
                        {<LoadingButton variant="contained"color="primary" style={{ color:"#fff",flex:1,margin:"2px",borderRadius:"10px"}}  className="class_button" disabled={disable} onClick={()=>{onSave();}}>Done</LoadingButton>}
                    </div>
                :
                    <div className="d-flex w-100 justify-content-center " style={{ height: "45px" }}>
                        <LoadingButton variant="contained" color="primary"  style={{ color:"#fff",flex:1,margin:"2px",borderRadius:"10px"}}  className="class_button"    disabled={disable}onClick={()=>{setedit(true)}} >Edit</LoadingButton>
                    </div>
                }
            </div>

          
        </div>
    )
}

export default NewsLetter;