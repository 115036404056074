import React from 'react';
const SearchInput=()=>{
    return(
        <div className="input-group" style={{ position:"relative",width: "fit-content",width: "100%" }}>
            <div className="form-outline" style={{ width: "100%" }}>
                <input id="search-input" type="text" className="form-control" placeholder="Search here ..." />
            </div>
            <div id="search-button" type="button" className="btn ">
                <i className="fas fa-search"></i>
            </div>
        </div>
    );
}
export default SearchInput;