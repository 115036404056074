import { useState, useEffect } from "react";
import LoaderPage from "../../../../components/Shared/LoaderPage";
import { getSocialMedia,EditSocialMedia} from "../../../../services/restaurant-admin/admin-dashboard";
import  Toast from "../../../../services/Toast";
import LoadingButton from "@mui/lab/LoadingButton";

const SocialMedia =()=>{

    const [data,setData] = useState(false);
    const [newdata,setnewData] = useState(false);
    const [edit,setedit] = useState(false);
    const [disable,setdisable] = useState(false);
    const initData = ()=>{
        setData(-1);
        getSocialMedia((d)=>{setData(d);setnewData(d)});
    }
    
    
    const CancelSocialMedia = ()=>{
        setdisable(false);
        setedit(false);
        setnewData(data);
    }
    const onSave = ()=>{
        setdisable(1);
        EditSocialMedia(newdata,()=>{
            Toast.success("It was saved successfully");
            setdisable(false);
            setedit(false);
            setData(newdata);
        });
    }
    const onChange = (e,k)=>{
        if(edit==false) return;
        let x = {...newdata};
        x[k]=e.target.value;
        setnewData(x);
    }
    
    useEffect(()=>{
        if(data===false)
            initData();
    });

    if(data === false || data === -1)
        return( <LoaderPage/>)
    
        
    return(
        <div className="d-flex flex-grow-1 container_details">
           <div className="flex-grow-1 element-1-cont" >
                <div className="d-flex flex-column justify-content-start align-items-start">
                    <h3 className="card-title" style={{ padding: 0 }}>Basic settings</h3>
                    <h5 className="text-muted" style={{ padding: 0,opacity:.9 }}>Social Media</h5><br/>
                </div>
                <br/>
                <div className="row">
                    <div className="col-md-6">
                        <label className="form-label label_primary">Facebook</label>
                        <input type="text" value={newdata.facebook} onChange={(e)=>{onChange(e,'facebook')}} className="form-control" placeholder="Facebook"/>
                        <br/>
                    </div>
                    <div className="col-md-6">
                        <label className="form-label label_primary">Instagram</label>
                        <input type="text" value={newdata.instagram} onChange={(e)=>{onChange(e,'instagram')}}  className="form-control" placeholder="Instagram"/>
                        <br/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <label className="form-label label_primary">Youtube</label>
                        <input type="text" value={newdata.youtube} onChange={(e)=>{onChange(e,'youtube')}} className="form-control" placeholder="Youtube"/>
                        <br/>
                    </div>
                    <div className="col-md-6">
                        <label className="form-label label_primary">Linkdin</label>
                        <input type="text" value={newdata.linkdin} onChange={(e)=>{onChange(e,'linkdin')}}  className="form-control" placeholder="Linkdin"/>
                        <br/>
                    </div>
                </div>
                <br/>
                {
                edit?
                    <div className="d-flex w-100 justify-content-center " style={{ height: "45px" }}>
                        {<LoadingButton variant="contained" color="secondary"  style={{ color:"#fff",flex:1,margin:"2px",borderRadius:"10px"}}  className="class_button"    disabled={disable} onClick={()=>{CancelSocialMedia()}} >Cancel</LoadingButton>}
                        {<LoadingButton variant="contained"color="primary" style={{ color:"#fff",flex:1,margin:"2px",borderRadius:"10px"}}  className="class_button" disabled={disable} onClick={()=>{onSave();}}>Done</LoadingButton>}
                    </div>
                :
                    <div className="d-flex w-100 justify-content-center " style={{ height: "45px" }}>
                        <LoadingButton variant="contained" color="primary"  style={{ color:"#fff",flex:1,margin:"2px",borderRadius:"10px"}}  className="class_button"    disabled={disable}onClick={()=>{setedit(true)}} >Edit</LoadingButton>
                    </div>
                }
            </div>

          
        </div>
    )
}

 


export default SocialMedia;