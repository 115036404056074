import React from "react";
import CRoutes from "../../routes/admin-dashboard"; 
import SearchInput from "../../components/Shared/SearchInput";
import "../../styles/RestaurantAdmin.css";
import ProfileNavElement from "../../components/Shared/ProfileNavElement";
import {AiOutlineMenu} from "react-icons/ai";
import { useLeftNavBarOpen } from "../../hooks/LeftNavBar";

const AdminDashboard = ()=>{
    const OpenNavBarMobile = ()=>{useLeftNavBarOpen()}
    return(
        <div className="page_container left_nvbar_model_2_cont">
            <div className="top">
                <div className="d-flex align-items-center" >
                    <div className="rmv_mobile_lftnv add_mobile_lftnv" style={{ padding:'5px' }} onClick={()=>{OpenNavBarMobile()}}>
                        <AiOutlineMenu size="20" color="#000" style={{ cursor:"pointer" }}  />
                        </div>
                 </div>
                 <div className="search_container">
                 <SearchInput/>
                 </div>
                 <ProfileNavElement/>
            </div>
            <CRoutes/>
        </div>
    );
} 
export default AdminDashboard;