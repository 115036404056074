import React, { useState } from "react";
import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
 makeStyles
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "../../components/navbar/Navbar-elements.js";
import ButtonAuthMenuItem from "./menuItems/authButtons";
import ContactSectionMenuItem from "./menuItems/contactSection.js";
import QuickLinksMenuItem from "./menuItems/quickLinks.js";
import LoggedInMenuItem from "./menuItems/loggedInSection.js";

const useStyles = makeStyles(()=>({
    link:{
        textDecoration:"none",
        color: "blue",
        fontSize: "20px",
    },
    icon:{
        color: "white"
    }
}));

function MenuBarMobile(props) {
    const classes = useStyles();
    function close()
    {
        setOpenDrawer(false);
    }
    const [openDrawer, setOpenDrawer] = useState(false);
    return (
        <>
            <Drawer
                open={openDrawer}
                onClose={() => close()}
            >
                <img onClick={()=>close()} style={{width:"18px",cursor:"pointer"}} src="/svg/close.svg" icon="fa-solid fa-xmark" className="rightCorner m-4" />
                {!props?.user&&<List>
                    <NavLink onClick={()=>{close();}} to="/" className="navlink paddingMenu " activeStyle>
                        Home
                    </NavLink>
                    <NavLink onClick={()=>{close();}} to="/restaurants" className="navlink paddingMenu" activeStyle>
                        Restaurants
                    </NavLink>
                    <NavLink onClick={()=>{close();}} to="/foods" className="navlink paddingMenu" activeStyle>
                        Foods
                    </NavLink>
                    <NavLink onClick={()=>{close();}} to="/about" className="navlink paddingMenu" activeStyle>
                        About
                    </NavLink>
                    <NavLink onClick={()=>{close();}} to="/help" className="navlink paddingMenu" activeStyle>
                        Help
                    </NavLink>
                    <ButtonAuthMenuItem done={()=>{close();}}/>
                    <ContactSectionMenuItem />
                    <QuickLinksMenuItem done={()=>{close();}}/>
                </List>}
                {props?.user&&
                <List>
                    <LoggedInMenuItem user={props.user} done={()=>{close();}}/>
                </List>
                }
            </Drawer>
            <div className="menuBarMobile">
                <IconButton className="m-3" onClick={() => setOpenDrawer(!openDrawer)}>
                    <FontAwesomeIcon icon="fa-solid fa-bars" />
                </IconButton>
                <div className="centerText w-100">
                    <Link to="/"><img style={{width:"60px"}} src="/svg/LogoMobile.svg" alt="Table.ma" /></Link>
                </div>
            </div>
        </>
    );
}
export default MenuBarMobile;