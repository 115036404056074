import React, { useEffect, useState } from "react";
import Toast from "../../services/Toast";
import { unliking, liking } from "../../services/client/general";
import { useNavigate } from "react-router-dom";
import { LIKE_A_RESTAURANT, UNLIKE_A_RESTAURANT } from "../../services/mutations/userMustation";
import { useMutation, useQuery } from "@apollo/client";
function Card(props) {
  var redirectPath = "/reservation/";
  var path = "./info-";
  const navigate = useNavigate();
  const [liked, setLiked] = useState(null);
  const onCompletedHandlerLikeRestaurantMutation = ({ likeRestaurant }) => {
    const { success, errors } = likeRestaurant
    if (success) onDoneLiking()
  }
  const onCompletedHandlerUnLikeRestaurantMutation = ({ unLikeRestaurant }) => {
    const { success, errors } = unLikeRestaurant
    if (success) onDoneLiking()
  }
  const onErrorHandlerunLikeRestaurantMutation = (data) => {

  }
  const onErrorHandlerlikeRestaurantMutation = (data) => {

  }
  const [
    likeRestaurant,
    { loading: loadinglikeRestaurant, error: errorlikeRestaurant, data: datalikeRestaurant },
  ] = useMutation(LIKE_A_RESTAURANT, {

    onCompleted: onCompletedHandlerLikeRestaurantMutation,
    onError: onErrorHandlerlikeRestaurantMutation,
  });
  const [
    unLikeRestaurant,
    { loading: loadingunLikeRestaurant, error: errorunLikeRestaurant, data: dataunLikeRestaurant },
  ] = useMutation(UNLIKE_A_RESTAURANT, {
    onCompleted: onCompletedHandlerUnLikeRestaurantMutation,
    onError: onErrorHandlerunLikeRestaurantMutation,
  });
  useEffect(() => {

    setLiked(props?.isLiked);
  }, []);

  function onDoneLiking() {
    let msg = (liked ? "Unliked" : "Liked") + " successfully";
    setLiked(!liked);
    Toast.success(msg);
  }

  function doLikeUnlike() {

    if (liked)
      return unLikeRestaurant({ variables: { restaurant: props?.id } })
    return likeRestaurant({ variables: { restaurant: props?.id } })
  }

  const redirect = () => {
    navigate(redirectPath + props?.id);
  }
  return (
    <>


      {/* item ------------------------------------- */}

      <div className="card div-inline restaurant-card pb-4-mb">
        <div className={"card-imaged div-inline pt-2 " + props.palceholderimage}>
          <img src={props.img} alt="" onClick={redirect} className="w-100 card-image pointing"></img>
          {liked != null &&
            <div className="isLiked-cercle mt-3 container pointing flex-container-mb flex-center-mb" onClick={doLikeUnlike}>
              <img
                src={liked ? "/liked.svg" : "/like.svg"}
                alt=""
                className="isLiked "
              ></img>
            </div>
          }
          {/* <span className="isLiked"></span> */}
        </div>
        <div className="cat_info">
          <div className={"card-title card-item div-inline pointing " + props.palceholdertitle} onClick={redirect}>{props.title}</div>
          <div className={"card-description card-item div-inline " + props.placeholderdes}>
            {props.desc} 
          </div>
          <div className={"card-review my-auto card-item " + props.placeholderfav}>
            <img src="./star.png" alt="" className="starIcon"></img>
            <span className="reviewNum">{props.review}</span>
          </div>
          <div className={"card-category card-item " + props.palceholdercat}>
            <div className="status">
              <div className="stat_left">
                <img src="./catIcon.png" alt="" className="starIcon"></img>
                <span className="category-txt">{props.category}</span></div>
              <div className={"stat_right " + props.status}>
                <i className="far fa-info-circle"></i>
                <span className="restaurant-status stat">
                  {props.status}
                </span></div>
            </div>
          </div></div>
      </div>
    </>
  );
}

export default Card;
