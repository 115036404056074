import React from "react";
import { useNavigate } from "react-router-dom";
import HelpElement from "../../../components/client/helpCard";
import "../../../styles/client/Client.css";
import "../../../styles/client/ClientHelp.css";


export default function Help()
{
    let navigate = useNavigate(); 
    const navigateTo = (path,params) =>{ 
        navigate(path,params);
    }
    return (
        <React.Fragment>
            <div className="w-100 centerText mv-5p">
                <div style={{fontSize:"15px",textTransform:"uppercase",height:"22px"}} className="w-100 title-1 green">Help</div>
                <div style={{fontSize:"32px"}} className="w-100 title-1">You need any help?</div>
            </div>
            <div className="flex-container mb-4 mh-5p gapHelp">
                <HelpElement className="one-flex" title="FAQ" button="Go to FAQ" navigate={()=>{navigateTo("/faq",{});}}
                text="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some."/>
                <HelpElement className="one-flex" title="Support" button="Contact us" navigate={()=>{navigateTo("/contact",{});}}
                text="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some."/>
            </div>
        </React.Fragment>
    )
}