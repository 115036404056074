import { Avatar, Button } from "@mui/material";
import { BsChevronRight } from "react-icons/bs";
import { useState, useEffect } from "react";
import { getTime } from "../../../../../services/general";
import moment from "moment";
const OrdersElement = ({ data, onClick, item }) => {

  const duration = moment(data.createdAt).fromNow()

  return (
    <div
      className="d-flex col-12 card order_container_element ResElement"
      style={{ cursor: "pointer" }}
      onClick={() => {
        onClick();
      }}
    >
      <div className="d-flex flex-grow-1 " style={{ alignSelf: "flex-start" }}>
        <Avatar
          className="avatar_img"
          src={data.user.image}
          variant="rounded"
        />
        <div className="flex-contenue">
          <h3 className="card-title" style={{ padding: 0, margin: 0 }}>
            <span>{data.user.lastName}</span>
            {data.user.firstName}
          </h3>
          <div
            className="d-flex  align-items-lg-stretch"
            style={{ flexDirection: "column" }}
          >
            <p className="text-muted">
              <small>{data.user.email}</small>
            </p>
            <p className="text-muted2">
              <small>{duration} </small>
            </p>
          </div>
        </div>
      </div>
      <div
        className="d-flex align-items-center"
        style={{ alignSelf: "center" }}
      >
        <div className="text-muted " style={{ marginRight: "10px" }}>
          <small>
            Order N°: <span style={{ color: "#43AB8C" }}>#{data.id} </span>
          </small>
        </div>
        <Button
          disabled={item}
          className="button_right_icon"
          variant="contained"
          startIcon={<BsChevronRight size="25" color="#fff" />}
        />
      </div>
    </div>
  );
};

export default OrdersElement;
