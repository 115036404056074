import { gql } from "@apollo/client";

export const CITIES_QUERY = gql`
  query AllCities($pageSize: Int, $page: Int,$search:String) {
    allCities(pageSize: $pageSize, page: $page,search:$search) {
      page
      pages
      count
      objects {
        name
        id
      }
    }
  }
`;
