
import { MdOutlineFavoriteBorder} from "react-icons/md";
import { AiOutlineUser, AiFillBuild } from "react-icons/ai";
import PersonalInformaiton from "../../pages/user-dashboard/element/PersonalInformaiton";
import MyFavorite from "../../pages/user-dashboard/element/MyFavorite";
import Past from "../../pages/user-dashboard/element/reservation/Past";
import UpComing from "../../pages/user-dashboard/element/reservation/UpComing";

export const userDashboard = [
    {   
        title:"Personal Information",
        icon:<AiOutlineUser className="svgicon1"/>,
        path:"profile",
        element:<PersonalInformaiton/>
    },
    {   
        title:"My reservations",
        icon:<AiFillBuild className="svgicon1"/>,
        path:"reservations",
        element:<UpComing/>,
        children:[
            {title:"Up coming",path:"up-coming",element:<UpComing/>},
            {title:"past",path:"past",element:<Past/>},
        ]
    },
    {   
        title:"My Favorite",
        icon:<MdOutlineFavoriteBorder className="svgicon1"/>,
        path:"favorite",
        element:<MyFavorite/>
    },
];