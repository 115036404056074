import * as React from 'react';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Stack from '@mui/material/Stack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { ButtonPrimary } from '../button';
import { BsBoxArrowRight, BsFillPlusCircleFill } from "react-icons/bs";
import { logout } from '../../../services/client/auth';
import { useQuery } from "@apollo/client";
import { CURRENT_USER } from '../../../services/Queries/UserQuery';
export default function DropDownLoggedIn(props) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const { loading, error, data } = useQuery(CURRENT_USER);
  const navigate = useNavigate();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    navigate('/');
    setOpen(false);
    window.location.href = '/'
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <Stack direction="row" spacing={2}>
      <div>
        <Button
          ref={anchorRef}
          id="composition-button"
          aria-controls={open ? 'composition-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          {props.image} <span style={{ margin: '0 2px' }} />{props?.first?.toString()} {props?.last?.toString()} <FontAwesomeIcon className="m-1" icon="fa-solid fa-chevron-down" />
        </Button>
        <Popper
          style={{ zIndex: "10000000" }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom-start' ? 'left top' : 'left bottom',
              }}
            >
              <Paper className="card-general profeMenu flagDrop">
                <ClickAwayListener>
                  {data?.me?.role == "A_2" ?
                    <MenuList
                      autoFocusItem={open}
                      id="composition-menu"
                      aria-labelledby="composition-button"
                      onKeyDown={handleListKeyDown}
                    >

                      <MenuItem className="pb-2" onClick={(event) => { navigate("/admin/Dashboard") }}>Overview</MenuItem>
                      <MenuItem className="pb-2" onClick={(event) => { navigate("/admin/Orders") }}>Orders</MenuItem>
                      <MenuItem className="pb-2" onClick={(event) => { navigate("/admin/Settings") }}>Settings</MenuItem>

                      <div className="w-100 flex-container" style={{ justifyContent: "center" }}>
                        <ButtonPrimary
                          className="mx-auto secondaryButtonStyle p-0 displayBlock marginMenu w-75 centerText logoutButton" text={<><BsBoxArrowRight className="colorNextToSecond iconNextTo" /> Log out</>}
                          click={(event) => { logout(() => { handleClose(event); }) }} />
                      </div>
                    </MenuList> :
                    <MenuList
                      autoFocusItem={open}
                      id="composition-menu"
                      aria-labelledby="composition-button"
                      onKeyDown={handleListKeyDown}
                    >

                      <MenuItem className="pb-2" onClick={(event) => { navigate("/user/profile") }}>My profil</MenuItem>
                      <MenuItem className="pb-2" onClick={(event) => { navigate("/user/reservations") }}>Reservations</MenuItem>
                      <MenuItem className="pb-2" onClick={(event) => { navigate("/user/favorite") }}>My favorite</MenuItem>
                      <div onClick={(event) => { navigate("/register-restaurant") }}>
                        <div className="w-100 flex-container mt-3 mb-3 add" style={{ justifyContent: "center", gap: "4%", cursor: "pointer" }}>
                          <BsFillPlusCircleFill className="colorIconGreen" />
                          <div className="addResto">
                            Add my restaurant
                          </div>
                        </div>
                      </div>
                      <div className="w-100 flex-container" style={{ justifyContent: "center" }}>
                        <ButtonPrimary
                          className="mx-auto secondaryButtonStyle p-0 displayBlock marginMenu w-75 centerText logoutButton" text={<><BsBoxArrowRight className="colorNextToSecond iconNextTo" /> Log out</>}
                          click={(event) => { logout(() => { handleClose(event); }) }} />
                      </div>
                    </MenuList>
                  }
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </Stack>
  );
}