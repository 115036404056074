import React from 'react';
import Avatar from '@mui/material/Avatar';
import { useEffect, useState } from 'react';
import { getUserData } from '../../services/client/auth';
import { CURRENT_USER } from '../../services/Queries/UserQuery';
import { useQuery } from "@apollo/client";
import {
    useFloating,
    useInteractions,
    useHover,
    useFocus,
    useRole,
    useClick,
    useDismiss,
    flip, shift, offset
} from '@floating-ui/react-dom-interactions';
import styleClass from './style.module.css';
import { ButtonPrimary } from '../client/button';
import { BsBoxArrowRight, BsFillPlusCircleFill } from "react-icons/bs";
import TokenManger from '../../api/TokenManger';
const ProfileNavElement = () => {

    const { loading, error, data } = useQuery(CURRENT_USER,);
    const [open, setOpen] = useState(false);
    const { removeTokens } = TokenManger()
    const logOut = () => {
      
        removeTokens()
        window.location.href = '/'

    }
    const { x, y, reference, floating, strategy, context } = useFloating({
        open,
        onOpenChange: setOpen,
        placement: 'bottom',
        middleware: [offset(20), flip(), shift(),]
    });
    const hover = useHover(context, { enabled: false });
    const focus = useFocus(context, { enabled: false });
    const role = useRole(context,);
    const click = useClick(context,);
    const dismiss = useDismiss(context)
    const { getReferenceProps, getFloatingProps, getItemProps } =
        useInteractions([role, hover, focus, click, dismiss]);
    // useEffect(()=>{
    //     setData(getUserData())
    // },[]);
    if (loading) return <></>

    return (
        <>

            <div className="d-flex justify-content-center align-items-center profile_ad_cont" style={{ cursor: "pointer" }} >
                <Avatar variant="rounded"
                    src={data?.me?.image} />
                <div style={{ margin: "0 10px" }} ref={reference} {...getReferenceProps()} >{`${data?.me?.firstName} ${data?.me?.lastName} `}</div>
            </div>
            {open && (
                <div className={styleClass.menu}
                    ref={floating}
                    {...getFloatingProps()}
                    style={{
                        position: strategy,
                        top: y ?? 0,
                        left: x ?? 0,
                        width: "150px",
                    }}
                >

                    <div className="w-100 flex-container" style={{ justifyContent: "center", alignItems: 'center', margin: '19px 0' }}>
                        <ButtonPrimary
                            className="mx-auto secondaryButtonStyle p-0 displayBlock marginMenu w-75 centerText logoutButton"
                            text={<><BsBoxArrowRight className="colorNextToSecond iconNextTo"
                                onClick={logOut}
                            /> Log out</>}
                        />

                    </div>
                </div>
            )
            }
        </>
    );
}

export default ProfileNavElement;