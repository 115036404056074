import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
const ServerError = ({ nonFieldErrors, itemClassName, ...rest }) => {
  return (
    <div {...rest}>
      {nonFieldErrors.map((error, i) => (
        <p key={i} className={itemClassName}>{error.message}</p>
      ))}
    </div>
  );
};
const useFormWithServerErrors = (params) => {
  const [nonFieldErrors, setNonFieldErrors] = useState([]);
  let methods = useForm();
  if (params) {
    const { useFormInit } = params;
    methods = useForm(useFormInit);
  }
  const { setError } = methods;
  const setErrors = (errors) => {
    console.log(' errors ', errors)
    if (errors) {
      if ("nonFieldErrors" in errors) {
        setNonFieldErrors(errors["nonFieldErrors"]);
      }
      delete errors.nonFieldErrors;

      for (const [key, value] of Object.entries(errors)) {
        // setError(key, value);

        value.map((item) => {

          if ('message' in item) {
            setError(key, item);
          }
          else
            setError(key, { type: 'custom', message: item });

        });
      }
    }
  };
  return {
    ...methods,
    useFormMethods: { ...methods },
    nonFieldErrors,
    setNonFieldErrors,
    setErrors,
    ErrorMessage,
    ServerError: (rest) => (
      <ServerError nonFieldErrors={nonFieldErrors} {...rest} />
    ),
  };
};

export default useFormWithServerErrors;
