import React from "react";
import "../../styles/client/Client.css";
import { useEffect,useState } from "react";

export default function TextArea(props)
{
    const [validateFlag,setValidate]=useState("none");
    const [valueInput,setValue]=useState(null);
    const [errors,setErrors]=useState([]);
    function validate()
    {
        if(errors.length==0&&valueInput==null)
            return "null";
        if(errors.length==0)
            return "true";
        return "false";
    }
    useEffect(()=>{
        setErrors(props.validateFunc(valueInput));
    },[valueInput]);
    useEffect(()=>{
        setValidate(validate());
        props.shouldSendData(errors.length==0&&valueInput!=null);
    },[errors]);
    return (
        <>
            <textarea 
                    id={props.id} 
                    className={`input-client p-3 ${props.className}`} 
                    value={valueInput} placeholder={props?.placeholder} 
                    onChange={(evt)=>{props.onChange(evt);setValue(evt.target.value)}} 
                    validate={validateFlag}>

            </textarea>
            <ul className="ul-input">{errors&&errors.map(text=>{
                return <li key={text}><p className="text-danger">{text}</p></li>;
            })}
            </ul>
        </>
    );
}