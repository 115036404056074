import { useState, useEffect, useRef, useContext } from "react";
import LoaderPage from "../../../../components/Shared/LoaderPage";

import Toast from "../../../../services/Toast";
import { Button } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";

import { useQuery, useMutation } from "@apollo/client";
import { RESTAURANT_MENU_QUERY } from "../../../../services/Queries/RestaurantQuery";
import { NUMBER_VALIDATION } from "../../../../validation/regexr";
import {
  EDIT_RESTAURANT_MENU_ITEM,
  DElELT_RESTAURANT_MENU_ITEM,
  CREATE_RESTAURANT_MENU_ITEM,
} from "../../../../services/mutations/restaurantMutaions";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import useFormWithServerErrors from "../../../../hooks/useFormWithServerErrors";
import { MENU_KEYS } from "../../../../constants/keys";
import { RestaurantManagerContext } from "../..";
import useDeleteDialog from "../../DeleteDialog";
const Menu = () => {
  const myRestaurant = useContext(RestaurantManagerContext);
  const [page, setPage] = useState(1);
  const [data, setData] = useState(false);
  const [selectedItem, setSelectedItem] = useState(-1);
  const [DatatoShow, setDatatoShow] = useState([]);
  const [disable, setDisable] = useState(false);
  const detailsref = useRef(null);
  const [edit, setEdit] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const {
    loading,
    data: menuList,
    refetch,
  } = useQuery(RESTAURANT_MENU_QUERY, {
    variables: {
      restaurantMenuId: myRestaurant.id,
      page: page,
      pageSize: MENU_KEYS.USER_PAGE_SIZE,
    },
  });

  const setSelectedData = (i, e) => {
    setEdit(true);
    setSelectedItem(i);
    setSelectedMenu(e);

    if (selectedItem != -1) detailsref.current.scrollIntoView(true);
    else
      setTimeout(() => {
        detailsref.current.scrollIntoView(true);
      }, 200);
  };
  const setAdd = () => {
    setEdit(true);
    setSelectedItem(-1);
    setSelectedMenu(null);

    setSelectedItem(-2);

    if (selectedItem != -1) detailsref.current.scrollIntoView(true);
    else
      setTimeout(() => {
        detailsref.current.scrollIntoView(true);
      }, 200);
  };

  const onAddMenu = () => {
    // setDisable(true);
  };
  const onEditMenu = () => {};
  const onDeleteMenu = (i) => {
    setDisable(true);
  };
  const CancelMenu = () => {
    setSelectedItem(-1);
    setEdit(false);
    setDisable(false);
  };
  const handlePageChange = (e, val) => {
    setPage(val);
  };
  if (loading) return <LoaderPage />;
  return (
    <div className="d-flex flex-grow-1 container_details MN">
      {menuList?.restaurantMenu.objects.length > 0 ? (
        <div className="flex-grow-1 element-1-cont">
          <div className="d-flex justify-content-between align-items-center">
            <h3 className="card-title" style={{ padding: 0 }}>
              Menu
            </h3>
            <Button
              id="addbutton"
              variant="contained"
              color="primary"
              onClick={() => {
                setAdd();
              }}
            >
              Add new
            </Button>
          </div>
          <br />
          <div className="d-flex " style={{ flexDirection: "column" }}>
            {menuList?.restaurantMenu.objects.map((e, i) => (
              <MenuElement
                index={i}
                onEdit={() => {
                  setSelectedData(i, e);
                }}
                refetch={refetch}
                setDisable={setDisable}
                data={e}
                item={selectedItem != i}
                key={i}
              />
            ))}
            <Stack spacing={2} style={{ alignItems: "center" }}>
              <Pagination
                count={menuList?.restaurantMenu?.pages}
                page={menuList?.restaurantMenu?.page}
                onChange={handlePageChange}
                shape="rounded"
                color="success"
              />
            </Stack>
          </div>
        </div>
      ) : (
        <div
          className="d-flex justify-content-center align-items-center card flex-grow-1"
          style={{ borderRadius: "10px", minHeight: "50vh" }}
        >
          <h3>There is no data </h3>
          <br />
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setAdd();
            }}
            style={{
              color: "#fff",
              height: "40px",
              width: "130px",
              margin: "20px 2px",
              borderRadius: "10px",
            }}
          >
            Add new
          </Button>
        </div>
      )}
      <div
        ref={detailsref}
        style={{
          display: selectedItem == -1 && "none",
          flex: 1,
          width: "100%",
          minWidth: "300px",
          marginLeft: "10px",
        }}
      >
        <h3 className="card-title" style={{ padding: 0 }}>
          {selectedItem == -2 ? "Add new Item" : "Edit"}
        </h3>
        <br />
        {selectedItem != -1 ? (
          <MenuDetails
            data={selectedMenu}
            disableandLoad={disable}
            edit={edit}
            setEdit={setEdit}
            setdata={setDatatoShow}
            doneButtonLabel={"Save"}
            cancelButtonLaber={"Cancel"}
            editButtonLabel={"Edit"}
            deleteButtonLaber={"Delete"}
            refetch={refetch}
            doneButtonClick={onAddMenu}
            cancelButtonClick={CancelMenu}
            editButtonClick={onEditMenu}
            deleteButtonClick={onDeleteMenu}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
const getNumber = (i) => {
  if (i < 9) return "0" + (i + 1);
  return i + 1 + "";
};

const MenuElement = ({ refetch, data, onEdit, index, setDisable }) => {
  const myRestaurant = useContext(RestaurantManagerContext);
  const {
    useFormMethods,
    ServerError,
    setErrors,
    setNonFieldErrors,
    ErrorMessage,
  } = useFormWithServerErrors();

  const {
    register,
    unregister,
    reset,
    watch,
    control,
    handleSubmit,
    formState: { errors },
  } = useFormMethods;

  const onCompleteDeleteHandler = ({ deleteRestaurantMenuItem }) => {

    const { errors, success } = deleteRestaurantMenuItem;
    setOpen(false);
    if (setDisable) {
      setDisable(false);
    }
    if (success) {
      Toast.success("The menu Item has been delete");
      if (refetch) {
        refetch();
      }
      if (deleteButtonClick) {
        deleteButtonClick();
      }

      if (cancelButtonClick) cancelButtonClick();
    } else {
      setErrors(JSON.parse(errors));
    }
  };

  const onErrorDeleteHandler = ({ deleteRestaurantMenuItem }) => {};

  const [
    deleteMenu,
    { loading: loadingDelete, error: errorDelete, data: dataDelete },
  ] = useMutation(DElELT_RESTAURANT_MENU_ITEM, {
    onCompleted: onCompleteDeleteHandler,
    onError: onErrorDeleteHandler,
  });
  const onCompleteEditHandler = ({ editRestaurantMenuItem }) => {
    const { success, errors, menuItem } = editRestaurantMenuItem;
    if (props.setDisable) {
      props.setDisable(false);
    }
    if (success) {
      Toast.success("The menu Item has been edit");
      if (props.editButtonClick) props.editButtonClick();
      if (props.cancelButtonClick) props.cancelButtonClick();
    } else {
      setErrors(JSON.parse(errors));
    }
  };
  const onErrorEditHandler = (data) => {};

  const [editMenu, { loading: loadingEdit, error: errorEdit, data: dataEdit }] =
    useMutation(EDIT_RESTAURANT_MENU_ITEM, {
      onCompleted: onCompleteEditHandler,
      onError: onErrorEditHandler,
    });
  const { Dialog, setOpen } = useDeleteDialog({
    text: " Are you sure you wanna delete this menu item ",
    title: "Deletion",
    actionLabel: "Yes",
    onAction: () =>
      deleteMenu({ variables: { id: data?.id, restaurant: myRestaurant.id } }),
  });
  const onSubmit = (values) => {
    setDisable(true);
    if (data?.id) {
      editMenu({
        variables: {
          ...values,
          restaurant: myRestaurant.id,
          editRestaurantMenuItemId: data?.id,
        },
      });
    }
  };
  const onDelete = () => {
    setDisable(true);
    setOpen(true);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Dialog />
      <div className="number_label">{getNumber(index)} </div>
      <div className="d-flex col-12   ">
        <div
          className="d-flex flex-column flex-grow-1  "
          style={{ marginRight: "10px" }}
        >
          <div className="d-flex flex-row flex-grow-1">
            <div className="input-group mb-3 d-flex">
              <input
                type="text"
                className="form-control"
                placeholder="Title"
                {...register("title", { value: data.title })}
              />
            </div>
            <div className=" d-flex justify-content-start align-items-start">
              <input
                value={data.price}
                type="text"
                className="form-control small"
                placeholder="Price"
                style={{ paddingRight: "40px" }}
                {...register("price", { value: data.price })}
              />
            </div>
          </div>
          <div className="input-group mb-3 d-flex">
            <textarea
              type="text"
              style={{ resize: "none" }}
              rows="5"
              className="form-control"
              placeholder="Description"
              {...register("description", { value: data.description })}
            />
          </div>
        </div>
        <div className="d-flex  align-items-start">
          <Button
            onClick={onDelete}
            className="add_edit_btn_icon"
            color="error"
            variant="contained"
            startIcon={
              <AiOutlineDelete
                style={{ margin: "auto" }}
                size="20"
                color="#fff"
              />
            }
          />
          <Button
            // type="submit"
            onClick={onEdit}
            className="add_edit_btn_icon"
            variant="contained"
            loading={loadingDelete | loadingEdit}
            startIcon={
              <AiOutlineEdit
                style={{ margin: "auto" }}
                size="20"
                color="#fff"
              />
            }
          />
        </div>
      </div>
    </form>
  );
};

const MenuDetails = (props) => {
  const myRestaurant = useContext(RestaurantManagerContext);
  const {
    useFormMethods,
    ServerError,
    setErrors,
    ErrorMessage,
  } = useFormWithServerErrors();

  const {
    register,
    unregister,
    reset,
    handleSubmit,
    formState: { errors },
  } = useFormMethods;

  const onCompleteCreateHandler = ({ createRestaurantMenuItem }) => {
    const { errors, success, menuItem } = createRestaurantMenuItem;
    if (props.setDisable) {
      props.setDisable(false);
    }
    if (success) {
      if (props.cancelButtonClick) props.cancelButtonClick();
      if (props.refetch) props.refetch();
      Toast.success("The menu  has been created");
    } else {
      setErrors(JSON.parse(errors));
    }
  };

  const onErrorCreateHandler = ({ createRestaurantMenuItem }) => {};

  const onErrorEditHandler = (data) => {};
  const [
    createMenu,
    { loading: loadingCreate, error: errorCreate, data: dataCreate },
  ] = useMutation(CREATE_RESTAURANT_MENU_ITEM, {
    onCompleted: onCompleteCreateHandler,
    onError: onErrorCreateHandler,
  });

  const onCompleteEditHandler = ({ editRestaurantMenuItem }) => {
    const { success, errors, menuItem } = editRestaurantMenuItem;
    if (props.setDisable) {
      props.setDisable(false);
    }

    if (success) {
      Toast.success("The menu Item has been edit");
      if (props.editButtonClick) props.editButtonClick();
      if (props.cancelButtonClick) props.cancelButtonClick();
    } else {
      setErrors(JSON.parse(errors));
    }
  };

  const [editMenu] = useMutation(EDIT_RESTAURANT_MENU_ITEM, {
    onCompleted: onCompleteEditHandler,
    onError: onErrorEditHandler,
  });

  const onSubmit = (values) => {
    if (props.setDisable) {
      props.setDisable(true);
    }
    if (props.data?.id) {
      editMenu({
        variables: {
          ...values,
          editRestaurantMenuItemId: props.data.id,
          restaurant: myRestaurant.id,
        },
      });
    } else {
      createMenu({
        variables: {
          ...values,
          restaurant: myRestaurant.id,
        },
      });
    }
  };

  useEffect(() => {
    if (props?.data) {
      reset({
        ...props?.data,
      });
    } else {
      reset({
        price: "",
        title: "",
        description: "",
      });
    }

    return () => {
      unregister();
    };
  }, [props?.data?.id, props.edit]);

  return (
    <div className="MN-detail">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="number_label"></div>
        <div className="d-flex col-12 flex-column ">
          <ServerError />

          <ErrorMessage
            errors={errors}
            name="title"
            render={({ message }) => (
              <p className="text-danger p-1">{message}</p>
            )}
          />
          <ErrorMessage
            errors={errors}
            name="description"
            render={({ message }) => (
              <p className="text-danger p-1">{message}</p>
            )}
          />
          <ErrorMessage
            errors={errors}
            name="price"
            render={({ message }) => (
              <p className="text-danger p-1">{message}</p>
            )}
          />

          <div className="d-flex flex-grow-1 ">
            <div className="d-flex  flex-grow-1">
              <div className="input-group mb-3 d-flex">
                <input
                  {...register("title", {
                    value: props?.data?.title,
                    required: "title is required",
                  })}
                  type="text"
                  className="form-control"
                  placeholder="Title"
                />
              </div>
              <div
                className=" d-flex justify-content-start align-items-start"
                style={{
                  marginLeft: "5px",
                  marginRight: "10px",
                  position: "relative",
                  height: "fit-content",
                }}
              >
                <input
                  type="text"
                  className="form-control"
                  placeholder="Price"
                  {...register("price", {
                    value: props?.data?.price,
                    ...NUMBER_VALIDATION("price"),
                    required: "Price is required",
                  })}
                  style={{ paddingRight: "40px" }}
                />
                <span className="DH_span">DH</span>
              </div>
            </div>
          </div>
          <div className="input-group mb-3 d-flex">
            <textarea
              {...register("description", { value: props?.data?.description })}
              type="text"
              style={{ resize: "none" }}
              rows="5"
              className="form-control"
              placeholder="Description"
            />
          </div>
        </div>
        <div className="d-flex w-100 justify-content-center ">
          {props?.cancelButtonLaber && (
            <LoadingButton
              variant="contained"
              color="secondary"
              style={{
                color: "#fff",
                flex: 1,
                margin: "2px",
                borderRadius: "10px",
              }}
              className="class_button"
              disabled={props.disableandLoad}
              loading={props.disableandLoad == 2}
              onClick={() => {
                props.cancelButtonClick();
              }}
            >
              {props.disableandLoad == 2 ? "" : props.cancelButtonLaber}
            </LoadingButton>
          )}
          {props?.doneButtonLabel && (
            <LoadingButton
              variant="contained"
              style={{
                color: "#fff",
                flex: 1,
                margin: "2px",
                borderRadius: "10px",
              }}
              className="class_button"
              type="submit"
              disabled={props.disableandLoad}
              loading={props.disableandLoad == 1}
              // onClick={() => {
              //   AddorEdit();
              // }}
            >
              {props.disableandLoad == 1 ? "" : props.doneButtonLabel}{" "}
            </LoadingButton>
          )}
        </div>
      </form>
    </div>
  );
};

export default Menu;
