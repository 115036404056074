import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
// import Draggable from "react-draggable";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function useDeleteDialog({
  onAction,
  actionLabel,
  title,
  text,
  onCancel,
}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const onCancelHandler = () => {
    setOpen(false);
    if (onCancel) onCancel();
  };
  const onActionHandler = () => {
    if (onAction) onAction();
  };
  return {
    open: open,
    setOpen: setOpen,
    Dialog: () => (
      <Dialog
        open={open}
        onClose={onCancelHandler}
        // PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Alert severity="warning">
              <AlertTitle>Warning</AlertTitle>
              {text}
            </Alert>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={onCancelHandler}>
            Cancel
          </Button>
          <Button onClick={onActionHandler}>{actionLabel}</Button>
        </DialogActions>
      </Dialog>
    ),
  };
}
