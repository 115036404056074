import React from "react";
import CRoutes from "../../routes/user-dashboard"; 
import "../../styles/RestaurantAdmin.css";
import "../../styles/client/dashboardUser.css";
import ProfileNavElement from "../../components/Shared/ProfileNavElement";
import {AiOutlineMenu} from "react-icons/ai";
import { useLeftNavBarOpen } from "../../hooks/LeftNavBar";
import Navbar from "../../components/navbar";

const UserDashboard = ()=>{
    const OpenNavBarMobile = ()=>{useLeftNavBarOpen()}
    return(
        <div className="page_container left_nvbar_model_2_cont">
            <Navbar/>
            <CRoutes/>
        </div>
    );
} 
export default UserDashboard;