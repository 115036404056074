export function reserve(data) {
    /* should return true if reservation done otherwise false */
    return true;
}

const url_img = 'https://images.unsplash.com/photo-1414235077428-338989a2e8c0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8cmVzdGF1cmFudHxlbnwwfHwwfHw%3D&w=1000&q=80';
const url_img1 = "https://thumbs.dreamstime.com/b/christmas-family-dinner-table-concept-80369233.jpg";
const description = `Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.
, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.
The standard chunk of Lorem Ipsum used since t
The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.`;

export function reservation(id, onDone) {

    onDone({
        id: id,
        galery: [url_img, url_img1, url_img1, url_img, url_img1, url_img, url_img1, url_img1, url_img, url_img, url_img, url_img],
        title: "The Fiver Fishermen",
        adress: "Rue Georges Sand, n°20 Val Fleuri, Casablanca",
        description: description,
        url: 'www.tf-fishermen.com',
        email: 'contact@tf-fishermen.com',
        phone: '+ 212 (547) 547 4578',
        menu: [
            { id: 1, price: "15", title: "Demi magret de canard du Sud Ouest ", description: "dmldl mldmlm mlm lldmld lm lm !" },
            { id: 2, price: "20", title: "Pêche du jour ", description: "dmldl mldmlm mlm lldmld lm lm !" },
            { id: 3, price: "12", title: "Pêche du jour ", description: "dmldl mldmlm mlm lldmld lm lm !" },
            { id: 3, price: "12", title: "Pêche du jour ", description: "dmldl mldmlm mlm lldmld lm lm !" },
            { id: 3, price: "12", title: "Pêche du jour ", description: "dmldl mldmlm mlm lldmld lm lm !" },
            { id: 3, price: "12", title: "Pêche du jour ", description: "dmldl mldmlm mlm lldmld lm lm !" },
            { id: 3, price: "12", title: "Pêche du jour ", description: "dmldl mldmlm mlm lldmld lm lm !" },
        ],
        extraService: [
            { id: 1, price: "15", title: "50% off the a la carte menu", description: "Preset menus and drinks not included. Available on the booked timeslot" },
            { id: 2, price: "20", title: "90% off the a la carte menu", description: "Preset menus and drinks not included. Available on the booked timeslot" },
            { id: 3, price: "12", title: "30% off the a la carte menu", description: "Preset menus and drinks not included. Available on the booked timeslot" },
        ],
        workhours: {
            monday: { open: true, from: "9:00PM", to: "22:00AM", reason: "" },
            tuesday: { open: true, from: "9:00PM", to: "22:00AM", reason: "" },
            wednesday: { open: true, from: "9:00PM", to: "22:00AM", reason: "" },
            thursday: { open: false, from: "9:00PM", to: "22:00AM", reason: "" },
            friday: { open: true, from: "9:00PM", to: "22:00AM", reason: "" },
            saturday: { open: true, from: "9:00PM", to: "22:00AM", reason: "" },
            sunday: { open: true, from: "9:00PM", to: "22:00AM", reason: "" },
        },
        reviews: {
            foodrating: 4.2,
            servicerating: 5,
            ambiencerating: 4.2,
            rating: 4.3,
        },
        offers: [
            { id: 1, price: "15", title: "50% off the a la carte menu", description: "Preset menus and drinks not included. Available on the booked timeslot" },
            { id: 2, price: "20", title: "90% off the a la carte menu", description: "Preset menus and drinks not included. Available on the booked timeslot" },
            { id: 3, price: "12", title: "30% off the a la carte menu", description: "Preset menus and drinks not included. Available on the booked timeslot" },
        ],
        customerReviews: [
            { name: 'Denise M. Walker', review: 4.5, image: url_img1, description: 'ldldl ldldl ldldl l' },
            { name: 'Denise M. Walker', review: 5.0, image: url_img, description: 'ldldl ldldl ldldl l' },
        ]
    })
}

export const addReview = (data, onDone) => {
    //send data
    //then
    onDone({ name: 'Denise M. Walker', review: 5.0, image: url_img, description: 'ldldl ldldl ldldl l' });
}