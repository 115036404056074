import React from "react";
import "../../../styles/client/Client.css";
import { ButtonPrimary } from "../button";

export default function ContactSectionMenuItem(props)
{
    return (
        <div className="w-100 smallsize mt-3">
            <div className="simple-text">
                it is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout. The point
              of using lorem ipsum is that it.
            </div>
            <div>
                <h5> Email: <a href="mailto:support@tabla.ma">support@tabla.ma</a></h5>
                <h5> Phone: <a href="tel:2125585474758">+212 (558) 547 4758 </a></h5>
            </div>
        </div>
    );
}