import Hero from "../../../components/hero";
import Feed from "../../../components/Feed";
import FeedPREMIUM from "../../../components/FeedPremium";
import Gat from "../../../components/category";
import React, { useEffect, useState } from "react";
import SecondPart from "../../../components/secondPart";
import {
  useGetCategories,
  useGetlistinghomepage,
  SetListingPupular,
  useGetListPopularRestaurant,
} from "../../../services/client/general";
import CarouselCards from "../../../components/client/carouselCards";
import { useNavigate } from "react-router-dom";
export default function Home() {
  const [dataHome, setDataHome] = useState([]);
  // const [dataPopular,setDataPopular]=useState([]);
  const dataPopular = useGetListPopularRestaurant();
  const navigate = useNavigate();
  const restaurantsData = useGetlistinghomepage()

  useEffect(() => {
    setDataHome(null);
  }, []);
  console.log(' restaurantsData dataPopular ', dataPopular)
  return (
    <React.Fragment>
      <Hero className="hero mb-5" />
      <div className="container my-auto mb-5 ">
        <div className="mt-4 Popular-restaurants">
          <h2> New Restaurants</h2>
          <button
            className="popular-restaurant-viewMore"
            onClick={() => navigate("/restaurants")}
          >
            View more
          </button>
        </div>
      </div>
      <div className="desktopOnly container cards-container mb-5 mt-5 ">
        <Feed restaurants={restaurantsData} />
      </div>

      <div className="mobileOnly">
        <CarouselCards data={useGetlistinghomepage()} />
      </div>
      <div className="container my-auto mb-5 ">
        <div className="mt-4 Popular-restaurants">
          <h2> Popular Restaurants</h2>
          <button
            className="popular-restaurant-viewMore"
            onClick={() => navigate("/restaurants")}
          >
            View more
          </button>
        </div>
      </div>
      <div className="desktopOnly container cards-container mb-5 ">
        <Feed restaurants={dataPopular} />
      </div>
      <div className="mobileOnly">
        <CarouselCards data={dataPopular} />
      </div>
      <div className="container my-auto mb-5 ">
        <div className="mt-4 Popular-restaurants">
          <h2>Categories</h2>
        </div>
      </div>

      <div className="container my-auto mb-5 " id="category">
        {useGetCategories()?.map((e) => {
          return <Gat id={e?.id} img={e?.image} name={e?.name} />;
        })}
      </div>
      <div className="container my-auto mb-5 ">
        <div className="mt-4 Popular-restaurants">
          <h2> Restaurants near to you</h2>
          <button
            className="popular-restaurant-viewMore"
            onClick={() => navigate("/restaurants")}
          >
            View more
          </button>
        </div>
      </div>
      <div className="desktopOnly container cards-container mb-5 ">
        <Feed restaurants={restaurantsData} />
      </div>
      <div className="mobileOnly">
        <CarouselCards data={dataPopular} />
      </div>
      <SecondPart />
    </React.Fragment>
  );
}
