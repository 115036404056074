import { useState, useEffect } from "react";
import Avatar from "../../../components/Shared/profile";
import Toast from "../../../services/Toast";
// import Button from "../../../components/Button/Button-S";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

import LoaderPage from "../../../components/Shared/LoaderPage";
import useFormWithServerErrors from "../../../hooks/useFormWithServerErrors";

import { useMutation, useQuery } from "@apollo/client";
import { CURRENT_USER } from "../../../services/Queries/UserQuery";
import { UPDATE_PASSWORD } from "../../../services/mutations/userMustation";
import { PASSWORD_VALIDATION } from "../../../validation/regexr";
const PasswordInformation = () => {
    //   const [data, setData] = useState(false);


    const {
        register,
        setNonFieldErrors,
        ServerError,
        ErrorMessage,
        getValues,
        watch,
        handleSubmit,
        reset,
        formState: { isDirty, errors },
    } = useFormWithServerErrors();
    const watchedPassword = watch("newPassword1");
    const onErrorHandlerMutation = (data) => { };


    const onCompletedHandlerMutation = ({ passwordChange }) => {

    };
    const [
        updatePassword,
        { loading: loadingMutation, error: errorMutation, data: dataMutation },
    ] = useMutation(UPDATE_PASSWORD, {
        onCompleted: onCompletedHandlerMutation,
        onError: onErrorHandlerMutation,
    });
    const handleOnSubmit = (values) => {
      
        updatePassword({ variables: { ...values } });
    };


    if (loadingMutation) return <LoaderPage />;

    return (
        <div className="d-flex flex-grow-1 container_details">
            <div className="" style={{ width: '100%' }}>
                <form onSubmit={handleSubmit(handleOnSubmit)}>

                    <ServerError />

                    <div className="col-inp">
                        <div className="col">
                            <input
                                type="password"
                                {...register("oldPassword", {

                                })}
                                className="form-control"
                                placeholder="Old password"
                            />
                            <ErrorMessage
                                errors={errors}
                                name="oldPassword"
                                render={({ message }) => (
                                    <p className="text-danger">{message}</p>
                                )}
                            />
                        </div>

                    </div>
                    <br />
                    <div className="col-inp">
                        <div className="col">
                            <input
                                type="password"
                                {...register("newPassword1", PASSWORD_VALIDATION)}

                                className="form-control"
                                placeholder="New password"
                            />
                            <ErrorMessage
                                errors={errors}
                                name="newPassword1"
                                render={({ message }) => (
                                    <p className="text-danger">{message}</p>
                                )}
                            />
                        </div>
                    </div>
                    <br />
                    <div className="col-inp">
                        <div className="col">
                            <input
                                type="text"
                                {...register("newPassword2", {
                                    PASSWORD_VALIDATION,
                                    validate: (value) =>
                                        value === watchedPassword ||
                                        "Password Confirmation is not Equal to password",
                                })}
                                className="form-control"
                                placeholder="Retype password"
                            />
                            <ErrorMessage
                                errors={errors}
                                name="newPassword2"
                                render={({ message }) => (
                                    <p className="text-danger">{message}</p>
                                )}
                            />
                        </div>

                    </div>
                    <br />
                    <Stack spacing={2} direction="row" className="group-btn">
                        {isDirty && (
                            <Button
                                variant="outlined"
                                onClick={() =>
                                    reset({
                                    })
                                }
                            >
                                Cancel
                            </Button>
                        )}
                        <Button
                            variant="contained"
                            color="success"
                            disabled={!isDirty}
                            type="submit"
                        >
                            Save
                        </Button>
                    </Stack>
                </form>
            </div>
        </div>
    );
};

export default PasswordInformation;
