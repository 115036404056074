import React, { useEffect, useState, useContext } from "react";
import { LogoTabla } from "../Shared/Logo";
import defaults from "./default.png";
import { useLocation, useNavigate, createSearchParams, redirect } from "react-router-dom";
import 'antd/dist/antd.css';
import { AutoComplete, Select } from 'antd';
// import { faCoffee } from "@fortawesome/free-brands-svg-icons";
import { NavLink, Bars, NavMenu, Nav } from "./Navbar-elements";
import MobileMenuBar from "../../components/client/menuBarMobile";
import { useQuery } from "@apollo/client";
import { getUserData } from "../../services/client/auth";
import DropDownLoggedIn from "../client/menuItems/dropDownLoggedIn";
import { CURRENT_USER } from "../../services/Queries/UserQuery";
import { useGetCities, useGetCategories } from "../../services/client/general";
import Avatar from '@mui/material/Avatar';
import useCities from "../../hooks/useCities";
import { SEARCH_POSTS_PAGE } from "../settings/constant";
import { SearchParamsContext } from "../../constants/contexts";
const useNavigateSearch = () => {
    const { setSearchParams } = useContext(SearchParamsContext)
    const navigate = useNavigate();
    return (pathname, params) => {

        setSearchParams(params)
        return navigate(`${pathname}?${createSearchParams(params)}`, { replace: true });
    }
};

function useQueryURL() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}
function Navbar() {
    const navigateSearch = useNavigateSearch();
    const { loading, error, data } = useQuery(CURRENT_USER, { fetchPolicy: "network-only" });
    let query = useQueryURL();
    const [search, setSearch] = useState(query.get("search"));
    const [city, setCity] = useState(query.get("city"));
    const [category, setCategory] = useState(query.get("category"));
    let QueryUser;

    if (data) {
        QueryUser = data.me;
    }
    const token = localStorage.getItem("token");
    const cityOptions = useGetCities()?.map((e) => {
        return (
            { value: e?.id, label: e?.name }
        );
    })
    const searchOptions = useGetCategories()?.map((res, i) => { return { value: res.name, type: "CATEGORY", label: res.name } })
    const sendData = () => {

        // const query = {
        //   city: book,
        //    bookData
        // };
        // const search = setStateToUrl(query);
        // navigate({
        //   pathname: SEARCH_POSTS_PAGE,
        //   search: search,
        // });

        const newParma = { city, search: search };

        navigateSearch(SEARCH_POSTS_PAGE, newParma);
    };
    return (
        <>
            <div className="desktopOnly shadow">
                <Nav>
                    <NavLink className="navlink" to="/">
                        <LogoTabla />
                    </NavLink>
                    <Bars />
                    <div className="flex-container" style={{ width: '50%' }}>
                        <div className="col-sm search-keyword-nav"
                            style={{
                                border: '1px solid black',
                                borderBottomLeftRadius: '5px',
                                borderTopLeftRadius: '5px',
                                borderRight: 'none',
                                // width: '45%',
                                // maxWidth:'45%',

                            }}>
                            <i className="icon-ic24-search" style={{ margin: '1px 4px', color: 'black' }}></i>

                            <AutoComplete
                                style={{
                                    width: '90%',
                                    maxWidth: '90%',

                                }}
                                allowClear={true}
                                // options={searchOptions}
                                //  allowClear={true}
                                value={search}
                                const onChange={(data) => {
                                    setSearch(data)
                                }}
                                //  defaultOpen={true}
                                //  onSearch={(val) => {
                                //      refetchRestaurant({ variables: { search: val } });
                                //  }}
                                placeholder="category, restaurant..."
                            //  filterOption={(inputValue, option) =>
                            //      option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                            //  }
                            />
                        </div>
                        <div className="col-sm my-auto  search-city-nav" style={{
                            border: '1px solid black',
                            // width: '45%',
                            // maxWidth:'45%',
                            borderBottomRightRadius: '5px',
                            borderTopRightRadius: '5px',
                        }}>
                            <i className="fal fa-building" style={{ margin: '1px 4px', color: 'black' }}></i>
                            <Select
                                style={{
                                    width: '90%',

                                }}
                                value={city}
                                onChange={(val) => setCity(val)}
                                allowClear={true}
                                placeholder="City"
                                optionFilterProp="children"
                                options={cityOptions}
                            // onSelect={(val) => {
                            //     setBook({ ...book, city:val.value });
                            // }}
                            // onSearch={onSearch}
                            // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                            >

                            </Select>

                        </div>
                        <div className=" my-auto book-grp search-button-nav" style={{ width: '6%', }}>
                            <button type="submit" className="btn btn-success " style={{ height: '100%', }} onClick={sendData} >Search</button>
                        </div>

                    </div>
                    <Bars />
                    <NavMenu className="right">
                        {!token && (
                            <div className="flex-container">
                                <button className="btn-tabla btn ">
                                    <NavLink className="p-0" to="/login">
                                        Login
                                    </NavLink>
                                </button>
                                <button className="btn btn-bg-tabla">
                                    <NavLink className="p-0" to="/register">
                                        Sign up
                                    </NavLink>
                                </button>
                            </div>
                        )}
                        {token && (
                            <div>
                                {loading ? (
                                    <div className="header--isloading">
                                        <div className="loading placeholderprofile">Image</div>
                                        <div className="loading placeholdertitle">Title</div>
                                    </div>
                                ) : (
                                    <div className="displayInline">

                                        <DropDownLoggedIn
                                            first={QueryUser?.firstName}
                                            last={QueryUser?.lastName}
                                            image={
                                                <Avatar variant="rounded"
                                                    src={QueryUser?.image} />

                                            }
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                    </NavMenu>
                </Nav>
            </div>
            <div className="mobileOnly">
                <MobileMenuBar />
            </div>
        </>
    );
}

export default Navbar;
