import React, { useEffect, useState, useContext } from "react";
import SearchInputGroup from "../../../components/client/searchInputGroup";
import ItemBoxResturant from "../../../components/item-resturant";
import MapsCa from "../../../components/maps/Mapsearch";
import "../../../styles/client/Client.css";
import moment from "moment";
import SearchElement from "../../../components/client/searchElement";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import LoaderPage from "../../../components/Shared/LoaderPage";
import { useRestaurantSearch } from "../../../services/client/restaurant";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Map from "../../../components/Map";
import { SearchParamsContext } from "../../../constants/contexts";
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
const buildQuery = (query) => {
  const obj = {};
  for (const [key, value] in query.entries()) {

    if (key !== null && key !== "null") {
      obj[key] = value;
    }
  }
  return obj;
};

export default function SearchClient(props) {
  let query = useQuery();
  const { searchParams: searchParamsValue } = useContext(SearchParamsContext);
  let [searchParamsrr, setSearchParamsrr] = useSearchParams();
  const [searchParams, setSearchParams] = useState({
    // category: query.get("category"),
    pageSize: 6,
    // city: query.get("city"),
    // category: query.get("category"),
    // guests: query.get("guests"),
    filter: {
      search: query.get("search"),
      city: query.get("city"),
      // category: query.get("category"),
      guests: query.get("guests"),
    },
    page: 1,
  });
  const {
    data: searchResult,
    loading,
    error,
    refetch,
  } = useRestaurantSearch({ variables: searchParams });

  const searchDate = new Date(query.get("date"));
  const navigate = useNavigate();
  const [done, setDone] = useState(true);
  const [data, setData] = useState(null);
  const [overlay, setOverlay] = useState(true)
  // let { city } = useParams();
  // console.log(' query.get("city" === ', city)
  const [selectedRestaurant, setSelectedRestaurant] = useState(null);
  const [hoveredRestaurant, setHoveredRestaurant] = useState(null);
  const location = useLocation()
  const [position, setPosition] = useState({
    latitude: -7.603869,
    longitude: 33.589886,
  });
  const handlePageChange = (e, val) => {
    setSearchParams({ ...searchParams, page: val });
  };

  const [bookData, setBookData] = useState({
    text: query.get("text"),
    date: query.get("date"),
    time: query.get("time"),
    city: query.get("city"),
    search: query.get("search"),
    guests: query.get("guests"),
  });
  const getSearchValue = (val) => {
    setOverlay(false)
    const { filter } = searchParams

    const obj = { ...filter, ...val, city: query.get("city"), search: query.get("search"), };
    const filterUrl = {

      date: query.get("date"),
      city: query.get("city"),
      search: query.get("search"),
      guests: query.get("guests"),
    }
    Object.keys(obj).forEach((key) => {
      if (obj[key] === null || obj[key] === "null") {
        delete obj[key];
      }
    });
    setSelectedRestaurant(null);
    let datetimeReservation = null;
    let dater = new Date();
    let timer = null;
    if (obj.date) {
      dater = new Date(Date.parse(obj.date));
      datetimeReservation = new Date(Date.parse(obj.date));
    }
    if (obj.time) {
      timer = new Date(Date.parse(obj.time));
      const hourAndMinutes = obj.time.split(':')
      // dater.setTime(timer.getTime())
      // const momentTime = moment(dater);
      dater.setSeconds(hourAndMinutes[1]);
      dater.setHours(hourAndMinutes[0]);

      datetimeReservation = dater;
    }
    delete obj["datetimeReservation"];
    setBookData({ ...obj });
    delete obj["date"];
    delete obj["time"];



    const mdate=moment(datetimeReservation)
    
    setSearchParams({
      filter: {
        ...obj, datetimeReservation: datetimeReservation, 
      },
      offerMenuDate:mdate.format('YYYY-MM-DD'), offerfilters: {
        date: mdate.format('YYYY-MM-DD'),
        // time: mdate.format('HH:mm')
      }

    });
  };

  const onPositionHandler = (r) => {

    setSelectedRestaurant(r);
    if (r?.location)
      setPosition({
        latitude: r?.location?.coordinates[0],
        longitude: r?.location?.coordinates[1],
      });
  };
  useEffect(() => {

    const obj = { ...bookData };
    Object.keys(obj).forEach((key) => {
      if (obj[key] === null || obj[key] === "null") {
        delete obj[key];
      }
    });
    let datetimeReservation = null;
    let dater = null;
    let timer = null;
    if (obj.date) {
      dater = new Date(Date.parse(obj.date));
      datetimeReservation = new Date(Date.parse(obj.date));
    }

    if (obj.time) {
      timer = new Date(Date.parse(obj.time));

      dater.setSeconds(timer.getSeconds());
      dater.setHours(timer.getHours());
      datetimeReservation = dater;
    }
    setBookData(obj);
    delete obj["date"];
    delete obj["time"];
    delete obj["datetimeReservation"];
    setSearchParams({
      page: 1, pageSize: 6, filter: {
        ...obj, datetimeReservation: datetimeReservation, 
      },
    });
  }, []);
  useEffect(() => {
    if (searchResult?.allRestaurant?.objects.length > 0) {
      const r = searchResult?.allRestaurant?.objects[0];
      // setSelectedRestaurant(r);
      if (r?.location?.coordinates)
      setPosition({
        latitude: r?.location?.coordinates[0],
        longitude: r?.location?.coordinates[1],
      });
    }
  }, [searchResult?.allRestaurant?.objects.length]);
  useEffect(() => {
    // console.log(' city ',city)
    const filter = {

      date: query.get("date"),
      city: query.get("city"),
      search: query.get("search"),
      guests: query.get("guests"),
    }
    const filters = {
      search: query.get("search"),
      pageSize: 6,
      city: query.get("city"),
      // category: query.get("category"),
      guests: query.get("guests"),
      filter: {
        search: query.get("search"),
        city: query.get("city"),
        guests: query.get("guests"),
      },
      page: 1,
    }
    setBookData({
      ...filter, time: query.get("time"),
    })
    setSearchParams(filters)

    refetch({ ...filters })

  }, [searchParamsValue])
  return (
    <React.Fragment>
      <div className="w-100 centerText mv-5p">
        {data != null && (
          <div>
            <div style={{ fontSize: "32px" }} className="w-100 title-1">
              Here is your search result
            </div>
          </div>
        )}
        {data == null && (
          <div className="w-100 centerText mv-5p">
            <div style={{ fontSize: "32px" }} className="w-100 title-1">
              Search for your match restaurant
            </div>
          </div>
        )}

      </div>
      <div className="w-100 centerText mv-5p " style={{ position: 'sticky', top: '0', zIndex: '300' }}>

        <SearchInputGroup

          bookData={bookData}
          setBookData={setBookData}
          getSearchValue={getSearchValue}
        />
      </div>
      {data == null && (
        <>
          {/* <div className="container my-auto mb-5 ">
            <div className="mt-4 Popular-restaurants">
              <h6 className="foundhead">
                {searchResult?.allRestaurant.objects.length} Restaurant
                {searchResult?.allRestaurant.objects.length > 1 ? "s" : ""}{" "}
                found
              </h6>
            </div>
          </div> */}
          <div className="container " onScroll={() => setOverlay(false)}>
            {overlay ? <div
              onClick={() => setOverlay(false)}
              style={{
                backgroundColor: '#000000d6', width: '100%',
                height: '100vh', position: 'absolute', zIndex: '3', left: 0
              }} /> : null}
            <div className="mt-4 Popular-restaurants">
              <h6 className="foundhead">
                {searchResult?.allRestaurant.objects.length} Restaurant
                {searchResult?.allRestaurant.objects.length > 1 ? "s" : ""}{" "}
                found
              </h6>
            </div>

            <section className="result_search">

              {!searchResult ? (
                <div style={{ alignItems: "center" }}>
                  <LoaderPage />
                </div>
              ) : (
                <div className="Feddresult">
                  <div >
                    {searchResult?.allRestaurant.objects.map((r, i) => {
                      return (
                        <ItemBoxResturant
                          searchParams={bookData}
                          dataRestaurant={r}
                          id={r?.id}
                          key={i}
                          img={r?.image}
                          title={r?.name}
                          city={r?.city.name}
                          description={r?.description}
                          category={r?.category.name}
                          status={r?.status}
                          averagePrice={r?.averagePrice}
                          showButtonLocation={r?.location}
                          selectedRestaurant={selectedRestaurant}
                          rating={r?.rating}
                          onMouseEnter={() => setHoveredRestaurant(r)}
                          onMouseLeave={() => setHoveredRestaurant(null)}
                        // onClick={() => onPositionHandler(r)}
                        />
                      );
                    })}
                  </div>
                  <Stack spacing={2} style={{ alignItems: "center" }}>
                    <Pagination
                      count={searchResult?.allRestaurant?.pages}
                      page={searchResult?.allRestaurant?.page}
                      onChange={handlePageChange}
                      shape="rounded"
                      color="success"
                    />
                  </Stack>
                </div>
              )}
              <div
                className="cartMap"

              >
                <Map
                  restaurants={searchResult?.allRestaurant.objects}
                  hoveredRestaurant={hoveredRestaurant}
                  searchParams={bookData}
                  // {...position}
                  latitude={position.latitude}
                  onSelectMapRestaurant={onPositionHandler}
                  longitude={position.longitude}
                  restaurant={selectedRestaurant}
                />
              </div>
            </section>
          </div>
        </>
      )}
      {!done && data == null && <LoaderPage />}
      {data != null && (
        <>
          <div
            className="flex-container flex-row mt-4 p-4"
            style={{ alignItems: "initial" }}
          >

            <div style={{ backgroundColor: 'black', width: '100%', height: '100%' }}>
              s
            </div>
            <div className="FullSearch">
              <div className="list-search">
                <div className="simple-text" style={{ textAlign: "left" }}>
                  {searchResult?.allRestaurant.objects.length} Restaurant
                  {searchResult?.allRestaurant.objects.length > 0 ? "s" : ""}{" "}
                  found
                </div>
                {data?.map((e) => {
                  return (
                    <SearchElement
                      key={e?.id}
                      img={e?.img}
                      title={e?.title}
                      capacity={e?.capacity}
                      address={e?.address}
                      desc={e?.desc}
                      onClick={() => {
                        navigate("/reservation/" + e?.id);
                      }}
                    />
                  );
                })}
              </div>
              <div className="Map">
                <MapContainer
                  center={[51.505, -0.09]}
                  zoom={13}
                  scrollWheelZoom={false}
                >
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  <Marker position={[51.505, -0.09]}>
                    <Popup>
                      A pretty CSS3 popup. <br /> Easily customizable.
                    </Popup>
                  </Marker>
                </MapContainer>
              </div>
            </div>
          </div>
        </>
      )}
    </React.Fragment>
  );
}
