import React from "react";
import "../../styles/client/Client.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import Card from "../card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function CarouselCards(props)
{
    const ArrowLeft=(props)=>{
        return(
            <>
                <div onClick={props?.action} className="flex-container control-arrow black">
                    <FontAwesomeIcon icon="fa-solid fa-arrow-left" />
                </div>
            </>
        );
    }

    const ArrowRight=(props)=>{
        return(
            <>
                <div onClick={props?.action} className="flex-container control-arrow right-0 black">
                    <FontAwesomeIcon icon="fa-solid fa-arrow-right" />
                </div>
            </>
        );
    }
    return (
        <Carousel autoPlay={true} infiniteLoop={true}
            renderArrowPrev={(onClickHandler, hasPrev, label)=><ArrowLeft condition={hasPrev} action={onClickHandler}/>}
            renderArrowNext={(onClickHandler, hasNext, label)=><ArrowRight condition={hasNext} action={onClickHandler}/>}>
            {props?.data?.map(e=>{
                    return (
                        <Card
                            id={e?.id}
                            img={e?.image}
                            title={e?.title}
                            isLiked={e?.isLiked}
                            desc={e?.desc}
                            review={e?.review}
                            category={e?.category}
                            status={e?.status}/>
                    );
                })}
        </Carousel>);
}