import React, { useEffect, useState } from "react";
import Card from "../card";
import { useQuery } from "@apollo/client";
import { GET_ALL_RESTURANT } from "./../../services/Queries/ResturantsQuery";

function Feed({ restaurants }) {
  const { loading, error, data } = useQuery(GET_ALL_RESTURANT);
  if (loading) return <p>loading</p>;

  return (
    <>
      {restaurants?.map((e) => {
        return (
          <>
            {loading ? (
              <Card
                id="none"
                img="none"
                title="none"
                palceholderimage="loading"
                palceholdertitle="loading"
                placeholderdes="loading"
                placeholderfav="loading"
                palceholdercat="loading"
                desc="none"
                review="none"
                category="none"
                status="none"
              />
            ) : (
              <Card
                id={e?.id}
                img={e?.image}
                title={e?.name}
                isLiked={e?.isLiked}
                desc={e?.description}
                review={e?.rating}
                category={e?.category.name}
                status={e?.status}
              />
            )}
          </>
        );
      })}
    </>
  );
}
export default Feed;
