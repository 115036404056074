import CircularProgress from "@mui/material/CircularProgress";

const LoaderPage = () => {
  return (
    <div
      style={{
        // do your styles depending on your needs.
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "250px",
      }}
    >
      <CircularProgress style={{ width: "unset", height: "unset" }} />
    </div>
  );
};
export default LoaderPage;
