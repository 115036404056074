import { useState, useEffect, useRef } from "react";
import LoaderPage from "../../../components/Shared/LoaderPage";
import { getSubscribtions,sendSubscribtions} from "../../../services/restaurant-admin/admin-dashboard";
import  Toast from "../../../services/Toast";
import { Button, TextField, Checkbox, } from "@mui/material";
import { BsChevronRight } from "react-icons/bs";
import LoadingButton from "@mui/lab/LoadingButton";

const Subscribtions =()=>{

    const [data,setData] = useState(false);
    const [selectedItem,setselectedItem] = useState(-1);
    const [DatatoSend,setDatatoSend] = useState({Subject:"",Mail:""});
    const [disable,setdisable] = useState(false);
    const detailsref = useRef(null)
    const [emails,setEmails]=useState([]);
    const [send,setsend]=useState(false);

    const initData = ()=>{
        setData(-1);
        getSubscribtions(setData);
    }
    const setSelectedData =(e)=>{
        let x = emails;
        if(x.includes(e)){
            x = x.filter(i=>i!=e);
        }
        else x.push(e);
        setEmails([...x]);
        console.log(x);
    }
    const onChangeValue=(k,v)=>{
        let j = DatatoSend;
        j[k]=v;
        setDatatoSend({...j});
    }
    const onSendMessage = ()=>{
        setdisable(true);
        sendSubscribtions({...DatatoSend,emails:emails},(resp)=>{
            CancelSubscribtions();
            Toast.success('Message sended successfully');
        });
    }
    const CancelSubscribtions = ()=>{
        setsend(false);
        setDatatoSend({Subject:"",Mail:""});
       setdisable(false);
    }
    useEffect(()=>{
        if(data===false)
            initData();
    });

    if(data === false || data === -1)
        return( <LoaderPage/>)
    
        
    return(
        <div className="d-flex flex-grow-1 container_details">
           {data.length>0? 
           <div className="flex-grow-1 element-1-cont" >
                <div className="d-flex justify-content-between align-items-center">
                    <h3 className="card-title" style={{ padding: 0 }}>Subscribtions</h3>
                    <Checkbox style={{ marginRight:"17px" }} checked={data.length==emails.length} onClick={()=>{if(data.length==emails.length)setEmails([]);else setEmails([...data]);}} />
                </div>
                <br/>
                <div className="d-flex " style={{ flexDirection:"column" }}>
                   {data.map((e,i)=><SubscribtionsElement emails={emails.includes(e)} onClick={()=>{setSelectedData(e);}} data={e}  item={i} />)}
                </div>
                <br/>
                <div className="d-flex w-100 justify-content-center " style={{ height: "45px" }}>
                <LoadingButton variant="contained" style={{ margin:"auto",color:"#fff",flex:1,margin:"2px",borderRadius:"10px"}}  className="class_button" onClick={()=>{setsend(true)}}>Send</LoadingButton>
                </div>
            </div>
            :
            <div  className="d-flex justify-content-center align-items-center card flex-grow-1" style={{ borderRadius: "10px",    minHeight: "50vh" }}>
                There is no data !
                <br/>
            </div>
            }
            {
                send?
                <div ref={detailsref}  style={{flex: 1,width: "100%", minWidth: "300px", marginLeft: "10px"}}>
                    <h3 className="card-title" style={{ padding: 0 }}>{'Email'}</h3>
                    <div>
                        <SubscribtionsDetails  cancelButtonClick={CancelSubscribtions} onChange={(key,v)=>{onChangeValue(key,v)}}
                        doneButtonClick={onSendMessage} disableandLoad={disable}
                        />
                    </div>
                    <br />
                </div>
            :""
            }
            
        </div>
    )
}

 
const SubscribtionsElement = ({data,onClick,item,emails})=>{
    const n = (item<9)?('0'+(item+1)):(item+1);
    return(
    <div className="d-flex col-12 card order_container_element el-flex-row"  style={{ cursor:"pointer",flexDirection:"row" }} onClick={()=>{onClick()}}>
        <div className="d-flex flex-grow-1 " >
            <div className="avatar_img title_img_eleme">{(n)}</div>
            <div className="d-flex  align-items-center" >
                <div className="d-flex  align-items-center"  style={{ flexDirection:"column",marginTop:"5px" }}>
                    <p className="text-muted"><h3 className="card-title" style={{ padding: 0,margin:0 }}>{data}</h3></p>
                </div>
            </div>
        </div>
        {emails}
        <div className="d-flex align-items-center" >
            <Checkbox checked={emails}  />
        </div>
    </div>
    );
}

const SubscribtionsDetails = (props)=>{
    const AddorEdit = ()=>{
        props.doneButtonClick();
    }
        return(
            <div className="d-flex" style={{ flexDirection:"column" }} >
                    <div className="d-flex align-items-start" style={{  margin:"25px 5px",width:"100%" , flexDirection:"column"}}>
                        <div className="d-flex" style={{width:"100%" }}>
                            <TextField className="flex-grow-1" value={props.data?.title}  onChange={(e)=>{props.onChange("Subject",e.target.value)}} label="Subject" />
                        </div>
                        <br/>
                        <TextField style={{ width:"100%"}} value={props.data?.description} onChange={(e)=>{props.onChange("Mail",e.target.value)}}  label="Mail" multiline rows={4} />
                    </div>
                <div>
                <br/>
            </div>
            <div className="d-flex w-100 justify-content-center " style={{ height: "45px" }}>
                    <LoadingButton variant="contained" style={{flex:1,margin:"2px",borderRadius:"10px"}}  color="secondary"  className="class_button"   
                     disabled={props.disableandLoad} loading={props.disableandLoad==2}onClick={()=>{props.cancelButtonClick()}} >Cancel</LoadingButton>
                    <LoadingButton variant="contained" style={{ color:"#fff",flex:1,margin:"2px",borderRadius:"10px"}}   className="class_button" 
                    disabled={props.disableandLoad} loading={props.disableandLoad==1} onClick={()=>{AddorEdit()}}>{props.disableandLoad==1?"":"Send"}</LoadingButton>
            </div>
        </div>
        );
}

export default Subscribtions;

