import { useState, useEffect, useRef, useContext } from "react";
import LoaderPage from "../../../../components/Shared/LoaderPage";

import Toast from "../../../../services/Toast";
import { Button } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { useQuery, useMutation } from "@apollo/client";
import {
  RESTAURANT_EXTRA_SERVICES_QUERY,
  MY_RESTAURANT_ID,
} from "../../../../services/Queries/RestaurantQuery";
import { NUMBER_VALIDATION } from "../../../../validation/regexr";
import {
  EDIT_EXTRA_SERVICE_ITEM,
  DElELT_EXTRA_SERVICE_ITEM,
  CREATE_EXTRA_SERVICE_ITEM,
} from "../../../../services/mutations/restaurantMutaions";
import { RESERVATION_KEYS } from "../../../../constants/keys";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import useFormWithServerErrors from "../../../../hooks/useFormWithServerErrors";
import { EXTRASERVICES_KEYS } from "../../../../constants/keys";
import { RestaurantManagerContext } from "../..";
import useDeleteDialog from "../../DeleteDialog";
const Extraservices = () => {
  const myRestaurant = useContext(RestaurantManagerContext);
  const [page, setPage] = useState(null);
  const [data, setData] = useState(false);
  const [selectedItem, setSelectedItem] = useState(-1);
  const [DatatoShow, setDatatoShow] = useState([]);
  const [disable, setDisable] = useState(false);
  const detailsref = useRef(null);
  const [edit, setEdit] = useState(false);
  const [selectedExtraService, setSelectedExtraService] = useState(null);

  const {
    loading,
    error,
    data: extraServices,
    refetch,
  } = useQuery(RESTAURANT_EXTRA_SERVICES_QUERY, {
    variables: {
      // filter: { type: off },
      restaurantExtraServicesId: myRestaurant.id,
      page: page,
      pageSize: EXTRASERVICES_KEYS.USER_PAGE_SIZE,
    },
  });

  const setSelectedData = (i, extraService) => {
    setEdit(false);
    setSelectedItem(i);
    setSelectedExtraService(extraService);
    setDatatoShow(data[i]);
    if (selectedItem != -1) detailsref.current.scrollIntoView(true);
    else
      setTimeout(() => {
        detailsref.current.scrollIntoView(true);
      }, 200);
  };
  const setAdd = () => {
    setEdit(true);
    setSelectedItem(-1);
    setSelectedExtraService(null);
    setSelectedItem(-2);

    if (selectedItem != -1) detailsref.current.scrollIntoView(true);
    else
      setTimeout(() => {
        detailsref.current.scrollIntoView(true);
      }, 200);
  };

  const onAddExtraservices = () => {
    setDisable(true);
  };
  const onEditExtraservices = () => {};
  const onDeleteExtraservices = (i) => {
    setDisable(true);
  };
  const CancelExtraservices = () => {
    setSelectedItem(-1);
    setEdit(false);
    setDisable(false);
  };
  const handlePageChange = (e, val) => {
    setPage(val);
  };
  if (loading) return <LoaderPage />;
  return (
    <div className="d-flex flex-grow-1 container_details MN">
      {extraServices?.restaurantExtraServices.objects.length > 0 ? (
        <div className="flex-grow-1 element-1-cont">
          <div className="d-flex justify-content-between align-items-center">
            <h3 className="card-title" style={{ padding: 0 }}>
              Extraservices
            </h3>
            <Button
              variant="contained"
              color="primary"
              id="addbutton"
              onClick={() => {
                setAdd();
              }}
            >
              Add new
            </Button>
          </div>
          <br />
          <div className="d-flex " style={{ flexDirection: "column" }}>
            {extraServices?.restaurantExtraServices.objects.map((e, i) => (
              <ExtraservicesElement
                index={i}
                onEdit={() => {
                  setSelectedData(i, e);
                }}
                data={e}
                item={selectedItem != i}
                setDisable={setDisable}
                refetch={refetch}
                cancelButtonClick={CancelExtraservices}
                key={i}
              />
            ))}
            <Stack spacing={2} style={{ alignItems: "center" }}>
              <Pagination
                count={extraServices?.restaurantExtraServices?.pages}
                page={extraServices?.restaurantExtraServices?.page}
                onChange={handlePageChange}
                shape="rounded"
                color="success"
              />
            </Stack>
          </div>
        </div>
      ) : (
        <div
          className="d-flex justify-content-center align-items-center card flex-grow-1"
          style={{ borderRadius: "10px", minHeight: "50vh" }}
        >
          <h3>There is no data </h3>
          <br />
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setAdd();
            }}
            style={{
              color: "#fff",
              height: "40px",
              width: "130px",
              margin: "20px 2px",
              borderRadius: "10px",
            }}
          >
            Add new
          </Button>
        </div>
      )}
      <div
        ref={detailsref}
        style={{
          display: selectedItem == -1 && "none",
          flex: 1,
          width: "100%",
          minWidth: "300px",
          marginLeft: "10px",
        }}
      >
        <h3 className="card-title" style={{ padding: 0 }}>
          {selectedItem == -2 ? "Add new Item" : "Edit"}
        </h3>
        <br />
        {selectedItem != -1 ? (
          <ExtraservicesDetails
            data={selectedExtraService}
            disableandLoad={disable}
            edit={edit}
            refetch={refetch}
            setEdit={setEdit}
            setdata={setDatatoShow}
            doneButtonLabel={"Save"}
            cancelButtonLaber={"Cancel"}
            editButtonLabel={"Edit"}
            deleteButtonLaber={"Delete"}
            doneButtonClick={onAddExtraservices}
            cancelButtonClick={CancelExtraservices}
            editButtonClick={onEditExtraservices}
            deleteButtonClick={onDeleteExtraservices}
            setDisable={setDisable}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
const getNumber = (i) => {
  if (i < 9) return "0" + (i + 1);
  return i + 1 + "";
};

const ExtraservicesElement = ({
  data,
  onEdit,
  index,
  cancelButtonClick,
  refetch,
  setDisable,
  deleteButtonClick,
}) => {
  const myRestaurant = useContext(RestaurantManagerContext);

  const onCompleteDeleteHandler = ({ deleteExtraServiceItem }) => {
    const { errors, success } = deleteExtraServiceItem;
    setOpen(false)
    if (setDisable) {
      setDisable(false);
    }

    if (success) {
      if (deleteButtonClick) {
        deleteButtonClick();
      }
      if (refetch) {
        refetch();
      }
      Toast.success("The service Item has been delete");
      if (cancelButtonClick) cancelButtonClick();
    } else {
      setErrors(JSON.parse(errors));
    }
  };
  const onErrorDeleteHandler = ({ deleteExtraServiceItem }) => {};

  const [
    deleteService,
    { loading: loadingDelete, error: errorDelete, data: dataDelete },
  ] = useMutation(DElELT_EXTRA_SERVICE_ITEM, {
    onCompleted: onCompleteDeleteHandler,
    onError: onErrorDeleteHandler,
  });
  const { Dialog, setOpen } = useDeleteDialog({
    text: " Are you sure you wanna delete this service",
    title: "Deletion",
    actionLabel: "Yes",
    onAction: () =>
      deleteService({
        variables: {
          deleteExtraServiceItemId: data?.id,
          restaurant: myRestaurant.id,
        },
      }),
  });
  const onDelete = () => {
    setOpen(true)
    setDisable(true);
  };
  return (
    <div>
      <Dialog />
      <div className="number_label">{getNumber(index)} </div>
      <div className="d-flex col-12   ">
        <div
          className="d-flex flex-column flex-grow-1  "
          style={{ marginRight: "10px" }}
        >
          <div className="d-flex flex-row flex-grow-1">
            <div className="input-group mb-3 d-flex">
              <input
                value={data.title}
                type="text"
                className="form-control"
                placeholder="Title"
              />
            </div>
            <div
              className=" d-flex justify-content-start align-items-start"
              style={{ position: "relative" }}
            >
              <input
                value={data.price}
                type="text"
                className="form-control"
                placeholder="Price"
                style={{ paddingRight: "40px" }}
              />
              <span className="DH_span">DH</span>
            </div>
          </div>
        </div>
        <div className="d-flex  align-items-start">
          <Button
            onClick={onDelete}
            className="add_edit_btn_icon"
            color="error"
            variant="contained"
            startIcon={
              <AiOutlineDelete
                style={{ margin: "auto" }}
                size="20"
                color="#fff"
              />
            }
          />
          <Button
            onClick={onEdit}
            className="add_edit_btn_icon"
            variant="contained"
            startIcon={
              <AiOutlineEdit
                style={{ margin: "auto" }}
                size="20"
                color="#fff"
              />
            }
          />
        </div>
      </div>
    </div>
  );
};

const ExtraservicesDetails = (props) => {
  const myRestaurant = useContext(RestaurantManagerContext);
  const { useFormMethods, ServerError, setErrors,ErrorMessage} = useFormWithServerErrors();

  const {
    register,
    unregister,
    reset,
    watch,
    control,
    handleSubmit,
    formState: { errors },
  } = useFormMethods;
  const onCompleteCreateHandler = ({ createExtraServiceItem }) => {
    const { errors, success, extraServiceItem } = createExtraServiceItem;
    if (props.setDisable) {
      props.setDisable(false);
    }
    if (success) {
      if (props.cancelButtonClick) props.cancelButtonClick();
      if (props.refetch) props.refetch();
      Toast.success("The services has been created");
    } else {
      setErrors(JSON.parse(errors));
    }
  };
  const onCompleteEditHandler = ({ editExtraServiceItem }) => {
    const { success, errors, offer } = editExtraServiceItem;
    if (props.setDisable) {
      props.setDisable(false);
    }
    if (success) {
      Toast.success("The service has been edit");
      if (props.editButtonClick) props.editButtonClick();
      if (props.cancelButtonClick) props.cancelButtonClick();
    } else {
      setErrors(JSON.parse(errors));
    }
  };
  const onErrorEditHandler = (data) => {};
  const onErrorCreateHandler = ({ createExtraServiceItem }) => {};
  const [
    createExtraService,
    { loading: loadingCreate, error: errorCreate, data: dataCreate },
  ] = useMutation(CREATE_EXTRA_SERVICE_ITEM, {
    onCompleted: onCompleteCreateHandler,
    onError: onErrorCreateHandler,
  });
  const [
    editExtraService,
    { loading: loadingEdit, error: errorEdit, data: dataEdit },
  ] = useMutation(EDIT_EXTRA_SERVICE_ITEM, {
    onCompleted: onCompleteEditHandler,
    onError: onErrorEditHandler,
  });
  const onSubmit = (values) => {
    console.log(values);

    if (props.setDisable) {
      props.setDisable(true);
    }
    if (props.data?.id) {
      editExtraService({
        variables: {
          ...values,
          editExtraServiceItemId: props?.data.id,
          restaurant: myRestaurant.id,
        },
      });
    } else {
      createExtraService({
        variables: {
          ...values,
          restaurant: myRestaurant.id,
        },
      });
    }
  };

  useEffect(() => {
    if (props?.data) {
      reset({
        ...props?.data,
      });
    } else {
      reset({
        title: "",
        price: "",
      });
    }

    return () => {
      unregister();
    };
  }, [props?.data?.id, props.edit]);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="number_label"></div>
      <div className="d-flex col-12 flex-column ">
        <ServerError />
        <ErrorMessage
          errors={errors}
          name="price"
          render={({ message }) => <p className="text-danger p-1">{message}</p>}
        />
        <ErrorMessage
          errors={errors}
          name="title"
          render={({ message }) => <p className="text-danger p-1">{message}</p>}
        />
        <div className="d-flex flex-grow-1 ">
          <div className="d-flex  flex-grow-1">
            <div className="input-group mb-3 d-flex">
              <input
                type="text"
                className="form-control"
                placeholder="Title"
                {...register("title", { value: props?.data?.title })}
              />
            </div>
            <div
              className=" d-flex justify-content-start align-items-start"
              style={{
                marginLeft: "10px",
                position: "relative",
                height: "fit-content",
              }}
            >
              <input
                type="text"
                className="form-control"
                placeholder="Price"
                {...register("price", {
                  value: props?.data?.price,
                  ...NUMBER_VALIDATION("price"),
                  required: "Price is required",
                })}
              />
              <span className="DH_span">DH</span>
            </div>
          </div>
        </div>
      </div>
      <div
        className="d-flex w-100 justify-content-center "
        style={{ height: "45px" }}
      >
        {props?.cancelButtonLaber && (
          <LoadingButton
            variant="contained"
            color="secondary"
            style={{
              color: "#fff",
              flex: 1,
              margin: "2px",
              borderRadius: "10px",
            }}
            className="class_button"
            disabled={props.disableandLoad}
            loading={props.disableandLoad == 2}
            onClick={() => {
              props.cancelButtonClick();
            }}
          >
            {props.disableandLoad == 2 ? "" : props.cancelButtonLaber}
          </LoadingButton>
        )}
        {props?.doneButtonLabel && (
          <LoadingButton
            variant="contained"
            style={{
              color: "#fff",
              flex: 1,
              margin: "2px",
              borderRadius: "10px",
            }}
            className="class_button"
            disabled={props.disableandLoad}
            loading={props.disableandLoad == 1}
            type="submit"
          >
            {props.disableandLoad == 1 ? "" : props.doneButtonLabel}{" "}
          </LoadingButton>
        )}
      </div>
    </form>
  );
};

export default Extraservices;
