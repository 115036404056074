import React, { useEffect, useState } from "react";
import Time from "../../components/Calendar/Time";
import 'antd/dist/antd.css';
import { AutoComplete, Select } from 'antd';
import { useGetCities, useGetCategories } from "../../../src/services/client/general";
import fontawesome from "@fortawesome/fontawesome";
import { faSearch, faCalendar, faBus } from "@fortawesome/fontawesome-free-solid";
import "../../styles/client/ClientHome.css";
import Calendar from "../Calendar/Calendar";
import { SEARCH_POSTS_PAGE } from "../settings/constant";
import { useNavigate, createSearchParams } from "react-router-dom";
import "react-google-flight-datepicker/dist/main.css";
import Gueste from './../Calendar/Gueste';
import { useMutation, useQuery } from "@apollo/client";
import { CITIES_QUERY } from "../../services/Queries/CitiesQuery";
import { RESTAURANTS_SEARCH_QUERY } from "../../services/Queries/RestaurantQuery";
import { CATEGORIES_QUERY } from "../../services/Queries/CategoriesQuery";
const useNavigateSearch = () => {
    const navigate = useNavigate();
    return (pathname, params) =>
        navigate(`${pathname}?${createSearchParams(params)}`);
};
const defaultDateValue = { year: 2019, month: 10, day: 5 };
fontawesome.library.add(faSearch, faCalendar, faBus);

function BoxSearch(props) {
    const onCompletedRestaurant = () => {

    }
    const [value, setValue] = useState('');
    const navigate = useNavigate();
    const navigateSearch = useNavigateSearch();
    const [nextOpen, setnextOpen] = useState(false);
    const [page, setPage] = useState(null);
    const [search, setSearch] = useState(null);
    const [city, setCity] = useState(null);
    const [category, setCategory] = useState(null);
    const [bookData, setbookData] = useState({
        guests: 1,
        date: null,
        time: null,
    });

    const [book, setBook] = useState({
        text: null,
        city: null,
        date: defaultDateValue,
        guests: 1,
        time: "08:00",
        category: null,
    });
    const {
        loading: loadingCat,
        error: errorCat,
        data: categoriesData,
        refetch: refetchCategories
    } = useQuery(CATEGORIES_QUERY, {
        variables: {
            page: page,
            pageSize: 4,
            search: search,
        },
        onCompleted: onCompletedRestaurant
    })
    const {
        loading,
        error,
        data: restaurantsData,
        refetch: refetchRestaurant
    } = useQuery(RESTAURANTS_SEARCH_QUERY, {
        variables: {
            page: page,
            pageSize: 4,
            search: search,
        },
        onCompleted: onCompletedRestaurant
    })

    const sendData = () => {

        // const query = {
        //   city: book,
        //    bookData
        // };
        // const search = setStateToUrl(query);
        // navigate({
        //   pathname: SEARCH_POSTS_PAGE,
        //   search: search,
        // });
        let date = "12/01/2022"
        const newParma = {};
        if (city) {
            newParma['city'] = city
        }
        if (search) {
            newParma['search'] = search

        }
        navigateSearch(SEARCH_POSTS_PAGE, newParma);
    };
    const options = [
        {
            value: 'All restaurants',
        },
        {
            value: 'Downing Street',
        },
        {
            value: 'Wall Street',
        },
    ];
    let restaurantsOptions = [];
    if (restaurantsData?.allRestaurant) {
        restaurantsOptions = restaurantsData?.allRestaurant?.objects?.map((res, i) => { return { value: res.name, type: "RESTURANT" } })


    }
    let categoriesOptions = [];
    if (categoriesData?.allCategories) {
        categoriesOptions = categoriesData?.allCategories?.objects?.map((res, i) => { return { value: res.name, label: res.name } })
    }

    // const searchOptions = [{ value: '', type: 'All',label:'All' }, ...categoriesOptions,];
    const cityOpetions = useGetCities()?.map((e) => {
        return (
            { value: e?.id, label: e?.name }
        );
    })

    const onSelectCityHandler = (val) => {
        setCity(val)



    }
    return (
        <>
            <div className="flex-container">
                <div className="OnlyBoxsearch">
                    {props.Search && (
                        <div className="col-sm my-auto search-grp">
                            <i className="icon-ic24-search gray"></i>
                            <AutoComplete
                                style={{
                                    width: 300,
                                }}
                                // options={searchOptions}
                                allowClear={true}
                                value={search}
                                onChange={(data) => {
                                    setSearch(data);
                                }}
                                defaultOpen={true}
                                // onSearch={(val) => {
                                //     refetchRestaurant({ variables: { search: val } });
                                // }}
                                placeholder="category, restaurant..."
                                filterOption={(inputValue, option) =>
                                    option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                }
                            />

                        </div>
                    )}
                    {props.category && (
                        <div className="col-sm my-auto search-grp">
                            <i className="icon-ic24-search"></i>
                            <select
                                className="custom-select city-select"
                                id="inputGroupSelect02"
                                onChange={(val) => {
                                    setBook({ ...book, category: val.target.value });
                                }}
                                defaultValue={book?.city}
                            >
                                <option selected={!book?.category} disabled>
                                    category
                                </option>
                                {useGetCategories()?.map((e) => {
                                    return (
                                        <option
                                            selected={book?.category}
                                            key={e?.value}
                                            value={e?.id}
                                        >
                                            {e?.name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    )}

                    {props.date && (
                        <div className="col-sm  my-auto date-grp">
                            <div className="date-time">
                                <Calendar
                                    value={book?.date}
                                    onChange={(val) => {
                                        setBook({ ...book, date: val });
                                    }}
                                //   Due to the issue this component raises an error Uncaught TypeError: T.current is null
                                />
                            </div>
                        </div>
                    )}
                    {props.time && (
                        <div className="col-sm  my-auto date-grp">
                            <div className="date-time">
                                <i className="icon-ic24-time"></i>
                                <Time
                                    value={book?.time}
                                    onChange={(val) => {
                                        setBook({ ...book, time: val });
                                    }}
                                />
                            </div>

                        </div>
                    )}        {props.guests && (
                        <div className="col-sm my-auto search-grp OnlyGuest">
                            <i className="far fa-user"></i>
                            <Gueste
                                onChange={(e) => setBook({ ...book, guests: e })}
                            />
                        </div>
                    )}
                    {props.city && (
                        <div className="col-sm my-auto city-grp">
                            <div className="city-grp-wrapper">
                                <i className="fal fa-building gray"></i>
                                <Select
                                    style={{
                                        width: 120,

                                    }}
                                    // value={city}
                                    showSearch
                                    allowClear={true}
                                    placeholder="City"
                                    optionFilterProp="children"
                                    onSelect={onSelectCityHandler}
                                    options={cityOpetions
                                    }
                                // onSearch={onSearch}
                                // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                >

                                </Select>
                            </div>
                        </div>
                    )}
                    <div className="col-sm my-auto book-grp">
                        <button
                            type="submit"
                            className="btn btn-success btn-book mx-auto mb-2"
                            onClick={sendData}
                        >
                            Book
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}
export default BoxSearch;
