import React, { useState } from "react";
import "../../../styles/client/Client.css";
import "../../../styles/client/ClientContact.css";


export default function AboutHeader()
{
    return (
        <React.Fragment>
            <div className="w-75 centerText mx-auto">
                <div style={{fontSize:"15px",textTransform:"uppercase",height:"22px"}} className="w-100 title-1 green">About us</div>
                <div style={{fontSize:"32px"}} className="w-100 title-1">Who are we?</div>
                <div className="w-100 simple-text">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.  
                </div>
            </div>
        </React.Fragment>
    )
}