import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../../styles/client/Client.css";

export default function IconLabeled(props)
{
    const [isHere,setHere]=useState(false);
    let location = useLocation();
    
    useEffect(()=>{
        setHere(location.pathname==props?.url);
    },[location]);
    const navigate=useNavigate();
    return (
        <div onClick={()=>navigate(props?.url)} style={{justifyContent: "center",flexFlow:"wrap",cursor:"pointer"}} className={`flex-container ${isHere?"green":"grey"}`}>
            <div style={{flex:"100%",fontSize:"26px"}}>
                {props.icon}
            </div>
            <div style={{flex:"100%",fontSize:"12px"}}>
                {props.label}
            </div>
        </div>
    );
}