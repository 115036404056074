import React, { useEffect, useState } from "react";
import "../../../styles/client/Client.css";
import "../../../styles/client/ClientContact.css";


export default function AboutFooterButtom()
{
    useEffect(()=>{
        var right=document.getElementById('rightdiv').style.height;
        var left=document.getElementById('leftdiv').style.height;
        if(left>right)
        {
            document.getElementById('rightdiv').style.height=left;
        }
        else
        {
            document.getElementById('leftdiv').style.height=right;
        }
    },[]);
    return (
        <React.Fragment>
            <div className="row ph-2">
                <div id="rightdiv" className="col-sm-6 col-sm-6 flex-container flex-center">
                    <img className="svgSize " src="./svg/About2.svg" alt="SVG" />
                </div>
                <div id="leftdiv" className="col-sm-6">
                    <div className="w-100">
                        <div style={{fontSize:"32px"}} className="w-100 title-1">About <span className="green">Tabla.ma</span></div>
                    </div>
                    <div className="w-100">
                        <p className="simple-text justify-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                        <p className="simple-text justify-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                        <p className="simple-text justify-text">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing.</p>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}