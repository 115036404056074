import React, { useEffect, useState } from "react";
import { HeroSVG } from "./hero-elements";
import icon from "./Icon.png";
import Time from "../../components/Calendar/Time";
import BoxSearch from "../BoxSearch"
import {
  useGetCities,
  useGetCategories,
} from "../../../src/services/client/general";
import svgHero from "./HeroVector.svg";
import fontawesome from "@fortawesome/fontawesome";
import {
  faSearch,
  faCalendar,
  faBus,
} from "@fortawesome/fontawesome-free-solid";
import "../../styles/client/ClientHome.css";
import Calendar from "../Calendar/Calendar";
import { SEARCH_POSTS_PAGE } from "../settings/constant";
import { useNavigate, createSearchParams } from "react-router-dom";

import {
  RangeDatePicker,
  SingleDatePicker,
} from "react-google-flight-datepicker";
import "react-google-flight-datepicker/dist/main.css";
const useNavigateSearch = () => {
  const navigate = useNavigate();
  return (pathname, params) =>
    navigate(`${pathname}?${createSearchParams(params)}`);
};
const defaultDateValue = {
  year: 2019,
  month: 10,
  day: 5,
};

fontawesome.library.add(faSearch, faCalendar, faBus);

function Hero({ history }) {
  const navigate = useNavigate();
  const navigateSearch = useNavigateSearch();
  const [bookData, setbookData] = useState({
    guests: 1,
    date: null,
    time: null,
  });

  const [book, setBook] = useState({
    text: null,
    city: null,
    date: defaultDateValue,
    guests: 1,
    time: "08:05",
    category: null,
  });

  const sendData = () => {
    // const query = {
    //   city: book,
    //    bookData
    // };
    // const search = setStateToUrl(query);
    // navigate({
    //   pathname: SEARCH_POSTS_PAGE,
    //   search: search,
    // });

    let newParma = { ...book };
    Object.keys(newParma).forEach((key) => {
      if (newParma[key] === null || newParma[key] === "null") {
        delete newParma[key];
      }
    });
    // if (newParma.time) {
    //   console.log(" Time");
    //   const newTime = new Date(Date.parse(newParma.time));
    //   newParma = {
    //     ...newParma,
    //     time:newTime.toLocaleTimeString(),
    //   };
    // }
    // if (newParma.date) {
    //   console.log(" Time");
    //   const newDate = new Date(Date.parse(newParma.date));
    //   newParma = {
    //     ...newParma,
    //     date: newDate.toLocaleDateString(),
    //   };
    // }

    navigateSearch(SEARCH_POSTS_PAGE, newParma);
  };

  return (
    <>
      <div className="flex-container flex-row mt-4 hero-bottom">
        <div className="left_side">
          <div className="flex-6">
            <div className="hero-txt">
              <div className="hero-tabla">
                <img width="15px" src={icon} alt="icon" />
                <a> at tabla.ma </a>
              </div>
              <div className="hero-h">
                <h2>Discover Your Next Table</h2>
              </div>
              <div className="hero-p">
                <h4>
                  it is a long established fact that a reader will be distracted
                  by the readable content of a page when looking at its layout.
                  The point of using lorem ipsum is that it.
                </h4>
                <BoxSearch
                  //enable needed boxs 
                  Search={true}
                  // options={options}
                  category={false}
                  date={false}
                  guests={false}
                  time={false}
                  city={true}

                  //Onchange
                  onChangeSearch={(val) => {
                    setBook({ ...book, text: val.target.value });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex-6 right-side">
          <img className="svgHero displayBlock" src={svgHero} alt="heroVSG" />
        </div>

      </div>

    </>
  );
}
const GeustElement = ({ data, onChange }) => {
  return (
    <div className="guest">
      <i className="far fa-user"></i>
      <span className="a1">Guests</span>
      <div className="contour">
        <i
          className="fal fa-minus"
          onClick={() => {
            onChange(data == 0 ? 0 : data - 1);
          }}
        ></i>
        <a>{data}</a>
        <i
          className="fal fa-plus"
          onClick={() => {
            onChange(data + 1);
          }}
        ></i>
      </div>
    </div>
  );
};
export default Hero;
