import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import Avatar from "@mui/material/Avatar";

import { useForm } from "react-hook-form";
import { getUserData } from "../../services/client/auth";
import { UPLOAD_IMAGE_MUTATION } from "../../services/mutations/userMustation";
import { AiOutlineDelete, AiOutlinePlus } from "react-icons/ai";
import LoaderPage from "./LoaderPage";
import { MY_PROFILE_IMAGE_QUERY } from "../../services/Queries/UserQuery";

// import { API_SERVER } from "../config/constant";

const UploadTheMainImage = ({
  defaultImage,
  onCompletedHandlerMutation,
  onErrorHandlerMutation,
}) => {
  const {
    register,
    watch,
    setValue,
    control,
    formState: { errors, isValid },
  } = useForm();
  const watchImage = watch("image", null);
  const isThereAFile = watchImage ? watchImage.length > 0 : false;
  const deleteImage = (e) => {
    setValue("image", "");
  };
  const url = isThereAFile ? URL.createObjectURL(watchImage[0]) : null;
  const [uploadImage, { loading, error, data }] = useMutation(
    UPLOAD_IMAGE_MUTATION,
    {
      onCompleted: onCompletedHandlerMutation,
      onError: onErrorHandlerMutation,
    }
  );
  const uploadImageProfile = (e) => {
    uploadImage({ variables: { image: e.target.files[0] } });
  };

  const { data: imageData } = useQuery(MY_PROFILE_IMAGE_QUERY);
  if (loading)
    return (
      <div style={{ width: "fit-content", margin: "auto" }}>
        <div
          className="element_image"
          style={{ margin: "10px", position: "relative" }}
        >
          <LoaderPage />
        </div>
      </div>
    );

  return (
    <div style={{ width: "fit-content", margin: "auto" }}>
      <div
        className="element_image"
        style={{ margin: "10px", position: "relative" }}
      >
        {isThereAFile || defaultImage ? (
          <>
            <img
              src={url || defaultImage}
              style={{ height: "100%", width: "100%", position: "absolute" }}
            />
            <span
              onClick={deleteImage}
              style={{
                height: "32%",
                width: "32%",
                position: "absolute",
                top: 1,
                right: 1,
                backgroundColor: "#000",
                opacity: "0.7",
              }}
            >
              <AiOutlineDelete
                style={{ margin: "auto" }}
                size="20"
                color="#fff"
              />
            </span>
            <input
              type="file"
              accept="image/*"
              {...register("image", {
                required: true,
                onChange: (e) => uploadImageProfile(e),
              })}
              style={{
                opacity: 0,
                height: "100%",
                width: "100%",
                position: "absolute",
              }}
              placeholder="Select an element"
            />
          </>
        ) : (
          <>
            <span
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                height: "100%",
                width: "100%",
              }}
            >
              <AiOutlinePlus
                style={{ margin: "auto", opacity: "0.5" }}
                size="100%"
                color="#000"
              />
            </span>
            <input
              type="file"
              accept="image/*"
              {...register("image", {
                required: true,
                onChange: (e) => uploadImageProfile(e),
              })}
              style={{
                opacity: 0,
                height: "100%",
                width: "100%",
                position: "absolute",
              }}
              placeholder="Select an element"
            />
          </>
        )}
      </div>
    </div>
  );
};
const Profile = ({ firstName, lastName, imageUrl }) => {
  const onErrorHandlerMutation = (data) => {};
  const onCompletedHandlerMutation = ({ image }) => {
    const values = getValues();

    if (me) {
      for (const key of Object.keys(values)) {
        setValue(key, me[key]);
      }
      refetchUser();
    }
  };

  return (
    <div className="myvectore">
      <UploadTheMainImage
        defaultImage={imageUrl}
        // uploadImage={uploadImage}
        // loading={loading}
        // error={error}
        onCompletedHandlerMutation
        onErrorHandlerMutation={onErrorHandlerMutation}
      />
      {firstName && lastName && (
        <div className="name_pf">
          <span>{firstName}</span> <span>{lastName}</span>{" "}
        </div>
      )}
    </div>
  );
};

export default Profile;
