import styled from "styled-components";

export const Container2 = styled.div `
  width: 100%;
`;

export const TitleOfBi = styled.h1 `
color: #44bba4;
    text-align: center;
    font-size: 35px;
    margin-top: 50px;
    margin-bottom: 40px;
`;

export const MiniContainer2 = styled.div `
padding: 0px 70px !important;
box-shadow: 0px 5px 50px rgb(0 67 101 / 8%);
margin: 0px 80px;
padding-bottom: 40px !important;
margin-bottom: 80px !important;
border-radius: 20px;
`;

export const ContainerLeft = styled.div `

`;

export const ContainerRight = styled.div `
`;

export const Headinng1 = styled.h1 `
  font-size: 24px;
  font-weight: 600;
  width: 100%;
  margin: 0;
  margin-top: 80px;
`;

export const Paragr = styled.p `
font-size: 15px;
font-weight: 500;
margin: 20px 0px;
color: rgba(10, 9, 8, 0.2);
`;

export const ButtonRegLog = styled.button `
  width: 145px;
  height: 45px;
  border: 3px solid #57b894;
  box-sizing: border-box;
  color: #57b894;
  background-color: white;
`;


export const GreenSpot = styled.div `
  width: 100%;
  height: 180px;
  background-color: #57b894;
  display: flex;
  justify-content: space-between;
  margin-top: 5%;
`;

export const MiniContainer1 = styled.div `
  width: 80%;
  height: 180px;
  background-color: #57b894;
  justify-content: space-between;
  display: flex;
  margin: auto;
`;

export const ForCompon = styled.div `
  width: 25%;
  height: 100%;
  color: white;
  display: flex;
`;

export const NumbersOf = styled.h1 `
  color: white;
  font-weight: 500;
  display: block;
  margin: 0px;
  margin-left: 10px;
`;

export const TitleOf = styled.h1 `
color: white;
font-weight: 300;
font-size: 15px;
margin: 0px;
margin-left: 10px;
`;

export const Foooter = styled.div `
  width: 80%;
  height: 400px;
  justify-content: space-between;
  display: grid;
  grid-template-columns: 20% 20% 20% 20%;
  margin: auto;
`;

export const FourCompon = styled.div `
  height: 360px;
  margin-top: 70px;
`;

export const CopyRight = styled.div `
  width: 100%;
  height: 60px;
  margin: auto;
  text-align: center;
`;