import React from "react";
import "../../../styles/client/Client.css";
import { ButtonPrimary } from "../button";
import { useNavigate } from "react-router-dom";

export default function ButtonAuthMenuItem(props)
{
    const navigate = useNavigate();
    return (
        <div className="w-100">
            <ButtonPrimary style={{justifyContent:"center"}}
            className="mx-auto marginMenu w-75 centerText secondaryButtonStyle" text="Login" 
                click={(event)=>{navigate("/login");props.done();}}/>
            <ButtonPrimary  style={{justifyContent:"center"}}
            className="mx-auto marginMenu w-75 centerText " text="Sign up" 
                click={(event)=>{navigate("/register");props.done();}}/>
        </div>
    );
}