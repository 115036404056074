import { gql } from "@apollo/client";

export const CREATE_RESTAURANT = gql`
  mutation CreateRestaurant(
    $name: String!
    $address: String
    $workHours: WorkHoursInput
    $numberOfGuests: Int
    $city: ID
    $category: ID
  ) {
    createRestaurant(
      name: $name
      address: $address
      workHoursInput: $workHours
      numberOfGuests: $numberOfGuests
      city: $city
      category: $category
    ) {
      errors
      success
      restaurant {
        name
        id
        manager {
          email
        }
        workHours {
          mondayTo
          mondayIsOpen
          mondayFrom
        }
      }
    }
  }
`;

export const REGISTER_RESTAURANT = gql`
  mutation RegisterRestaurant(
    $firstName: String!
    $email: String!
    $password1: String!
    $password2: String!
    $phone: String
    $lastName: String!
    $name: String!
    $address: String
    $allowReservation: Boolean
  ) {
    createRestaurant(
      name: $name
      address: $address
      allowReservation: $allowReservation
    ) {
      errors
      success
      restaurant {
        name
        id
      }
    }
  }
`;

export const EDIT_RESTAURANT = gql`
  mutation EditRestaurant(
    $editRestaurantId: ID!
    $email:String
    $workHours: WorkHoursInput
    $webSite: String
    $description: String
    $address: String
    $allowReservation: Boolean
    $categories: [ID]
    $category: ID
    $city: ID
    $image: Upload
    $lat: Float
    $long: Float
    $averagePrice: Decimal
    $phone: String
    $name: String
    $numberOfGuests: Int
    $status: String
  ) {
    editRestaurant(
      id: $editRestaurantId
      email:$email
      workHoursInput: $workHours
      webSite: $webSite
      phone: $phone
      description: $description
      address: $address
      allowReservation: $allowReservation
      categories: $categories
      category: $category
      city: $city
      image: $image
      lat: $lat
      averagePrice: $averagePrice
      long: $long
      name: $name
      numberOfGuests: $numberOfGuests
      status: $status
    ) {
      errors
      success
      restaurant {
        id
        name
        status
        image
      }
    }
  }
`;

export const UPLOAD_RESTAURANT_IMAGE_MUTATION = gql`
  mutation CreateImage($file: Upload!, $restaurant: ID!) {
    createImage(file: $file, restaurant: $restaurant) {
      image {
        id
        file
      }
      success
      errors
    }
  }
`;
export const DELETE_RESTAURANT_IMAGE_MUTATION = gql`
  mutation DeleteImage($id: ID!) {
    deleteImage(id: $id) {
      
      success
      errors
    }
  }
`;
export const CREATE_RESERVATION = gql`
  mutation CreateReservation(
    $datetime: DateTime!
    $restaurant: ID!
    $commenter: String
    $numberOfGuests: Int
  ) {
    createReservation(
      datetime: $datetime
      restaurant: $restaurant
      commenter: $commenter
      numberOfGuests: $numberOfGuests
    ) {
      reservation {
        restaurant {
          name
        }
        status
        commenter
        datetime
        numberOfGuests
      }
      errors
      success
    }
  }
`;
export const CREATE_RESTAURANT_MENU_ITEM = gql`
  mutation CreateRestaurantMenuItem(
    $price: Float!
    $restaurant: ID!
    $title: String!
  ) {
    createRestaurantMenuItem(
      price: $price
      restaurant: $restaurant
      title: $title
    ) {
      menuItem {
        title
        price
        id
        description
      }
      success
      errors
    }
  }
`;
export const EDIT_RESTAURANT_MENU_ITEM = gql`
  mutation EditRestaurantMenuItem(
    $restaurant: ID!
    $description: String
    $editRestaurantMenuItemId: ID!
    $price: Float
    $title: String
  ) {
    editRestaurantMenuItem(
      restaurant: $restaurant
      description: $description
      id: $editRestaurantMenuItemId
      price: $price
      title: $title
    ) {
      errors
      success
      menuItem {
        id
        title
        price
        description
      }
    }
  }
`;
export const DElELT_RESTAURANT_MENU_ITEM = gql`
  mutation EditRestaurantMenuItem($id: ID!, $restaurant: ID!) {
    deleteRestaurantMenuItem(id: $id, restaurant: $restaurant) {
      errors
      success
    }
  }
`;

export const CREATE_RESTAURANT_OFFER_ITEM = gql`
  mutation CreateOfferItem(
    $percentage: Int!
    $restaurant: ID!
    $title: String!
    $type: String!
    $validFromDate: Date!
    $validToDate: Date!
    $timeRangeFrom: Time
    $timeRangeTo: Time
    $description: String
  ) {
    createOfferItem(
      percentage: $percentage
      restaurant: $restaurant
      title: $title
      type: $type
      validFromDate: $validFromDate
      validToDate: $validToDate
      timeRangeFrom: $timeRangeFrom
      timeRangeTo: $timeRangeTo
      description: $description
    ) {
      offer {
        id
        title
        description
        type
        percentage
        validFromDate
        validToDate
        timeRangeFrom
        timeRangeTo
        createAt
      }
      success
      errors
    }
  }
`;
export const EDIT_RESTAURANT_OFFER_ITEM = gql`
  mutation EditOfferItem(
    $editOfferItemId: ID!
    $percentage: Int!
    $restaurant: ID!
    $title: String!
    $type: String!
    $validFromDate: Date!
    $validToDate: Date!
    $description: String
    $timeRangeFrom: Time
    $timeRangeTo: Time
  ) {
    editOfferItem(
      id: $editOfferItemId
      percentage: $percentage
      restaurant: $restaurant
      title: $title
      type: $type
      validFromDate: $validFromDate
      validToDate: $validToDate
      description: $description
      timeRangeFrom: $timeRangeFrom
      timeRangeTo: $timeRangeTo
    ) {
      errors
      success
      offer {
        title
        description
        type
        percentage
        validFromDate
        validToDate
        timeRangeFrom
        timeRangeTo
        createAt
        id
      }
    }
  }
`;
export const DElELT_RESTAURANT_OFFER_ITEM = gql`
  mutation DeleteOfferItem($offerItemId: ID!, $restaurant: ID!) {
    deleteOfferItem(id: $offerItemId, restaurant: $restaurant) {
      errors
      success
    }
  }
`;
export const CREATE_EXTRA_SERVICE_ITEM = gql`
  mutation CreateExtraServiceItem(
    $price: Float!
    $title: String!
    $restaurant: ID!
  ) {
    createExtraServiceItem(
      price: $price
      title: $title
      restaurant: $restaurant
    ) {
      errors
      success
      extraServiceItem {
        id
        title
        price
      }
    }
  }
`;
export const EDIT_EXTRA_SERVICE_ITEM = gql`
  mutation EditExtraServiceItem(
    $editExtraServiceItemId: ID!
    $restaurant: ID!
    $price: Float
    $title: String
  ) {
    editExtraServiceItem(
      id: $editExtraServiceItemId
      restaurant: $restaurant
      price: $price
      title: $title
    ) {
      errors
      success
      extraServiceItem {
        id
        title
        price
      }
    }
  }
`;
export const DElELT_EXTRA_SERVICE_ITEM = gql`
  mutation DeleteExtraServiceItem(
    $deleteExtraServiceItemId: ID!
    $restaurant: ID!
  ) {
    deleteExtraServiceItem(
      id: $deleteExtraServiceItemId
      restaurant: $restaurant
    ) {
      errors
      success
      extraServiceItem {
        id
        title
        price
      }
    }
  }
`;
export const CREATE_REVIEW_MUTAION = gql`
  mutation createReview($ambienceRating:Int,$foodRating:Int,$serviceRating:Int,$description:String!,$file:Upload,$restaurant:ID!) {
    createReview(ambienceRating:$ambienceRating,foodRating:$foodRating,serviceRating:$serviceRating,
      description:$description,file:$file,restaurant:$restaurant) {
        review{
          description
          rating
          file
        }
        errors
        success

    }
  }
`;
export const CREATE_USER_REPLAY_MUTAION = gql`
  mutation createUserReplay($message:String!,$review:ID!) {
    createUserReplay(
      message:$message,review:$review) {
        reviewReplay{
          message
          user {
            firstName
            lastName
          }
      
        }
        errors
        success

    }
  }
`;