import {toast} from "react-toastify";

const success=(text)=>toast.success(text,{
    iconTheme: {
        primary: '#4caf50',
        secondary: '#151515',
      },
  });

const error=(text)=> toast.error(text,{
    iconTheme: {
        primary: '#e64a19',
        secondary: '#151515',
      },
});

const info =(text)=> toast.info(text,{
    iconTheme: {
        primary: '#007bff',
        secondary: '#151515',
      },
});


export default {success,error,info};