import React from "react";
import "../../../styles/client/Client.css";
import "../../../styles/client/ClientContact.css";
import SocialButton from "../../../components/client/socialButton";
import Separator from "../../../components/client/separator";
import Input from "../../../components/client/input";
import {validateGeneral,validateConfirmationPassword} from "../../../validation/client";
import { useState } from "react";
import { ButtonPrimary } from "../../../components/client/button";
import Toast from '../../../services/Toast';
import InfoCard from "../../../components/client/infoCard";
import ButtonSocialSmall from "../../../components/client/socialButtonSmall";
import { faFacebookF, faInstagram, faLinkedinIn, faYoutube } from "@fortawesome/free-brands-svg-icons";


export default function DetailsContact()
{
    return (
        <React.Fragment>
            <div className="row">
                <div className="desc-contact">
                <div className="col-12">
                    <span className="title-1 small-header">How we can help you?</span>
                </div>
                <div className="col-12">
                    <span className="textLow-1">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some.</span>
                </div></div>
                <div className="col-12">
                    <Separator classNameText="seperator-small" text="Or contact us via"/>
                </div>
                <div className="col-12 mt-4 mb-4 box-contact">
                <span className="icon-ic24-email"></span> <p>support@tabla.ma</p>
                </div>
                <div className="col-12 mt-4 mb-4 box-contact">
                    <span className="icon-ic24-phone"></span><p>+212 568 784 7845</p>
                </div>
                <div className="col-12">
                    <Separator classNameText="seperator-small" text="Follow us on social media"/>
                </div>
                <div className="col-12 mt-4 mb-4">
                    <div className="flex-container" style={{justifyContent: "center",gap:"20px"}}>
                        <div className="flex-container iconContainerLink undefined"><span className="icon-ic24-facebook"></span></div>
                        <div className="flex-container iconContainerLink undefined"><i className="fab fa-linkedin-in"></i></div>
                        <div className="flex-container iconContainerLink undefined"><i className="fab fa-youtube"></i></div>
                        <div className="flex-container iconContainerLink undefined"><i className="fab fa-instagram"></i></div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}