import { gql } from "@apollo/client";

export const UPDATE_ACCOUNT = gql`
  mutation updateAccount(
    $firstName: String
    $lastName: String
    $email: String
    $phone: String
    $city: String
    $country: String
  ) {
    updateAccount(
      firstName: $firstName
      lastName: $lastName
      phone: $phone
      email: $email
      city: $city
      country: $country
    ) {
      success
      errors
    }
  }
`;
export const UPLOAD_IMAGE_MUTATION = gql`
  mutation Mutations($image: Upload) {
    uploadImageAccount(image: $image) {
      errors
      success
      image
    }
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation PasswordChange(
    $oldPassword: String!
    $newPassword1: String!
    $newPassword2: String!


  ) {
    passwordChange(
      oldPassword: $oldPassword
      newPassword1: $newPassword1
      newPassword2: $newPassword2

    ) {
      success
      errors
      refreshToken
      token
    }
  }
`;
export const LIKE_A_RESTAURANT = gql`
  mutation LikeRestaurant(
    $restaurant: ID!
  ) {
    likeRestaurant(
      restaurant: $restaurant
    ) {
      success
      errors
     
    }
  }
`;
export const UNLIKE_A_RESTAURANT = gql`
  mutation UnlikeRestaurant(
    $restaurant: ID!
  ) {
    unlikeRestaurant(
      restaurant: $restaurant
    ) {
      success
      errors
    }
  }
`;