import React from "react";
import "../../../styles/client/Client.css";
import "../../../styles/client/ClientLogin.css";
import "../../../styles/client/ClientContact.css";
import Input from "../../../components/client/input";
import {validateGeneral,validateEmail} from "../../../validation/client";
import { useState } from "react";
import { ButtonPrimary } from "../../../components/client/button";
import Toast from '../../../services/Toast';
import { contactUsService } from "../../../services/client/general";
import TextArea from "../../../components/client/textArea";


export default function FormContact(props)
{
    const [isValidEmail,setValidEmail]=useState(false);
    const [isValidSubject,setValidSubject]=useState(false);
    const [isValidMessage,setValidMessage]=useState(false);
    const [isValidFirstname,setValidFirstname]=useState(false);
    const [isValidLastname,setValidLastname]=useState(false);
    const eligableForSending = ()=>{

        return isValidEmail&&isValidSubject&&isValidMessage&&isValidFirstname&&isValidLastname;
    };

    const [email,setEmail]=useState(null);
    const [subject,setSubject]=useState(null);
    const [message,setMessage]=useState(null);
    const [firstname,setFirstname]=useState(null);
    const [lastname,setLastname]=useState(null);
    return (
        <React.Fragment>
                    <div className="row">
                        <form>
                            <div className="row col-12 mx-auto">
                                <div className="col-6 mt-2 pl-0">
                                    <Input id="firstname"
                                        type="text" 
                                        placeholder="Firstname" 
                                        onChange={(evt)=>{setFirstname(evt.target.value);}}
                                        shouldSendData={setValidFirstname}
                                        validateFunc={validateGeneral}/>
                                </div>
                                <div className="col-6 mt-2  pr-0">
                                    <Input id="lastname"
                                        type="text" 
                                        placeholder="Lastname" 
                                        onChange={(evt)=>{setLastname(evt.target.value);}}
                                        shouldSendData={setValidLastname}
                                        validateFunc={validateGeneral}/>
                                </div>
                            </div>
                            <div className="col-12 ml-4 mr-4 mt-2">
                                <Input id="email"
                                    type="email" 
                                    placeholder="Email" 
                                    onChange={(evt)=>{setEmail(evt.target.value);}}
                                    shouldSendData={setValidEmail}
                                    validateFunc={validateEmail}/>
                            </div>
                            <div className="col-12 ml-4 mr-4 mt-2">
                                <Input id="subject"
                                    type="text" 
                                    placeholder="Subject" 
                                    onChange={(evt)=>{setSubject(evt.target.value);}}
                                    shouldSendData={setValidSubject}
                                    validateFunc={validateGeneral}/>
                            </div>
                            <div className="col-12 ml-4 mr-4 mt-2">
                                <TextArea id="message"
                                    className="textarea"
                                    placeholder="Your message" 
                                    onChange={(evt)=>{setMessage(evt.target.value);}}
                                    shouldSendData={setValidMessage}
                                    validateFunc={validateGeneral}/>
                            </div>
                            <div className="col-12 centerText">
                                <ButtonPrimary style={{width:"auto"}} className="mx-auto" text="Sign up" 
                                click={(event)=>{
                                    event.preventDefault();
                                    if(!eligableForSending())
                                    {
                                        Toast.info("Veuillez saisir tous les champs");
                                        return;
                                    }
                                    if(contactUsService({email:email,subject:subject,firstname:firstname,lastname:lastname,message:message}))
                                        props.setSent(true);
                                }}/>
                            </div>
                        </form>
                    </div>
        </React.Fragment>
    )
}