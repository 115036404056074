import { useState, useEffect } from "react";
import Avatar from "../../../components/Shared/profile";
import Toast from "../../../services/Toast";
// import Button from "../../../components/Button/Button-S";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import {
  getPersonalInformaiton,
  EditPersonalInformaiton,
} from "../../../services/client/dashboard";
import LoaderPage from "../../../components/Shared/LoaderPage";
import useFormWithServerErrors from "../../../hooks/useFormWithServerErrors";
import { EMAIL_REGXR, NAME_REGXR } from "../../../validation/regexr";
import { useMutation, useQuery } from "@apollo/client";
import { CURRENT_USER } from "../../../services/Queries/UserQuery";
import { UPDATE_ACCOUNT } from "../../../services/mutations/userMustation";
import useCities from "../../../hooks/useCities";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PasswordInformation from "./PasswordInformation";
import PersonalInformaitontab from "./PersonalInformaitontab";
//Todo: add city and Country fields to the form and image
const PersonalInformaiton = () => {
  //   const [data, setData] = useState(false);
  const [newdata, setnewData] = useState(false);
  const [save, setedit] = useState(false);
  const [disable, setdisable] = useState(false);
  const {
    register,
    setNonFieldErrors,
    ServerError,
    ErrorMessage,
    getValues,
    setValue,
    handleSubmit,

    reset,
    formState: { isDirty, errors },
  } = useFormWithServerErrors();
  const onCompletedHandlerQuery = ({ me }) => {
    const values = getValues();
    if (me)
      for (const key of Object.keys(values)) {
        if (key == "city") {
          setValue(key, me[key]["id"]);

          continue;
        }
        setValue(key, me[key]);
      }
  };
  const onErrorHandlerQuery = (data) => { };

  const onErrorHandlerMutation = (data) => { };
  const { isLoading: isLoadingCities, cities } = useCities();
  const {
    loading: loadingQuery,
    error: errorQuery,
    data: dataQuery,
    refetch: { refetchUser },
  } = useQuery(CURRENT_USER, {
    onCompleted: onCompletedHandlerQuery,
    onError: onErrorHandlerQuery,
    fetchPolicy: "network-only",
  });
  const onCompletedHandlerMutation = ({ me }) => {
    const values = getValues();

    if (me) {
      for (const key of Object.keys(values)) {
        if (key == "city") {
          setValue(key, me[key]["id"]);

          continue;
        }
        setValue(key, me[key]);
      }
      refetchUser();
    }
  };
  const [
    updateAccount,
    { loading: loadingMutation, error: errorMutation, data: dataMutation },
  ] = useMutation(UPDATE_ACCOUNT, {
    onCompleted: onCompletedHandlerMutation,
    onError: onErrorHandlerMutation,
  });
  const handleOnSubmit = (values) => {
    updateAccount({ variables: { ...values } });
  };


  if (loadingMutation || loadingQuery) return <LoaderPage />;

  return (
    <div className="d-flex flex-grow-1 container_details">
      <div className="myprof">
        <Accordion sx={{ width: '100%' }}>

          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="h6" gutterBottom>  Personal  Information</Typography>
          </AccordionSummary>
          <br />
          <AccordionDetails>
            {/* <div className="myprof">
              <form onSubmit={handleSubmit(handleOnSubmit)}>
                <Avatar
                  firstName={dataQuery?.me?.firstName}
                  lastName={dataQuery?.me?.lastName}
                  imageUrl={dataQuery?.me?.image}
                />
                <ServerError />

                <div className="col-inp">
                  <div className="col-d">
                    <input
                      type="text"
                      {...register("firstName", {
                        pattern: NAME_REGXR,
                      })}
                      className="form-control"
                      placeholder="First name"
                    />
                    <ErrorMessage
                      errors={errors}
                      name="firstName"
                      render={({ message }) => (
                        <p className="text-danger">{message}</p>
                      )}
                    />
                  </div>
                  <div className="col-d">
                    <input
                      type="text"
                      {...register("lastName", {
                        pattern: NAME_REGXR,
                      })}
                      className="form-control"
                      placeholder="Last name"
                    />
                    <ErrorMessage
                      errors={errors}
                      name="lastName"
                      render={({ message }) => (
                        <p className="text-danger">{message}</p>
                      )}
                    />
                  </div>
                </div>
                <div className="col-inp">
                  <div className="col-d">
                    <input
                      type="text"
                      {...register("email", {
                        pattern: EMAIL_REGXR,
                      })}
                      className="form-control"
                      placeholder="Email"
                    />
                    <ErrorMessage
                      errors={errors}
                      name="Email"
                      render={({ message }) => (
                        <p className="text-danger">{message}</p>
                      )}
                    />
                  </div>
                  <div className="col-d">
                    <input
                      type="text"
                      {...register("phone")}
                      className="form-control"
                      placeholder="phone number"
                    />
                    <ErrorMessage
                      errors={errors}
                      name="phone"
                      render={({ message }) => (
                        <p className="text-danger">{message}</p>
                      )}
                    />
                  </div>
                </div>
                <div className="col-inp">
                  <div className="col-d">
                    <select {...register("city")} className="form-control ">
                      {cities.map((city, i) => (
                        <option value={city.id} key={i}>
                          {city.name}
                        </option>
                      ))}
                    </select>

                    <ErrorMessage
                      errors={errors}
                      name="city"
                      render={({ message }) => (
                        <p className="text-danger text-sm">{message}</p>
                      )}
                    />
                  </div>
                  <div className="col-d">
                    <input
                      type="text"
                      {...register("country")}
                      className="form-control"
                      placeholder="Country"
                    />
                    <ErrorMessage
                      errors={errors}
                      name="country"
                      render={({ message }) => (
                        <p className="text-danger text-sm">{message}</p>
                      )}
                    />
                  </div>
                </div>

                <Stack spacing={2} direction="row" className="group-btn">
                  {isDirty && (
                    <Button
                      variant="outlined"
                      onClick={() =>
                        reset({
                          lastName: dataQuery.me.lastName,
                          firstName: dataQuery.me.firstName,
                          phone: dataQuery.me.phone,
                          country: dataQuery.me.country,
                          email: dataQuery.me.email,
                        })
                      }
                    >
                      Cancel
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    color="success"
                    disabled={!isDirty}
                    type="submit"
                  >
                    Save
                  </Button>
                </Stack>
              </form>
            </div> */}
            <PersonalInformaitontab />
          </AccordionDetails>
        </Accordion>
        <br />
        <Accordion sx={{ width: '100%' }}>

          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="h6" gutterBottom> Change the password</Typography>
          </AccordionSummary>
          <br />
          <AccordionDetails>
            <PasswordInformation />
          </AccordionDetails>
        </Accordion>
      </div>

    </div>
  );
};

export default PersonalInformaiton;
