import {
  Routes,
  Route,
} from "react-router-dom";
import { adminDashboard } from "../constants/routesData/admin-dashboard";
import LeftNavbar from "../components/Shared/LeftNavbar";
import { Fragment } from "react";
import "../styles/LeftNavBar.css";



import React from 'react';
const CRoutes = ()=>{

    return(
        <div className="element left_nv2_container">
            <div className="d-flex" style={{ zIndex:1 }}>
                <div className="left_nvbar_model_2">
                    <LeftNavbar logo={true} data={adminDashboard} url="admin-dashboard/"/>
                </div>
            </div>
            
            
            <Routes>
                    {
                        adminDashboard.map(e=>{
                            if(e?.children)
                                return(
                                <Fragment>
                                    <Route path={e.path} element={e.element}/>
                                    {e?.children && e.children.map((c,i)=><Route path={e.path+"/"+c.path} element={c.element}/>)}
                                </Fragment>
                                );
                            return(
                            <Route path={e.path} element={e.element}/>
                            );
                        })
                    }
            </Routes>
        </div>
    )
}

export default CRoutes;