import { FaCity } from "react-icons/fa";
import { BiBookAlt } from "react-icons/bi";
import { BsFillPersonFill } from "react-icons/bs";
import { IoRestaurant } from "react-icons/io5";
import { validateEmail } from "../../validation/client";
import Toast from "../Toast";
import FetchAxios from "../Fetch";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  gql,
} from "@apollo/client";
import axios from "axios";
import { ENV_SERVER } from "../../env/ENV";

export function contactUsService(data) {
  /* should return a boolean */
  return true;
}

export function getDataForBand() {
  /* Data value need to be changed only */
  let data = [
    {
      icon: <IoRestaurant className="iconBand" />,
      label: "Best Restaurants",
      value: 465,
    },
    {
      icon: <BiBookAlt className="iconBand" />,
      label: "Reservations",
      value: 775,
    },
    {
      icon: <BsFillPersonFill className="iconBand" />,
      label: "Clients",
      value: 825,
    },
    { icon: <FaCity className="iconBand" />, label: "Cities", value: 27 },
  ];
  return data;
}

export function subscribeNewsLetter(email) {
  let valid = validateEmail(email);
  if (valid.length != 0) {
    Toast.error("Email non valide");
    return;
  }
  Toast.success("Abonné avec succès");
}

export function useGetlistinghomepage(pageSize = 3) {
  const RESTAURANTS = gql`
    query allRestaurant {
      allRestaurant(pageSize: ${pageSize}, orderBy: { orderBy: DATE, direction: DESC }) {
        objects {
          id
          name
          rating
          isLiked
          image
          city {
      id
      name
    }
          status
          description
          category {
            name
          }
        }
      }
    }
  `;
  const { loading, error, data } = useQuery(RESTAURANTS);
  if (error) {
    console.log(error);
    return [];
  }
  if (data) {
    console.log(data);
    let out = data?.allRestaurant?.objects?.map((e) => {
      return {
        id: e?.id,
        image: e?.image,
        title: e?.name,
        name: e?.name,
        city: e?.city,
        description: e?.description,
        review: e?.rating,
        rating: e?.rating,
        category: e?.category?.name,
        status: e?.status?.toLowerCase(),
        isLiked: e?.isLiked,
      };
    });
    console.log(out);
    return out;
  }
}
export function useGetListPopularRestaurant() {
  const POPULAR_RESTAURANTS = gql`
    query allRestaurant {
      allRestaurant(
        pageSize: 4
        orderBy: { orderBy: POPULARITY, direction: ASC }
      ) {
        objects {
          id
          name
          rating
          image
          isLiked
          status
          description
          category {
            name
          }
        }
      }
    }
  `;

  const { loading, error, data } = useQuery(POPULAR_RESTAURANTS);
  if (error) {
    console.log(error);
    return [];
  }
  if (data) {
    console.log(data);
    let out = data?.allRestaurant?.objects?.map((e) => {
      return {
        id: e?.id,
        image: e?.image,
        title: e?.name,
        name: e?.name,
        city: e?.city,
        description: e?.description,
        review: e?.rating,
        rating: e?.rating,
        category: e?.category?.name,
        status: e?.status?.toLowerCase(),
        isLiked: e?.isLiked,
      };
    });
    console.log(out);
    return out;
  }
}

export function SetListingPupular(onDone) {
  const RESTAURANTS = `
        query allRestaurant {
            allRestaurant(pageSize: 3,orderBy:number_of_guests) {
                objects {
                    id
                    name
                    rating
                    image
                    status
                    description
                    category
                    {
                        name
                    }
                }
            
            }
        }`;
  FetchAxios.post("", { query: RESTAURANTS })
    .then((response) => response.json())
    .then((result) => {
      console.log(result);
    })
    .catch((err) => {
      console.log(err);
    });
}

export function useGetCategories() {
  const CAREGORIES = gql`
    query allCategories {
      allCategories(pageSize: 5, page: 1, search: "") {
        objects {
          id
          image
          name
        }
      }
    }
  `;
  const { loading, error, data } = useQuery(CAREGORIES);
  if (error) {
    console.log(error);
    return [];
  }
  if (data) {
    console.log(data);
    return data?.allCategories?.objects?.map((e) => {
      return {
        id: e?.id,
        image: e?.image,
        name: e?.name,
      };
    });
  }
}
export function useGetCities() {
  const CITIES = gql`
    query allCities {
      allCities(pageSize: 24, page: 1, search: "") {
        objects {
          id
          name
          image
          restaurants {
            id
            name
          }
        }
      }
    }
  `;
  const { loading, error, data } = useQuery(CITIES);
  if (error) {
    console.log(error);
    return [];
  }
  if (data) {
    console.log(data);
    return data?.allCities?.objects?.map((e) => {
      return {
        id: e?.id,
        name: e?.name,
        image: e?.image,
      };
    });
  }
}

export function liking(id, onDone) {
  onDone();
}

export function unliking(id, onDone) {
  onDone();
}

export const OVERVIEWS_QUERY = gql`
  query Overview {
    overview {
      totalUsers
      totalRestaurants
      totalReservations
      totalCities
    }
  }
`;
