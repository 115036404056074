import { useState, useEffect, useRef, useContext } from "react";
import LoaderPage from "../../../components/Shared/LoaderPage";
import SearchSelect, { SelectTable } from "../../../components/SearchSelect";
import Toast from "../../../services/Toast";
import { Button, TextField, OutlinedInput } from "@mui/material";
import { BsChevronRight, BsHourglass } from "react-icons/bs";
import LoadingButton from "@mui/lab/LoadingButton";
import { Controller } from "react-hook-form";
import { useQuery, useMutation } from "@apollo/client";
import { RESTAURANT_OFFERS_QUERY } from "../../../services/Queries/RestaurantQuery";
import {
  EDIT_RESTAURANT_OFFER_ITEM,
  CREATE_RESTAURANT_OFFER_ITEM,
  DElELT_RESTAURANT_OFFER_ITEM,
} from "../../../services/mutations/restaurantMutaions";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { OFFERS_KEYS } from "../../../constants/keys";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import useFormWithServerErrors from "../../../hooks/useFormWithServerErrors";
import { MobileTimePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { RestaurantManagerContext } from "../";
import useDeleteDialog from "../DeleteDialog";
const Offers = () => {
  const myRestaurant = useContext(RestaurantManagerContext);
  const [data, setData] = useState(false);
  const [selectedItem, setSelectedItem] = useState(-1);
  const [DatatoShow, setDataToShow] = useState([]);
  const [disable, setDisable] = useState(false);
  const detailsRef = useRef(null);
  const [edit, setEdit] = useState(false);
  const [page, setPage] = useState(null);
  const [selectedOffer, setSelectedOffer] = useState(null);

  const {
    loading,
    error,
    data: offers,
    refetch,
  } = useQuery(RESTAURANT_OFFERS_QUERY, {
    variables: {
      restaurantOffersId: myRestaurant?.id,
      page: page,
      pageSize: OFFERS_KEYS.ADMIN_PAGE_SIZE,
    },
  });

  const setSelectedData = (i, e) => {
    setEdit(false);
    console.log("  setSelectedData ", e);
    setSelectedOffer(e);
    setSelectedItem(i);
    setDataToShow(data[i]);
    if (selectedItem != -1) detailsRef.current.scrollIntoView(true);
    else
      setTimeout(() => {
        detailsRef.current.scrollIntoView(true);
      }, 200);
  };
  const setAdd = () => {
    setEdit(true);
    setSelectedItem(-1);
    setSelectedOffer(null);
    // setDataToShow({ percentage: "", title: "", description: "" });
    setSelectedItem(-2);

    if (selectedItem != -1) detailsRef.current.scrollIntoView(true);
    else
      setTimeout(() => {
        detailsRef.current.scrollIntoView(true);
      }, 200);
  };
  const checkPercentage = () => {
    let n = parseInt(DatatoShow.percentage);
    return n < 0 || n > 100;
  };

  const onAddOffers = () => {
    setDisable(true);
  };
  const onEditOffers = () => {
    setDisable(false);
    setSelectedItem(-1);
    Toast.success("Offer Edit successfully");
  };
  const onDeleteOffers = () => {
    setDisable(false);

    Toast.success("Offer deleted successfully");
  };
  const CancelOffers = () => {
    setSelectedItem(-1);
    setEdit(false);
    setDisable(false);
  };
  const handlePageChange = (e, val) => {
    setPage(val);
  };
  if (loading) return <LoaderPage />;

  return (
    <div className="d-flex flex-grow-1 container_details offre">
      {offers?.restaurantOffers?.objects.length > 0 ? (
        <div className="flex-grow-1 element-1-cont">
          <div className="d-flex justify-content-between align-items-center offreHead">
            <h3 className="card-title" style={{ padding: 0 }}>
              Offers
            </h3>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setAdd();
              }}
              style={{
                color: "#fff",
                height: "40px",
                width: "130px",
                margin: "0 2px",
                borderRadius: "10px",
              }}
            >
              Add new
            </Button>
          </div>
          <br />
          <div className="d-flex " style={{ flexDirection: "column" }}>
            {offers?.restaurantOffers?.objects.map((e, i) => (
              <OffersElement
                onClick={() => {
                  setSelectedData(i, e);
                }}
                data={e}
                item={selectedItem != i}
                key={i}
              />
            ))}
            <Stack spacing={2} style={{ alignItems: "center" }}>
              <Pagination
                count={offers?.restaurantOffers?.pages}
                page={offers?.restaurantOffers?.page}
                onChange={handlePageChange}
                shape="rounded"
                color="success"
              />
            </Stack>
          </div>
        </div>
      ) : (
        <div
          className="d-flex justify-content-center align-items-center card flex-grow-1"
          style={{ borderRadius: "10px", minHeight: "50vh" }}
        >
          There is no Offers !
          <br />
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setAdd();
            }}
            style={{
              color: "#fff",
              height: "40px",
              width: "130px",
              margin: "20px 2px",
              borderRadius: "10px",
            }}
          >
            Add new
          </Button>
        </div>
      )}
      <div
        ref={detailsRef}
        style={{
          display: selectedItem == -1 && "none",
          flex: 1,
          width: "100%",
          minWidth: "300px",
          marginLeft: "10px",
        }}
      >
        <h3 className="card-title" style={{ padding: 0 }}>
          {selectedItem == -2 ? "Add new Item" : "Details"}
        </h3>

        {selectedItem != -1 ? (
          <OffersDetails
            data={selectedOffer}
            disableandLoad={disable}
            setDisable={setDisable}
            edit={edit}
            setEdit={setEdit}
            setdata={setDataToShow}
            doneButtonLabel={"Save"}
            cancelButtonLaber={"Cancel"}
            editButtonLabel={"Edit"}
            deleteButtonLaber={"Delete"}
            refetch={refetch}
            doneButtonClick={onAddOffers}
            cancelButtonClick={CancelOffers}
            editButtonClick={onEditOffers}
            deleteButtonClick={onDeleteOffers}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

const OffersElement = ({ data, onClick, item }) => {
  return (
    <div
      className="d-flex col-12 card order_container_element"
      style={{ cursor: "pointer" }}
      onClick={() => {
        onClick();
      }}
    >
      <div className="d-flex flex-grow-1 ">
        <div className="Porcentage title_img_eleme">{data.percentage}%</div>
        <div className="offre-Head">
          <h3 className="card-title" style={{ padding: 0, margin: 0 }}>
            {data.title}
          </h3>
          <div
            className="d-flex  align-items-lg-stretch"
            style={{ flexDirection: "column", marginTop: "5px" }}
          >
            <p className="text-muted">
              <small>{data.description}</small>
            </p>
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center">
        <Button
          disabled={item}
          className="button_right_icon"
          variant="contained"
          startIcon={<BsChevronRight size="25" color="#fff" />}
        />
      </div>
    </div>
  );
};

const OffersDetails = (props) => {
  const myRestaurant = useContext(RestaurantManagerContext);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const data = props?.data;
  const {
    useFormMethods,
    ServerError,
    setErrors,
    setNonFieldErrors,
    ErrorMessage,
  } = useFormWithServerErrors();

  const {
    register,
    unregister,
    reset,
    watch,
    control,
    handleSubmit,
    formState: { errors },
  } = useFormMethods;
  const onCompleteEditHandler = ({ editOfferItem }) => {
    const { success, errors, offer } = editOfferItem;
    if (props.setDisable) {
      props.setDisable(false);
    }
    if (success) {
      Toast.success("The offer has been edit");
      if (props.editButtonClick) props.editButtonClick();
      if (props.cancelButtonClick) props.cancelButtonClick();
    } else {
      setErrors(JSON.parse(errors));
    }
  };
  const onCompleteCreateHandler = ({ createOfferItem }) => {
    const { errors, success, offer } = createOfferItem;
    if (props.setDisable) {
      props.setDisable(false);
    }
    if (success) {
      if (props.cancelButtonClick) props.cancelButtonClick();
      if (props.refetch) props.refetch();
      Toast.success("The offer has been created");
    } else {
      setErrors(JSON.parse(errors));
    }
  };
  const onCompleteDeleteHandler = ({ deleteOfferItem }) => {
    setOpen(false);
    const { errors, success } = deleteOfferItem;
    if (props.setDisable) {
      props.setDisable(false);
    }

    if (success) {
      if (props.deleteButtonClick) {
        props.deleteButtonClick();
      }
      if (props.refetch) {
        props.refetch();
      }

      if (props.cancelButtonClick) props.cancelButtonClick();
    } else {
      setErrors(JSON.parse(errors));
    }
  };
  const onErrorCreateHandler = ({ createOfferItem }) => { };
  const onErrorDeleteHandler = ({ deleteOfferItem }) => { };

  const onErrorEditHandler = (data) => { };
  const [
    createOffer,
    { loading: loadingCreate, error: errorCreate, data: dataCreate },
  ] = useMutation(CREATE_RESTAURANT_OFFER_ITEM, {
    onCompleted: onCompleteCreateHandler,
    onError: onErrorCreateHandler,
  });
  const [
    deleteOffer,
    { loading: loadingDelete, error: errorDelete, data: dataDelete },
  ] = useMutation(DElELT_RESTAURANT_OFFER_ITEM, {
    onCompleted: onCompleteDeleteHandler,
    onError: onErrorDeleteHandler,
  });
  const [
    editOffer,
    { loading: loadingEdit, error: errorEdit, data: dataEdit },
  ] = useMutation(EDIT_RESTAURANT_OFFER_ITEM, {
    onCompleted: onCompleteEditHandler,
    onError: onErrorEditHandler,
  });
  const { Dialog, setOpen } = useDeleteDialog({
    text: " Are you sure you wanna delete this offer",
    title: "Deletion",
    actionLabel: "Yes",
    onAction: () =>
      deleteOffer({
        variables: { offerItemId: data.id, restaurant: myRestaurant?.id },
      }),
    onCancel: () => {
      console.log('onCancel ===> ')
      if (props.setDisable)
        props.setDisable(false)
    },
  });
  const onSubmit = (values) => {
    console.log(values);
    const dateNow = moment().format("YYYY-MM-DD");

    const validFromDate = values?.validFromDate.format("YYYY-MM-DD");
    const validToDate = values?.validToDate.format("YYYY-MM-DD");

    if (props.setDisable) {
      props.setDisable(true);
    }
    if (data?.id) {
      editOffer({
        variables: {
          ...values,
          editOfferItemId: data.id,
          restaurant: myRestaurant.id,
          validFromDate: validFromDate,
          validToDate: validToDate,
        },
      });
    } else {
      createOffer({
        variables: {
          ...values,
          restaurant: myRestaurant.id,
          validFromDate: validFromDate,
          validToDate: validToDate,
        },
      });
    }
  };

  const typeWatched = watch("type");
  const validFromDateWatched = watch("validFromDate");

  useEffect(() => {
    if (props?.data) {
      const api_data = props?.data;
      const validFromDate = moment(api_data.validFromDate, "YYYY-MM-DD");
      const validToDate = moment(api_data.validToDate, "YYYY-MM-DD");
      reset({
        ...props?.data,
        validFromDate: validFromDate,
        validToDate: validToDate,
      });
    } else {
      reset({
        percentage: "",
        title: "",
        description: "",
        type: OFFERS_KEYS.TYPE.MENU,
        validFromDate: moment(),
        validToDate: moment(),
      });
    }

    return () => {
      unregister();
    };
  }, [props?.data?.id, props.edit]);
  const onDelete = () => {

    setOpen(true);
  };
  if (props.edit)
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="d-flex" style={{ flexDirection: "column" }}>
          <div
            className="d-flex align-items-start offreformula"
            style={{
              margin: "25px 5px",
              width: "100%",
              flexDirection: "column",
            }}
          >
            <div className="my-10" style={{ margin: "20px 5px" }}>
              <ServerError />
              <ErrorMessage
                errors={errors}
                name="percentage"
                render={({ message }) => (
                  <p className="text-danger p-1">{message}</p>
                )}
              />
              <ErrorMessage
                errors={errors}
                name="title"
                render={({ message }) => (
                  <p className="text-danger p-1">{message}</p>
                )}
              />
              <ErrorMessage
                errors={errors}
                name="description"
                render={({ message }) => (
                  <p className="text-danger p-1">{message}</p>
                )}
              />
              <ErrorMessage
                errors={errors}
                name="validFromDate"
                render={({ message }) => (
                  <p className="text-danger p-1">{message}</p>
                )}
              />
              <ErrorMessage
                errors={errors}
                name="validToDate"
                render={({ message }) => (
                  <p className="text-danger p-1">{message}</p>
                )}
              />
            </div>
            <div className="d-flex" style={{ margin: "20px 0", width: "100%" }}>
              <Controller
                name="percentage"
                rules={{ required: "Percentage  is required" }}
                control={control}
                error={true}
                // defaultValue={data?.percentage}
                render={({ field }) => (
                  <FormControl error={errors?.percentage?.length > 0}>
                    <TextField
                      style={{ marginRight: "10px", width: "80px" }}
                      // onChange={(e) => {
                      //   onChange("percentage", e.target.value);
                      // }}
                      {...field}
                      label={"%"}
                    />
                  </FormControl>
                )}
              />

              <Controller
                name="title"
                control={control}
                // defaultValue={data?.title}
                rules={{ required: "Title  is required" }}
                render={({ field }) => (
                  <FormControl
                    error={errors?.title?.length > 0}
                    style={{ width: "90%" }}
                  >
                    <TextField
                      className="flex-grow-1 "
                      // onChange={(e) => {
                      //   onChange("title", e.target.value);
                      // }}
                      label="Title"
                      {...field}
                    />
                  </FormControl>
                )}
              />
            </div>
            <br />
            <div style={{ width: "100%" }}>
              <FormControl sx={{ minWidth: "100%" }} style={{ width: "100%" }}>
                <InputLabel
                  id="demo-simple-select-autowidth-label"
                  style={{ width: "100%" }}
                >
                  Type
                </InputLabel>
                <Controller
                  name="type"
                  control={control}
                  // defaultValue={data?.title}
                  render={({ field }) => (
                    <Select
                      labelId="demo-simple-select-autowidth-label"
                      id="demo-simple-select-autowidth"
                      style={{ width: "100%" }}
                      {...field}
                      autoWidth
                      label="Age"
                      input={<OutlinedInput label="Name" />}
                      MenuProps={MenuProps}
                    >
                      <MenuItem value={OFFERS_KEYS.TYPE.MENU}>Menu</MenuItem>
                      <MenuItem value={OFFERS_KEYS.TYPE.TIME}>Time</MenuItem>
                    </Select>
                  )}
                />
              </FormControl>
            </div>
            <div className="d-flex" style={{ margin: "15px 0", width: "100%" }}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <Controller
                  name="validFromDate"
                  control={control}
                  rules={{ required: "Valid From is required" }}
                  // defaultValue={data?.title}
                  render={({ field }) => (
                    <DesktopDatePicker
                      label="Valid From"
                      minDate={moment()}
                      inputFormat="MM/DD/YYYY"
                      style={{ margin: "0px 25px" }}
                      {...field}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  )}
                />
                <Controller
                  name="validToDate"
                  control={control}
                  // defaultValue={data?.title}
                  render={({ field }) => (
                    <DesktopDatePicker
                      label="Valid To"
                      rules={{ required: "Valid To is required" }}
                      minDate={moment(validFromDateWatched)}
                      inputFormat="MM/DD/YYYY"
                      style={{ margin: "0px 25px" }}
                      {...field}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
            {typeWatched == OFFERS_KEYS.TYPE.TIME && (
              <div
                className="d-flex"
                style={{ margin: "10px 0", width: "100%" }}
              >



                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <Controller
                    name="timeRangeFrom"
                    control={control}
                    // defaultValue={data?.title}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <TimeElement onChange={onChange} data={value} placeholder='Time from' selectedDate={moment(validFromDateWatched)} />

                    )} />
                  <Controller
                    name="timeRangeTo"
                    control={control}
                    // defaultValue={data?.title}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <TimeElement onChange={onChange} data={value} placeholder='Time to' />

                    )}
                  />
                </LocalizationProvider>
              </div>
            )}
            <Controller
              name="description"
              control={control}
              // defaultValue={data?.description}
              render={({ field }) => (
                <TextField
                  style={{ margin: "20px 0", width: "100%" }}
                  {...field}
                  // onChange={(e) => {
                  //   onChange("description", e.target.value);
                  // }}
                  label="Description"
                  multiline
                  rows={4}
                />
              )}
            />
          </div>
          <div
            className="d-flex w-100 justify-content-center "
            style={{ height: "45px" }}
          >
            {props?.cancelButtonLaber && (
              <LoadingButton
                variant="contained"
                color="secondary"
                style={{
                  color: "#fff",
                  flex: 1,
                  margin: "2px",
                  borderRadius: "10px",
                }}
                className="class_button"
                disabled={props.disableandLoad}
                loading={props.disableandLoad == 2}
                onClick={() => {
                  props.cancelButtonClick();
                }}
              >
                {props.disableandLoad == 2 ? "" : props.cancelButtonLaber}
              </LoadingButton>
            )}
            {props?.doneButtonLabel && (
              <LoadingButton
                variant="contained"
                type="submit"
                style={{
                  color: "#fff",
                  flex: 1,
                  margin: "2px",
                  borderRadius: "10px",
                }}
                className="class_button"
                disabled={props.disableandLoad}
                loading={props.disableandLoad == 1}
              // onClick={() => {
              //   AddorEdit();
              // }}
              >
                {props.disableandLoad == 1 ? "" : props.doneButtonLabel}{" "}
              </LoadingButton>
            )}
          </div>
        </div>
      </form>
    );
  return (
    <div className="d-flex offreView" style={{ flexDirection: "column" }}>
      <Dialog />
      <div
        className="d-flex align-items-start"
        style={{ margin: "15px 20px", width: "100%", flexDirection: "column" }}
      >
        <div className="d-flex">
          <div className="Porcentage title_img_eleme">
            {props.data.percentage}%
          </div>
          <h3
            className="card-title"
            style={{
              padding: "0px",
              height: "fit-content",
              margin: "auto",
              marginLeft: 0,
            }}
          >
            {props.data.title}
          </h3>
        </div>
        <div className="offre-Head">
          <div
            className="d-flex  align-items-lg-stretch"
            style={{ flexDirection: "column" }}
          >
            <p className="text-muted">
              <small>{props.data.description}</small>
            </p>
          </div>
        </div>
      </div>
      <div>
        <br />
      </div>
      <div
        className="d-flex w-100 justify-content-center "
        style={{ height: "45px" }}
      >
        {props?.deleteButtonLaber && (
          <LoadingButton
            variant="contained"
            color="error"
            style={{
              color: "#fff",
              flex: 1,
              margin: "2px",
              borderRadius: "10px",
            }}
            className="class_button"
            disabled={props.disableandLoad}
            loading={props.disableandLoad == 2}
            onClick={onDelete}
          >
            {props.disableandLoad == 2 ? "" : props.deleteButtonLaber}
          </LoadingButton>
        )}
        {props?.editButtonLabel && (
          <LoadingButton
            variant="contained"
            style={{
              color: "#fff",
              flex: 1,
              margin: "2px",
              borderRadius: "10px",
            }}
            className="class_button"
            disabled={props.disableandLoad}
            loading={props.disableandLoad == 1}
            onClick={() => {
              props.setEdit(true);
            }}
          >
            {props.disableandLoad == 1 ? "" : props.editButtonLabel}{" "}
          </LoadingButton>
        )}
      </div>
    </div>
  );
};

export default Offers;
const TimeElement = ({ data, onChange, isOpen, selectedDate, onClick, placeholder, allHours }) => {
  const [options, setOptions] = useState([])
  const startHour = moment().hour(9);
  const EndHour = moment().hour(23);

  useEffect(() => {
    const hours = []

    let sh = 9
    // console.log(' val > now ', moment(selectedDate).isSame(moment(), 'day'))
    // if (moment(selectedDate).isSame(moment(), 'day') && moment().hour() > 9)
    //   sh = moment().hour() + 1


    while (sh < 25) {

      const pHours = moment().hour(sh).minutes(0).format('H:mm')

      hours.push({ value: pHours, label: pHours })
      const halfHour = moment().hour(sh).minutes(30).format('H:mm')
      hours.push(
        {
          value:
            halfHour, label: halfHour
        }
      );


      sh++;
    }
    setOptions(hours)
  }, [selectedDate])


  return (
    <SelectTable
      options={options}
      style={{ border: '1px solid #777', minHeight: '55px' }}
      value={data}
      onClick={onClick}
      placeholder={placeholder}
      IconBefore={<BsHourglass className="iconSearch" />}
      onSelect={onChange} isOpen={isOpen} />
  );
};