import VerificationPage from "./Pages/VerificationPage";
import FAQPage from "./Pages/FAQPage";
import { useState } from "react";

const Pages = () => {
    const [page, setPage] = useState("1");
    return ( <
        div style = {
            { width: "100%" } } >
        <
        div className = "d-flex justify-content-between align-items-center" >
        <
        h3 className = "card-title"
        style = {
            { padding: 0 } } > Pages < /h3> <
        select className = "form-select  form-select-g select-e"
        style = {
            { maxWidth: "250px" } }
        onChange = {
            (e) => setPage(e.target.value) } >
        <
        option value = "1" > Verification Page < /option> <
        option value = "2" > FAQ Page < /option> <
        /select> <
        /div> <
        div style = {
            { marginTop: "50px" } } > {
            page == "1" ?
            <
            VerificationPage / >
            :
                <
                FAQPage / >
        } <
        /div>

        <
        /div>
    )
}

export default Pages;