import React from 'react';
import { BrowserRouter, Routes, Route,Navigate } from "react-router-dom";
import './index.css';
import 'bootstrap/dist/css/bootstrap.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RestaurantAdmine from './pages/restaurant-admin';
import Client from "./pages/client-panel/Client";
import AdminDashboard from './pages/admin-dashboard';
import { createTheme,ThemeProvider } from "@mui/material";
import "./App.css";
import "./styles/client/Placeholder-loading.css";
import "./Responsive.css";
import "./fonts/font-awsome/css/all.css";
import "./fonts/font-awsome/icons/style.css";
import "./fonts/font-familly/stylesheet.css";
import UserDashboard from './pages/user-dashboard';
import PrivateRoute from './components/utils/PrivateRoutes'
import TokenManger from "./api/TokenManger"

const theme = createTheme({
  palette: {
    primary: {
      main:"#43AB8C",
    },
    secondary:{
      main:"rgba(10, 9, 8, 0.2)"
    }
  },
});

function App() {
  const getToken = localStorage.getItem('token');
  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <BrowserRouter >
          <Routes>
            <Route path="admin/*" element={getToken ? <RestaurantAdmine/> : <Navigate to="/login"  />}/>
            <Route path="/*" element={<Client/>}/>
            <Route path="admin-dashboard/*" element={getToken ? <AdminDashboard/> : <Navigate to="/login"  />}/>
            <Route path="user/*" element={getToken ? <UserDashboard/> : <Navigate to="/login"  />}/>
          </Routes>
        </BrowserRouter>
        <ToastContainer position="bottom-center"a utoClose={5000} hideProgressBarnewestOnTop={false} closeOnClickrtl={false} pauseOnFocusLossdraggablepauseOnHover/>
    </ThemeProvider>
  </React.StrictMode>
  );
}

export default App;
