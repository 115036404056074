import { useQuery } from "@apollo/client";
import React, { useState } from "react";
import { CATEGORIES_QUERY } from "../services/Queries/CategoriesQuery";

export const useCategories = (props) => {
  const [categories, setCategories] = useState([]);
  const onCompletedHandler = ({ allCategories }) => {
    const { objects } = allCategories;
    setCategories(objects);
    if(props)
    if(props.onCompleted){
      props.onCompleted(allCategories)
    }
  };

  const { isLoading, error, data } = useQuery(CATEGORIES_QUERY, {
    onCompleted: onCompletedHandler,
  });
  return {
    isLoading,
    error,
    categories,
  };
};

export default useCategories;
