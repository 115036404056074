import Dashboard from "../../pages/restaurant-admin/element/Dashboard";
import PostOrders from "../../pages/restaurant-admin/element/Orders/PostOrders";
import RejectedOrders from "../../pages/restaurant-admin/element/Orders/RejectedOrders";
import UpcomingOrders from "../../pages/restaurant-admin/element/Orders/UpcomingOrders";
import Offers from "../../pages/restaurant-admin/element/Offers";
import Menu from "../../pages/restaurant-admin/element/Menu/Menu";
import Extraservices from "../../pages/restaurant-admin/element/Menu/Extraservices";
import Inbox from "../../pages/restaurant-admin/element/Inbox";
import Reviews from "../../pages/restaurant-admin/element/Reviews";
import Settings from "../../pages/restaurant-admin/element/Settings";
import {BsFillGridFill} from "react-icons/bs";
import {FaBook,FaPercent, FaInbox} from "react-icons/fa";
import {IoBook, IoSettingsSharp} from "react-icons/io5";
import {IoMdChatbubbles} from "react-icons/io";


export const restaurantAdminData = [
    {   
        title:"Dashboard",
        icon:<BsFillGridFill className="svgicon1"/>,
        path:"Dashboard",
        element:<Dashboard/>
    },
    {   
        title:"Orders",
        icon:<FaBook className="svgicon1"/>,
        path:"Orders",
        element:<UpcomingOrders/>,
        children:[
            {title:"Upcoming",path:"Upcoming",element:<UpcomingOrders/>},
            {title:"Post",path:"Post",element:<PostOrders/>},
            {title:"Rejected",path:"Rejected",element:<RejectedOrders/>},
        ]
    },
    {   
        title:"Offers",
        icon:<div className="svgicon2"><FaPercent /></div>,
        path:"Offers",
        element:<Offers/>
    },
    {   
        title:"Menu",
        icon:<IoBook className="svgicon1"/>,
        path:"Menu",
        element:<Menu/>,
        children:[
            {title:"Menu",path:"",element:<Menu/>},
            {title:"Extra services",path:"Extra-services",element:<Extraservices/>},
        ]
    },
    // {   
    //     title:"Inbox",
    //     icon:<FaInbox className="svgicon1"/>,
    //     path:"Inbox",
    //     element:<Inbox/>
    // },
    {   
        title:"Reviews",
        icon:<IoMdChatbubbles className="svgicon1"/>,
        path:"Reviews",
        element:<Reviews/>
    },
    {   
        title:"Settings",
        icon:<IoSettingsSharp className="svgicon1"/>,
        path:"Settings",
        element:<Settings/>
    },
];