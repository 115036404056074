import React, { forwardRef } from "react";
import "../../styles/client/Client.css";
import { useEffect, useState } from "react";

export default function Input(props) {
  const [validateFlag, setValidate] = useState("none");
  const [valueInput, setValue] = useState(null);
  const [errors, setErrors] = useState([]);
  function validate() {
    if (errors.length == 0 && valueInput == null) return "null";
    if (errors.length == 0) return "true";
    return "false";
  }
  useEffect(() => {
    if (props.value) setValue(props.value);
  }, []);
  useEffect(() => {
    setErrors(props.validateFunc(valueInput));
  }, [valueInput]);
  useEffect(() => {
    setValidate(validate());
    props.shouldSendData(errors.length == 0 && valueInput != null);
  }, [errors]);
  return (
    <>
      <input
        id={props.id}
        value={valueInput}
        type={props.type}
        placeholder={props?.placeholder}
        className="input-client p-3 inCont"
        onChange={(evt) => {
          props.onChange(evt);
          setValue(evt.target.value);
        }}
        validate={validateFlag}
      />
      <ul className="ul-input">
        {errors &&
          errors.map((text) => {
            return (
              <li key={text}>
                <p className="text-danger">{text}</p>
              </li>
            );
          })}
      </ul>
    </>
  );
}

export const StandardInput = forwardRef((props, ref) => (
  <>
    <input ref={ref} className="input-client p-3 inCont" {...props} />
  </>
));
