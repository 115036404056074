import { useState, useEffect, useRef } from "react";
import LoaderPage from "../../../components/Shared/LoaderPage";
import { getDashboard } from "../../../services/restaurant-admin/restaurant-admin";
import Toast from "../../../services/Toast";
import LoadingButton from "@mui/lab/LoadingButton";
import { Avatar, Button } from "@mui/material";
import { BsChevronRight } from "react-icons/bs";
import { LogoTabla } from "../../../components/Shared/Logo";
import Rating from "@mui/material/Rating";
import CircularProgress from "@mui/material/CircularProgress";
import ChartElement from "../../../components/Shared/ChartElement";
import OrdersElement from "./Orders/element/OrdersElement";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import LocalDiningIcon from '@mui/icons-material/LocalDining';
import NoMealsIcon from '@mui/icons-material/NoMeals';
import { pink, green } from '@mui/material/colors';
import {
  MY_RESTAURANT,
  RESTAURANT_RESERVATION,
} from "../../../services/Queries/reservationQuery";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
const Dashboard = () => {
  const { loading, error, data: restaurant } = useQuery(MY_RESTAURANT);
  const {
    loading: loadingReservation,
    error: reservationError,
    data: reservation,
  } = useQuery(RESTAURANT_RESERVATION, {
    variables: {
      pageSize: 3,
      restaurantReservationsId: 2,
      filter: { status: "PENDING" },
    },
  });
  const [data, setData] = useState(false);
  const navigate = useNavigate();

  const initData = () => {
    setData(-1);
    getDashboard(setData);
  };

  useEffect(() => {
    if (data === false) initData();
  });

  if (loading) return <LoaderPage />;
  console.log(" myRestaurant ", restaurant);
  return (
    <div className="d-flex flex-grow-1 container_details">
      <div className="row flex-grow-1">
        <div className="col-md" style={{ minWidth: "68%" }}>
          <div className="flex-grow-1 ">
            <h3 className="card-title" style={{ padding: 0 }}>
              Overview
            </h3>
            <h4 className="sous-card-title" style={{ padding: 0 }}>
               Last week reservations
            </h4>
            <div className="row ">
              <div className="col-md-12 ">
                {restaurant && (
                  <ChartElement
                    color={"#ff4f4f"}
                    height={
                      restaurant.myRestaurant.overview
                        .totalReservationsLeast7Days
                    }
                    id="datachart"
                    data={[
                      {
                        name: "Reservation",
                        data: restaurant.myRestaurant.overview
                          .last7DaysReservations.daysReservationsCont,
                      },
                    ]}
                    categories={
                      restaurant?.myRestaurant?.overview.last7DaysReservations
                        .labels
                    }
                  />
                )}
              </div>
            </div>
            <div className="row ">
              <div className="col-md-12 ">
                <FeaturedInfo {...restaurant.myRestaurant.overview} />
              </div>
            </div>
            <h3 className="sous-card-title" style={{ padding: 0 }}>
              Reservation need approval
            </h3>
            {reservation && (
              <div className="d-flex " style={{ flexDirection: "column" }}>
                {reservation?.restaurantReservations.objects.map((e, i) => (
                  <OrdersElement
                    onClick={() => {
                      navigate("/admin/Orders");
                    }}
                    data={e}
                  />
                ))}
              </div>
            )}
            <div className="d-flex justify-content-center">
              <Button
                onClick={() => {
                  navigate("/admin/Orders");
                }}
                variant="contained"
                style={{ color: "#fff" }}
              >
                View more
              </Button>
            </div>
            <br />
          </div>
        </div>

        <div className="col-md Review" style={{ minWidth: "30%" }}>
          <h3 className="card-title" style={{ padding: 0 }}>
            Customers Satisfaction
          </h3>
          <ReviewDetails data={restaurant.myRestaurant} />
          <div className="d-flex justify-content-center">
            <Button
              onClick={() => {
                navigate("/admin/Reviews");
              }}
              variant="contained"
              style={{ color: "#fff" }}
            >
              See customer reviews
            </Button>
          </div>
          <br />
          {/* <h3 className="card-title" style={{ padding: 0 }}>
            Inbox
          </h3>
          <br />
          <div className="d-flex " style={{ flexDirection: "column" }}>
            {data?.inbox.map((e, i) => (
              <ElementInbox
                onClick={() => {
                  navigate("/admin/Inbox");
                }}
                data={e}
              />
            ))}
          </div>
          <div className="d-flex justify-content-center">
            <Button
              onClick={() => {
                navigate("/admin/Inbox");
              }}
              variant="contained"
              style={{ color: "#fff" }}
            >
              View more
            </Button>
          </div> */}
          <br />
        </div>
      </div>
    </div>
  );
};

const ReviewDetails = (props) => {
  const rating = props.data.rating;

  return (
    <div className="d-flex">
      <div
        className="d-flex align-items-center justify-content-center "
        style={{ margin: "0px", flexDirection: "column", width: "100%" }}
      >
        <div
          className="d-flex flex-row align-items-center justify-content-center"
          style={{ width: "100%" }}
        >
          <div className="position-relative" style={{ marginRight: "10px" }}>
            <CircularProgress
              style={{ width: "90px", height: "90px" }}
              variant="determinate"
              value={rating * 20}
            />
            <div
              className="rate_label_number"
              style={{ width: "90px", height: "90px" }}
            >
              <div className="rate_label" style={{ margin: 0 }}>
                {parseFloat(rating).toFixed(2)}
              </div>
            </div>
          </div>
          <div className="d-flex flex-column" style={{ marginLeft: "10px" }}>
            <Rating name="disabled" value={rating} precision={0.5} readOnly />
            {/* <div className="rate_label">Very Good</div> */}
          </div>
        </div>
        <div className="d-flex w-100">
          <div className="text-center star" style={{ padding: "5px" }}>
            <span className="rate_label" style={{ margin: 0 }}>
              {props.data.foodRating}

            </span>
            <br />
            <span className="text_rating_type2">Food</span>
          </div>
          <div
            className="text-center service_rate_container star"
            style={{ padding: "5px" }}
          >
            <span className="rate_label" style={{ margin: 0 }}>
              {props.data.serviceRating}
            </span>
            <br />
            <span className="text_rating_type2">Service</span>
          </div>
          <div className="text-center star" style={{ padding: "5px" }}>
            <span className="rate_label" style={{ margin: 0 }}>
              {props.data.ambienceRating}
            </span>
            <br />
            <span className="text_rating_type2">Ambience</span>
          </div>
        </div>
        <br />
      </div>
    </div>
  );
};

const ElementInbox = ({ data, onClick, item }) => {
  const LastMessage = () => {
    let x = data.conversation.filter((d) => d.user != "admin");
    let n = x.length;
    if (n > 0) return x[n - 1].message;
    else return x[0].message;
  };
  return (
    <div
      className="d-flex col-12 card order_container_element ResElement InBox"
      style={{ cursor: "pointer" }}
      onClick={() => {
        onClick();
      }}
    >
      <div className="d-flex flex-grow-1 " style={{ alignSelf: "flex-start" }}>
        <Avatar
          className="avatar_img"
          src={data.user.image}
          variant="rounded"
        />
        <div className="InBoxElement">
          <div className="InBoxHead">
            <h3 className="card-title" style={{ padding: 0, margin: 0 }}>
              {data.user.name}
            </h3>
            <p className="text-muted2">
              <small>4 months ago</small>
            </p>
          </div>
          <div
            className="d-flex  align-items-lg-stretch"
            style={{ flexDirection: "column" }}
          >
            <p className="text-muted3 text_inbox">
              <small>{LastMessage()}</small>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import FlareIcon from "@mui/icons-material/Flare";
import RestoreIcon from "@mui/icons-material/Restore";
import PendingIcon from "@mui/icons-material/Pending";
import CollectionsBookmarkIcon from "@mui/icons-material/CollectionsBookmark";
import Tooltip from "@mui/material/Tooltip";
import Stack from "@mui/material/Stack";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
function FeaturedInfo({
  totalPendingReservations,
  totalReservations,
  totalReservationsLeast7Days,
  totalCanceledReservationsLeast7Days,
}) {
  const boxStyle = {
    display: 'flex', justifyContent: 'space-around', padding: '4px', margin: '2px', border: '1px solid grey',
    flexDirection: 'column',
    boxShadow: ' -5px 5px 1px lightblue ',
    borderRadius: '10px'
  }
  return (
    <div className="featured">
      <div style={{ display: 'grid', gridTemplateColumns: '50% 50%', padding: '4px', margin: '1px', columnGap: '10px', rowGap: '8px' }}>

        <div style={boxStyle}>
          <Item as='div'>
            <LocalDiningIcon sx={{ fontSize: 45, color: green[500] }} />
          </Item>
          <Item as='div' sx={{ fontSize: 25, color: green[500] }}>
            {totalReservationsLeast7Days}

          </Item>

          <Item as='div'>
            <h6><strong>Total reservations last week</strong></h6>
          </Item>
        </div>
        <div style={boxStyle}>
          <Item as='div'>
            <NoMealsIcon sx={{ fontSize: 45, color: pink[500] }} />
          </Item>
          <Item as='div' sx={{ fontSize: 25, color: pink[500] }}>
            {totalCanceledReservationsLeast7Days}
          </Item>

          <Item as='div'>
            <h6><strong>Total reservations canceled last week</strong></h6>
          </Item>
        </div>

      </div>
    </div >
  );
}
export default Dashboard;
