import React from "react";
import "../../styles/client/Client.css";
import "../../styles/client/ClientLogin.css";
import { useState } from "react";
import { ButtonPrimary } from "../../components/client/button";
import CircularProgress from "@mui/material/CircularProgress";
import { useParams, useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { PASSWORD_RESET_MUTATION } from "../../services/mutations/authMutations";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import LoaderPage from "../../components/Shared/LoaderPage";
import useFormWithServerErrors from "../../hooks/useFormWithServerErrors";
import { PASSWORD_VALIDATION } from "../../validation/regexr";
import { StandardInput } from "../../components/client/input";
const PasswordReset = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  //   const [nonFieldErrors, setNonFieldErrors] = useState([]);
  const {
    ServerError,
    setErrors,
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useFormWithServerErrors();
  const watchedPassword = watch("newPassword1");
  const handelOnCompleted = (passwordReset) => {
    const { success, errors } = passwordReset;
    if (errors) {
      setErrors(errors);
    } else if (success) {
      setNonFieldErrors([]);
      //   navigate(succussRedirectPath);
    }
  };
  const [passwordReset, { data, loading, error, success }] = useMutation(
    PASSWORD_RESET_MUTATION,
    {
      onCompleted: handelOnCompleted,
    }
  );
  const onSubmit = (data) => {
    passwordReset({ variables: { ...data, token } });
  };

  if (loading) return <LoaderPage />;
  if (error)
    return (
      <h1 className="title-1 text-info p-4 ">
        We can not reset your password for the moment
      </h1>
    );
  return (
    <React.Fragment>
      <div className="row m-4">
        <div className="col-lg-6 p-4">
          <div className="row">
            <div className="col-12">
              <span className="title-1 ">Reset your password</span>
            </div>
            <div className="col-12">
              {data?.passwordReset?.success ? (
                <h1 className="text-success p-4 title-2">
                  You password has been successfully Reset
                  <br />
                  <button className="btn " onClick={() => navigate("/login")}>
                    Go to login
                  </button>
                </h1>
              ) : (
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="col-12 ml-4 mr-4 mt-2">
                    <StandardInput
                      id="password"
                      type="password"
                      placeholder="Password"
                      {...register("newPassword1", PASSWORD_VALIDATION)}
                      className="input-client p-3 inCont"
                    />
                    <ErrorMessage
                      errors={errors}
                      name="newPassword1"
                      render={({ message }) => (
                        <p className="text-danger">{message}</p>
                      )}
                    />
                  </div>
                  <div className="col-12 ml-4 mr-4 mt-2">
                    <StandardInput
                      id="confirmedpassword"
                      type="password"
                      placeholder="Password Confirmation"
                      className="input-client p-3 inCont"
                      {...register("newPassword2", {
                        PASSWORD_VALIDATION,
                        validate: (value) =>
                          value === watchedPassword ||
                          "Password Confirmation is not Equal to password",
                      })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="newPassword2"
                      render={({ message }) => (
                        <p className="text-danger">{message}</p>
                      )}
                    />
                  </div>
                  <div className="col-12 centerText">
                    <button
                      style={{ width: "auto" }}
                      className={`btn btn-success btn-book mx-auto `}
                    >
                      Reset
                    </button>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <img
            className="svgSize onlyDesktopSVG"
            src="/svg/Forget1.svg"
            alt="SVG"
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default PasswordReset;
