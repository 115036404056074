import React, { useState, useEffect, useContext } from "react";
import LoaderPage from "../../../components/Shared/LoaderPage";
import Toast from "../../../services/Toast";
import LoadingButton from "@mui/lab/LoadingButton";
import SwitchElement from "../../../components/Shared/Switch";
import SearchSelect from "../../../components/SearchSelect";

import {
  getSettings,
} from "../../../services/restaurant-admin/restaurant-admin";
import UploadImages from "../../../components/Shared/UploadImages";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useQuery, useMutation } from "@apollo/client";
import { MY_RESTAURANT_SETTING_QUERY, MY_RESTAURANT_SETTING_GALLERY_QUERY } from "../../../services/Queries/RestaurantQuery";
import useFormWithServerErrors from "../../../hooks/useFormWithServerErrors";
import { FormProvider, useFormContext, Controller } from "react-hook-form";
import {
  EMAIL_REGXR,
  TIME_REGXR,
  PHONE_NUMBER_VALIDATION,
} from "../../../validation/regexr";
import { AiOutlineDelete, AiOutlinePlus, AiOutlineEdit } from "react-icons/ai";
import useCities from "../../../hooks/useCities";
import useCategories from "../../../hooks/useCategories";
import {
  EDIT_RESTAURANT,
  UPLOAD_RESTAURANT_IMAGE_MUTATION, DELETE_RESTAURANT_IMAGE_MUTATION
} from "../../../services/mutations/restaurantMutaions";
import { RestaurantManagerContext } from "..";
import PersonalInformaiton from "../../user-dashboard/element/PersonalInformaiton";
import PasswordInformation from "../../user-dashboard/element/PasswordInformation";
import { Button } from "@mui/material";
import LocationCity from '@mui/icons-material/LocationCity'

import LocationOnRounded from '@mui/icons-material/LocationOnRounded'
import PersonalInformaitontab from "../../user-dashboard/element/PersonalInformaitontab";
const UploadTheMainImage = () => {
  const {
    register,
    watch,
    setValue,
    control,
    formState: { errors, isValid },
  } = useFormContext();
  const watchImage = watch("image", null);

  const isThereAFile = watchImage ? watchImage.length > 0 : false;
  const deleteImage = (e) => {
    setValue("image", "");
  };

  const url = isThereAFile
    ? typeof watchImage === "string"
      ? watchImage
      : URL.createObjectURL(watchImage[0])
    : null;
  return (
    <div style={{ width: "fit-content", margin: "auto" }}>
      <div
        className="element_image"
        style={{ margin: "10px", position: "relative" }}
      >
        {isThereAFile ? (
          <>
            <img
              src={url}
              style={{ height: "100%", width: "100%", position: "absolute" }}
            />

            <span

              style={{
                height: "32%",
                width: "32%",
                position: "absolute",
                top: 1,
                right: 1,
                backgroundColor: "#000",
                opacity: "0.7",
              }}
            >
              <input
                type="file"
                accept="image/*"
                {...register("image")}
                style={{
                  opacity: 0,
                  height: "100%",
                  width: "100%",
                  position: "absolute",
                }}
                placeholder="Select an element"
              />
              <AiOutlineEdit
                style={{ margin: "auto" }}
                size="20"
                color="#fff"
              />
            </span>
          </>
        ) : (
          <>
            <span
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                height: "100%",
                width: "100%",
              }}
            >
              <AiOutlinePlus
                style={{ margin: "auto", opacity: "0.5" }}
                size="100%"
                color="#000"
              />
            </span>
            <input
              type="file"
              accept="image/*"
              {...register("image")}
              style={{
                opacity: 0,
                height: "100%",
                width: "100%",
                position: "absolute",
              }}
              placeholder="Select an element"
            />
          </>
        )}
      </div>
    </div>
  );
};
const Gallery = ({ restaurantId }) => {
  const [newdata, setnewdata] = useState({})
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };
  const onCompletedUploadGalleryImageHandler = ({ createImage }) => {
    const { success, error, image } = createImage
    handleToggle()
    if (success) {
      refetch()
    }

  }
  const onErrorUploadGalleryImageHandler = (data) => { };
  const [upoadImageGallery, {
    loading: isLoadingUploadGalleryImage,
    error: errorUploadGalleryImage,
    data: uploadGalleryImageData,
  }] = useMutation(UPLOAD_RESTAURANT_IMAGE_MUTATION, {
    onCompleted: onCompletedUploadGalleryImageHandler,
    onError: onErrorUploadGalleryImageHandler,
  });
  const {
    loading,
    error,
    data: restaurant,
    refetch
  } = useQuery(MY_RESTAURANT_SETTING_GALLERY_QUERY, {

  });
  const onDeleteGalleryImageHandler = ({ deleteImage }) => {
    const { errors, success, image } = deleteImage
    handleToggle()
    if (success) {
      refetch()
    }
  }
  const onErrorDeleteImageHandler = (data) => {

  }
  const [deleteImage, {
    loading: deleteGalleryImage,
    error: errorDelete }] = useMutation(DELETE_RESTAURANT_IMAGE_MUTATION, {
      onCompleted: onDeleteGalleryImageHandler,
      onError: onErrorDeleteImageHandler,
    });
  const onAddFileHandler = (file) => {
    upoadImageGallery({ variables: { file: file, restaurant: restaurantId } })


  }
  const onDeleteFile = (id) => {
    handleToggle()
    deleteImage({ variables: { id } })

  }
  return (
    <>

      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoadingUploadGalleryImage}

      >
        <CircularProgress color="inherit" style={{ width: "unset", height: "unset" }} />
      </Backdrop>
      <div className="">{
        restaurant?.myRestaurant?.gallery.map((image, i) =>
          <div className=" element_image"
            key={i}
            style={{ margin: "10px", position: "relative", display: 'inline-block' }}

          >
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loading}

            >
              <CircularProgress color="inherit" style={{ width: "unset", height: "unset" }} />
            </Backdrop>
            <img src={image.file}
              style={{
                height: "100%",
                width: "100%",
                position: "absolute",
                left: 0,
                top: 0
              }}
            />
            <span
              onClick={() => onDeleteFile(image.id)}
              style={{
                height: "32%",
                width: "32%",
                position: "absolute",
                top: 1,
                right: 1,
                backgroundColor: "#000",
                opacity: "0.7",
              }}
            >
              <AiOutlineDelete
                size="20"
                color="#fff"
              />
            </span>
          </div>
        )
      }
        <UploadImages
          controlled={true}
          // disabled={!edit}
          style={{ display: 'inline-block', margin: '0', width: 'auto' }}
          value={[]}
          onAddFile={onAddFileHandler}

        />

      </div>


    </>
  )

}


const Settings = () => {
  const [workHoursFields, setWorkHoursFields] = useState({
    monday: { open: true, from: "", to: "", reason: "" },
    tuesday: { open: true, from: "", to: "", reason: "" },
    wednesday: { open: true, from: "", to: "", reason: "" },
    thursday: { open: true, from: "", to: "", reason: "" },
    friday: { open: true, from: "", to: "", reason: "" },
    saturday: { open: true, from: "", to: "", reason: "" },
    sunday: { open: true, from: "", to: "", reason: "" },
  });

  const { isLoading: isLoadingCities, cities } = useCities();
  const { isLoading: isLoadingCategories, categories } = useCategories();
  const myRestaurant = useContext(RestaurantManagerContext);
  const {
    useFormMethods,
    ServerError,
    setErrors,
    setNonFieldErrors,
    ErrorMessage,
  } = useFormWithServerErrors();
  const {
    setValue,
    register,
    reset,
    watch,
    control,
    handleSubmit,
    formState: { errors },
  } = useFormMethods;
  const onCompletedHandler = ({ myRestaurant }) => {

    reset({
      ...myRestaurant,
      city: myRestaurant?.city.id,
      category: myRestaurant?.category.id,
      lat: myRestaurant?.location?.coordinates[0],
      long: myRestaurant?.location?.coordinates[1],
    });
  };
  const watchLat = watch('lat')
  const watchLong = watch('long')
  const onCompletedEditRestaurantHandler = ({ editRestaurant }) => {
    const { errors, success, restaurant } = editRestaurant;
    setdisable(false);
    if (success) {
      Toast.success(" Your profile has been updated");
      // setpage(page + 1);
    }
    // console.log("editRestaurant ==>", editRestaurant);
    //
  };
  const onErrorEditRestaurantHandler = (data) => { };
  const {
    loading,
    error,
    data: restaurant,
  } = useQuery(MY_RESTAURANT_SETTING_QUERY, {
    onCompleted: onCompletedHandler,
  });

  const [
    editRestaurant,
    {
      loading: isLoadingEditRestaurant,
      error: errorEditRestaurant,
      data: editRestaurantUserData,
    },
  ] = useMutation(EDIT_RESTAURANT, {
    onCompleted: onCompletedEditRestaurantHandler,
    onError: onErrorEditRestaurantHandler,
  });

  const [data, setData] = useState(false);
  const [newdata, setnewdata] = useState(false);
  const [locationLoading, setLocationLoading] = useState(false);
  const [edit, setedit] = useState(false);
  const [disable, setdisable] = useState(false);
  const initData = () => {
    setData(-1);
    getSettings((d) => {
      setData(d);
      setnewdata({ ...d });
    });
  };

  const CancelSettings = () => {
    setnewdata({ ...data });
    setdisable(false);
    setedit(false);
  };
  const onSave = () => {
    setdisable(1);
  };

  useEffect(() => {
    if (data === false) initData();
  });
  const onChangeEl = (e, key) => {
    if (!edit) return;
    let x = { ...newdata };
    x[key] = e.target.value;
    setnewdata(x);
  };

  const onChangeHoursData = (e, i) => {
    if (!edit) return;
    let x = { ...newdata };
    x.workhours[i] = e;
    setnewdata(x);
  };
  const onSubmit = (value) => {
    delete value['location']
    delete value?.workHours?.__typename
    setdisable(1);
    let image = null;

    if (value?.image.length > 0 && typeof value?.image !== "string") {
      image = value?.image[0];
    } else {
      image = null;
    }

    let lat = null
    let long = null
    if (value?.lat && value?.long) {
      lat = value?.lat
      long = value?.long
    }
    editRestaurant({
      variables: {
        ...value,
        image: image,
        editRestaurantId: myRestaurant?.id,
        lat: lat,
        long: long
      },
    });
  };
  function geoSuccess(position) {
    setLocationLoading(false)
    var lat = position.coords.latitude;
    var lng = position.coords.longitude;
    setValue('lat', lat)
    setValue('long', lng)

  }
  function geoError() {
    setLocationLoading(false)
    alert("Geocoder failed.");
  }
  const setLocation = () => {

    if (navigator.geolocation) {
      setLocationLoading(true)
      navigator.geolocation.getCurrentPosition(geoSuccess, geoError);
    } else {
      alert("Geolocation is not supported by this browser.");

    }


  }
  if (loading || isLoadingEditRestaurant) return <LoaderPage />;


  return (
    <>
      <div className="d-flex flex-grow-1 container_details ST" >

        <div className="flex-grow-1 element-1-cont" style={{ maxWidth: '100%' }}>
          <Accordion sx={{ width: '100%' }}>

            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h6" gutterBottom>  Restaurant Information</Typography>
            </AccordionSummary>
            <br />
            <AccordionDetails>
              <FormProvider {...useFormMethods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <h3
                      className="card-title"
                      style={{
                        padding: 0,
                        width: "fit-content",
                        margin: "15px auto",
                      }}
                    >
                      Main Image
                    </h3>

                    <br />
                    <div>
                      <UploadTheMainImage />
                      <br />
                      <br />
                      <input type='hidden'  {...register('lat')} />
                      <input type='hidden' {...register('long')} />
                    </div>
                    <div className="col-flex">
                      <div className="col-md-6">

                        <select  {...register("status")} className="form-control " >
                          <option value={'OPEN'} >Open</option>
                          <option value={'CLOSE'}>Close</option>
                        </select>
                        <br />
                      </div>
                      <div className="col-md-6">

                        <div className="">

                          <LoadingButton variant="contained" color="primary" size="large"
                            sx={{ mb: 2, maxWidth: 1000, width: 1 }}
                            loading={locationLoading}

                            className=" form-control " startIcon={<LocationOnRounded />} onClick={setLocation} >
                            {watchLat && watchLong ? 'change your location' : 'set new location'}
                          </LoadingButton>
                        </div>

                        <br />
                      </div>
                    </div>
                    <br />
                    <div className="col-12">
                      <input
                        {...register("name")}
                        type="text"
                        className="form-control"
                        placeholder="Restaurant name"
                      />
                      <ErrorMessage
                        errors={errors}
                        name="name"
                        render={({ message }) => (
                          <p className="text-danger text-sm">{message}</p>
                        )}
                      />
                      <br />
                    </div>
                    <div className="col-flex">
                      <div className="col-md-6">
                        <input
                          type="text"
                          className="form-control"
                          {...register("email", {
                            pattern: EMAIL_REGXR,
                            required: true,
                          })}
                          placeholder="Email Adress"
                        />
                        <ErrorMessage
                          errors={errors}
                          name="email"
                          render={({ message }) => (
                            <p className="text-danger text-sm">{message}</p>
                          )}
                        />

                        <br />
                      </div>
                      <div className="col-md-6">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Phone Number"
                          {...register("phone", { ...PHONE_NUMBER_VALIDATION })}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="phone"
                          render={({ message }) => (
                            <p className="text-danger text-sm">{message}</p>
                          )}
                        />

                        <br />
                      </div>
                    </div>
                    <div className="col-12">
                      <input
                        {...register("address", {
                          required: "Address is required",
                        })}
                        type="text"
                        className="form-control"
                        placeholder="Adress"
                      />

                      <ErrorMessage
                        errors={errors}
                        name="address"
                        render={({ message }) => (
                          <p className="text-danger text-sm">{message}</p>
                        )}
                      />
                      <br />
                    </div>
                    <div className="col-12">
                      <input
                        {...register("webSite")}
                        type="text"
                        className="form-control"
                        placeholder="Website"
                      />
                      <ErrorMessage
                        errors={errors}
                        name="webSite"
                        render={({ message }) => (
                          <p className="text-danger text-sm">{message}</p>
                        )}
                      />
                      <br />
                    </div>
                    <div className="col-12">
                      <select
                        {...register("category", {
                          required: "Category  is required",
                        })}
                        className="form-control "
                        placeholder="Category"
                      >
                        {categories.map((category, i) => (
                          <option value={category.id} key={i}>
                            {category.name}
                          </option>
                        ))}
                      </select>
                      <ErrorMessage
                        errors={errors}
                        name="category"
                        render={({ message }) => (
                          <p className="text-danger text-sm">{message}</p>
                        )}
                      />
                      <br />
                    </div>
                    <div className="col-12">
                      <select
                        {...register("city", {
                          required: "City  is required",
                        })}
                        className="form-control "
                        placeholder="City"
                      >
                        {/* <option value={null} defaultValue>city</option> */}
                        {cities.map((city, i) => (
                          <option value={city.id} key={i}>
                            {city.name}
                          </option>
                        ))}
                      </select>
                      <ErrorMessage
                        errors={errors}
                        name="city"
                        render={({ message }) => (
                          <p className="text-danger text-sm">{message}</p>
                        )}
                      />
                      <br />
                    </div>
                    <div className="col-12">
                      <input
                        {...register("averagePrice")}
                        type="text"
                        className="form-control"
                        placeholder="Average Price"
                      />
                      <ErrorMessage
                        errors={errors}
                        name="Average Price"
                        render={({ message }) => (
                          <p className="text-danger text-sm">{message}</p>
                        )}
                      />
                      <br />
                    </div>
                    <h3
                      className="card-title"
                      style={{
                        padding: 0,
                        width: "fit-content",
                        margin: "15px auto",
                      }}
                    >
                      Description
                    </h3>
                    <br />
                    <div className="col-12 none">
                      <textarea
                        rows="5"
                        style={{ resize: "none" }}
                        {...register("description")}
                        type="text"
                        className="form-control"
                        placeholder="Restaurant details"
                      />
                      <br />
                    </div>
                    <h3
                      className="card-title"
                      style={{
                        padding: 0,
                        width: "fit-content",
                        margin: "15px auto",
                      }}
                    >
                      Hours of works
                    </h3>
                    <br />
                    {Object.keys(workHoursFields).map((e, i) => (
                      <HoursWorkElement
                        key={i}
                        title={e}
                        newdata={workHoursFields[e]}
                        onChange={(j) => onChangeHoursData(j, e)}
                      />
                    ))}
                    <h3
                      className="card-title"
                      style={{
                        padding: 0,
                        width: "fit-content",
                        margin: "15px auto",
                      }}
                    >
                      Available tables
                    </h3>
                    <br />
                    <div className="d-flex justify-content-center">
                      <div className="table-element">
                        <label className="text-muted">All tables</label>
                        <Controller
                          name="numberOfGuests"
                          rules={{ required: "Tables number is required" }}
                          control={control}
                          defaultValue={restaurant?.myRestaurant.numberOfGuests}
                          error={true}
                          // defaultValue={data?.percentage}
                          render={({ field }) => <TablesElement {...field} />}
                        />
                      </div>
                      <div className="table-element">
                        <label className="text-muted">Reserved tables</label>
                        <TablesElement

                          value={restaurant?.myRestaurant.reservedTables}
                          onChange={(e) =>
                            setnewdata({
                              ...newdata,
                              tables: { ...newdata.tables, reserved: e },
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <br />
                  <br />
                  {edit ? (
                    <div
                      className="d-flex w-100 justify-content-center edit"
                      style={{ height: "45px" }}
                    >
                      {
                        <LoadingButton
                          variant="contained"
                          color="secondary"
                          style={{
                            color: "#fff",
                            flex: 1,
                            margin: "2px",
                            borderRadius: "10px",
                          }}
                          className="class_button"
                          disabled={disable}
                          onClick={() => {
                            CancelSettings();
                          }}
                        >
                          Cancel
                        </LoadingButton>
                      }
                      {
                        <LoadingButton
                          variant="contained"
                          type="submit"
                          color="primary"
                          style={{
                            color: "#fff",
                            flex: 1,
                            margin: "2px",
                            borderRadius: "10px",
                          }}
                          className="class_button"
                          disabled={disable}
                        >
                          Done
                        </LoadingButton>
                      }
                    </div>
                  ) : (
                    <div
                      className="d-flex w-100 justify-content-center edit"
                      style={{ height: "45px" }}
                    >
                      <LoadingButton
                        variant="contained"
                        color="primary"
                        className="class_button"
                        style={{
                          color: "#fff",
                          flex: 1,
                          margin: "2px",
                          borderRadius: "10px",
                        }}

                        disabled={disable}
                        onClick={() => {
                          setedit(true);
                        }}
                      >
                        Edit
                      </LoadingButton>
                    </div>
                  )}
                </form>
              </FormProvider >
            </AccordionDetails>
          </Accordion>
          <Accordion style={{ width: '100%' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h6" gutterBottom>  Restaurant gallery</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ width: '100%' }}>

              <Gallery restaurantId={myRestaurant.id} />

            </AccordionDetails>
          </Accordion>
          <Accordion style={{ width: '100%' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h6" gutterBottom>         Personal Information </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ width: '100%' }}>
              <div className="row">
                <PersonalInformaitontab />
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion style={{ width: '100%' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h6" gutterBottom>      Change the password</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ width: '100%' }}>
              <div className="row">
                <PasswordInformation />
              </div>
            </AccordionDetails>
          </Accordion>

        </div>
      </div>
    </>
  );
};

const HoursWorkElement = (props) => {
  const {
    register,
    watch,
    control,
    formState: { errors, isValid },
  } = useFormContext();
  const watchIsOpen = watch(`workHours.${props.title}IsOpen`, false);
  const watchFrom = watch(`workHours.${props.title}From`, "09:00");
  const watchTo = watch(`workHours.${props.title}To`, "22:00");
  return (
    <div
      className="col-12 d-flex align-items-center OurWorkIn"
      style={{ marginBottom: "20px" }}
    >
      <div className="card-title" style={{ width: "120px", padding: 0 }}>
        {props.title}
      </div>
      <Controller
        name={`workHours.${props.title}IsOpen`}
        control={control}
        render={({ field }) => <SwitchElement {...field} checked={watchIsOpen} />}
      />
      {watchIsOpen === true ? (
        <div className="from_to_reasen_ele">
          <input
            {...register(`workHours.${props.title}From`, {
              required: {
                value: watchIsOpen,
              },
              pattern: { TIME_REGXR },
              shouldUnregister: !watchIsOpen,
              deps: [`workHours.${props.title}IsOpen`],
            })}
            type="text"
            className="form-control"
            placeholder="from"
          />
          <input
            type="text"
            {...register(`workHours.${props.title}To`, {
              shouldUnregister: !watchIsOpen,
              pattern: { TIME_REGXR },
              deps: [`workHours.${props.title}IsOpen`],
            })}
            className="form-control"
            placeholder="to"
          />
        </div>
      ) : (
        <div className="from_to_reasen_ele">
          {/* <input
            style={{ width: "98%" }}
            onChange={(e) => {
              props.onChange({ ...props.newdata, reason: e.target.value });
            }}
            value={props.newdata.reason}
            type="text"
            className="form-control"
            placeholder="Reason"
          /> */}
        </div>
      )}
      <br />
    </div>
  );
};

const TablesElement = ({ value, onChange }) => {
  return (
    <div
      className="form-group position-relative InResflex"
      style={{ marginBottom: "20px" }}
    >
      <div className="elementPlus">
        <div
          className="element_plus"
          onClick={() => {
            onChange(value == 0 ? 0 : value - 1);
          }}
        >
          -
        </div>
        {value}
        <div
          className="element_plus"
          onClick={() => {
            onChange(value + 1);
          }}
        >
          +
        </div>
      </div>
      <input disabled="true" className="InrevTab" value={"Tables"} />
    </div>
  );
};
const PersonalInformationAdmin = ({ }) => {
  return (
    <PersonalInformaiton />
  )
}
const Password = () => {
  return (<></>)
}
export default Settings;
const TimeElement = ({ data, onChange, isOpen, selectedDate, onClick }) => {
  const [options, setOptions] = useState([])
  const startHour = moment().hour(9);
  const EndHour = moment().hour(23);

  useEffect(() => {
    const hours = []

    let sh = 9
    console.log(' val > now ', moment(selectedDate).isSame(moment(), 'day'))
    if (moment(selectedDate).isSame(moment(), 'day') && moment().hour() > 9)
      sh = moment().hour() + 1

    while (sh < 25) {

      const pHours = moment().hour(sh).minutes(0).format('H:mm')

      hours.push({ value: pHours, label: pHours })
      const halfHour = moment().hour(sh).minutes(30).format('H:mm')
      hours.push(
        {
          value:
            halfHour, label: halfHour
        }
      );


      sh++;
    }
    setOptions(hours)
  }, [selectedDate])


  return (
    <SearchSelect options={options} placeholder='Time'
      onClick={onClick}
      IconBefore={<BsHourglass className="iconSearch" />}
      onSelect={onChange} isOpen={isOpen} />
  );
};