import React from "react";
import "../../styles/client/Client.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function SocialButton(props)
{
    return (
        <button className="btn-social">
            <FontAwesomeIcon className="iconSocial" icon={props.icon} onClick={props.callback} />
            <span>{props.text}</span>
        </button>
    );
}