import { useState, useEffect, useRef } from "react";
import LoaderPage from "../../../../components/Shared/LoaderPage";
import { getGeneralsettingsFooter, EditGeneralsettingsFooter, } from "../../../../services/restaurant-admin/admin-dashboard";
import  Toast from "../../../../services/Toast";
import { Button } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";

const Footer =()=>{

    const [data,setData] = useState(false);
    const [selectedItem,setselectedItem] = useState(-1);
    const [DatatoShow,setDatatoShow] = useState([]);
    const [disable,setdisable] = useState(false);
    const [disabled,setdisabled] = useState(false);
    const [disablef,setdisablef] = useState(false);
    const detailsref = useRef(null)
    const [edit,setedit]=useState(false);
    const [description,setdescription]=useState(-1);
    const [copyright,setcopyright]=useState(-1);

    const initData = ()=>{
        setData(-1);
        getGeneralsettingsFooter(setData);
    }
    const setSelectedData = (i)=>{
        setedit(false);
        setselectedItem(i);
        setDatatoShow(data.menus[i]);
        if(selectedItem!=-1)
            detailsref.current.scrollIntoView(true);
        else
            setTimeout(() => {
                detailsref.current.scrollIntoView(true);
            }, 200);
    }
    const setAdd = ()=>{
        setedit(true);
        setselectedItem(-1);
        setDatatoShow({title:""});
        setselectedItem(-2);
        
        if(selectedItem!=-1)
            detailsref.current.scrollIntoView(true);
        else
            setTimeout(() => {
                detailsref.current.scrollIntoView(true);
            }, 200);
    }
   
    const DataIsValid = () =>{
        let f = true;
       
        if(!DatatoShow?.title || DatatoShow.title.length<2){
            Toast.error("Invalid value of title");
            f = false;
        }
        return f;
    }
    const onAddFooter = ()=>{
        if(!DataIsValid())
            return;
        setdisable(true);
        let x = data;
        if(!edit) x.menus[selectedItem]={...DatatoShow};
        else x.menus.push({...DatatoShow});
            EditGeneralsettingsFooter(x,()=>{
                setData({...x});
                Toast.success('Footer element edited successfully');
                CancelFooter();
            });
    }
    const onEditFooter = ()=>{
    }
    const onDeleteFooter = (i)=>{
        setdisable(true);
        let x = data;
        x.menus = x.menus.filter((d,j)=>i!=j);
        EditGeneralsettingsFooter(x,()=>{
            setData({...x});
            setselectedItem(-1);
            Toast.success('Footer element deleted successfully');
            CancelFooter();
            
        })
    }
    const CancelFooter = ()=>{
        setselectedItem(-1);
        setedit(false);
        setdisable(false);
        setdisabled(false);
        setdisablef(false);
        setdescription(-1);
        setcopyright(-1);
    }
    
    useEffect(()=>{
        if(data===false)
            initData();
    });
    const updateDesc = ()=>{
        setdisabled(true);
        let x = {...data,description:description};
        EditGeneralsettingsFooter(x,()=>{
            setData({...x});
            Toast.success('Element edited successfully');
            CancelFooter();
        });
    }
    const updatecopyright = ()=>{
        setdisabled(true);
        let x = {...data,copyright:copyright};
        EditGeneralsettingsFooter(x,()=>{
            setData({...x});
            Toast.success('Element edited successfully');
            CancelFooter();
        });
    }

    if(data === false || data === -1)
        return( <LoaderPage/>);
    return(
        <div className="d-flex flex-grow-1 container_details">
            <div className="flex-grow-1 element-1-cont" >
                <div className="">
                    <h3 className="card-title" style={{ padding: 0 }}>General settings</h3>
                    <h5 className="text-muted" style={{ padding: 0,opacity:.9 }}>Menu</h5><br/>
                </div>
                <br/>
                <div className="d-flex " style={{ flexDirection:"column" }}>
                   {data.menus.map((e,i)=><FooterElement  index={i} onEdit={()=>{setSelectedData(i);}} onDelete={()=>{ onDeleteFooter(i) }} data={e}  item={selectedItem!=i} />)}
                </div>
                <div className="d-flex">
                    <Button variant="contained" color="primary" onClick={()=>{setAdd();}}  style={{ color:"#fff",height:"40px",width:"130px" ,margin:"0 auto",borderRadius:"10px"}}>Add new</Button>
                </div>
                <div className="">
                    <h3 className="card-title" style={{ padding: 0 }}>Description</h3><br/>
                </div>
                <div className="input-group mb-3 d-flex" >
                    <textarea value={description===-1?data.description:description}  onChange={(e)=>{setdescription(e.target.value)}} type="text" style={{ resize: 'none' }}  rows="5" className="form-control" placeholder="Description" />
                </div>
                {   description===-1?
                   <div className="d-flex w-100 justify-content-center"> <LoadingButton variant="contained"color="primary" style={{ color:"#fff",flex:1,margin:"2px",borderRadius:"10px"}}  className="class_button"  onClick={()=>{setdescription(data.description);}}>Edit</LoadingButton></div>

                :
                    <div className="d-flex w-100 justify-content-center " style={{ height: "45px" }}>
                        {<LoadingButton variant="contained" color="secondary"  style={{ color:"#fff",flex:1,margin:"2px",borderRadius:"10px"}}  className="class_button"    disabled={disabled} onClick={()=>{setdescription(false)}} >Cancel</LoadingButton>}
                        {<LoadingButton variant="contained"color="primary" style={{ color:"#fff",flex:1,margin:"2px",borderRadius:"10px"}}  className="class_button" disabled={disabled} onClick={()=>{updateDesc();}}>Done</LoadingButton>}
                    </div>
                }
                <div className="">
                    <h3 className="card-title" style={{ padding: 0 }}>Copyright</h3><br/>
                    </div>
                    <div className="input-group mb-3 d-flex" >
                    <input value={copyright===-1?data.copyright:copyright}  onChange={(e)=>{setcopyright(e.target.value)}} type="text" style={{ resize: 'none' }}  rows="5" className="form-control" placeholder="copyright" />
                </div>
                {   copyright===-1?
                    <div className="d-flex w-100 justify-content-center"> <LoadingButton variant="contained"color="primary" style={{ color:"#fff",flex:1,margin:"2px",borderRadius:"10px"}}  className="class_button"  onClick={()=>{setcopyright(data.copyright);}}>Edit</LoadingButton></div>
                    :
                    <div className="d-flex w-100 justify-content-center " style={{ height: "45px" }}>
                        {<LoadingButton variant="contained" color="secondary"  style={{ color:"#fff",flex:1,margin:"2px",borderRadius:"10px"}}  className="class_button"    disablef={disablef} onClick={()=>{setcopyright(false)}} >Cancel</LoadingButton>}
                        {<LoadingButton variant="contained"color="primary" style={{ color:"#fff",flex:1,margin:"2px",borderRadius:"10px"}}  className="class_button" disablef={disablef} onClick={()=>{updatecopyright();}}>Done</LoadingButton>}
                    </div>
                }
            </div>
            <div ref={detailsref}  style={{display: selectedItem==-1 && "none",flex: 1,width: "100%", minWidth: "300px", marginLeft: "10px"}}>
                <h3 className="card-title" style={{ padding: 0 }}>{selectedItem==-2 ?'Add new Item' :'Edit'}</h3>
                <br />
                {
                    selectedItem!=-1 ?
                    <FooterDetails 
                        data={DatatoShow}  disableandLoad = {disable}  edit={edit}  setedit={setedit} setdata ={setDatatoShow}
                        doneButtonLabel={"Save"} cancelButtonLaber={'Cancel'} editButtonLabel={'Edit'} deleteButtonLaber={'Delete'}
                        doneButtonClick={onAddFooter} cancelButtonClick={CancelFooter} editButtonClick={onEditFooter} deleteButtonClick={onDeleteFooter}
                    />
                    : ''
                }
            </div>
        </div>
    )
}


const FooterElement = ({data,onEdit,onDelete,index})=>{
    
    return(
        <div>
            <div className="d-flex col-12   "  >
                <div className="d-flex flex-column flex-grow-1  " style={{ marginRight:"10px" }} >
                    <div className="d-flex flex-row flex-grow-1">
                        <div className="input-group mb-3 d-flex" >
                            <input value={data.title}  type="text" className="form-control" placeholder="Title" />
                        </div>
                    </div>
                </div>
                <div className="d-flex  align-items-start" >
                    <Button onClick={onDelete}   className="add_edit_btn_icon" color="error"  variant="contained" startIcon={<AiOutlineDelete style={{ margin:"auto" }} size="20" color="#fff" />}/>
                    <Button onClick={onEdit}  className="add_edit_btn_icon" variant="contained" startIcon={<AiOutlineEdit style={{ margin:"auto" }} size="20" color="#fff" />}/>
                </div>
            </div>
        </div>
    );
}

const FooterDetails = (props)=>{
    const AddorEdit = ()=>{
        props.doneButtonClick();
    }
    const onChange = (key,e)=>{
        let x = {...props.data};
        x[key]=e;
        props.setdata(x);
    }
        return(
            <div>
                <div className="number_label"></div>
                <div className="d-flex col-12 flex-column "  >
                    <div className="d-flex flex-grow-1 "  >
                        <div className="d-flex  flex-grow-1">
                            <div className="input-group mb-3 d-flex" >
                                <input value={props.data.title} onChange={(e)=>{onChange("title",e.target.value)}}  type="text" className="form-control" placeholder="Title" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex w-100 justify-content-center " style={{ height: "45px" }}>
                    {props?.cancelButtonLaber   && <LoadingButton variant="contained" color="secondary"  style={{ color:"#fff",flex:1,margin:"2px",borderRadius:"10px"}}  className="class_button"    disabled={props.disableandLoad} loading={props.disableandLoad==2}onClick={()=>{props.cancelButtonClick()}} >{props.disableandLoad==2?"":props.cancelButtonLaber}</LoadingButton>}
                    {props?.doneButtonLabel     && <LoadingButton variant="contained" style={{ color:"#fff",flex:1,margin:"2px",borderRadius:"10px"}}  className="class_button" disabled={props.disableandLoad} loading={props.disableandLoad==1} onClick={()=>{AddorEdit()}}>{props.disableandLoad==1?"":props.doneButtonLabel} </LoadingButton>}
                </div>
            </div>
        )
}

export default Footer;

