import { gql } from "@apollo/client";
export const GET_ALL_RESTURANT = gql`
  query allRestaurant {
    allRestaurant {
      objects {
        id
        name
        description
        image
        status
        category {
          id
          name
        }
        rating
      }
    }
  }
`;
export const GET_ALL_RESTURANT_PREMIUM = gql`
  query {
    allRestaurant(pageSize: 3) {
      objects {
        id
        name
        description
        image
        status
        category {
          id
          name
        }
        rating
      }
    }
  }
`;
export const GET_RESTURANT_BY_ID = gql`
  query restaurantById($id: ID!,  $offerMenuDate: Date, $offerfilters: offersSeachInput) {
    restaurantById(id: $id) {
      id
      status
      image
      name
      menu {
        title
        price
      }
      location
      address
      city {
        name
        id
      }
      offers {
        title
        description
        type
        percentage
      }
      category {
        id
        name
        image
      }
      extraServices {
        title
        price
      }
      description

      workHours {
        id
        mondayTo
        mondayFrom
        mondayIsOpen
        tuesdayFrom
        tuesdayTo
        tuesdayIsOpen
        wednesdayFrom
        wednesdayTo
        wednesdayIsOpen
        thursdayFrom
        thursdayTo
        thursdayIsOpen
        fridayFrom
        fridayTo
        fridayIsOpen
        saturdayFrom
        saturdayTo
        saturdayIsOpen
        sundayFrom
        sundayTo
        sundayIsOpen
      }
      reservationSet {
        id
        user {
          id
          lastName
          firstName
        }
        restaurant {
          id
          name
        }
        status
      }
      menuOffer(date: $offerMenuDate ){
        title
        percentage
      }
      timeOffers(filters: $offerfilters){
        count
        objects{
          id
          title
          percentage
          timeRangeFrom
          timeRangeTo
        }


      }
      reviews {
        pages
        hasNext
        hasPrev
        objects {
          id
          description
          rating

          user {
            id
            lastName
            firstName
            image
          }
        }
      }
      averagePrice
      rating
      foodRating
      ambienceRating
      serviceRating
      gallery {
        id
        file
      }
      distance
    }
  }
`;

export const GET_RESTUARNT_REVIEWS_QUERY = gql`
query restaurantReviews($pageSize: Int,$page:Int,$search:String,$restaurantId:ID!){
  restaurantReviews(pageSize: $pageSize,page:$page,search:$search,id:$restaurantId){
    pages
    hasNext
    count
    hasPrev
    objects {
      id
      description
      rating
      replies(pageSize: $pageSize,page: $page){
        page
        count
        pages

      }

      user {
        id
        lastName
        firstName
        image
      }
    }
  }
  
}

`
export const GET_RESTUARNT_REVIEW_BY_ID_QUERY = gql`
query reviewById($reviewBy:ID!, $pageSize:Int,$page:Int ){
  reviewById(id:$reviewBy){
      id
      description
      rating
      replies(pageSize: $pageSize,page: $page){
        page
        count
        pages
        objects{
          user{
            id
            lastName
            firstName
            image
          }
          message
        }
      }

      user {
        id
        lastName
        firstName
        image
      }
   
  }
  
}

`