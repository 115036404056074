import TimePicker from "./timepicker";

const App = ({ onChange, value }) => {
  const onChangeValue = (val) => {
    if (onChange) {

      onChange(val);
    }
  };

  return (
    <TimePicker
      color1="#57B894"
      placeholder="Time"
      className="time"
      onSet={onChangeValue}
      value={new Date(Date.parse(value))}
      onChange={onChangeValue}
    />
  );
};

export default App;
