import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import BandElement from "../../../components/client/bandElement";
import { FaCity } from "react-icons/fa";
import { BiBookAlt } from "react-icons/bi";
import { BsFillPersonFill } from "react-icons/bs";
import { IoRestaurant } from "react-icons/io5";
import {
  getDataForBand,
  OVERVIEWS_QUERY,
} from "../../../services/client/general";
import "../../../styles/client/Client.css";
import "../../../styles/client/ClientContact.css";

export default function AboutBand() {
  const [data, setData] = useState([]);
  const { isLoading, error, data: overViewData } = useQuery(OVERVIEWS_QUERY);
  useEffect(() => {
    setData(getDataForBand());
  }, []);
  if (overViewData)
    return (
      <div className="w-100 flex-container flex-center bg-green flex-gap-20 pv-1">
        <BandElement
          key="restaurant"
          label="Restaurants"
          icon={<IoRestaurant className="iconBand" />}
          value={overViewData.overview.totalRestaurants}
        />
        <BandElement
          key="Reservations"
          label="Reservations"
          icon={<BiBookAlt className="iconBand" />}
          value={overViewData.overview.totalReservations}
        />

        <BandElement
          key="Clients"
          label="Clients"
          icon={<BsFillPersonFill className="iconBand" />}
          value={overViewData.overview.totalUsers}
        />

        <BandElement
          key="Cities"
          label="Cities"
          icon={<FaCity className="iconBand" />}
          value={overViewData.overview.totalCities}
        />
      </div>
    );
  return (
    <React.Fragment>
      <div className="w-100 flex-container flex-center bg-green flex-gap-20 pv-1"></div>
    </React.Fragment>
  );
}
