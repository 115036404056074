import React from "react";
import "../../styles/client/Client.css";
import { BsChevronRight } from "react-icons/bs";
import { Avatar, Button } from "@mui/material";

export default function SearchElement(props)
{
    return (
        <div className="d-flex col-12 card element-search"  style={{ cursor:"pointer"}} onClick={()=>{props?.onClick()}}>
            <div className="d-flex flex-grow-1 " >
                <Avatar  className="avatar_img" src={props?.img} variant="rounded"/>
                <div className="d-flex justify-content-center flex-column">
                    <h3 className="card-title" style={{ padding: 0,margin:0 }}>{props?.title}</h3>
                    <div className="d-flex  align-items-lg-stretch"  style={{ flexDirection:"column",marginTop:"5px" }}>
                        <p className="text-muted mb-0">{props?.address}</p>
                        <p className=" mb-0">Capacity : <span className="green">{props?.capacity}</span></p>
                        <p className="text-muted mb-0" style={{ overflow:"hidden",maxHeight:"20px" }}><small>{props?.desc}</small></p>
                    </div>
                </div>
            </div>
            <div className="d-flex align-items-center" >
                <Button className="button_right_icon" variant="contained" startIcon={<BsChevronRight size="25" color="#fff" />}/>
            </div>
        </div>
    );
}

