import React from "react";
import "../../../styles/client/Client.css";
import "../../../styles/client/ClientLogin.css";
import Input from "../../../components/client/input";
import { validateGeneral } from "../../../validation/client";
import { useState } from "react";
import { ButtonPrimary } from "../../../components/client/button";
import { forget2 } from "../../../services/client/auth";
import Toast from "../../../services/Toast";
import ForgetSendRequest from "./forget1";
import { useLocation, useNavigate } from "react-router-dom";

export default function ForgetConfirmation(props) {
  const location = useLocation();

  const [isValidCode, setValidCode] = useState(false);
  const eligableForSending = () => {
    return isValidCode;
  };
  let navigate = useNavigate();
  const navigateTo = (params) => {
    let path = `/forget/confirmation/done`;
    navigate(path, params);
  };

  const returnTo = () => {
    let path = `/forget`;
    window.location.href = path;
  };

  const [code, setCode] = useState(null);
  if (location.state?.vkey == null || location.state?.vkey == undefined) {
    returnTo();
  }
  return (
    <React.Fragment>
      <div className="row m-4">
        <div className="col-lg-6 p-4">
          <div className="row">
            <div className="col-12">
              <span className="title-1">Reset your password</span>
            </div>

            <div className="col-12 centerText p-4">
              <h1>Check your email to continue </h1>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <img
            className="svgSize onlyDesktopSVG"
            src="/svg/Forget1.svg"
            alt="SVG"
          />
        </div>
      </div>
    </React.Fragment>
  );
}
