import { useState, useEffect, useRef } from "react";
import LoaderPage from "../../../../components/Shared/LoaderPage";
import { getGeneralsettingsCategory, AddGeneralsettingsCategory, EditGeneralsettingsCategory,DeleteGeneralsettingsCategory} from "../../../../services/restaurant-admin/admin-dashboard";
import  Toast from "../../../../services/Toast";
import { Button } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";

const Category =()=>{

    const [data,setData] = useState(false);
    const [selectedItem,setselectedItem] = useState(-1);
    const [DatatoShow,setDatatoShow] = useState([]);
    const [disable,setdisable] = useState(false);
    const detailsref = useRef(null)
    const [edit,setedit]=useState(false);

    const initData = ()=>{
        setData(-1);
        getGeneralsettingsCategory(setData);
    }
    const setSelectedData = (i)=>{
        setedit(false);
        setselectedItem(i);
        setDatatoShow(data[i]);
        if(selectedItem!=-1)
            detailsref.current.scrollIntoView(true);
        else
            setTimeout(() => {
                detailsref.current.scrollIntoView(true);
            }, 200);
    }
    const setAdd = ()=>{
        setedit(true);
        setselectedItem(-1);
        setDatatoShow({image:null,title:"",url:"",});
        setselectedItem(-2);
        
        if(selectedItem!=-1)
            detailsref.current.scrollIntoView(true);
        else
            setTimeout(() => {
                detailsref.current.scrollIntoView(true);
            }, 200);
    }
    
    const DataIsValid = () =>{
        let f = true;
        if(!DatatoShow?.title || DatatoShow.title.length<2){
            Toast.error("Invalid value of title");
            f = false;
        }
        if(!DatatoShow?.url || DatatoShow.url.length<2){
            Toast.error("Invalid value of url");
            f = false;
        }
        return f;
    }
    
    const onAddCategory = ()=>{
        if(!DataIsValid())
            return;
        setdisable(true);
        if(DatatoShow?.id ){
            EditGeneralsettingsCategory(DatatoShow,()=>{
                let x = data;
                x[selectedItem]= {...DatatoShow};
                setData([...x]);
                Toast.success('Category element edited successfully');
                CancelCategory();
            });
        }
        else {
            AddGeneralsettingsCategory(DatatoShow,(resp)=>{
                CancelCategory();
                let x = [...data];
                x.push(resp);
                setData([...x]);
                Toast.success('Category element added successfully');
            });
        }

    }
    const onEditCategory = ()=>{
    }
    const onDeleteCategory = (i)=>{
        setdisable(true);
       DeleteGeneralsettingsCategory(data[i],()=>{
            let x = data.filter((d,j)=>i!=j);
            setData([...x]);
            setselectedItem(-1);
            Toast.success('Category element deleted successfully');
            CancelCategory();
            
        })
    }
    const CancelCategory = ()=>{
        setselectedItem(-1);
        setedit(false);
        setdisable(false);
    }
    
    useEffect(()=>{
        if(data===false)
            initData();
    });

    if(data === false || data === -1)
        return( <LoaderPage/>);
    return(
        <div className="d-flex flex-grow-1 container_details">
           {data.length>0? 
           <div className="flex-grow-1 element-1-cont" >
                <div className="d-flex justify-content-between align-items-center">
                <div className="">
                    <h3 className="card-title" style={{ padding: 0 }}>General settings</h3>
                    <h5 className="text-muted" style={{ padding: 0,opacity:.9 }}>Category</h5><br/>
                </div>                    
                </div>
                <br/>
                <div className="d-flex " style={{ flexDirection:"column" }}>
                   {data.map((e,i)=><CategoryElement  index={i} onEdit={()=>{setSelectedData(i);}} onDelete={()=>{ onDeleteCategory(i) }} data={e}  item={selectedItem!=i} />)}
                   <Button variant="contained" color="primary" onClick={()=>{setAdd();}}  style={{ color:"#fff",height:"40px",width:"130px" ,margin:"0 auto",borderRadius:"10px"}}>Add new</Button>
                </div>
            </div>
            :
            <div  className="d-flex justify-content-center align-items-center card flex-grow-1" style={{ borderRadius: "10px",    minHeight: "50vh" }}>
                <h3>There is no data </h3>
                <br/>
                <Button variant="contained" color="primary" onClick={()=>{setAdd()}}  style={{ color:"#fff",height:"40px",width:"130px" ,margin:"20px 2px",borderRadius:"10px"}}>Add new</Button>
            </div>
            }
            <div ref={detailsref}  style={{display: selectedItem==-1 && "none",flex: 1,width: "100%", minWidth: "300px", marginLeft: "10px"}}>
                <h3 className="card-title" style={{ padding: 0 }}>{selectedItem==-2 ?'Add new Item' :'Edit'}</h3>
                <br />
                {
                    selectedItem!=-1 ?
                    <CategoryDetails 
                        data={DatatoShow}  disableandLoad = {disable}  edit={edit}  setedit={setedit} setdata ={setDatatoShow}
                        doneButtonLabel={"Save"} cancelButtonLaber={'Cancel'} editButtonLabel={'Edit'} deleteButtonLaber={'Delete'}
                        doneButtonClick={onAddCategory} cancelButtonClick={CancelCategory} editButtonClick={onEditCategory} deleteButtonClick={onDeleteCategory}
                    />
                    : ''
                }
            </div>
        </div>
    )
}

const CategoryElement = ({data,onEdit,onDelete,index})=>{
    
    return(
        <div>
            <div className="d-flex col-12"  >
                <div className="d-flex flex-grow-1  " style={{ marginRight:"10px" , marginBottom:"15px"}} >
                            <div className="element_image">
                                <div className="no_image" style={{ display:(data.image!=null?"none":"block" )}}  >
                                    <div className="plus_icon">+</div>
                                    <div>Upload vector</div>
                                </div>
                                <img className="with_image " style={{ display:(data.image==null?"none":"flex" )}}  />
                                </div><br/>
                            <div style={{ flex:1 }}>
                                <div className=" d-flex justify-content-start align-items-start position-relative" style={{ marginLeft:'10px',position:"relative",height:'fit-content',marginBottom:'20px'}} >
                                    <input value={data.title}  type="text" className="form-control" placeholder="Title" />
                                </div>
                                <div className=" d-flex justify-content-start align-items-start position-relative" style={{ marginLeft:'10px',position:"relative",height:'fit-content',marginBottom:'20px'}} >
                                    <input value={data.url}  type="text"  className="form-control" placeholder="Price" style={{ paddingLeft:'78px' }} />
                                    <div className="url_clss">{`${'tabla.ma/ '}`} </div>
                                </div>
                            </div>
                </div>
                <div className="d-flex  align-items-start" >
                    <Button onClick={onDelete}   className="add_edit_btn_icon" color="error"  variant="contained" startIcon={<AiOutlineDelete style={{ margin:"auto" }} size="20" color="#fff" />}/>
                    <Button onClick={onEdit}  className="add_edit_btn_icon" variant="contained" startIcon={<AiOutlineEdit style={{ margin:"auto" }} size="20" color="#fff" />}/>
                </div>
            </div>
        </div>
    );
}

const CategoryDetails = (props)=>{
    const AddorEdit = ()=>{
        props.doneButtonClick();
    }
    const onChange = (key,e)=>{
        let x = {...props.data};
        x[key]=e;
        props.setdata(x);
    }
    const onChangeImage = (e)=>{
        var file = e.target.files[0];
        let x = {...props.data};
        x["image"]=file;
        props.setdata(x);
        if(file){
            var reader = new FileReader();
            reader.onload = function(){
                let d = e.target.parentNode;
                d.querySelector('.with_image').src= reader.result;
            }
            reader.readAsDataURL(file);
        }
    }
        return(
            <div>
                <div className="number_label"></div>
                <div className="d-flex col-12 flex-column "  >
                    <div className="element_image">
                        <input type="file" onChange={(e)=>onChangeImage(e)}  className="input_image" accept="image/*"/>
                        <div className="no_image" style={{ display:(props.data.image!=null?"none":"block" )}}  >
                            <div className="plus_icon">+</div>
                            <div>Upload vector</div>
                        </div>
                        <img className="with_image " src={props.data.image}  style={{ display:(props.data.image==null?"none":"flex" )}}  />
                    </div><br/>
                    <div className=" d-flex justify-content-start align-items-start position-relative" style={{ marginLeft:'10px',position:"relative",height:'fit-content',marginBottom:'20px'}} >
                        <input value={props.data.title} onChange={(e)=>{onChange("title",e.target.value)}}  type="text" className="form-control" placeholder="Title" />
                    </div>
                     <div className=" d-flex justify-content-start align-items-start position-relative" style={{ marginLeft:'10px',position:"relative",height:'fit-content',marginBottom:'20px'}} >
                        <input value={props.data.url} onChange={(e)=>{onChange("url",e.target.value)}}   type="text"  className="form-control"  style={{ paddingLeft:'78px' }} />
                        <div className="url_clss">{`${'tabla.ma/ '}`} </div>
                    </div>
                </div>
                <div className="d-flex w-100 justify-content-center " style={{ height: "45px" }}>
                    {props?.cancelButtonLaber   && <LoadingButton variant="contained" color="secondary"  style={{ color:"#fff",flex:1,margin:"2px",borderRadius:"10px"}}  className="class_button"    disabled={props.disableandLoad} loading={props.disableandLoad==2}onClick={()=>{props.cancelButtonClick()}} >{props.disableandLoad==2?"":props.cancelButtonLaber}</LoadingButton>}
                    {props?.doneButtonLabel     && <LoadingButton variant="contained" style={{ color:"#fff",flex:1,margin:"2px",borderRadius:"10px"}}  className="class_button" disabled={props.disableandLoad} loading={props.disableandLoad==1} onClick={()=>{AddorEdit()}}>{props.disableandLoad==1?"":props.doneButtonLabel} </LoadingButton>}
                </div>
            </div>
        )
}

export default Category;

