import { useState, useEffect } from "react";
import Avatar from "../../../components/Shared/profile";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import LoaderPage from "../../../components/Shared/LoaderPage";
import useFormWithServerErrors from "../../../hooks/useFormWithServerErrors";
import { EMAIL_REGXR, NAME_REGXR, PHONE_NUMBER_VALIDATION } from "../../../validation/regexr";
import { useMutation, useQuery } from "@apollo/client";
import { CURRENT_USER } from "../../../services/Queries/UserQuery";
import { UPDATE_ACCOUNT } from "../../../services/mutations/userMustation";
import useCities from "../../../hooks/useCities";
//Todo: add city and Country fields to the form and image
const PersonalInformaitontab = () => {
    //    const [data, setData] = useState(false);
    // const [newdata, setnewData] = useState(false);
    // const [save, setedit] = useState(false);
    // const [disable, setdisable] = useState(false);
    const {
        register,
        setErrors,
        ServerError,
        ErrorMessage,
        getValues,
        setValue,
        handleSubmit,
        reset,
        formState: { isDirty, errors },
    } = useFormWithServerErrors();
    const onCompletedHandlerQuery = ({ me }) => {
        const values = getValues();
        if (me)
            for (const key of Object.keys(values)) {
                if (key == "city") {
                    setValue(key, me[key]["id"]);

                    continue;
                }
                setValue(key, me[key]);
            }
    };
    const onErrorHandlerQuery = (data) => { };

    const onErrorHandlerMutation = (data) => {
        console.log(' onErrorHandlerMutation', data)
    };
    const { isLoading: isLoadingCities, cities } = useCities();
    const {
        loading: loadingQuery,
        error: errorQuery,
        data: dataQuery,
        refetch: { refetchUser },
    } = useQuery(CURRENT_USER, {
        onCompleted: onCompletedHandlerQuery,
        onError: onErrorHandlerQuery,
        fetchPolicy: "network-only",
    });
    const onCompletedHandlerMutation = ({ updateAccount }) => {
        console.log(' onCompletedHandlerMutation', updateAccount)
        // const { me } = data
        const { success, errors } = updateAccount
        const values = getValues();
        if (success) {

            for (const key of Object.keys(values)) {
                if (key == "city") {
                    setValue(key, me[key]["id"]);

                    continue;
                }
                setValue(key, me[key]);
            }
            refetchUser();

        } else {
            setErrors(errors)
        }
    };
    const [
        updateAccount,
        { loading: loadingMutation, error: errorMutation, data: dataMutation },
    ] = useMutation(UPDATE_ACCOUNT, {
        onCompleted: onCompletedHandlerMutation,
        onError: onErrorHandlerMutation,
    });
    const handleOnSubmit = (values) => {
        updateAccount({ variables: { ...values } });
    };


    if (loadingMutation || loadingQuery) return <LoaderPage />;

    return (
        <div className="d-flex flex-grow-1 ">
            <div className="">
                <form onSubmit={handleSubmit(handleOnSubmit)}>
                    <Avatar
                        firstName={dataQuery?.me?.firstName}
                        lastName={dataQuery?.me?.lastName}
                        imageUrl={dataQuery?.me?.image}
                    />
                    <ServerError itemClassName='text-danger text-sm' />

                    <div className="col-inp">
                        <div className="col-d">
                            <input
                                type="text"
                                {...register("firstName", {
                                    pattern: NAME_REGXR,
                                })}
                                className="form-control"
                                placeholder="First name"
                            />
                            <ErrorMessage
                                errors={errors}
                                name="firstName"
                                render={({ message }) => (
                                    <p className="text-danger">{message}</p>
                                )}
                            />
                        </div>
                        <div className="col-d">
                            <input
                                type="text"
                                {...register("lastName", {
                                    pattern: NAME_REGXR,
                                })}
                                className="form-control"
                                placeholder="Last name"
                            />
                            <ErrorMessage
                                errors={errors}
                                name="lastName"
                                render={({ message }) => (
                                    <p className="text-danger">{message}</p>
                                )}
                            />
                        </div>
                    </div>
                    <div className="col-inp">
                        <div className="col-d">
                            <input
                                type="text"
                                {...register("email", {
                                    pattern: EMAIL_REGXR,
                                })}
                                className="form-control"
                                placeholder="Email"
                            />
                            <ErrorMessage
                                errors={errors}
                                name="Email"
                                render={({ message }) => (
                                    <p className="text-danger">{message}</p>
                                )}
                            />
                        </div>
                        <div className="col-d">
                            <input
                                type="text"
                                {...register("phone", {
                                    ...PHONE_NUMBER_VALIDATION

                                })}
                                className="form-control"
                                placeholder="phone number"
                            />
                            <ErrorMessage
                                errors={errors}
                                name="phone"
                                render={({ message }) => (
                                    <p className="text-danger">{message}</p>
                                )}
                            />
                        </div>
                    </div>
                    <div className="col-inp">
                        <div className="col-d">
                            <select {...register("city")} className="form-control ">
                                {cities.map((city, i) => (
                                    <option value={city.id} key={i}>
                                        {city.name}
                                    </option>
                                ))}
                            </select>

                            <ErrorMessage
                                errors={errors}
                                name="city"
                                render={({ message }) => (
                                    <p className="text-danger text-sm">{message}</p>
                                )}
                            />
                        </div>
                        <div className="col-d">
                            <input
                                type="text"
                                {...register("country")}
                                className="form-control"
                                placeholder="Country"
                            />
                            <ErrorMessage
                                errors={errors}
                                name="country"
                                render={({ message }) => (
                                    <p className="text-danger text-sm">{message}</p>
                                )}
                            />
                        </div>
                    </div>

                    <Stack spacing={2} direction="row" className="group-btn">
                        {isDirty && (
                            <Button
                                variant="outlined"
                                onClick={() =>
                                    reset({
                                        lastName: dataQuery.me.lastName,
                                        firstName: dataQuery.me.firstName,
                                        phone: dataQuery.me.phone,
                                        country: dataQuery.me.country,
                                        email: dataQuery.me.email,
                                    })
                                }
                            >
                                Cancel
                            </Button>
                        )}
                        <Button
                            variant="contained"
                            color="success"
                            disabled={!isDirty}
                            type="submit"
                        >
                            Save
                        </Button>
                    </Stack>
                </form>
            </div>
        </div>
    );
};

export default PersonalInformaitontab;
