import React from "react";
import "../../../styles/client/Client.css";
import "../../../styles/client/ClientLogin.css";
import Input from "../../../components/client/input";
import {validateGeneral,validateConfirmationPassword} from "../../../validation/client";
import { useState } from "react";
import { ButtonPrimary } from "../../../components/client/button";
import { forget2 } from "../../../services/client/auth";
import Toast from '../../../services/Toast';
import ForgetSendRequest from "./forget1";
import {useLocation} from 'react-router-dom';


export default function ForgetDone(props)
{
    const location = useLocation();

    const [isValidPassword,setValidPassword]=useState(false);
    const [isValidPassworConfirmed,setValidPasswordConfirmed]=useState(false);
    const eligableForSending = ()=>{

        return isValidPassword&&isValidPassworConfirmed;
    };

    const returnTo = () =>{ 
        let path = `/forget`; 
        window.location.href=path;
    }

    const [password,setPassword]=useState(null);
    const [passwordConfirmed,setPasswordConfirmed]=useState(null);

    if(location.state?.vkey==null||location.state?.vkey==undefined)   
    {
        returnTo();
    }
    return (
        <React.Fragment>
            <div className="row m-4">
                <div className="col-lg-6 p-4">
                    <div className="row">
                        <div className="col-12">
                            <span className="title-1">Reset your password</span>
                        </div>
                        <form>
                        <div className="col-12 ml-4 mr-4 mt-2">
                                <Input id="password"
                                    type="password" 
                                    placeholder="Password" 
                                    onChange={(evt)=>{setPassword(evt.target.value);}}
                                    shouldSendData={setValidPassword}
                                    validateFunc={validateGeneral}/>
                            </div>
                            <div className="col-12 ml-4 mr-4 mt-2">
                                <Input id="confirmedpassword"
                                    type="password" 
                                    placeholder="Password Confirmation" 
                                    onChange={(evt)=>{setPasswordConfirmed(evt.target.value);}}
                                    shouldSendData={setValidPasswordConfirmed}
                                    validateFunc={(v)=>validateConfirmationPassword(v,password)}/>
                            </div>
                            <div className="col-12 centerText">
                                <ButtonPrimary style={{width:"auto"}} className="mx-auto" text="Change" 
                                click={(event)=>{
                                    event.preventDefault();
                                    if(password!=passwordConfirmed)
                                    {
                                        Toast.error("Mot de passe ne correspant pas à la confirmation");
                                        return;
                                    }
                                    if(!eligableForSending())
                                    {
                                        Toast.info("Veuillez saisir tous les champs");
                                        return;
                                    }
                                    //forget1({code:code,password:password,username:null});
                                }}/>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="col-lg-6">
                    <img className="svgSize onlyDesktopSVG" src="/svg/Forget1.svg" alt="SVG" />
                </div>
            </div>
        </React.Fragment>
    )
}