import React, { useEffect, useState, useRef } from "react";
import Calendar from "../Calendar/Calendar";
import Time from "../../components/Calendar/Time";
import { useNavigate } from "react-router-dom";
import TimeInput from "../../components/TimeInput"
import SearchSelect from "../../components/SearchSelect"
import "../../styles/client/Client.css";
import { BsHourglass, BsPerson } from "react-icons/bs";
import moment from "moment";
import { useOnClickOutside } from "../../hooks/useDetectOutsideClick";

export default function SearchInputGroup(props) {
  const navigate = useNavigate();
  const searchRef = useRef(null);
  const [isDateOpen, setIsDateOpen] = useState(true)
  const [isTimeOpen, setIsTimeOpen] = useState(false)
  const [isGuestsOpen, setIsGuestsOpen] = useState(false)
  const [selectedDate, setSelectedDate] = useState(false)
  useOnClickOutside(searchRef, () => { setIsTimeOpen(null); setIsGuestsOpen(null) })
  const [book, setBook] = useState({
    text: props?.bookData?.text,
    city: props?.bookData?.city,
    date: props?.bookData?.date,
    time: props?.bookData?.time,
    guests: props?.bookData?.guests,
    category: props?.bookData?.category,
  });
  const getSearchValueHandler = (event) => {
   
    if (props.getSearchValue) {

      props.getSearchValue(book);

    }
  };

  const onChangeDate = (val) => {


    setSelectedDate(val)
    setBook({ ...book, date: val })
    setIsTimeOpen(true)
    setIsDateOpen(false)
  }
  const onChangeTimeHandler = (val) => {

    setIsTimeOpen(false)
    setIsGuestsOpen(true)
    setBook({ ...book, time: val })

  }
  const onChangGuestsHandler = (val) => {
    setBook({ ...book, guests: val })
    setIsGuestsOpen(false)
    if (props.getSearchValue) {
      props.getSearchValue(book);
    }
  }
  const onClickGeustHandler = () => {
    setIsTimeOpen(null)
    setIsGuestsOpen(null)
  }
  const onClickTimeHandler = () => {
    setIsGuestsOpen(false)
    setIsDateOpen(false)
  }
  return (
    <div className="flex-container" ref={searchRef}>
      <div className="date-picker flex-6-sr container row mx-auto-adapted" style={{
        border: '1px solid #aaa7a7', position: 'sticky',
        top: 0
      }}>



        <div className="col-sm  my-auto date-grp">
          <div className="date-time">
            <Calendar
              onOpen={() => setIsDateOpen(true)}
              onClose={() => setIsDateOpen(false)}
              isOpen={isDateOpen}
              defaultValue={book.date}
              onChange={onChangeDate}
            />
          </div>
        </div>

        {/* <div className="col-sm  my-auto date-grp">
          <div className="date-time">
            <Time
              value={book?.time}
              onChange={(val) => {
                setBook({ ...book, time: val });
              }}
            />
          </div>
        </div> */}
        <div className="col-sm  my-auto date-grp">
          <TimeElement isOpen={isTimeOpen} onChange={onChangeTimeHandler} selectedDate={selectedDate} onClick={onClickTimeHandler} />
        </div>
        {/* <div className="col-sm my-auto search-grp">
          <i className="icon-ic24-search"></i>
          <select
            className="custom-select city-select"
            id="inputGroupSelect02"
            onChange={(val) => {
              setBook({ ...book, category: val.target.value });
            }}
            value={book?.category}
          >
            <option selected={!book?.category} disabled>
              category
            </option>
            {useGetCategories()?.map((e) => {
              return (
                <option
                  selected={book?.category == e?.id}
                  key={e?.id}
                  value={e?.id}
                >
                  {e?.name}
                </option>
              );
            })}
          </select>
        </div> */}
        <div className="col-sm my-auto ">
          <GeustElement
            isOpen={isGuestsOpen}
            data={book?.guests}
            // isGuestsOpen={isGuestsOpen}
            onChange={onChangGuestsHandler}
            onClick={onClickGeustHandler}

          />
        </div>


        {/* <div className="col-sm my-auto city-grp">
          <div className="city-grp-wrapper">
            <i className="fal fa-building"></i>
            <select
              className="custom-select city-select"
              id="inputGroupSelect02"
              onChange={(val) => {
                setBook({ ...book, city: val.target.value });
              }}
              value={book?.city}
            >
              <option selected={!book?.city} disabled>
                City
              </option>
              {useGetCities()?.map((e) => {
                return (
                  <option
                    selected={book?.city == e?.id}
                    key={e?.id}
                    value={e?.id}
                  >
                    {e?.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div> */}
        <div className="col-sm my-auto book-grp">
          <button
            type="submit"
            className="btn btn-success btn-book mx-auto mb-2"
            onClick={getSearchValueHandler}
          >
            Book
          </button>
        </div>
      </div>
    </div>
  );
}
// const GeustElement = ({ data, onChange }) => {
//   return (
//     <div className="guest">
//       <i className="far fa-user"></i>
//       <span className="a1">Guests</span>
//       <div className="contour">
//         <i
//           className="fal fa-minus"
//           onClick={() => {
//             onChange(data == 0 ? 0 : data - 1);
//           }}
//         ></i>
//         <a>{data}</a>
//         <i
//           className="fal fa-plus"
//           onClick={() => {
//             onChange(data + 1);
//           }}
//         ></i>
//       </div>
//     </div>
//   );
// };

const GeustElement = ({ data, onChange, isOpen, onClick }) => {

  const [options, setptions] = useState([{ label: '1 person', value: 1 }, { label: '2 person', value: 2 }, { label: '3 person', value: 3 },
  { label: '4 person', value: 4 }, { label: '5 person', value: 5 }, { label: '6 person', value: 6 }, 
  { label: '7 person', value: 7},{ label: '8 person', value: 8}, {label: '9 person', value: 9}, {label: '10 person', value: 10}



])


  return (
    <SearchSelect options={options}
      onClick={onClick}
      placeholder='Number of people' IconBefore={<BsPerson className="iconSearch" />} onSelect={onChange} isOpen={isOpen} />
  );
};


const TimeElement = ({ data, onChange, isOpen, selectedDate, onClick }) => {
  const [options, setOptions] = useState([])
  const startHour = moment().hour(9);
  const EndHour = moment().hour(23);

  useEffect(() => {
    const hours = []

    let sh = 9
    console.log(' val > now ', moment(selectedDate).isSame(moment(), 'day'))
    if (moment(selectedDate).isSame(moment(), 'day') && moment().hour() > 9)
      sh = moment().hour() + 1

    while (sh < 25) {

      const pHours = moment().hour(sh).minutes(0).format('H:mm')

      hours.push({ value: pHours, label: pHours })
      const halfHour = moment().hour(sh).minutes(30).format('H:mm')
      hours.push(
        {
          value:
            halfHour, label: halfHour
        }
      );


      sh++;
    }
    setOptions(hours)
  }, [selectedDate])


  return (
    <SearchSelect options={options} placeholder='Time'
      onClick={onClick}
      IconBefore={<BsHourglass className="iconSearch" />}
      onSelect={onChange} isOpen={isOpen} />
  );
};