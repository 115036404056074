import { useQuery } from "@apollo/client";
import { RESTAURANTS_SEARCH_QUERY } from "../Queries/RestaurantQuery";

export const useRestaurantSearch = ({variables}) => {
  const { loading, error, data, refetch} = useQuery(RESTAURANTS_SEARCH_QUERY, {
    variables: variables,
  });

  return { loading, error, data,refetch };
};
