import { gql } from "@apollo/client";

export const EDIT_RESERVATION = gql`
mutation EditReservation($editReservationId: ID!, $status: String) {
    editReservation(id: $editReservationId, status: $status) {
      success
      errors
      reservation {
        datetime
        numberOfGuests
        restaurant {
          name
          image
          description
        }
        status
      }
    }
  }
`;
