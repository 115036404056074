import React from "react";
import { ButtonPrimary } from "../../../components/client/button";
import "../../../styles/client/Client.css";
import TemplateStatic from "./template";
import { useNavigate,useLocation } from "react-router-dom";
export default function ReservationDone()
{

    const location = useLocation();
    
    const navigate = useNavigate(); 

    const navigateTo = (path,params) =>{ 
        navigate(path,params);
    }
    const returnTo = () =>{ 
        let path = `/`; 
        window.location.href=path;
    }
    if(location.state?.vkey==null||location.state?.vkey==undefined)   
    {
        returnTo();
    }

    const Comp = () => {return (<>
        <div className="row centerText">
            <div className="col-12">
                <span className="title-1">Your reservation is confirmed</span>
            </div>
            <div className="col-12">
                <span className="textLow-1">It is a long established fact that a reader will be distracted by the readable content.</span>
            </div>
            <div className="col-12">
            <ButtonPrimary style={{width:"auto"}} className="mx-auto" text="Back to home" 
                click={(event)=>
                {
                    navigateTo("/",{});
                }}/>
            </div>
        </div>
    </>)};
    return (
        <React.Fragment>
            <TemplateStatic comp={<Comp />}/>
        </React.Fragment>
    )
}