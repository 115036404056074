import { gql } from "@apollo/client";

export const ALL_RESTAURANT_QUERY = gql`
  query AllRestaurant(
    $search: String
    $pageSize: Int
    $page: Int
    $orderBy: RestaurantOrderByInput
    $city: ID
    $categories: [ID]
    $long: Float
    $lat: Float
    $distance: Int
    $filter: RestaurantPublicSearchInput
  ) {
    allRestaurant(
      search: $search
      pageSize: $pageSize
      page: $page
      orderBy: $orderBy
      city: $city
      categories: $categories
      long: $long
      lat: $lat
      distance: $distance
      filter: $filter
    ) {
      page
      pages
      count
      hasNext
      hasPrev
      objects {
        id
        name
        image
        address
        rating
        popularity
        description
      }
    }
  }
`;
export const RESTAURANTS_SEARCH_QUERY = gql`
  query AllRestaurant(
    $search: String
    $pageSize: Int
    $page: Int
    $orderBy: RestaurantOrderByInput
    $city: ID
    $long: Float
    $lat: Float
    $distance: Int
    $filter: RestaurantPublicSearchInput
    $offerMenuDate: Date
    $offerfilters: offersSeachInput
  ) {
    allRestaurant(
      search: $search
      pageSize: $pageSize
      page: $page
      orderBy: $orderBy
      city: $city
      long: $long
      lat: $lat
      distance: $distance
      filter: $filter
    ) {
      page
      pages
      count
      hasNext
      hasPrev
      objects {
        id
        name
        averagePrice
        status
        image
        location
        
        reviewsCount
        address
        city {
          name
        }
        category {
          name
        }
        menuOffer(date: $offerMenuDate ){
          title
          percentage
        }
        timeOffers(filters: $offerfilters){
       
          count
          objects{
            title
            percentage
            timeRangeFrom
            timeRangeTo
          }
  

        }
        description
        rating
        popularity
      }
    }
  }
`;

export const RESTAURANT_EXTRA_SERVICES_QUERY = gql`
  query RestaurantExtraServices($restaurantExtraServicesId: ID) {
    restaurantExtraServices(id: $restaurantExtraServicesId) {
      page
      pages
      count
      hasNext
      hasPrev
      objects {
        id
        title
        price
      }
    }
  }
`;
export const RESTAURANT_MENU_QUERY = gql`
  query RestaurantMenu(
    $restaurantMenuId: ID
    $search: String
    $pageSize: Int
    $page: Int
  ) {
    restaurantMenu(
      id: $restaurantMenuId
      search: $search
      pageSize: $pageSize
      page: $page
    ) {
      page
      pages
      count
      hasNext
      hasPrev
      objects {
        id
        title
        price
        description
      }
    }
  }
`;

export const RESTAURANT_OFFERS_QUERY = gql`
query RestaurantOffers($restaurantOffersId: ID) {
  restaurantOffers(id: $restaurantOffersId) {
    page
    pages
    count
    hasNext
    hasPrev
    objects {
      id
      title
      description
      type
      percentage
      validFromDate
      validToDate
      timeRangeFrom
      timeRangeTo
      createAt
    }
  }
}
`;
export const RESTAURANT_REVIEWS_QUERY = gql`
query RestaurantReviews($restaurantReviewsId: ID, $pageSize: Int, $page: Int) {
  restaurantReviews(id: $restaurantReviewsId, pageSize: $pageSize, page: $page) {
    page
    count
    pages
    hasNext
    hasPrev
    objects {
      foodRating
      serviceRating
      ambienceRating
      description
      rating
      user {
        firstName
        lastLogin
        lastName
        image
        email
      
      }
      replies{
        count
      }
      id
      createdAt
      editAt
    }
  }
}
`;
export const MY_RESTAURANT_SETTING_QUERY = gql`
  query MyRestaurant {
    myRestaurant {
      name
      phone
      status
      location
      averagePrice
      email
      image
      webSite
      address
      description
      reservedTables
      numberOfGuests
      category {
        id
        name
      }
      city {
        id
        name
      }

      workHours {
        mondayTo
        mondayFrom
        mondayIsOpen
        tuesdayFrom
        tuesdayTo
        tuesdayIsOpen
        wednesdayFrom
        wednesdayTo
        wednesdayIsOpen
        thursdayFrom
        thursdayTo
        thursdayIsOpen
        fridayFrom
        fridayTo
        fridayIsOpen
        saturdayFrom
        saturdayTo
        saturdayIsOpen
        sundayFrom
        sundayTo
        sundayIsOpen
      }
    }
  }
`;

export const MY_RESTAURANT_SETTING_GALLERY_QUERY = gql`
  query MyRestaurant {
    myRestaurant {
      gallery{
        id
        file
      }

    }
  }
`;
export const CHECK_RESERVATION_QUERY = gql`
  query checkReservation($reservation:CheckReservationSearchType) {
    checkReservation(reservation:$reservation) {
      isAvailable

    }
  }
`;
