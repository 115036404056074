import { useState, useEffect, useRef } from "react";
import LoaderPage from "../../../../components/Shared/LoaderPage";
import { getGeneralsettingsMenu,EditGeneralsettingsMenu} from "../../../../services/restaurant-admin/admin-dashboard";
import  Toast from "../../../../services/Toast";
import { Button } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";

const Menu =()=>{

    const [data,setData] = useState(false);
    const [selectedItem,setselectedItem] = useState(-1);
    const [DatatoShow,setDatatoShow] = useState([]);
    const [disable,setdisable] = useState(false);
    const detailsref = useRef(null)
    const [edit,setedit]=useState(false);
    const [element,setelement]=useState(null);
    const [ind,setind]=useState(null);

    const CancelMenu = ()=>{
        setselectedItem(-1);
        setedit(false);
        setdisable(false);setind(null);setelement(null);setDatatoShow([]);
    }
    
    const initData = ()=>{
        setData(-1);
        getGeneralsettingsMenu((r=>{setData(r);console.log(r)}));
    }
    const setSelectedData = (i,key,j)=>{
        setedit(false);
        setselectedItem(i);
        setelement(key);
        setind(i);
        console.log(key,i)
        if(key=='Header')
           {setDatatoShow(data[key][i]);}
        else setDatatoShow(data[key][i]["menu"][j]);
        
        if(selectedItem!=-1)
            detailsref.current.scrollIntoView(true);
        else
            setTimeout(() => {
                detailsref.current.scrollIntoView(true);
            }, 200);
    }
    const setAdd = (e,i)=>{
        setelement(e);setind(i);
        setedit(true);
        setselectedItem(-1);
        setDatatoShow({url:"",title:""});
        setselectedItem(-2);
        
        if(selectedItem!=-1)
            detailsref.current.scrollIntoView(true);
        else
            setTimeout(() => {
                detailsref.current.scrollIntoView(true);
            }, 200);
    }
    
    const DataIsValid = () =>{
        let f = true;
        if(!DatatoShow?.url || DatatoShow.url.length<1){
            Toast.error("Invalid value of url");
            f = false;
        }
        if(!DatatoShow?.title || DatatoShow.title.length<2){
            Toast.error("Invalid value of title");
            f = false;
        }
        return f;
    }
    const onAddMenu = ()=>{
        if(!DataIsValid())
            return;
        setdisable(true);
        let x =data;
        console.log('***',ind,element,x[element],selectedItem)
        if(selectedItem!==-2){
            if(element=="Header")
                x[element][selectedItem] = {...DatatoShow};
            else x[element][ind]["menu"][selectedItem] = {...DatatoShow};
        }
        else {
            if(ind==-1){
                x[element] =[...x[element],{...DatatoShow}]
            }
            else x[element][ind]["menu"].push({...DatatoShow});
        }
        EditGeneralsettingsMenu(x,()=>{
            setData({...x});
            Toast.success('Element edited successfully');
            CancelMenu();
        });
    }
    const onDeleteMenu = (i,key,j)=>{
        let x =data;
        if(j==-1){
           x[key] =(x[key]).filter((a,b)=>b!=i);
        }
        else {
            if((x[key][j]["menu"]).length===1) x[key][j]["menu"]=[];
            else 
              x[key][j]["menu"] =(x[key][i]["menu"]).filter((a,b)=>b!=i);
        }
        setdisable(true);
        EditGeneralsettingsMenu(x,()=>{
            setData({...x});
            setselectedItem(-1);
            Toast.success('Element deleted successfully');
            CancelMenu();
            
        })
    }
    
    useEffect(()=>{
        if(data===false)
            initData();
    });

    if(data === false || data === -1)
        return( <LoaderPage/>);
    return(
        <div className="d-flex flex-grow-1 container_details">
          
           <div className="flex-grow-1 element-1-cont" >
                <div className="">
                    <h3 className="card-title" style={{ padding: 0 }}>General settings</h3>
                    <h5 className="text-muted" style={{ padding: 0,opacity:.9 }}>Menu</h5><br/>
                </div>
                <br/>
                <h3 className="card-title" style={{ padding: 0 }}>Header menu</h3><br/>
                <div className="d-flex " style={{ flexDirection:"column" }}>
                   {(data.Header).map((e,i)=><MenuElement  index={i} onEdit={()=>{setSelectedData(i,'Header',i);}} onDelete={()=>{ onDeleteMenu(i,'Header',-1) }} data={e}  item={selectedItem!=i} />)}
                   <Button variant="contained" color="primary" onClick={()=>{setAdd('Header','-1');}}  style={{ color:"#fff",height:"40px",width:"130px" ,margin:"0 auto",borderRadius:"10px"}}>Add new</Button>
                </div>
                {
                    data["Footer"].map((a,b)=>{
                        return(
                            <div><br/>
                            <div className="">
                                <h3 className="card-title" style={{ padding: 0 }}>Footer menu</h3>
                                <h5 className="text-muted" style={{ padding: 0,opacity:.9 }}>Column - {b} </h5><br/>
                            </div>
                                <div className="d-flex " style={{ flexDirection:"column" }}>
                                    {(a.menu).map((e,i)=><MenuElement  index={i} onEdit={()=>{setSelectedData(b,'Footer',i);}} onDelete={()=>{ onDeleteMenu(i,'Footer',b) }} data={e}  item={selectedItem!=i} />)}
                                    <Button variant="contained" color="primary" onClick={()=>{setAdd('Footer',b);}}  style={{ color:"#fff",height:"40px",width:"130px" ,margin:"0 auto",borderRadius:"10px"}}>Add new</Button>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <div ref={detailsref}  style={{display: selectedItem==-1 && "none",flex: 1,width: "100%", minWidth: "300px", marginLeft: "10px"}}>
                <h3 className="card-title" style={{ padding: 0 }}>{selectedItem==-2 ?'Add new Item' :'Edit'}</h3>
                <br />
                {
                    selectedItem!=-1 ?
                    <MenuDetails 
                        data={DatatoShow}  disableandLoad = {disable}  edit={edit}  setedit={setedit} setdata ={setDatatoShow}
                        doneButtonLabel={"Save"} cancelButtonLaber={'Cancel'} editButtonLabel={'Edit'} deleteButtonLaber={'Delete'}
                        doneButtonClick={onAddMenu} cancelButtonClick={CancelMenu}  deleteButtonClick={onDeleteMenu}
                    />
                    : ''
                }
            </div>
        </div>
    )
}

const MenuElement = ({data,onEdit,onDelete,index})=>{
    
    return(
        <div>
            <div className="d-flex col-12   "  >
                <div className="d-flex flex-column flex-grow-1  " style={{ marginRight:"10px" }} >
                    <div className="d-flex flex-row flex-grow-1">
                        <div className="input-group mb-3 d-flex"  style={{ maxWidth:"150px" }} >
                            <input value={data.title}  type="text" className="form-control" placeholder="Title" />
                        </div>
                        <div className=" d-flex justify-content-start align-items-start position-relative" style={{ marginLeft:'10px',position:"relative",height:'fit-content',}} >
                            <input value={data.url}  type="text"  className="form-control" placeholder="Price" style={{ paddingLeft:'78px' }} />
                            <div className="url_clss">{`${'tabla.ma/ '}`} </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex  align-items-start" >
                    <Button onClick={onDelete}   className="add_edit_btn_icon" color="error"  variant="contained" startIcon={<AiOutlineDelete style={{ margin:"auto" }} size="20" color="#fff" />}/>
                    <Button onClick={onEdit}  className="add_edit_btn_icon" variant="contained" startIcon={<AiOutlineEdit style={{ margin:"auto" }} size="20" color="#fff" />}/>
                </div>
            </div>
        </div>
    );
}

const MenuDetails = (props)=>{
    const AddorEdit = ()=>{
        props.doneButtonClick();
    }
    const onChange = (key,e)=>{
       
        let x = {...props.data};
        x[key]=e;
        props.setdata(x);
    }
        return(
            <div>
                <div className="number_label"></div>
                <div className="d-flex col-12 flex-column "  >
                    <div className="d-flex flex-grow-1 "  >
                        <div className="d-flex  flex-grow-1">
                            <div className="input-group mb-3 d-flex" style={{ maxWidth:"150px" }} >
                                <input value={props.data.title} onChange={(e)=>{onChange("title",e.target.value)}}  type="text" className="form-control" placeholder="Title" />
                            </div>
                            <div className=" d-flex justify-content-start align-items-start" style={{ marginLeft:'10px',position:"relative",height:'fit-content',maxWidth:"300px"}} >
                                <input value={props.data.url}  type="text"  onChange={(e)=>{onChange("url",e.target.value)}} className="form-control" style={{ paddingLeft:'78px' }}/>
                                <div className="url_clss">{`${'tabla.ma/ '}`} </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex w-100 justify-content-center " style={{ height: "45px" }}>
                    {props?.cancelButtonLaber   && <LoadingButton variant="contained" color="secondary"  style={{ color:"#fff",flex:1,margin:"2px",borderRadius:"10px"}}  className="class_button"    disabled={props.disableandLoad} loading={props.disableandLoad==2}onClick={()=>{props.cancelButtonClick()}} >{props.disableandLoad==2?"":props.cancelButtonLaber}</LoadingButton>}
                    {props?.doneButtonLabel     && <LoadingButton variant="contained" style={{ color:"#fff",flex:1,margin:"2px",borderRadius:"10px"}}  className="class_button" disabled={props.disableandLoad} loading={props.disableandLoad==1} onClick={()=>{AddorEdit()}}>{props.disableandLoad==1?"":props.doneButtonLabel} </LoadingButton>}
                </div>
            </div>
        )
}


export default Menu;