import React from "react";
import "../../styles/client/Client.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NumbersOf, TitleOf } from "../secondPart/SecondPart-elements";

export default function BandElement(props)
{
    return (
        <div style={{color: "white"}}  className={`flex-container ${props.classNameIconContainer}`}>
            <div className="AdjustCenter">
              {props.icon}
            </div>
            <div className="AdjustCenter">
              <NumbersOf className="fs-vw4"> {props.value} </NumbersOf>
              <TitleOf className="fs-vw2"> {props.label} </TitleOf>
            </div>
        </div>
    );
}