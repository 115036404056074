import { getFAQPage} from "../../../services/restaurant-admin/admin-dashboard";
import  React,{ useState, useEffect } from "react";
import LoaderPage from "../../../components/Shared/LoaderPage";
import Accordion from 'react-bootstrap/Accordion';
import "../../../styles/Observer.css"

const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
        const nvE  =  document.querySelector('#'+entry.target.id+"_nv");
        
    if (entry.isIntersecting) {
            entry.target.classList.add('active_ele');
            nvE.classList.add('active_ele_nv');
            
    }
    else {
        entry.target.classList.remove('active_ele');
        nvE.classList.remove('active_ele_nv');
    }
    });
});
const onObserveE =  (i)=>{
     observer.observe(i);
}
const FAQ = ()=>{

    const [data,setData] = useState(false);
    const [active,setActive] = useState('element_0');

    const initData = ()=>{
        setData(-1);
        getFAQPage(setData);
    }

    useEffect(()=>{
        if(data===false)
            initData();
    });
    const scrollEl = (i)=>{
        setTimeout(() => {
            let a = document.querySelector('#element_'+i);
            a.scrollIntoView();
        },100);

    }

    if(data === false || data === -1)
        return( <LoaderPage/>)
    return(
        <div>
            <div className="title_cont">
                <div className="title_1e">FAQ</div>
                <div className="title_2e">Frequently Asked Questions</div>
            </div>
            <div className="nv_elemnts_cont">
                <div className="nav_elem">
                    {data.map((e,i)=><div onClick={()=>{scrollEl(i)}} id={`element_${i}_nv`} className="nv_elm ">{e.title} <div className="border__bttm" /></div>)}
                </div>
                <div id="element_cont">
                    {data.map((e,i)=><div className="cot_ele_f"><div id={`element_${i}`} className="element_fq" ></div><FAQElement data={e.data} title={e.title} index={i} /></div>)}
                </div>
            </div>
        </div>
    );
}


const FAQElement = ({title,data,index})=>{
    useEffect(()=>{onObserveE(document.getElementById(`element_${index}`))},[]);
    
    const getIndex = (i)=>{return (i>9)?i:`0${i}`}
    return(
        <div  >
            <div  className="el-tit">{getIndex(index+1)}. {title}</div>
            <br/>
            <div  className="element__h">
            <Accordion >
                {data.map((e,i)=> <QAElement i={i} e={e} index={index}  />)}
            </Accordion>
            </div>
        </div>
    );
}


const QAElement = ({e,i})=>{

    return(
        <Accordion.Item eventKey={i}>
            <Accordion.Header>{e.question}</Accordion.Header>
            <Accordion.Body>{e.answer}
            </Accordion.Body>
        </Accordion.Item>
    );
}




export default FAQ;