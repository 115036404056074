import React, { useState } from "react";
import "../../../styles/client/Client.css";
import "../../../styles/client/ClientLogin.css";
import { faFacebook, faGoogle } from "@fortawesome/free-brands-svg-icons";
import SocialButton from "../../../components/client/socialButton";
import Separator from "../../../components/client/separator";
import { StandardInput } from "../../../components/client/input";
import {
  validateGeneral,
  validateConfirmationPassword,
  validateEmail,
} from "../../../validation/client";
import {
  NAME_REGXR,
  EMAIL_REGXR,
  PASSWORD_VALIDATION,
} from "../../../validation/regexr";

import { NavLink } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { REGISTER_MUTATION } from "../../../services/mutations/authMutations";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import LoaderPage from "../../../components/Shared/LoaderPage";
import { useNavigate, useLocation } from "react-router-dom";
import TokenManger from "../../../api/TokenManger";

const succussRedirectPath = "/";
export default function Register() {
  const navigate = useNavigate();
  const location = useLocation();
  const [nonFieldErrors, setNonFieldErrors] = useState([]);
  const {
    register,
    handleSubmit,
    setError,
    watch,
    formState: { errors },
  } = useForm();
  const watchedPassword = watch("password1");
  const handelOnCompleted = ({ register }) => {
    // Todo :   Use  refreshToken token  in the header request  and protect private routes
    const { refreshToken, token, success, errors } = register;
    // https://www.googleapis.com/geolocation/v1/geolocate?key=%GOOGLE_LOCATION_SERVICE_API_KEY%
    if (errors) {
      if ("nonFieldErrors" in errors) {
        setNonFieldErrors(errors["nonFieldErrors"]);
      }
      delete errors.nonFieldErrors;
      for (const [key, value] of Object.entries(errors)) {
        value.map((item) => {
          setError(key, item);
        });
      }
    } else if (success) {
      setNonFieldErrors([]);
      const { setTokens } = TokenManger();
      setTokens(register);

      if (location?.state?.next) {
        navigate("/reserving", {
          state: {
            ...location.state,
          },
        });
      } else {
        // navigate(succussRedirectPath)};
        window.location.href = succussRedirectPath
      }
      }
    };
    const [registerUser, { data, loading, error }] = useMutation(
      REGISTER_MUTATION,
      {
        onCompleted: handelOnCompleted,
      }
    );
    const onSubmit = (data) => {
      registerUser({ variables: data });
    };

    if (error) return `Submission error! ${error.message}`;
    console.log(" data, loading", { data, loading, error });
    return (
      <React.Fragment>
        <div className="row m-4 login">
          <div className="col-lg-6 p-4">
            <div className="row logIN">
              <div className="col-12 max-height">
                <span className="title-1">Create new account</span>
              </div>
              <div className="col-12 max-height mb-3">
                <span className="textLow-1">
                  It is a long established fact that a reader will be distracted
                  by the readable content.
                </span>
              </div>
              <div className="SocialLogin">
                <div className="col-6 startText">
                  <SocialButton
                    icon={faFacebook}
                    text="Facebook"
                    callback={() => {
                      console.log("clicked");
                    }}
                  />
                </div>
                <div className="col-6 startText">
                  <SocialButton
                    icon={faGoogle}
                    text="Google"
                    callback={() => {
                      console.log("clicked");
                    }}
                  />
                </div>
              </div>
              <div className="col-12 max-height mb-2 mt-2 borderOR">
                <Separator text="Or register with" />
              </div>

              {loading ? (
                <LoaderPage />
              ) : (
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="text-danger">
                    {nonFieldErrors.map((error, i) => (
                      <p key={i}>{error.message}</p>
                    ))}
                  </div>
                  <div className="row col-12 max-height mx-auto">
                    <div className="col-6 pl-0">
                      <StandardInput
                        id="firstName"
                        type="text"
                        placeholder="Firstname"
                        {...register("firstName", { pattern: NAME_REGXR })}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="firstName"
                        render={({ message }) => (
                          <p className="text-danger">{message}</p>
                        )}
                      />
                    </div>
                    <div className="col-6 pr-0">
                      <StandardInput
                        id="lastName"
                        type="text"
                        placeholder="Lastname"
                        className="input-client p-3 inCont"
                        {...register("lastName", { pattern: NAME_REGXR })}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="lastName"
                        render={({ message }) => (
                          <p className="text-danger">{message}</p>
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-12 max-height ml-4 mr-4">
                    <StandardInput
                      id="email"
                      type="email"
                      placeholder="Email"
                      {...register("email", { pattern: EMAIL_REGXR })}
                      className="input-client p-3 inCont"
                    />
                    <ErrorMessage
                      errors={errors}
                      name="email"
                      render={({ message }) => (
                        <p className="text-danger">{message}</p>
                      )}
                    />
                  </div>
                  <div className="col-12 max-height ml-4 mr-4">
                    <StandardInput
                      id="password"
                      type="password"
                      placeholder="Password"
                      {...register("password1", PASSWORD_VALIDATION)}
                      className="input-client p-3 inCont"
                    />
                    <ErrorMessage
                      errors={errors}
                      name="password1"
                      render={({ message }) => (
                        <p className="text-danger">{message}</p>
                      )}
                    />
                  </div>
                  <div className="col-12 max-height ml-4 mr-4">
                    <StandardInput
                      id="confirmedpassword"
                      type="password"
                      placeholder="Password Confirmation"
                      className="input-client p-3 inCont"
                      {...register("password2", {
                        PASSWORD_VALIDATION,
                        validate: (value) =>
                          value === watchedPassword ||
                          "Password Confirmation is not Equal to password",
                      })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="password2"
                      render={({ message }) => (
                        <p className="text-danger">{message}</p>
                      )}
                    />
                  </div>
                  <div className="col-12 forgot">
                    <span>Already have an account?</span>
                    <NavLink to="/login" className="m-2 navlink green">
                      Sign In
                    </NavLink>
                  </div>
                  <div className="col-12 buttonSubmit">
                    <button
                      style={{ width: "auto" }}
                      className={`btn btn-success btn-book mx-auto  `}
                    >
                      Sign up
                    </button>
                  </div>
                  <br /> <br />
                </form>
              )}
            </div>
          </div>
          <div className="col-lg-6">
            <img
              className="svgSize onlyDesktopSVG"
              src="./svg/Register.svg"
              alt="SVG"
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
