
import {LinkActiveOrNot,LinkswithChildren} from "./LinkActiveOrNot";
import "../../styles/LeftNavBar.css";
import {AiOutlineClose} from "react-icons/ai";
import { useLeftNavBarClose } from "../../hooks/LeftNavBar";
import { LogoTabla } from "./Logo";

const LeftNavbar = ({data,url,logo})=>{
    const CloseNVInMobile = ()=>{useLeftNavBarClose();}
    return(
        <div className="left_nav_bar card" id="lft_nv_ad" >
            <div className="rmv_mobile_lftnv" onClick={()=>{CloseNVInMobile()}}>
                <AiOutlineClose style={{ cursor:"pointer" }} color="#000" size="18"/>
            </div>
            {logo===true?<div className="logo_lfnvb"><LogoTabla/></div>:""}
            <div></div>
            {
            data.map(e=>{
                if(e?.children) 
                    return(<LinkswithChildren   onClick={()=>{CloseNVInMobile()}} element={e} urlcontainer={url}/>)
                return(
                    <div >
                        <LinkActiveOrNot  onClick={()=>{CloseNVInMobile()}} to={e.path} urlcontainer={url} >
                            <div className="icon">{e.icon} </div>
                            <div className="title_">{e.title}</div>
                        </LinkActiveOrNot>
                    </div>
                );
            })
            }
        </div>
    );
}


export default LeftNavbar;