import { useState, useEffect, useRef } from "react";
import LoaderPage from "../../../../components/Shared/LoaderPage";
import { getAvailableUsers,BanAvailableUsers} from "../../../../services/restaurant-admin/admin-dashboard";
import  Toast from "../../../../services/Toast";
import { Button, TextField, OutlinedInput, Avatar } from "@mui/material";
import { BsChevronRight } from "react-icons/bs";
import LoadingButton from "@mui/lab/LoadingButton";

const AvailableUsers =()=>{

    const [data,setData] = useState(false);
    const [selectedItem,setselectedItem] = useState(-1);
    const [DatatoShow,setDatatoShow] = useState([]);
    const [disable,setdisable] = useState(false);
    const detailsref = useRef(null);
    const initData = ()=>{
        setData(-1);
        getAvailableUsers(setData);
    }
   
    
    const setSelectedData = (i)=>{
        setselectedItem(i);
        setDatatoShow(data[i]);
        if(selectedItem!=-1)
            detailsref.current.scrollIntoView(true);
        else
            setTimeout(() => {
                detailsref.current.scrollIntoView(true);
            }, 200);
    }
    
    const onBanAvailableUsers = ()=>{
        setdisable(1);
        BanAvailableUsers(data[selectedItem],()=>{
            Toast.success("It was deleted successfully");
            let x = selectedItem;
            setselectedItem(-1);
            setdisable(false);
            setData(data.filter((e,i)=>i!=x));
            setTimeout(() => { document.getElementsByTagName("body")[0].scrollIntoView(true); }, 200);        })
    }
    const CancelAvailableUsers = ()=>{
        setselectedItem(-1);
        setdisable(false);
    }
    
    useEffect(()=>{
        if(data===false)
            initData();
    });

    if(data === false || data === -1)
        return( <LoaderPage/>)
    
        
    return(
        <div className="d-flex flex-grow-1 container_details">
           {data.length>0? 
           <div className="flex-grow-1 element-1-cont" >
                <div className="d-flex justify-content-between align-items-center">
                    <h3 className="card-title" style={{ padding: 0 }}>Users</h3>
                </div>
                <br/>
                <div className="d-flex " style={{ flexDirection:"column" }}>
                   {data.map((e,i)=><AvailableUsersElement onClick={()=>{setSelectedData(i);}} data={e}  item={selectedItem!=i} />)}
                </div>
            </div>
            :
            <div  className="d-flex justify-content-center align-items-center card flex-grow-1" style={{ borderRadius: "10px",    minHeight: "50vh" }}>
                <h3>There is no data </h3>
                <br/>
            </div>
            }
            <div ref={detailsref}  style={{display: selectedItem==-1 && "none",flex: 1,width: "100%", minWidth: "300px", marginLeft: "10px"}}>
                <h3 className="card-title" style={{ padding: 0 }}>{'Details'}</h3>
                <br />
                {
                    selectedItem!=-1 ?
                    <AvailableUsersDetails 
                        data={DatatoShow}  disableandLoad = {disable}   setdata ={setDatatoShow}
                        deleteButtonLaber={'Cancel'} donButtonLabel={'Ban'} 
                        deleteButtonClick={CancelAvailableUsers}  doneButtonClick={()=>{onBanAvailableUsers()}}
                    />
                    : ''
                }
            </div>
        </div>
    )
}

 
const AvailableUsersElement = ({data,onClick,item})=>{
  
    return(
    <div className="d-flex col-12 card order_container_element"  style={{ cursor:"pointer" }} onClick={()=>{onClick()}}>
        <div className="d-flex flex-grow-1 " >
            <Avatar  className="avatar_img" src={data.image} variant="rounded"/>
            <div className="d-flex justify-content-center flex-column">
                <h3 className="card-title" style={{ padding: 0,margin:0 }}>{data.name}</h3>
                <div className="d-flex  align-items-lg-stretch"  style={{ flexDirection:"column",marginTop:"5px" }}>
                    <p className="text-muted"><small>{data.email}</small></p>
                </div>
            </div>
        </div>
        <div className="d-flex align-items-center" >
            <Button disabled={item} className="button_right_icon" variant="contained" startIcon={<BsChevronRight size="25" color="#fff" />}/>
        </div>
    </div>
    );
}

const AvailableUsersDetails = (props)=>{
 
    return(
        <div className="d-flex" style={{ flexDirection:"column", marginRight:"20px"}} >
            <div className="d-flex align-items-center justify-content-center flex-column" >
            <Avatar style={{ margin:0 }} className="avatar_img" src={props.data.image} variant="rounded"/>
            <div className="d-flex justify-content-center flex-column text-center">
                <br/>
                <h3 className="card-title" style={{ padding: 0,margin:0 }}>{props.data.name}</h3>
                <div className="d-flex  align-items-lg-stretch"  style={{ flexDirection:"column",marginTop:"5px" }}>
                    <p className="text-muted"><small>{props.data.email}</small></p>
                </div>
                <br/>
            </div>
            </div>
            <div className="d-flex w-100 justify-content-center " style={{ height: "45px" }}>
                    {<LoadingButton variant="contained" color="secondary"  style={{ color:"#fff",flex:1,margin:"2px",borderRadius:"10px"}}  className="class_button"    disabled={props.disableandLoad} loading={props.disableandLoad==2}onClick={()=>{props.deleteButtonClick()}} >{props.disableandLoad==2?"":props.deleteButtonLaber}</LoadingButton>}
                    {<LoadingButton variant="contained"color="error" style={{ color:"#fff",flex:1,margin:"2px",borderRadius:"10px"}}  className="class_button" disabled={props.disableandLoad} loading={props.disableandLoad==1} onClick={()=>{props.doneButtonClick();}}>{props.disableandLoad==1?"":props.donButtonLabel} </LoadingButton>}
            </div>
        </div>
    );
}

export default AvailableUsers;