import zIndex from '@mui/material/styles/zIndex';
import { margin, padding, width } from '@mui/system';
import styleClass from './style.module.css';
import './style.module.css';
import React, { useState, useRef } from 'react';
import { useDetectOutsideClick, useOnClickOutside } from '../../hooks/useDetectOutsideClick';
import {
    useFloating, flip, shift, offset, useInteractions,
    useHover, useDismiss,
    useFocus, useRole
} from '@floating-ui/react-dom-interactions';
const Option = ({ option, onSelect, selectedValue }) => {
    const isSelected = selectedValue == option?.value ? true : false
    return <div className={isSelected ? styleClass.selectedItem : styleClass.selectItem} onClick={() => onSelect(option.value)}>
        <span>{option.label}</span>

    </div>
}
const index = ({ isOpen = null, onSelect, options, placeholder, IconBefore, IconAfter, value, onClick, style }) => {

    const dropdownRef = useRef(null);
    const [isActive, setIsActive] = useState(isOpen || false);

    const [intrValue, setIntrValue] = useState(value)
    useOnClickOutside(dropdownRef, () => setIsActive(false));

    const handleOnSelected = (value) => {
        setIntrValue(value)
        if (onSelect) {
            onSelect(value)
        }
        setIsActive(false)
    }
    const isOpenControled = isOpen !== null
    const showDropDown = isOpenControled ? isOpen : isActive
    console.log({ showDropDown, isOpenControled, isOpen, isActive })
    const onClickHandler = () => {
        setIsActive(true)
        if (onClick) {
            onClick()
        }

    }

    return (
        <div className={styleClass.styleContiner} style={style}  >
            <div onClick={onClickHandler}>
                {IconBefore}
            </div>
            <div className={styleClass.inputContiner}>
                <input className={styleClass.styleInput}
                    value={intrValue} placeholder={placeholder} onClick={onClickHandler} readOnly />
            </div>
            <div ref={dropdownRef}
                className={[showDropDown ? styleClass.active : styleClass.menu, styleClass.styleDropDown].join(' ')}
            >
                {options.map((option, i) => <Option option={option} key={i} onSelect={handleOnSelected} selectedValue={intrValue} />)}
            </div>

            {IconAfter}
        </div >
    );
};

export default index;
const OptionTable = ({ option, onSelect, selectedValue }) => {
    const isSelected = selectedValue == option?.value ? true : false
    return <div className={isSelected ? styleClass.selectedItem : styleClass.selectItem} onClick={() => onSelect(option.value)}>
        <span>
            {option.label}</span>

    </div>
}
export const SelectTable = ({ isOpen = null, onSelect, options, placeholder, IconBefore, IconAfter, value, onClick, style }) => {

    const dropdownRef = useRef(null);
    const [isActive, setIsActive] = useState(isOpen || false);

    const [intrValue, setIntrValue] = useState(value)
    // useOnClickOutside(dropdownRef, () => setIsActive(false));

    const handleOnSelected = (value) => {
        setIntrValue(value)
        if (onSelect) {
            onSelect(value)
        }
        setIsActive(false)
    }
    const isOpenControled = isOpen !== null
    const showDropDown = isOpenControled ? isOpen : isActive
    const { x, y, reference, floating, strategy, context } = useFloating({
        open,
        onOpenChange: setIsActive,
        placement: 'bottom',
        middleware: [offset(20), flip(), shift(),]
    });
    const hover = useHover(context, { enabled: false });
    const focus = useFocus(context, { enabled: false });
    const dismiss = useDismiss(context);
    const role = useRole(context);
    const { getReferenceProps, getFloatingProps, getItemProps } =
        useInteractions([hover, focus, role, dismiss])
    console.log({ showDropDown, isOpenControled, isOpen, isActive })
    const onClickHandler = () => {
        setIsActive(true)
        if (onClick) {
            onClick()
        }

    }

    return (
        <div className={styleClass.styleContiner} style={style} ref={reference} {...getReferenceProps()} >
            <div onClick={onClickHandler}>
                {IconBefore}
            </div>
            <div className={styleClass.inputContiner}   >
                <input className={styleClass.styleInput}
                    value={intrValue} placeholder={placeholder} onClick={onClickHandler} readOnly />
            </div>
            <div ref={floating}
                {...getFloatingProps()}
                className={[showDropDown ? styleClass.active : styleClass.menu, styleClass.styleDropDown].join(' ')}
                style={{
                    position: strategy,
                    top: y ?? 0,
                    left: x ?? 0,

                }}
            >

                {options.map((option, i) => <OptionTable option={option} key={i} onSelect={handleOnSelected} selectedValue={intrValue} />)}
            </div>

            {IconAfter}
        </div >
    );
};
